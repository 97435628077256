import React from "react";
import { Col } from "react-bootstrap";
const NoTransactionView = ()=>{
    return(
        <>
        <div className="empty-data-view">
            <div className="text-center">
            <img src="/images/no-transactions.png" width={100}></img>
            <p className="mt-3">There are no transactions to show here</p>
            </div>
            
        </div>
        </>
    )
}
export default NoTransactionView