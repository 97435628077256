import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signInGoogle } from "../../actions/auth-action";

const GoogleLoginComponent = (props) => {
  const { clientId,signInGoogle,appSumoCode,referralCode } = props;
  const handleCredentialResponse = (response) => {
    loginGoogle(response)
  }
  const loginGoogle = (res) => {
    const { credential } = res;
    signInGoogle(credential,referralCode,appSumoCode?{app_sumo_code:appSumoCode}:false);
  };
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById("g-signin"),
      { theme: "outline", size: "large" ,shape:"circle",type:"icon"}  // customization attributes
    );
  }, []);
  

  return (
    <>

    <div id="g-signin"> </div>
    </>
  );
};
export default connect(null, { signInGoogle })(GoogleLoginComponent);
