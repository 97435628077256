import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import "./SocialPost.scss";
import { connect, useSelector } from "react-redux";
import {
  postAffiliateSocialPost,
  getAffiliateSocialPosts,
} from "../../../actions/affiliate-action";
import useInput from "../../hooks/useInput";
import { toast } from "react-toastify";
import { isUrl } from "../../../utils/Validation";
import { capitalize } from "../../../utils/Utils";
import { PostStatus } from "../../../Const";

const SocialPost = (props) => {
  const {
    bindShowSocialPost,
    setLoading,
    postAffiliateSocialPost,
    getAffiliateSocialPosts,
  } = props;
  const [url, bindUrl] = useInput("");
  const socialPosts = useSelector((state) => state.affiliate.socialPosts);
  useEffect(() => {
    getAffiliateSocialPosts();
  }, []);

  const clickSubmit = () => {
    if (url.length == 0 || !isUrl(url))
      return toast.error("Please input valid URL");
    setLoading(true);
    postAffiliateSocialPost(url)
      .then(() => {
        setLoading(false);
        toast.success("Submitted");
        getAffiliateSocialPosts();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const items = socialPosts.map((post, index) => (
    <tr key={index}>
      <td>{post.url}</td>
      <td>{post.status == PostStatus.verified ? "50" : "0"}</td>
      <td>{capitalize(post.status)}</td>
    </tr>
  ));
  return (
    <Modal {...bindShowSocialPost} size="lg" className="social-post">
      <Modal.Header closeButton>
        <Modal.Title>Social Posts</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 30 }}>
        <div>
          <label>Input post URL</label>
          <input {...bindUrl} />
          <button className="btn" onClick={clickSubmit}>
            Submit
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>URL</th>
              <th>Points</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, {
  postAffiliateSocialPost,
  getAffiliateSocialPosts,
})(SocialPost);
