import React, { useState } from "react";

const usePagination = (initStart, initItems) => {
  const [startItem, setStartItem] = useState(initStart || 0);
  const [itemsPerPage, setItemsPerPage] = useState(initItems || 5);
  const bindPagination = {
    startItem: startItem,
    onChange: setStartItem,
    itemsCountPerPage: itemsPerPage,
  };
  const range = [startItem, startItem + itemsPerPage];
  const page = Math.floor(startItem / itemsPerPage);
  return [range, bindPagination, setItemsPerPage, page];
};

export default usePagination;
