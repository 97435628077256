import React from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./Navbar.scss";
import { Row, Col, Navbar, Badge } from "react-bootstrap";
import { UserRole } from "../../Const";
import { useTranslation } from 'react-i18next'
import { Trans, withTranslation } from 'react-i18next';
import TopMenu from "./TopMenu";
import SideMenu from "./SideMenu";
import SettingsDropDown from "./SettingsDropDown";
import HelpDropDown from "./HelpDropDown";
import ExchangeDropDown from "./ExchangeDropDown";
import CustomDropDown from "../../utils/CustomDropDown";
import OffCanvasMenu from "./OffCanvasMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkSub, isMaster, isPremiumOrPro, isPro } from "../../utils/SubUtils";

const Header = (props) => {
  const { t } = useTranslation();
  const history = useHistory()
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const { subPremium, subTier } = checkSub(subscription);
  const isPaid = isPro(subscription) || isMaster(subscription);
  return (
    <>
        
        <div className="col-6">
			<div className="mr-inner-right">
				<div className="mr-title">
                    {/* <OffCanvasMenu /> */}
					{props.showBack ?<h4><a className="heading-back" href="#" onClick={()=>history.goBack()}> <i className="fa-solid fa-angle-left"></i> Back</a></h4>:<></>}
				</div>
				<div className="mr-dropdown">
						<ExchangeDropDown/>
				</div>
			</div>
		</div>
		<div className="col-6">
			<div className="mr-user">
				<ul>
					<li>
						<HelpDropDown></HelpDropDown>
					</li>
					{/* {isPaid && 
					<li>

						<a href="https://community.cryptohero.ai/auth/join/An3zBfaUTitEpxFAupOe1" target="_blank" className="mr-user-icon" id="mru-icon3"><i className="fa-solid fa-users"></i></a>
						
					</li>
					} */}
					<li>
                      	<SettingsDropDown></SettingsDropDown>
					</li>
				</ul>
			</div>
		</div>
    </>
  );
};

export default withTranslation(null)(Header);
