import React from "react";
import { Col, Form,Button, Row } from "react-bootstrap";
import { connect,useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateBotTemplate } from "../../../actions/bot-template-action";
import { getOwnTemplates, getTemplates } from "../../../actions/marketplace-action";
import { 
    BOT_SUBSCRIPTION_MONTHLY,
    BOT_SUBSCRIPTION_QUARTERLY,
    BOT_SUBSCRIPTION_HALFYEARLY ,
    BOT_SUBSCRIPTION_ANNUAL,
    BOT_SUBSCRIPTION_ONETIME,
    BOT_TEMPLATE_DESCRIPTION,
    BOT_TEMPLATE_TITLE
} from "../../../actions/types";
import { adaptTemplateEdit } from "../../bots/new-bot/bot-adapter/template-edit-adapter";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { restrictAlphabets } from "../../../utils/Utils";


const EditBotTemplateView = (props)=>{
    
    const {onHide,updateBotTemplate,template,getOwnTemplates,tab} = props;
    const monthlyRental =  template.bot?(template.bot.frequency==5?'19.99':'9.99'):""
    const quarterlyRental = template.bot?(template.bot.frequency==5?'59.99':'29.99'):""
    const yearlyRental = template.bot?(template.bot.frequency==5?'199.99':'99.99'):""
    const [setLoading, bindLoading] = useLoading(false);
    const dispatch = useDispatch()
    const botTitleChanged = (val)=>{
        dispatch({
            type: BOT_TEMPLATE_TITLE,
            payload: val,
        });
    }
    const botDescriptionChanged = (val)=>{
        dispatch({
            type: BOT_TEMPLATE_DESCRIPTION,
            payload: val,
        });
    }
    const botSubscriptionFeeChanged = (val,type)=>{
        dispatch({
            type: type,
            payload: val,
        });
    }
    const clickUpdateTemplate = ()=>{
        setLoading(true)
        updateBotTemplate().then(() => {
            setLoading(false);
            getOwnTemplates(1,8,tab)
            toast.success('Bot updated successfully');
            onHide()
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          });
    }
    return (
        <>
            <Loading {...bindLoading}>
            <Form>
                <Form.Control type="text" placeholder="Bot Title"  className="mt-3 input-text" onChange={(e)=>botTitleChanged(e.target.value)} value={template.title}/>
                <Form.Control as="textarea" rows={3} placeholder="Bot Description" className="mt-3 input-text-area" onChange={(e)=>botDescriptionChanged(e.target.value)} value={template.description}/>
                <Form.Group className="mb-3" >
                    <Form.Label>Monthly Subscription Fee: US$</Form.Label>
                    <Form.Control type="number" className="input-text" placeholder={`(Minimum is US$${monthlyRental})`} onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_MONTHLY)} value={parseFloat(template.monthlyFee)} onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Quarterly Subscription Fee: US$</Form.Label>
                    <Form.Control type="number" className="input-text" placeholder={`(Minimum is US$${quarterlyRental})`} onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_QUARTERLY)} value={parseFloat(template.quarterlyFee)} onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Annual Subscription Fee: US$</Form.Label>
                    <Form.Control type="number" className="input-text" placeholder={`(Minimum is US$${yearlyRental})`} onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_ANNUAL)} value={parseFloat(template.annualFee)} onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}/>
                </Form.Group>
                <Row>
                <Col xs={4}>
                <Button
                    variant="success"
                    className="btn blue-btn"
                    block={true}
                    onClick={clickUpdateTemplate}
                >
                    Save
                </Button>
                </Col>
                <Col xs={4}>
                <Button
                    variant=""
                    className="btn"
                    block={true}
                    onClick={onHide}
                >
                    Cancel
                </Button>
                </Col>
                </Row>
                
            </Form>
            </Loading>
        </>
    );
}
export default connect (null,{updateBotTemplate,getOwnTemplates})(EditBotTemplateView)