import React,{useCallback,useState} from "react"
import {useDropzone} from 'react-dropzone'
import { SET_PREVIEW_UPLOAD_IMAGE } from "../../../actions/types"
import { connect, useSelector, useDispatch } from "react-redux";
import 'react-image-crop/src/ReactCrop.scss'
import 'pintura/pintura.css';
import { openDefaultEditor } from 'pintura';

import "./editor.scss"
import { toast } from "react-toastify";
import { setImageSelect } from "../../../actions/image-upload-action";
const BotImageUpload = (props)=>{
    const {setImageSelect} = props;
    const dispatch = useDispatch()
    
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            if(file.size>201000){
                toast.error("Please upload files less than 200Kb!")
                return false
            }
            const imageFile = file;
            const imageState = {};
            const editor = openDefaultEditor({
                src: imageFile,
                imageCropAspectRatio: 1 / 1,
                imageState,
                //markupEditorTextInputMode:"modal"
            });
        
            editor.on('close', () => {
                // the user cancelled editing the image
            });
        
            editor.on('process', ({ dest, imageState }) => {
                Object.assign(dest, {
                    pintura: { file: imageFile, data: imageState },
                });
                setUploadPreview(URL.createObjectURL(dest))
                dispatch({
                    type: SET_PREVIEW_UPLOAD_IMAGE,
                    payload: dest,
                });
                setImageSelect(dest)
                //done(dest);
            });
            
        })
      }, [])
    const [uploadPreview,setUploadPreview] = useState("/images/upload-icon.png");
    const config = {onDrop,accept: 'image/jpeg, image/png',maxFiles:1}
    const {getRootProps, getInputProps, isDragActive} = useDropzone(config)
    return (
        <>
        <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                <p>Drop the files here ...</p> :<>
                <img src={uploadPreview} className="drag-upload-icon"></img>
                <p>Drag and drop an image, or click to upload</p></>
            }
        </div>
        </>
        
    )
}
export default connect(null,{setImageSelect}) (BotImageUpload);