import "./PaperWalletPanel.scss";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { getPaperWallet } from "../../actions/setting-action";
import PaperCrossTab from "./PaperCrossTab";
import PaperDepositWithdraw from "./PaperDepositWithdraw";
import PaperIsolatedTab from "./PaperIsolatedTab";
import PaperSpotTab from "./PaperSpotTab";
import PaperTransferModal from "./PaperTransferModal";
import { connect, useSelector } from "react-redux";

import { AccountName, AccountType } from "../../Const";
import { useTranslation } from "react-i18next";
const tabTitles = [AccountName.spot, AccountName.cross, AccountName.isolated];
const tabTypes = [AccountType.spot, AccountType.cross, AccountType.isolated];
const PaperWalletPanel = (props) => {
  const {t} = useTranslation()
  const { getPaperWallet } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [showDeposit, setShowDeposit] = useState(false);
  const [isDeposit, setIsDeposit] = useState(true);
  const [showTransfer, setShowTransfer] = useState(false);
  const {
    coins,
    crossCoins,
    pairs,
    risk,
    totalSpot,
    totalCross,
    totalEquityCross,
    totalDebtCross,
    totalIsolated,
    totalEquityIsolated,
    totalDebtIsolated,
  } = useSelector((state) => state.settings.paperWallet) || {};

  const total = (totalSpot || 0) + (totalCross || 0) + (totalIsolated || 0);
  useEffect(() => {
    tabTypes.forEach((el) => {
      getPaperWallet(el).then(console.log);
    });
  }, []);

  useEffect(() => {
    getPaperWallet(tabTypes[activeTab]).then(console.log);
  }, [activeTab]);

  const tabHeaders = tabTitles.map((item, index) => {
    return (
      <li className="skltbs-tab-item" key={index}>
       <button className={(index == activeTab ? "skltbs-tab skltbs-active" : "skltbs-tab" )} onClick={() => setActiveTab(index)}>{item}</button>
      </li>
    );
  });
  const walletTabs = [
    <PaperSpotTab coins={coins} totalSpot={totalSpot} />,
    <PaperCrossTab
      coins={crossCoins}
      totalCross={totalCross}
      totalEquityCross={totalEquityCross}
      totalDebtCross={totalDebtCross}
      risks={risk}
    />,
    <PaperIsolatedTab
      pairs={pairs}
      totalIsolated={totalIsolated}
      totalEquityIsolated={totalEquityIsolated}
      totalDebtIsolated={totalDebtIsolated}
    />,
  ];
  const clickDeposit = () => {
    setShowDeposit(true);
    setIsDeposit(true);
  };
  const clickWithdraw = () => {
    setShowDeposit(true);
    setIsDeposit(false);
  };
  return (
    <>
    <div className="col-sm-10 setting-right-col">
      <div className="settings-PaperWallet-area">
          <div className="settings-PaperWallet-heading">
            <div className="row spw-heading-row align-items-center">
                    <div className="col-xl-6">
                      <div className="spw-headong-left">
                        <button><img src="/images/icon-crash.svg" alt=""/></button>
                        <label>
                          <span>Total</span>
                          <h6>{(total || 0).toFixed(6)} BTC</h6>
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="spw-headong-right">
                        <button onClick={clickDeposit}>Add</button>
                        <button onClick={clickWithdraw}>Reduce</button>
                        {/* <button onClick={setShowTransfer}>Transfer</button> */}
                      </div>
                    </div>
              </div>
          </div>
          <div className="paperwallet-table-area position-tab-area">
            <ul className="skltbs-tab-group">
              {tabHeaders}
            </ul>
            {walletTabs[activeTab]}
          </div>
         
        </div>
        </div>
       
      
      <PaperDepositWithdraw
        show={showDeposit}
        onHide={() => setShowDeposit(false)}
        isDeposit={isDeposit}
        setIsDeposit={setIsDeposit}
        t={t}
      />
      {/* <PaperTransferModal show={showTransfer} onHide={setShowTransfer} /> */}
   
    </>
    
  );
};

export default connect(null, { getPaperWallet })(PaperWalletPanel);
