import React from "react";
import { useDispatch } from "react-redux";
import { UPDATE_VARIABLE_TSL } from "../../../../actions/create-bot-type";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isLong } from "../../../../utils/Utils";

const VariableTSL = (props) => {
  const { newBot } = props;
  const tsl = newBot.variableTSL;
  const long  = isLong(newBot.strategy)
  const dispatch = useDispatch();
  const updateTSL = (list) => {
    dispatch({
      type: UPDATE_VARIABLE_TSL,
      payload: list,
    });
  };

  const addVariabletSL = () => {
    updateTSL([...tsl, { triggerAt: 2, stopAt: 1 }]);
  };
  const tslItems = tsl.map((item, id) => {
    const changeStopAt = (val) => {
      const tempTsl = [...tsl];
      const trailing = tempTsl[id];
      trailing.stopAt = val;
      updateTSL(tempTsl);
    };

    const changeTriggerAt = (val) => {
      const tempTsl = [...tsl];
      const trailing = tempTsl[id];
      trailing.triggerAt = val;
      updateTSL(tempTsl);
    };

    const deleteTSL = () => {
      updateTSL(tsl.filter((_, index) => index !== id));
    };
    return (<>
      
      <div className="trailing-item d-flex align-items-center above-buy-price2 mb-2">
       
        <div>{id + 1}. Set{" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.stopAt}
                  onChange={(e) => changeStopAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text small"
                ></Form.Control>
            </label>
          </div>
        
        <div>{long?"below":"above"} {" "} current price when price hits{" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.triggerAt}
                  onChange={(e) => changeTriggerAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text small"
                ></Form.Control>
            </label>
          </div>
        <div> {long?"above":"below"} {long?"Buy":"Sell"} Price</div>
        <div className=""> 
        <img src="/images/x-mark-blue.svg" alt="" onClick={deleteTSL} className="pointer var-tp-cross"></img>
        </div>
      </div>
      </>
    );
  });
  return (
    <div>
      {tslItems}
      {tslItems.length <= 9 && (
        <div className="d-flex justify-content-end">
        <div className="abp-btn">
            <a className="pointer" onClick={() => addVariabletSL() }><img src="/images/plus-blue.svg" alt=""/> Add Another</a>
          </div>
    </div> 
      )}
    </div>
  );
};

export default VariableTSL;
