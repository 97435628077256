import React, { useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { getTopTemplates } from "../../actions/marketplace-action";
import { roundNumber, trimString } from "../../utils/Utils";

const TopPerformanceBots = (props)=>{
    const { getTopTemplates,t } = props;
    const templates = useSelector((state) => state.marketplace.top_templates) || [];
    useEffect(() => {
      getTopTemplates(5);
    }, []);
    const templateItems = templates.slice(0, 5).map((item, index) => {
   
      const link = `/marketplace-detail/${item.id}`;
      //const tutorial = (msg) => <Tooltip>{msg}</Tooltip>;
      return (
        <a href={link} key={index}>
          <div className="perform-col">
            <table>
                <tbody>
                <tr valign="top" key={index}>
                  <td><div className="perform-avatar" style={{backgroundImage: `url(${item.image})`}}></div></td>
                  <td>
                  <p>{trimString(item.title,50)}</p>
                  <div className="perform-stats grey-text">APY</div>
                  <div className="perform-stats black-text">{roundNumber(item.apy, 1)}%</div>
                  </td>
                </tr>
                </tbody>
            </table>
          </div>
        </a>
      );
    });
    return(
        <>
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 v-pad">
        <h3>Top Performance</h3>
        <br/>
        {templateItems}
        
      </div>
        </>
    )
}

export default connect(null,{getTopTemplates})(TopPerformanceBots)