import React, { useState } from "react";
import { Form, Dropdown, DropdownButton, Button } from "react-bootstrap";
import SelectableContext from "react-bootstrap/SelectableContext";
import SliderView from "./SliderView";
import "./AddIndicatorView.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CHANGE_ENTRY_INDICATORS,
  CHANGE_ENTRY_NUM,
  CHANGE_EXIT_INDICATORS,
  CHANGE_EXIT_NUM,
  CHANGE_TV_ALERTS_ENTRY,
  CHANGE_TV_ALERTS_EXIT,
  CLOSE_TV_ALERT_SETTINGS,
  EDITING_INDICATOR_SETTING,
  EDITING_TV_ALERT,
  SET_TRADING_VIEW_ENTRY_STATUS,
  SET_TRADING_VIEW_EXIT_STATUS,
} from "../../../actions/create-bot-type";
import IndicatorView from "./indicator-setting/IndicatorView";
import Switch from "react-switch";
import TvAlertSettingModal from "./indicator-setting/TvAlertSettingModal";
import TvIndicatorAlertView from "./indicator-setting/TvIndicatorAlertView";
import md5 from 'crypto-js/md5';
import { BotType, IndicatorType } from "../../../Const";
import { checkSub } from "../../../utils/SubUtils";
import LeftRightForm from "./modules/LeftRightForm";
import CustomSwitch from "./modules/CustomSwitch";
import IndicatorDropdown from "./modules/IndicatorDropdown";

const AddIndicatorView = (props) => {
  const { newBot, isEntry,t } = props;
  const indicatorNum = isEntry ? newBot.entryNum : newBot.exitNum;
  const indicators = isEntry ? newBot.entryIndicators : newBot.exitIndicators;
  const tvAlerts = isEntry ? newBot.tvAlertsEntry : newBot.tvAlertsExit;
  const dispatch = useDispatch();
  const defaultIndicators = useSelector((state) => state.bots.indicators) || [];
  const [showTvSettingModal,setShowTvSettingModal] = useState(false)
  const tradingViewCheck = isEntry ?newBot.tvAlertEntryStatus:newBot.tvAlertExitStatus;
  const userId = useSelector((state) => state.auth.user.id);
  const isSimple = newBot.botType==BotType.simple;
  const isGrid = newBot.botType==BotType.grid;
  const checksum = md5(userId+new Date()).toString()
  const baseCoin = newBot.baseCoin;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const indicatorNumChanged = (val) => {
    let reqNum = indicators.filter(x => x.is_compulsory==1).length
    val = val < reqNum ? reqNum:val
    dispatch({
      type: isEntry ? CHANGE_ENTRY_NUM : CHANGE_EXIT_NUM,
      payload: val,
    });
  };

  const indicatorsChanged = (val) => {
    dispatch({
      type: isEntry ? CHANGE_ENTRY_INDICATORS : CHANGE_EXIT_INDICATORS,
      payload: val,
    });
  };
  const setTradingViewStatus = (val) => {
    indicatorsChanged([])
    dispatch({
      type: isEntry ? CHANGE_ENTRY_NUM : CHANGE_EXIT_NUM,
      payload: 0,
    });
    dispatch({
      type: isEntry ? SET_TRADING_VIEW_ENTRY_STATUS : SET_TRADING_VIEW_EXIT_STATUS,
      payload: val,
    });
  };

  const editIndicator = (item) => {
    dispatch({
      type: EDITING_INDICATOR_SETTING,
      payload: {
        isEntry,
        indicator: item,
      },
    });
  };
  const editTvAlert = (item) => {
    dispatch({
      type: EDITING_TV_ALERT,
      payload: item
    });
    setShowTvSettingModal(true)
  };
  const addTvIndicator = () => {
    if(tvAlerts.length <=4){
      dispatch({
        type:EDITING_TV_ALERT,
        payload: {
            alert_id: tvAlerts.length>0?tvAlerts.length+1:0,
            checksum: checksum,
            order: isEntry?"base":"exit",
            type:isEntry?"entry":"exit"
          },
      })
      setShowTvSettingModal(true)
    }
    
  };
  const removeTvAlert = (id) => {
    let alerts = tvAlerts.filter((el) => id != el.alert_id);
    dispatch({
      type:isEntry?CHANGE_TV_ALERTS_ENTRY:CHANGE_TV_ALERTS_EXIT,
      payload: alerts
    });
  };

  const addIndicator = (id) => {
    const ids = indicators.map((el) => el.indicator_id);
    if(!subPremium){
      if (ids.includes(Number(id))){
        return false
      }
    }
    var element = defaultIndicators.find((el) => el.indicator_id == id);
    indicatorsChanged([
      ...indicators,
      {...element,ind_key:Math.floor(Math.random() * 10000)},
    ]);
    indicatorNumChanged(indicators.length + 1);
  };
  
  const closeTvIndicator = () => {
    dispatch({
      type: CLOSE_TV_ALERT_SETTINGS,
      payload: {}
    });
    setShowTvSettingModal(false)
  };

  const removeIndicator = (id) => {
    indicatorsChanged(indicators.filter((el) => {
      if(el.ind_key){
        return id != el.ind_key
      }
      else{
        return id != el.id
      }
      
    }));
    indicatorNumChanged(indicators.length - 1);
  };
  
  const indicatorOptions = defaultIndicators.map((item) => {
    if ((item.indicator_id == IndicatorType.VOL && isSimple) || item.indicator_id == IndicatorType.VOL && isGrid){
      return <></>
    }
    else{
      return (
          <li key={item.indicator_id} onClick={()=>addIndicator(item.indicator_id)}>
          <a className="pointer">{item.name}</a>
        </li>)
    }
  });
  

  const indicatorItems = indicators.map((item, id) => {
    return (
      <IndicatorView
        key={id}
        indicator={item}
        strategy={newBot.strategy}
        isEntry={isEntry}
        editIndicator={editIndicator}
        removeIndicator={removeIndicator}
        botType={newBot.botType}
        baseCoin = {baseCoin}
      />
    );
  });
  const tvAlertItems = tvAlerts.map((item, id) => {
    return (
      <TvIndicatorAlertView
        key={id}
        indicator={item}
        editTvAlert={editTvAlert}
        removeTvAlert={removeTvAlert}
      />
    );
  });

  const formatIndicators = (value) => `${value} of ${indicators.length}`;
  return (
    <>
    {!isSimple &&
    <div className="mt-4">
       <LeftRightForm
        left={<h6>Trading view</h6>}
        right={<>
        <CustomSwitch checked={tradingViewCheck} onChange={setTradingViewStatus}></CustomSwitch>
       
        {tradingViewCheck && <><div className="mt-2 mb-3">{tvAlertItems}</div>
          <button className="blue-button small"  onClick={() => addTvIndicator() }> 
            {t("add-indicator")}
          </button>
        
        </> }
        </>}>
      </LeftRightForm>
      </div>
      }
      {(tradingViewCheck && !isSimple) ?
        
        <></>
       :<>
      <div>
        <SliderView
          title={t("indicator-trig")}
          min={0}
          max={indicators.length}
          value={indicatorNum}
          formatLabel={formatIndicators}
          disabled={indicators.length == 0}
          onChange={(value) => {
                indicatorNumChanged(value);
              }
          }
          customClass2={'pl-2'}
        />
        <LeftRightForm
          left={<></>}
          right={<><div className="mb-3">{indicatorItems}</div>
          <IndicatorDropdown t={t} indicatorOptions={indicatorOptions}></IndicatorDropdown>
          </>}
        ></LeftRightForm>
        
      </div>
      </>}
      {showTvSettingModal && <TvAlertSettingModal
        onHide={()=>closeTvIndicator()}
        isEntry={isEntry}
        newBot={newBot}
      ></TvAlertSettingModal> }
    </>
  );
};

export default connect(null)(AddIndicatorView);
