import React ,{useState} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal,Tabs,Col,Row } from "react-bootstrap";
import BotImageUpload from "./BotImageUpload";
import BotImageTemplateLibrary from "./BotImageTemplateLibrary";
import {setLibraryImageSelect,setImageSelect } from "../../../actions/image-upload-action";


const BotImageUploadView = (props)=>{
    const imgSelected =  useSelector((state) => state.newTemplate.selectLibraryImage)
    const imgUploaded =  useSelector((state) => state.newTemplate.uploadPreview)
    const dispatch = useDispatch();
    const {
        onHide,
        setLibraryImageSelect,
        setImageSelect
    }=props;
    
    const show =  useSelector((state) => state.newTemplate.show)
    
    const [activeTab, setActiveTab] = useState(0);
    const tabTitles = ["Chose From Template Library","Upload"];
    const tabHeaders = tabTitles.map((item, index) => {
        return (
            <a
            onClick={() => setActiveTab(index)}
            className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
            >
            {item}
            </a>
        );
    });
    const tabs = [
        <BotImageTemplateLibrary/>,
        <BotImageUpload  />,
    ];
    return (
        <>
       
            <div>
            <div className="tab-header-template-gallery">{tabHeaders}</div>
            </div>
            <div className="mt-4">
            {tabs[activeTab]}
            </div>
                
            
            {/* <Modal.Footer className="flex-start">
                <button className="btn btn-primary mr-2" onClick={()=>{activeTab==1?setLibraryImageSelect(imgSelected):setImageSelect(imgUploaded)}}>Select</button>
                <button className="btn btn-outline-secondary mr-3" onClick={onHide}>Cancel</button>
            </Modal.Footer> */}
        
        </>
    )
}
export default connect(null,{setImageSelect,setLibraryImageSelect})(BotImageUploadView);