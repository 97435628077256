import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { connect, useSelector } from "react-redux";
import { getSummary } from "../../../actions/marketplace-action";
import TutorialPopup from "../../help/TutorialPopup";
import {PortfolioColors} from "../../../Const";
//import "./PortfolioPanel.scss";

const TimeFrames = ["1D", "3D", "1W", "1M", "3M", "1Y", "ALL"];

const NoBotSubmitted = (props) => {
  
  return (
    <div className="nobot-panel">
        <div className="nobot-panel-view-div">
            
            <p>You havn't submitted any bots to the marketplace.</p>
            
        </div>
    </div>
  );
};

export default connect(null, { getSummary })(NoBotSubmitted);


