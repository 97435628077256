import React, { useState, useEffect, useMemo } from "react";
import {
  Dropdown,
  DropdownButton,
  Button,
  Form,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import "./NewOrderPanel.scss";
import {
  OrderType,
  OrderName,
  SideEffectType,
  SideEffectName,
  AccountType,
  TradeSide,
} from "../../Const";
import useInput from "../hooks/useInput";
import { placeOrder } from "../../actions/terminal-action";
import { toast } from "react-toastify";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import { formatNumberToString, restrictAlphabets, roundNumber } from "../../utils/Utils";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";

const NewOrderPanel = (props) => {
  const {
    tradeSide,
    tradeSideChanged,
    baseCoin,
    quoteCoin,
    account,
    placeOrder,
    t
  } = props;

  const [type, setType] = useState(OrderType.limit);
  const [typeName, setTypeName] = useState(OrderName.limit);
  const [sideEffect, setSideEffect] = useState(SideEffectType.normal);
  const [isAmount, setIsAmount] = useState("true");
  const [stopPrice, bindStopPrice] = useInput("0");
  const [limitPrice, bindLimitPrice, resetLimitPrice, setLimitPrice] =
    useInput("0");
  const [amount, bindAmount, resetAmount, setAmount] = useInput("0");
  const [total, bindTotal, resetTotal, setTotal] = useInput("0");
  const [setLoading, bindLoading] = useLoading(false);
  const [maxTrade, setMaxTrade] = useState(0);

  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const selectedExchangeId = useSelector(
    (state) => state.settings.selectedExchangeId
  );
  const base = useSelector((state) => state.terminal.base) || {};
  const quote = useSelector((state) => state.terminal.quote) || {};
  const orderTypeNames =
    useMemo(() => {
      if (selectedExchangeId !== 1) {
        return { limit: OrderName.limit, market: OrderName.market };
      } else {
        return OrderName;
      }
    }, [selectedExchangeId]) || OrderName;

  useEffect(() => {
    setLimitPrice(selectedPair.c || "1");
  }, [selectedPair.s]);

  useEffect(() => {
    for (var key in OrderType) {
      if (OrderType[key] === type) {
        setTypeName(OrderName[key]);
        break;
      }
    }
  }, [type]);
  useEffect(() => {
    const balance = tradeSide === TradeSide.buy ? quote : base;
    if (sideEffect === SideEffectType.borrow) {
      setMaxTrade((balance.asset_value || 0) + (balance.max_borrowable || 0));
    } else {
      setMaxTrade(balance.asset_value || 0);
    }
  }, [tradeSide, base, quote, sideEffect]);

  const typeItems = Object.keys(orderTypeNames).map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={OrderType[item]}>
        {OrderName[item]}
      </Dropdown.Item>
    );
  });

  const sideEffectItems = Object.keys(SideEffectType).map((el) => {
    return (
      <Form.Check
        key={el}
        inline
        type="radio"
        name="sideEffect"
        value={SideEffectType[el]}
        label={SideEffectName[el]}
        defaultChecked={sideEffect === SideEffectType[el]}
      />
    );
  });

  const onChangeAmount = (e) => {
    setIsAmount(e.target.value);
  };

  const clickTradeBtn = () => {
    setLoading(true);
    const params = {
      exchange_id: selectedExchangeId,
      pair_id: selectedPair.realPairId,
      trade_type: account,
      quantity: amount,
      price: limitPrice,
      side: tradeSide,
      order_type: type,
      stop_price: stopPrice,
      total: total,
      side_effect_type: sideEffect,
    };
    placeOrder(params)
      .then(() => {
        setLoading(false);
        toast.success(t("new-order-msg"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const changeAmount = (e) => {
    const val = e.target.value;
    setAmount(val);
    setTotal(roundNumber(val * limitPrice));
  };

  const changeLimitPrice = (e) => {
    const val = e.target.value;
    setLimitPrice(val);
    setTotal(val * amount);
  };

  const changeTotal = (e) => {
    const val = e.target.value;
    setTotal(val);
    setAmount(
      roundNumber(limitPrice && limitPrice != 0 ? val / limitPrice : 0)
    );
  };

  return (
    <div className="mt-2 new-order">
      <h5>{t("place-order")}</h5>
      <div className="card-white">
                <div className="cw-body">
      <Row>
        <Col>
        <a className={(tradeSide == TradeSide.buy ? "list-tab tab-active" : "list-tab tab-inactive" )}
            onClick={() => tradeSideChanged(TradeSide.buy)}
          >BUY/LONG</a>
        <a className={(tradeSide == TradeSide.sell ? "list-tab tab-active" : "list-tab tab-inactive" )}
            onClick={() => tradeSideChanged(TradeSide.sell)}
          >SELL/SHORT</a>

        </Col>
      </Row>
      
      <br></br>
      <LeftRightForm
        left={t("order-type")}
        right={<>
        <DropdownButton
          title={typeName}
          onSelect={(index) => setType(index)}
          variant="outline-primary"
        >
          {typeItems}
        </DropdownButton>
        <div className="max-trade mt-2">
        <span>
          <i className="fas fa-wallet wallet-icon"></i>
        </span>{" "}
        <span>
          {formatNumberToString(maxTrade)}{" "}
          {tradeSide === TradeSide.buy ? baseCoin : quoteCoin}
        </span>
      </div></>}
      >

      </LeftRightForm>
      
      {account !== AccountType.spot && (
        <div className="my-2" onChange={(e) => setSideEffect(e.target.value)}>
          {sideEffectItems}
        </div>
      )}
      {type === OrderType.stoplossLimit && (
        <LeftRightForm
          left={<Form.Label className="new-order-title">{t("stop-price")}</Form.Label>}
          right={<label data-text={baseCoin} className="input-gp-label">
          <Form.Control
              as="input"
              type="number"
              {...bindStopPrice}
              onKeyPress={(e) => {
                restrictAlphabets(e);
              }}
              className="input-text input-gp-text"
            ></Form.Control>
        </label>}
        >

        </LeftRightForm>
        
      )}
      {type !== OrderType.market && (
        <LeftRightForm
        left={<Form.Label className="new-order-title">{t("limit-price")}</Form.Label>}
        right={<label data-text={baseCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            {...bindLimitPrice}
            onChange={changeLimitPrice}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>}
      >

      </LeftRightForm>
      )}

      {(type !== OrderType.market ||
        (type === OrderType.market && isAmount === "true")) && (
       
        <LeftRightForm
        left={<Form.Label className="new-order-title">{t("amount")}</Form.Label>}
        right={<label data-text={quoteCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            {...bindAmount}
            onChange={changeAmount}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>}
      >
      </LeftRightForm>
      )}
      {type !== OrderType.market && (
        <LeftRightForm
        left={<Form.Label className="new-order-title">{t("total")}</Form.Label>}
        right={<label data-text={baseCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            {...bindTotal}
            onChange={changeTotal}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>}
      >
      </LeftRightForm>
        
      )}
      {false && (
        <div className="my-2">
          <Form.Check
            inline
            label={t("amount")}
            value={"true"}
            type="radio"
            checked={isAmount === "true"}
            onChange={onChangeAmount}
          />
          <Form.Check
            inline
            label={t("total")}
            value={"false"}
            type="radio"
            checked={isAmount === "false"}
            onChange={onChangeAmount}
          />
        </div>
      )}
      <div className="mt-4 mb-3">
        <Button
         
          className="w-100 blue-btn"
          onClick={clickTradeBtn}
        >
          {tradeSide === TradeSide.buy ? "BUY" : "SELL"}
        </Button>
      </div>
      </div>
      </div>
      <Loading {...bindLoading} />
    </div>
  );
};

export default connect(null, { placeOrder })(NewOrderPanel);
