import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button,  Table } from "react-bootstrap";

import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";

import { toast } from "react-toastify";
import TemplateImage from "../dashboard/TemplateImage";
import TemplateInfoCard from "../dashboard/TemplateInfoCard";
import TemplateSettingsInfo from "./TemplateSettingsInfo";
import { useTranslation } from "react-i18next";
const ViewTemplateSettings = (props) => {
  const {
    onHide,
    template,
    onEditClick
    
  } = props;
  const {t} = useTranslation()
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [setLoading, bindLoading] = useLoading(false);

  

  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>
           
          {t("bot-tem-settings")}
            
          </Modal.Title>
         
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col xs={3}>
                <TemplateImage template={template} t={t}/>
            </Col>
            <Col xs={9}>
                <TemplateSettingsInfo template={template} t={t}/>
                {/* <TemplateRentDetails template={template}/> */}
            </Col>
        </Row>
            <Row className="my-4 justify-content-center">
              <Button
                variant="danger"
                onClick={()=>onEditClick(template)}
                className="mr-4"
              >
                 {t("edit")}
              </Button>
            </Row>
        </Modal.Body>
      </Modal>
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {})(ViewTemplateSettings);
