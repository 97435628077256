import React from "react";
import ToolTip from "./ToolTip";

const TutorialPopup = (props) => {
  return (
    <ToolTip content={props.content}>
      <i
        className="far fa-question-circle tooltip"
        style={{ fontSize: 12, marginLeft: 5 , marginRight:5}}
      ></i>
    </ToolTip>
  );
};

export default TutorialPopup;
