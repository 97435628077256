import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getBots,
  getIndicators,
  getRentalBots,
  getSubscription
} from "../../actions/bot-action";

import "./Bots.scss";

import ContentContainer from "../common/ContentContainer";

import { getBotDetail } from "../../actions/bot-action";

import {
  resetBotConfig,
  updateEditBotConfig,
  updatePresetConfig,
  updateCopyBotConfig,
  updateEditRentBotConfig
} from "../../actions/create-bot-action";
import { getDashboardAssets } from "../../actions/dashboard-action";

import { useTranslation } from "react-i18next";
import BotList from "./BotList";
import BotPageOverview from "./BotPageOverview";
import BotFilterPanel from "./BotFilterPanel";
import { capitalize, currencyFormat } from "../../utils/Utils";
import BotTypeList from "./BotTypeList";
import BalanceInfo from "./BalanceInfo";
import WelcomeWizard from "../tour/WelcomeWizard";
import { HIDE_BOT_INFO } from "../../actions/types";

const Bots = (props) => {
  const {
    getSubscription,
	getDashboardAssets
  } = props;
  const { t } = useTranslation()
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const fiatBalance = useSelector((state) => state.dashboard.assets.balance_fiat);
  const status = useSelector((state) => state.pagination.botsActiveTab) || "all";
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  const showBotInfo = useSelector((state) => state.bots.botInfo);
  const [searchBotName, setSearchBotName] = useState("");
  const dispatch = useDispatch()
  const bots = useSelector((state) => state.bots.bots) || [];

  const hideBotInfo=()=>{
    dispatch({
      type: HIDE_BOT_INFO,
      payload:false
    });
  }
  useEffect(() => {
    getSubscription();
	getDashboardAssets(selectedExchangeId)
  }, [selectedExchangeId]);
  useEffect(() => {
  }, [searchBotName]);

  return (
      <ContentContainer url="bots" title={t("bots")} showChat={true}>
        <div className="dashboard-newuser-area">
              
              {newUserIntroPupup && selectedExchangeId==0 ? <WelcomeWizard></WelcomeWizard> : <>
              <BalanceInfo selectedExchangeId={selectedExchangeId}></BalanceInfo>
              <div className="deshboard-normal-allBots-area">
								<div className="deshboard-normal-allBots-header">
									<h3>{capitalize(status)} Bots</h3>
									<div className="deshboard-normal-allBots-header-right">
										<BotTypeList ></BotTypeList>
										<BotFilterPanel status={status} searchBotName={searchBotName} setSearchBotName={setSearchBotName}></BotFilterPanel>
									</div>
								</div>
								<div className="deshboard-normal-allBots-body">
                {showBotInfo && 
                <div className="dna-dsc">
										<ul>
											<li>The bot has started a trade and will automatically complete the deal if the exit conditions are met.</li>
											<li>The bot is running and is monitoring market data in real time. It will automatically execute a trade if the entry conditions are met.</li>
											<li>The bot is currently inactive. No execution of trades will occur.</li>
										</ul>
										<i id="dna-dsc-icon" className="fa-solid fa-xmark" onClick={()=>hideBotInfo()}></i>
									</div> }
									<div className="dna-cart-content">
										<div className="row g-3">
											
											<BotList t={t} searchBotName={searchBotName}></BotList>
										</div>
									</div>
									
								</div>
							</div>
              </>}

              {/* end of all bot area */}
            </div>
      
      </ContentContainer>
  );
};

export default connect(null, {
  getBots,
  getSubscription,
  updateEditBotConfig,
  updateEditRentBotConfig,
  resetBotConfig,
  updatePresetConfig,
  getIndicators,
  updateCopyBotConfig,
  getBotDetail,
  getRentalBots,
  getDashboardAssets
})(Bots);
