import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { getBalance } from "../../../actions/bot-action";
import { Modal, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import AssetView from "../../bots/new-bot/AssetView";
import { getTvTimeframe } from "../../../utils/TvUtils";
import { TimeWindow, CreateBotModalType, BotType } from "../../../Const";
import {
  CHANGE_TIME_FRAME,
  SET_BOT_NAME,
  SET_RENT_BOT_NAME,
} from "../../../actions/create-bot-type";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import {
  updateBot, createRentBot, updateRentBot, resetRentBotConfig,
} from "../../../actions/create-bot-action";
import TradeParamsView from "../../bots/new-bot/TradeParamsView";
import TVChartContainer from "../../bots/TVChartContainer";
import AssetViewRent from "./AssetViewRent";
import TradeParamsViewRent from "./TradeParamsViewRent";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssetViewRentFutures from "./AssetViewRentFutures";
import TradeParamsViewRentFutures from "./TradeParamsViewRentFutures";

const Frequencies = {
  15: "15",
  60: "60",
  120: "120",
  240: "240",
  1440: "1D",
};

const TimeFrame_1D = ["1D", "1W", "1M", "3M", "6M", "1Y"];
const TimeFrame_1H = ["1D", "1W", "1M", "2M", "3M", "6M"];
const TimeFrame_15m = ["1D", "1W", "2W", "4W", "5W", "6W"];

const CreateBotFromRentModal = (props) => {
  const {
    modalType,
    onHide,
    getBalance,
    createRentBot,
    updateRentBot,
    rental,
    edit,
    resetBotConfig,
    resetRentBotConfig,
    itemsPerPage,
    pageNum,
    selectedTab 
  } = props;
  const {t} = useTranslation()
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  
  const { search } = useLocation();

  const [setLoading, bindLoading] = useLoading(false);
  const [timeFrames, setTimeFrames] = useState(TimeFrame_1D);
  const [interval, setInterval] = useState(timeFrames[timeFrames.length - 1]);
  const [editName, setEditName] = useState(false);

  const reloadTV = useSelector((state) => state.bots.reloadTV);
  const rentBot = useSelector((state) => state.createBot.rentBot);
  const Exchange = useSelector((state) => state.settings.selectedExchange) || 0;
  const isFutures = useSelector((state)=>state.settings.isFuture) || 0;
  const selectedExchange = edit?rentBot.exchange:Exchange;
  const exchangeId = selectedExchange.id;
  const frequency = rentBot.frequency;
  const baseCoin = rentBot.baseCoin;
  const quoteCoin = rentBot.quoteCoin;
  const strategy = edit?rentBot.strategy:rental.strategy;
  const pair = rentBot.pair;
  const botName = rentBot.name;
  const dispatch = useDispatch();

  const changeTimeFrame = (val) => {
    dispatch({
      type: CHANGE_TIME_FRAME,
      payload: val,
    });
  };
  useEffect(() => {
    changeTimeFrame(TimeWindow[interval]);
  }, [interval]);
  useEffect(() => {
    if(!edit){
      resetRentBotConfig(rental);
    }
    
  }, []);

  const setBotName = (val) => {
    dispatch({
      type: SET_RENT_BOT_NAME,
      payload: val,
    });
  };

  const clickCreateBot = () => {
    setLoading(true);
    createRentBot(itemsPerPage,pageNum,selectedTab,selectedExchangeId)
      .then(() => {
        setLoading(false);
        toast.success("Bot has been created");
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const clickUpdateBot = () => {
    setLoading(true);
    updateRentBot(itemsPerPage,pageNum,selectedTab,selectedExchangeId)
      .then(() => {
        setLoading(false);
        toast.success("Bot has been updated");
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  
  useEffect(() => {
    switch (frequency) {
      case 15:
        setTimeFrames(TimeFrame_15m);
        setInterval(TimeFrame_15m[TimeFrame_15m.length - 1]);
        break;
      case 60:
        setTimeFrames(TimeFrame_1H);
        setInterval(TimeFrame_1H[TimeFrame_1H.length - 1]);
        break;
      default:
        setTimeFrames(TimeFrame_1D);
        setInterval(TimeFrame_1D[TimeFrame_1D.length - 1]);
    }
  }, [frequency]);

  useEffect(() => {
    getBalance(exchangeId);
  }, [exchangeId]);

  const timeFrameButtons = timeFrames.map((item, index) => {
    const buttonClass = `w-100 ${
      item == interval ? "button-active" : "button-inactive"
    }`;
    return (
      <Col key={index}>
        <Button className={buttonClass} onClick={() => setInterval(item)}>
          {item}
        </Button>
      </Col>
    );
  });
  

  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>
            {!editName ? (
              botName
            ) : (
              <Form.Control
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
            )}{" "}
          </Modal.Title>
          <a className="ml-3" onClick={() => setEditName(!editName)}>
            <i className={editName ? "far fa-check-circle" : "far fa-edit"}></i>
          </a>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={7}>
              <Row style={{ height: "400px" }}>
                <TVChartContainer
                  pairId={pair && pair.id}
                  symbol={`${baseCoin}/${quoteCoin}`}
                  interval={Frequencies[frequency]}
                  reload={reloadTV}
                  timeframe={getTvTimeframe(interval)}
                />
              </Row>
              <Row noGutters className="mt-4">
                <Col xs="9">
                  <Row noGutters>{timeFrameButtons}</Row>
                </Col>
              </Row>
            </Col>
            <Col xl={5} className="mt-3 mt-xl-0">
              <Accordion defaultActiveKey="0">
                {isFutures?<AssetViewRentFutures rentBot={rentBot} selectedExchange={selectedExchange} t={t}/>:
                <AssetViewRent rentBot={rentBot} selectedExchange={selectedExchange} t={t}/>}
                {isFutures?
                <TradeParamsViewRentFutures
                  editing={edit}
                  rentBot={rentBot} rental={rental}
                  t={t}
                />:<TradeParamsViewRent
                  editing={edit}
                  rentBot={rentBot} rental={rental}
                  t={t}
                />}
              </Accordion>
              <Row className="mt-3">
                <div style={{ marginBottom: 30,width:'100%',padding:'10px 20px' }}>
                  {edit ? (
                    
                      <Button className="mx-auto" size="lg" variant="success" block={true} onClick={clickUpdateBot}>
                        Update Bot
                      </Button>
                      
                    
                  ) : (
                    <Button
                      className="mx-auto"
                      size="lg"
                      variant="success"
                      block={true}
                      onClick={clickCreateBot}
                    >
                      Create Bot
                    </Button>
                  )}
                </div>
              </Row>
            </Col>
            
          </Row>
          
        </Modal.Body>
      </Modal>
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {
  getBalance,
  updateBot,
  createRentBot,
  resetRentBotConfig,
  updateRentBot
})(CreateBotFromRentModal);
