import {
  Button,
  Row,
  Col,
  OverlayTrigger,
  ProgressBar,
} from "react-bootstrap";
import React from "react";
import "./MarketplaceCard.scss";
import { Link, useHistory } from "react-router-dom";
import { getTemplateDetail } from "../../../actions/marketplace-action";
import { connect } from "react-redux";
import { trimString } from "../../../utils/Utils";

const MarketplaceCard = (props) => {
  //console.log(props)
  const {template,getTemplateDetail,t,featured} = props;
  const back_test = template.back_test || {};
  const history = useHistory(); 
  const clickTemplateDetails = (id)=>{
    history.push(`/marketplace-detail/${id}`)
  }
  const checkPercentageStatus = (percentage)=>{
    switch(true){
      case percentage < 10:
        return "Very Low"
        break;
      case percentage < 30:
        return "Low"
        break;
      case percentage < 80:
        return "Medium"
        break;
      case percentage <= 89:
        return "High"
        break;
      case percentage >= 90:
        return "Very High"
        break;
    }
  }
  return (
    <div className="col-xl-3 col-lg-4 col-md-4  col-6">
            <div className="marketplace-landing-cart-content">
               <div className="mlc-heading">
               <Link to={`/marketplace-detail/${template.id}`}><img src={template.image} alt=""/></Link>
               </div>
               <div className="mlc-body">
                  <div className="template-tile-div">
                  <h4>{trimString(template.title,50) }</h4>
                  </div>
                  <div className="mlc-range-content">
                     <img src="images/mlc-bot-icon.svg" alt=""/>
                     <div className="mlc-range-item">
                        <label>
                           <span>Bot Activity</span>
                           <h6>{checkPercentageStatus(template.real_performances && template.real_performances.bot_activity)}</h6>
                        </label>
                        
                        <ProgressBar now={template.real_performances && template.real_performances.bot_activity}  className=""/>
                        
                     </div>
                  </div>
                  <div className="mlc-range-content">
                     <img src="images/mlc-win-icon.svg" alt=""/>
                     <div className="mlc-range-item">
                        <label>
                           <span>Win Rate</span>
                           <h6>{checkPercentageStatus(template.real_performances && template.real_performances.win_rate)}</h6>
                        </label>
                        <ProgressBar now={template.real_performances && template.real_performances.win_rate}   className=""/>
                     </div>
                  </div>
                  <div className="mlc-range-content">
                     <img src="images/mlc-trick-icon.svg" alt=""/>
                     <div className="mlc-range-item">
                        <label>
                           <span>Track Record</span>
                           <h6>{checkPercentageStatus(template.real_performances && template.real_performances.track_record)}</h6>
                        </label>
                        <ProgressBar now={template.real_performances && template.real_performances.track_record}   className=""/>
                     </div>
                  </div>
               </div>
               <div className="mlc-footer">
                  <label>
                  <img src="images/mlc-eye-icon.svg" alt=""/>
                  {template.view_count}
                  </label>
                  <a className={template.strategy=="Long"?"long":"short"}>{template.strategy}</a>
                  <label>
                  <i className="fa-solid fa-heart heart"></i>
                  {template.like_count}
                  </label>
               </div>
            </div>
            
         </div>
  );
};

export default connect(null,{getTemplateDetail})(MarketplaceCard);
