import React, { useState } from "react";
import { Table} from "react-bootstrap";
import { formatDate, futurePairName } from "../../utils/Utils";
import NoData from "../common/NoData";
import "./DealTab.scss";

const DealTradesTab = (props) => {
  const { trades,t} = props;
  
  const tradesItems = trades.map((item, index) => {
    return (
      <tr key={index}>
        <td className="bot-data">{item.bot_name}</td>
       
        <td>
            <div className="pair-data pl-0">
              {/* <img src="/images/coin-icon.svg" alt=""/> */}
              <h6>{futurePairName(item.pair)}</h6>
            </div>
        </td>
        <td className={`side-${item.side}`}>{item.side}</td> 
        <td>
        <div className="valume-data pl-0"><h6>{item.quote_quantity?item.quote_quantity.toFixed(2):0} {item.pair && item.pair.to}</h6></div>
        </td>
        <td>
        <h6 className="filled-data pl-0">{item.filled?item.filled.toFixed(6):0} {item.pair && item.pair.from}</h6>
        </td>
        <td>
        <h6 className="date-data pl-0">{formatDate(item.time,undefined, "DD MMM YYYY HH:mm:ss")}</h6>
        </td>
      </tr>
    );
  });
  return (
    <div className="skltbs-panel">
    <div className="open-table-content tlc">
                <table className="table">
                  <thead>
                  <tr>
                        {/* <td className="text-left"></td> */}
                        <td className="text-left">{t("Bot")}</td>
                        <td className="text-left">{t("pair")}</td>
                        <td>{t("side")}</td>
                        <td>{t("volume")}</td>
                        <td>{t("filled")}</td>
                        <td>{t("date")}</td>
                      </tr>
                  </thead>
                     <tbody>
                     {tradesItems}
                     </tbody>
                   
                </table>
                <NoData count={trades.length} />
            </div>
        </div>
  );
};

export default DealTradesTab;


