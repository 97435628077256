import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {Card} from "react-bootstrap"
import { getBotDetail } from "../../../actions/bot-action";

const BotStatisticsView = (props)=>{
    const {bot,getBotDetail,t} = props
    
    const selectedBot = useSelector((state) => state.newTemplate.botSelected) || {};
    useEffect(() => {
        bot.id && getBotDetail(bot.id)
    }, []);
    const analysis = selectedBot.deals_analysis
    return (
        <>
            {selectedBot.deals_analysis &&
                <>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                        <div>APY</div> <div>{analysis?analysis.apy:'0'}%</div> 
                </div>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                    <div>{t("performance")}</div> <div>${analysis?analysis.total_performance:'0'}</div> 
                </div>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                    <div>{t("w-l-ratio")}</div> <div>{analysis?analysis.wl_ratio:'0'}%</div> 
                </div>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                    <div>{t("total-deals")}</div> <div>{analysis ? analysis.total_position:0}</div> 
                </div>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                    <div>{t("a-md-d")}</div> <div>{analysis?analysis.avg_monthly_deal:'0'}</div> 
                </div>
                <div className="d-flex jcb ac bot-stat-div mt-4">
                    <div>{t("strategy")}</div> <div>{selectedBot?selectedBot.strategy:""}</div> 
                </div>
               
                </>
            }
            
        </>
    )
}
export default connect(null,{getBotDetail})(BotStatisticsView)