
import React, {  } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

const CancelRentalConfirm = (props) => {
  const {
    bot,
    onHide,
    onConfirm
  } = props;
  return (
    <>
    
      <Modal show={true} onHide={onHide} size="xs" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "280px"}} className="text-center">
        <img src="/images/alert.png" width={50}></img>
        <div className="text-center mt-3 mb-3"><p>Please note that after this rental is cancelled, all active bots that were created from this strategy will immediately deactivate and be deleted. All existing inactive bots will be deleted as well. There is no refund for any unused period.</p></div> 
        <button className="btn  blue-button mr-2" onClick={onConfirm}>Confirm</button>
        <button className="btn grey-button" onClick={onHide}>Cancel</button>
        </Modal.Body>
       
      </Modal>
      </>
  );
};

export default connect(null, {
})(CancelRentalConfirm);
