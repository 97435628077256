import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DealWarning = (props) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{props.note}</Tooltip>}
    >
      <i
        className="fas fa-exclamation-circle text-warning ml-2"
        style={{ fontSize: 13 }}
      ></i>
    </OverlayTrigger>
  );
};

export default DealWarning;
