import React, {  } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import "./AssetView.scss";
import { BotType, StrategyModule } from "../../../Const";
import FuturesTradeParamsView from "./FuturesTradeParamsView";
import GridBotParamsView from "./grid-bot/GridBotParamsView";
import TradeParamsView from "./TradeParamsView";

const TradeParamsViewModule = (props) => {
  const { newBot,t,editing,step } = props;
  const isFuture = useSelector((state) => state.settings.isFuture);
  const getTradeParamsView = ()=>{
    if(isFuture){
      return <FuturesTradeParamsView newBot={newBot} t={t} editing={newBot.isEditing}/>
    }
    else{
      switch (newBot.botType) {
        case BotType.grid:
          return <GridBotParamsView newBot={newBot} t={t} editing={newBot.isEditing} step={step}/>
        default:
          return <TradeParamsView newBot={newBot} t={t} editing={newBot.isEditing} step={step}/>
      }
    }
    
  }

  return (
    <>
      {getTradeParamsView()}
     </>
  );
};

export default connect(null,{})(TradeParamsViewModule);
