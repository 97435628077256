import React from "react";
import { DcaTime, WeekTime, WeekTimeName } from "../../../../Const";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_TIME } from "../../../../actions/create-bot-type";
import CustomCheckbox from "../modules/CustomCheckbox";
import CustomRadio from "../modules/CustomRadio";
import { Col, Row } from "react-bootstrap";

const DcaTimeEntry = (props) => {
  const { newBot,t } = props;
  const dcaTime = { ...newBot.dcaTime };
  const dispatch = useDispatch();
  const changeType = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        type: val,
      },
    });
  };

  const changeWeekValue = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        weekValue: val,
      },
    });
  };

  const changeMonthValue = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        monthValue: val,
      },
    });
  };

  const weekOptions = Object.keys(WeekTime).map((el, id) => (
    <option key={id} value={WeekTime[el]}>
      {WeekTimeName[el]}
    </option>
  ));

  const monthOptions = Array.from({ length: 31 }, (_, i) => String(i + 1)).map(
    (el, id) => (
      <option key={id} value={el}>
        {el}
      </option>
    )
  );
  return (
    <div className="cb-form-div">
              <div className="col-lg-4">
                <div className="ba-asset-left">

                </div>
                </div>
              <div className="col-lg-8">
                <div className="ba-asset-right">
                <div className="d-flex justify-content-between flex-column gap10">
                  <div>
                    <CustomRadio 
                        val={DcaTime.daily}
                        checked={dcaTime.type === DcaTime.daily}
                        callback={changeType}
                        label={t("Every Day")}
                        name="time">
                      </CustomRadio>
                  </div>
                  <div className="d-flex justify-content-start align-items-baseline gap20">
                      <div>
                        <CustomRadio 
                            val={DcaTime.weekly}
                            checked={dcaTime.type === DcaTime.weekly}
                            callback={changeType}
                            label={t("Every")}
                            name="time">
                          </CustomRadio>
                        </div>
                        <div>
                          <select
                          value={dcaTime.weekValue}
                          onChange={(e) => changeWeekValue(e.target.value)}
                          className="select-normal  small darker"
                        >{weekOptions}</select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-baseline gap20">
                      <div> <CustomRadio 
                          val={DcaTime.monthly}
                          checked={dcaTime.type === DcaTime.monthly}
                          callback={changeType}
                          label={t("Every")}
                          name="time">
                        </CustomRadio></div>
                      <div><select
                        value={dcaTime.monthValue}
                        onChange={(e) => changeMonthValue(e.target.value)}
                        className="select-normal small darker"
                      >
                        {monthOptions}
                      </select></div>
                      <div>{" "} day of the month</div>
                      
                      
                      </div>
                    </div>
      </div>
      </div>
      </div>
              
     
  );
};

export default DcaTimeEntry;
