import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_FUND, CHANGE_FUND_SECONDARY, CHANGE_IS_HOLD_CURRENCY } from "../../../../actions/create-bot-type";
import { BotType } from "../../../../Const";
import { formatNumberToString, isBoth, isLong, restrictAlphabets } from "../../../../utils/Utils";
import TutorialPopup from "../../../help/TutorialPopup";
import useCheckbox from "../../../hooks/useCheckbox";
import LongFundView from "./LongFundView";
import ShortFundView from "./ShortFundView";
import LeftRightForm from "../modules/LeftRightForm";
import CustomCheckbox from "../modules/CustomCheckbox";

const FundAllocationIntraArbitrage = (props)=>{
    const {t,newBot,editing,label} = props;
    const fund = newBot.initFund;
    const fund2 = newBot.initFund2;
    const strategy = newBot.strategy
    const isStrategyDirty = newBot.isStrategyDirty
    const isBothStrategy = isBoth(strategy)
    const balances = useSelector((state) => state.bots.balances);
    const longBalance =
    balances && balances.find((item) => item.coin === newBot.quoteCoin);
    const shortBalance =
    balances && balances.find((item) => item.coin === newBot.baseCoin);
    const isHoldCurrency = newBot.holdBaseCurrency?true:false;
    
   
    // console.log(calculateHoldFund(fund))
    const dispatch = useDispatch()
    const fundChanged = (val) => {
        dispatch({
          type: CHANGE_FUND,
          payload: val,
        });
    };
    const fund2Changed = (val) => {
        dispatch({
          type: CHANGE_FUND_SECONDARY,
          payload: val,
        });
    };
    useEffect(() => {
        if (longBalance && !editing || (editing && isStrategyDirty)) {
          fundChanged(editing?fund:longBalance.free);
        }
    }, [strategy,longBalance]);
    useEffect(() => {
      if (shortBalance && !editing || (editing && isStrategyDirty)) {
        fund2Changed(editing?fund2:shortBalance.free);
      }
    }, [strategy,shortBalance]);

    const setIsHoldCurrencyValue = (val) => {
      dispatch({
        type: CHANGE_IS_HOLD_CURRENCY,
        payload: val,
      });
    };
    
    return(
      <>
       {isBothStrategy ?<>
        <LeftRightForm
        left={t('fund-alloc')}
        right={<><label data-text={newBot.quoteCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={fund}
            onChange={(e) => {
              fundChanged(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
          {t("available")}: {formatNumberToString(longBalance && longBalance.free)}{" "}
          {newBot.quoteCoin}
        </p>
      </>}
      />
        <LeftRightForm
        left={t('fund-alloc')}
        right={<><label data-text={newBot.baseCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={fund2}
            onChange={(e) => {
              fund2Changed(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
          {t("available")}: {formatNumberToString(shortBalance && shortBalance.free)}{" "}
          {newBot.baseCoin}
        </p>
      </>}
      />
      </>:<>
      {isLong(strategy)?<><LongFundView t={t} newBot={newBot} editing={editing}></LongFundView>
      </>:<><ShortFundView t={t} newBot={newBot} editing={editing}></ShortFundView></>}
        <br></br>
      <LeftRightForm
        right={<CustomCheckbox 
          val={isHoldCurrency} 
          checked={isHoldCurrency==1} 
          callback={setIsHoldCurrencyValue}
          label={<>{isLong(strategy)?t("i-hold-base-currency"):t("i-hold-quote-currency")}<TutorialPopup content={t("hold-currency-msg")}></TutorialPopup></>}
          id={t("i-hold-base-currency")}
          />}
      />
     
      </>
      }
      </>
    )
}
export default FundAllocationIntraArbitrage

