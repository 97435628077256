import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { connect, useSelector } from 'react-redux';
import { subscribe } from '../../../actions/setting-action';
import { STRIPE_ID } from '../../../config';
import { useHistory, useLocation } from 'react-router-dom';
import { checkSub, getStripePlan } from '../../../utils/SubUtils';
import * as Analytic from '../../../utils/Analytic';
import { SUB_TIER } from '../../../Const';
import { subscribeBot } from '../../../actions/subscription-action';
import { toast } from 'react-toastify';

const RentBotCardForm = (props) => {
  const {  cardHolder, subscribeBot, plan,template,setLoading } = props;

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [subscribing, setSubscribing] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const location = useLocation();

  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (success) {
      history.push(
        `/bots`,
        { from: location }
      );
    } 
    // else if (subPremium && subTier === SUB_TIER.pro) {
    //   redirectBack();
    // }
  }, [success]);

  const redirectBack = () => {
    history.push('/bots');
  };

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setLoading(false)
      setError(String(error.message));
    } else {
      setSubscribing(true);
      subscribeBot(
        template,
        'stripe',
        plan,
        paymentMethod.id,
        cardHolder
      )
        .then(() => {
          //Analytic.sendEvent(Analytic.subscribeSuccess);
          setSuccess(true);
          //toast.success(`You are now subscribed to ${template.title}.`)
          setShowSuccessDialog(true)
          setLoading(false)
          setSubscribing(false);
        })
        .catch((err) => {
          //setShowSuccessDialog(true)
          setError(err);
          setLoading(false)
          setSubscribing(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-5">
          <label className="font-weight-bold">Card Number</label>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="">
          <Button
            type="submit"
            disabled={!stripe || subscribing}
            variant="outline-success"
            className="w-100"
          >
            {subscribing ? 'Processing..' : 'Confirm'}
          </Button>
          <Alert
            show={success || error != null}
            variant={success ? 'success' : 'danger'}
            className="mt-3"
          >
            {success ? 'Payment is success.' : error}
          </Alert>
        </div>
      </form>
      <Modal show={showSuccessDialog} onHide={() => setSuccess(true)} size='lg'>
        <Modal.Header>
          <Modal.Title>Subscription Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body className='subscription-success-modal'>
          <p>You are now subscribed to {template.title}. </p>
          <a className='link-green' href='https://docs.cryptohero.ai/getting-started/deploying-a-marketplace-bot'>Learn how to deploy the Marketplace bot</a>
          </Modal.Body>
          
        <Modal.Footer>
          <Button variant="success" onClick={() => setSuccess(true)} className='center'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { subscribeBot })(RentBotCardForm);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#002352',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};
