import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import {useSelector } from "react-redux";
import { Frequencies, FrequencyValues,BotTypeName } from "../../../Const";
import LeftRightForm from "../new-bot/modules/LeftRightForm";
import InfoDisplay from "../new-bot/modules/InfoDisplay";
import { capitalize } from "../../../utils/Utils";

const SimpleBotSetting = (props) => {
  const { bot,t } = props;
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const stoploss = bot.stop_loss || 0;
  const theme = useSelector((state) => state.settings.selected_theme);
  const entryIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "entry")
      .map((item) => item.name)
      .join(", ");
  const exitIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "exit")
      .map((item) => item.name)
      .join(", ");

  return (
    <div className="col-lg-6 col-md-6">
    <div className="bot-detail-left">
      <div className="bot-detail-left-header">
        <label>
          <img src="/images/overview-icon.svg" alt="" width={20}
                />
          Overview
        </label>
        <label>
          <img src={exchange.image} alt="" height={20}/>
          {exchange.name}
        </label>
      </div>
      <div className="bot-detail-left-body">
        <div className="row">
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("pair")}</span>
              <h6>{pair && `${pair.from}/${pair.to}`}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("bot-type")}</span>
              <h6>{bot && BotTypeName[bot.type]}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("strategy")}</span>
              <h6 >{bot && bot.strategy}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("Frequency")}</span>
              <h6>{bot && Frequencies[FrequencyValues.indexOf(bot.frequency)]}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item" >
              <span>{t("entry-indicators")}</span>
              <h6>{entryIndicators}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("exit-indicators")}</span>
              <h6>{exitIndicators}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("tp")}</span>
              <h6>{bot && bot.profit && parseFloat(bot.profit).toFixed(2)}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("stoploss")}</span>
              <h6>{stoploss}</h6>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  );
};

export default SimpleBotSetting;
