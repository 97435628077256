import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { MaxBotPerRent, RentSubscriptions, SUB_STATUS, SUB_TIER } from "../../../Const";
import { getMonthlyPrice, getYearlyPrice } from "../../../utils/SubUtils";
import "./RentBotPremiumInfo.scss";
import { RentPlans } from "../../../Const";
import TutorialPopup from "../../help/TutorialPopup";
import { useTranslation } from "react-i18next";
import { trimString } from "../../../utils/Utils";

const RentBotPremiumInfo = (props) => {
  const {
    isYearly,
    readOnly,
    onUpgrade,
    subStatus,
    subPremium,
    subTier,
    template,
    plan
  } = props;
  
  const history = useHistory();
  const {t} = useTranslation()

  // const clickUpgrade = () => {
  //   if (subStatus == SUB_STATUS.canceled) {
  //     onUpgrade && onUpgrade();
  //   } else if (
  //     !subPremium ||
  //     (subPremium && subTier === SUB_TIER.premium && tier === SUB_TIER.pro)
  //   ) {
  //     history.push(
  //       "/subscription?plan=" +
  //         (isYearly ? "yearly" : "monthly") +
  //         "&tier=" +
  //         tier
  //     );
  //   }
  // };
//   const clickUpgrade = () => {
//     history.push(
//       "/subscription?plan=" +
//         plan
//     );
//   };
  return (
    <>
    <div className="marketplace-card subscription">
       <div className="pos-relative marketplace-card-content">
      {/* {featured && <>
        <i className="fas fa-crown featured-icon"></i>
        <span className="featured-text">Featured</span>
        </> } */}
        <img src={template.image} className=""></img>
        <div className="marketplace-card-info-container">
          <p className="marketplace-card-title text-center w-100">{trimString(template.title,50) }</p>
          <div className="">
                <div className="w-100 d-flex justify-content-between mt-4">
                  <div className="text-center w-100 font-weight-bold">US ${template[RentSubscriptions[plan]]} / {t(RentPlans[plan])}</div>
                </div>
                <div className="w-100 d-flex justify-content-between mt-2">
                  <div className="text-center w-100 mt-4"> <i className="fas fa-check-circle item-check success"></i>{" "}
             {MaxBotPerRent} <span className="light-text"></span>{t("active-bots")}<TutorialPopup content={t("max-bot-msg")} /></div>
                </div>
               
          </div>
        </div>
      </div>
    </div>
    {/* <div className="round-bg premium-info text-center">
      <div>
          <img src={template.image} width={200} className="template-thumbnail"></img>
        <div className="header">
          <h5 className="header-row">
            {template.title}
          </h5>
          <div className="header-row row justify-content-center align-items-center h-100">
            <h5 className="text-success">
                ${template[RentSubscriptions[plan]]}
            </h5> <span className="text-success"> /{t(RentPlans[plan])}</span>
           
          </div>
        </div>
        <div>
          <h6 className="text-row">
            <i className="fas fa-check-circle item-check"></i>{" "}
             {MaxBotPerRent} {t("active-bots")}<TutorialPopup content={t("max-bot-msg")} />
          </h6>
          
        </div>
      </div>
    </div> */}
    </>
    
  );
};

export default RentBotPremiumInfo;
