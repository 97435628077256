import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import * as HelpContent from "../../utils/HelpContent"
import { connect, useDispatch, useSelector } from "react-redux";
import TutorialPopup from "../help/TutorialPopup";
import Select from "react-select";
import { PRICE_ALERT_PAIR } from "../../actions/types";
import useInput from "../hooks/useInput";
import { createPriceAlert } from "../../actions/price-alert-action";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import { futurePairName } from "../../utils/Utils";
const FuturesPriceAlertCreateModal = (props)=>{
    const {onHide,createPriceAlert,t} = props
    const selectedExchange = useSelector((state) => state.settings.selectedExchange) || {};
    const newAlert = useSelector((state) => state.priceAlert.newAlert) || {};
    const [loading, setLoading] = useState(false);
   
    const [baseCoin, setBaseCoin] = useState("perpetual");
    const [futureType, setFutureType] = useState("usd_m");
    const dispatch = useDispatch();
    const coinMDeliver = useSelector((state) => state.bots.coinMDeliver);
    const coinMPerpetual = useSelector((state) => state.bots.coinMPerpetual);
    const usdMDeliver = useSelector((state) => state.bots.usdMDeliver);
    const usdMPerpetual = useSelector((state) => state.bots.usdMPerpetual);
    const [condition, bindCondition, setCondition] = useInput(newAlert.condition?newAlert.condition:"le");
    const [price, bindPrice, setPrice] = useInput(newAlert.price?newAlert.price:0);
    
    const pairList = useMemo(() => {
        return (baseCoin === "deliver" && futureType === "coin_m")
          ? coinMDeliver
          : (baseCoin === "perpetual" && futureType === "coin_m")
          ? coinMPerpetual
          : (baseCoin === "deliver" && futureType === "usd_m")
          ?usdMDeliver:usdMPerpetual;
      }, [baseCoin,futureType]);
      const pairItems =
      (pairList &&
        pairList.map((item) => ({
          value: item.id,
          label: `${futurePairName(item.pair)}`,
          from: item.pair.from,
          to: item.pair.to,
        }))) ||
      [];
    const pair = newAlert.pair;
    const selectedPair = {
        value: newAlert.exchangePairId,
        label: `${futurePairName(pair)}`,
        from: pair.from,
        to: pair.to,
    };
    const handleSelectPair = (item) => {
        const newPair = pairList.find((el) => item.value == el.id) || {};
        dispatch({
          type: PRICE_ALERT_PAIR,
          payload: newPair,
        });
      };
    const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
        e.preventDefault()
    
    }
    const submitPriceAlert = ()=>{
        setLoading(true)
        let params = {
            exchange_id:selectedExchange.id,
            pair_id:pair.id,
            price:price,
            condition:condition,
        }
        createPriceAlert(params).then((res)=>{
            setLoading(false)
            toast.success(t("success"))
            onHide()
        }).catch((err)=>{
            toast.error(err.message)
            setLoading(false)
        })
    }
    return (
        <Loading show={loading} onHide={() => setLoading(false)}>
        <Modal show={true} onHide={onHide} size="lg" dialogClassName="modal-90w">
            <Modal.Header closeButton className="align-items-center">
              <Modal.Title>
               {t("create-p-alert")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                  <Col>
                  <Form.Group>
                    <Form.Label>
                        {t("exchange")} <TutorialPopup content={t("exchange-help")} />
                    </Form.Label>
                    <div className="mt-1 mb-3">
                        <img
                        src={selectedExchange.image}
                        width={20}
                        height={20}
                        style={{ marginRight: 10 }}
                        />
                        <span style={{ fontWeight: "bold", fontSize: 16 }}>
                        {selectedExchange.name}
                        </span>
                    </div>
                    </Form.Group>
                    <Form.Group style={{ marginTop: 20 }}>
                    <Form.Group style={{ marginTop: 20 }}>
              <Form.Label className="bot-asset-tour">
                {t("future-type")} 
              </Form.Label>
              <Row>
               

                <Col  xs={12} lg={12} className="asset-coins">
                  <Form.Group
                    as={Row}
                    className="mt-2"
                    onChange={(e) => setFutureType(e.target.value)}
                  >
                    <Form.Check
                      type="radio"
                      label="USD(S)-M"
                      name="future_type"
                      value="usd_m"
                      className="mr-3"
                      defaultChecked={futureType=="usd_m"}
                    />
                    <Form.Check
                      type="radio"
                      label="COIN-M"
                      name="future_type"
                      value="coin_m"
                      className="mr-3"
                      defaultChecked={futureType=="coin_m"}
                    />
                    
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.Label className="bot-asset-tour">
                {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} />
              </Form.Label>
              <Row>
                <Col >
                  <Select
                    value={selectedPair}
                    options={pairItems}
                    onChange={handleSelectPair}
                    className='react-select-container third-step'
                    classNamePrefix='react-select'
                  />
                </Col>

                <Col  xs={12} lg={12} className="asset-coins">
                  <Form.Group
                    as={Row}
                    className="mt-2"
                    onChange={(e) => setBaseCoin(e.target.value)}
                  >
                    <Form.Check
                      type="radio"
                      label="Perpetual"
                      name="filter"
                      value="perpetual"
                      className="mr-3"
                      defaultChecked={baseCoin=="perpetual"}
                    />
                    <Form.Check
                      type="radio"
                      label="Delivery"
                      name="filter"
                      value="deliver"
                      className="mr-3"
                      defaultChecked={baseCoin=="deliver"}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
            <Row>
                        <Col>
                        <div className="d-flex align-items-center justify-content-between price-alert-form mt-3">
                            <div>
                                <p>{t("alert-me-when-price")}</p>
                            </div>
                            <div>
                                <select {...bindCondition} className="form-control select-normal price-a-sele">
                                    <option value={`le`}>{t("less-than")}</option>
                                    <option value={`ge`}>{t("greater-than")}</option>
                                </select>
                            </div>
                            <div>
                            
                                    <label data-text={pair.to} className="input-gp-label">
                                        <Form.Control
                                            as="input"
                                            type="number"
                                            {...bindPrice} onKeyPress={(e) => {
                                            restrictAlphabets(e,e.target.value);
                                            }}
                                            className="input-text input-gp-text"
                                            ></Form.Control>
                                    </label>
                            </div>
                            
                        </div>
                        </Col>
                        </Row>
                       
                    </Form.Group>
                    <div className="d-flex justify-content-around">
                        <Button onClick={()=>onHide()}  className="w-50 mr-1">
                        {t('cancel')}
                        </Button>
                        <Button onClick={()=>submitPriceAlert()}  className="w-50 blue-btn">
                        {t("save")}
                        </Button>
                        </div>
                  </Col>
              </Row>
              </Modal.Body>
            </Modal>
            </Loading>
    )

}
export default connect(null,{createPriceAlert})(FuturesPriceAlertCreateModal)
