import React, { useState, useEffect } from "react";
import { Badge, Table } from "react-bootstrap";
import Switch from "react-switch";
import { connect,useDispatch,useSelector } from "react-redux";
import { activeBot, botFeedback, getRentBotDetail } from "../../actions/bot-action";
import NoData from "../common/NoData";
import "./BotTab.scss";
import { decimalOf, isLong } from "../../utils/Utils";
import { toast } from "react-toastify";
import CreateBotFromRentModal from "../marketplace/bots/CreateBotFromRentModal";
import RentBotViewDetail from "../marketplace/bots/RentBotViewDetail";
import { CREATE_BOT_START, SET_RENT_BOT_SELECTED } from "../../actions/create-bot-type";
import { resetRentBotConfig } from "../../actions/create-bot-action";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BotTabRent = (props) => {
  
  const { bots,startItem,getRentBotDetail,selectedExchangeId,itemsPerPage,pageNum,botFeedback,t,resetRentBotConfig} = props;
  const [setLoading, bindLoading] = useLoading(false);
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  //const [startItem, setStartItem] = useState(0);
  const [botSelected,setBotSelected] = useState(null)
  const [botDetailView,setBotDetailView] = useState(null)
  const history = useHistory()
  const thumbsUp="icon-like"
  const thumbsActive="icon-liked"
  const isWaiting=(bot)=>{
    return bot.status =='waiting';
  }
  const dispatch = useDispatch();
  const clickCreateBot = (bot)=>{
    if(isWaiting(bot)){
      return false
    }
    // if(bot.can_create_bot==0){
    //   toast.error(t("max-bots-reached"))
    //   return false
    // }
    resetRentBotConfig(bot);
    dispatch({
      type: SET_RENT_BOT_SELECTED,
      payload: bot,
    });
    dispatch({
      type: CREATE_BOT_START,
      payload: true,
    });
    setBotSelected(bot)
    history.push('/rent/create')
    //setShowCreateBotRentModal(true)
  }
  const clickThumbsUp = (e,val,id)=>{
    e.target.className= val==0?thumbsActive:thumbsUp
    botFeedback(val,id,selectedExchangeId,itemsPerPage,pageNum)
  }
  const showRentBotDetail = (bot)=>{
    setLoading(true);
    getRentBotDetail(bot.id).then(()=>{
      setBotSelected(bot)
      setBotDetailView(true)
      setLoading(false);
    })
    
  }
  const rows = bots
    //.slice(startItem, startItem + itemsPerPage)
    .map((bot, index) => {
      
      return (
        <tr key={bot.id} className={`theme-border ${theme}`}>
          <td>{startItem +index}</td>
          <td className="text-left"><img src={`/images/icons/bots/svg/rent.svg`} className="bot-icon-list"></img> {bot.template.title}</td>
          <td>{bot.template.wl_ratio}</td>
          <td>{bot.template.apy}%</td>
          <td>{bot.template.performance}%</td>
          <td><span className={isLong(bot.template.strategy) ? "long" : "short"}>{bot.template.strategy}</span></td>
          <td className="action-icons-td">
            <ul className="action-list">
              <li>
                <a href="#" onClick={()=>clickCreateBot(bot)} className="action-icon-link" >
                 <img src="/images/icons/plus-icon.svg" ></img>
                </a>
              </li>
              <li>
                <a href="#"  className="action-icon-link" >
                <img src={`/images/icons/${bot.thumbs_up==0?thumbsUp:thumbsActive}.svg`}  className={`${bot.thumbs_up==0?thumbsUp:thumbsActive} like`} onClick={(e)=>clickThumbsUp(e,bot.thumbs_up,bot.template.id)}></img>
                </a>
              </li>
            </ul>
            
            </td>
          <td><a>{isWaiting(bot)?t("waiting-for-payment"):<i className="far fa-eye view-icon" onClick={()=>showRentBotDetail(bot)}></i>}</a></td>
        </tr>
      );
    });
  return (
    <>
    <Loading {...bindLoading}>
    <div className="mt-4 bot-tab">
      <div className="" >
        <br></br>
        <div className="deals-table">
            <table className="">
                    <thead>
                        <tr valign="top">
                          <th>#</th>
                          <th className="text-left">{t("bt")}</th>
                          <th>{t("w-l-ratio")}</th>
                          <th>APY</th>
                          <th>{t("performance")}</th>
                          <th>{t("strategy")}</th>
                          <th>{t("action")}</th>
                          <th>{t("detail")}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {rows}
                      </tbody>
              </table>
          </div>
        </div>
      <NoData count={bots && bots.length} text="You have not rented any bots"/>
    
    </div>
    </Loading>
    {botDetailView && <RentBotViewDetail bot={botSelected} onHide={()=>setBotDetailView(false)} t={t}></RentBotViewDetail> }
    </>
  );
};

export default connect(null, { activeBot,resetRentBotConfig,getRentBotDetail,botFeedback })(BotTabRent);
