import Cookies from "universal-cookie";
import {
  GET_EXCHANGES,
  ADD_EXCHANGE_RESET,
  ADD_EXCHANGE_START,
  ADD_EXCHANGE_ERROR,
  ADD_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_SUCCESS,
  GET_PAPER_SETTING,
  UPDATE_PAPER_ERROR,
  UPDATE_PAPER_RESET,
  UPDATE_PAPER_START,
  UPDATE_PAPER_SUCCESS,
  GET_SETTINGS,
  GET_CURRENCIES,
  UPDATE_CURRENCY_SUCCESS,
  SELECT_EXCHANGE,
  GET_FILTERS,
  CHANGE_TIMEZONE,
  GET_USER_REFERRALS,
  TOGGLE_NAV,
  GET_PAPER_WALLET,
  UPDATE_USER_ROLE,
  GET_GA_CODE,
  UPDATE_GA_SETTING,
  SELECT_EXCHANGE_ID,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  SELECTED_THEME,
  GET_INTRO_TOUR_SETTING,
  TAKE_TOUR_START,
  TAKE_TOUR_END,
  SET_JOYRIDE_STEP,
  SET_TOUR_SIMPLE_BOT_WINDOW,
  CLOSE_TOUR_SIMPLE_BOT_WINDOW,
  NEW_USER_INTRO_POPUP,
  APP_LANGUAGE,
  FUTURE_UI,
  CHANGE_FUTURE_TYPE,
  CHANGE_RISK_FREE_RATE,
  SET_LOADING,
} from "../actions/types";
const cookies = new Cookies();
const initialState = {
  exchanges: [],
  addExchangeStart: false,
  addExchangeSuccess: false,
  addExchangeError: null,
  deleteExchangeSuccess: false,
  deleteExchangeError: null,
  paperBalances: [],
  updatePaperStart: false,
  updatePaperSuccess: false,
  updatePaperError: null,
  currencies: [],
  currency: {},
  selectedExchangeId: 0,
  selectedExchange: {},
  isFuture: false,
  futureType: "usd_m",
  timezone: "+00:00",
  refer_friends: 0,
  refer_friends_subscription: 0,
  premium_award: 0,
  refer_history: [],
  navExpand: true,
  paperWallet: {},
  userRole: "normal",
  gaEnabled: false,
  gaCode: "",
  gaQrCode: "",
  updateProfileStart:false,
  user:{},
  themes:{},
  selected_theme:'light',
  takeTour:0,
  newUserIntroPupup:0,
  startTakeTour:false,
  endTakeTour:false,
  joyRideStep:0,
  tourSimpleBotWindow:false,
  closeTourSimpleBotWindow:false,
  currentLang:cookies.get('current_lang')?cookies.get('current_lang'):"es",
  loading:false
};

const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EXCHANGES:
      return {
        ...state,
        exchanges: payload,
      };
    case ADD_EXCHANGE_SUCCESS:
      return {
        ...state,
        addExchangeStart: false,
        addExchangeSuccess: true,
        addExchangeError: null,
      };
    case ADD_EXCHANGE_ERROR:
      return {
        ...state,
        addExchangeStart: false,
        addExchangeSuccess: false,
        addExchangeError: payload,
      };
    case ADD_EXCHANGE_START:
      return {
        ...state,
        addExchangeStart: true,
        addExchangeSuccess: false,
        addExchangeError: null,
      };
    case ADD_EXCHANGE_RESET:
      return {
        ...state,
        addExchangeStart: false,
        addExchangeSuccess: false,
        addExchangeError: null,
        deleteExchangeError: null,
        deleteExchangeSuccess: false,
      };
    case DELETE_EXCHANGE_SUCCESS:
      return {
        ...state,
        deleteExchangeSuccess: true,
        deleteExchangeError: null,
        selectedExchangeId:
          payload == state.selectedExchangeId ? 0 : state.selectedExchangeId,
      };
    case DELETE_EXCHANGE_ERROR:
      return {
        ...state,
        deleteExchangeSuccess: false,
        deleteExchangeError: payload,
      };
    case GET_PAPER_SETTING:
      return {
        ...state,
        paperBalances: payload,
      };
    case UPDATE_PAPER_ERROR:
      return {
        ...state,
        updatePaperError: payload,
        updatePaperStart: false,
        updatePaperSuccess: false,
      };
    case UPDATE_PAPER_SUCCESS:
      return {
        ...state,
        updatePaperError: null,
        updatePaperStart: false,
        updatePaperSuccess: true,
      };
    case UPDATE_PAPER_START:
      return {
        ...state,
        updatePaperError: null,
        updatePaperStart: true,
        updatePaperSuccess: false,
      };
    case UPDATE_PAPER_RESET:
      return {
        ...state,
        updatePaperError: null,
        updatePaperStart: false,
        updatePaperSuccess: false,
      };
    case GET_SETTINGS:
      const currency = state.currency;
      return {
        ...state,
        ...payload,
        currency,
      };
    case GET_INTRO_TOUR_SETTING:
      return {
        ...state,
        takeTour: payload,
      };
    case NEW_USER_INTRO_POPUP:
      return {
        ...state,
        newUserIntroPupup: payload,
      };
    case APP_LANGUAGE:
      return {
        ...state,
        currentLang: payload,
      };
    case TAKE_TOUR_START:
      return {
        ...state,
        startTakeTour: payload,
      };
    case TAKE_TOUR_END:
      return {
        ...state,
        endTakeTour: payload,
      };
    case SET_JOYRIDE_STEP:
      return {
        ...state,
        joyRideStep: payload,
      };
    case SET_TOUR_SIMPLE_BOT_WINDOW:
      return {
        ...state,
        tourSimpleBotWindow: payload,
      };
    case CLOSE_TOUR_SIMPLE_BOT_WINDOW:
      return {
        ...state,
        closeTourSimpleBotWindow: payload,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        currency: payload,
      };
    case SELECT_EXCHANGE_ID:
      return {
        ...state,
        selectedExchangeId: payload,
      };
    case SELECT_EXCHANGE:
      return {
        ...state,
        selectedExchange: payload,
        selectedExchangeId: payload.id,
        newBot: {
          ...state.newBot,
          exchange: payload,
        },
    };
    case FUTURE_UI:
      return {
        ...state,
        isFuture: payload,
      };
    case CHANGE_FUTURE_TYPE:
      return {
        ...state,
        futureType: payload,
      };
    case CHANGE_RISK_FREE_RATE:
      return {
        ...state,
        risk_free_rate: payload,
      };
    case GET_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case CHANGE_TIMEZONE:
      return {
        ...state,
        timezone: payload,
      };
    case GET_USER_REFERRALS:
      return {
        ...state,
        ...payload,
      };
    case TOGGLE_NAV:
      return {
        ...state,
        navExpand: payload,
      };
    case GET_PAPER_WALLET:
      return {
        ...state,
        paperWallet: { ...state.paperWallet, ...payload },
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        userRole: payload,
      };
    case GET_GA_CODE:
      return {
        ...state,
        gaCode: payload.gaCode,
        gaQrCode: payload.gaQrCode,
      };
    case UPDATE_GA_SETTING:
      return {
        ...state,
        gaEnabled: payload,
      };
    case UPDATE_USER_PROFILE_START:
      return {
        ...state,
        updateProfileStart: true,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileStart: false,
        user:payload
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateProfileStart: false,
      };
    case SELECTED_THEME:
      return {
        ...state,
        selected_theme: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default settingReducer;
