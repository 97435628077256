import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getDashboardAssets } from "../../actions/dashboard-action";
import { CHANGE_FUTURE_TYPE } from "../../actions/types";
import PnlData from "./PnlData";
import DailyPnlChart from "../dashboard/DailyPnlChart";
import ProfitChart from "./ProfitChart";
import ChartTimeFrames from "../dashboard/ChartTimeFrames";
import BotTypeList from "./BotTypeList";


const BotPageOverview = (props) => {
  const { t, getDashboardAssets } = props;
  const isFuture = useSelector((state) => state.settings.isFuture);
  const futureTypeState = useSelector((state) => state.settings.futureType);
  const dailyPnl = useSelector((state) => state.dashboard.dailyPnl) || {};
  const currency = useSelector((state) => state.settings.currency);
  const allPnL = (dailyPnl.pnl && dailyPnl.pnl.fiat) || "0";
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [timeframe, setTimeframe] = useState("1M");
  const [showGraph, setShowGraph] = useState(false);
  const dispatch = useDispatch();
  const [futureType, setFutureType] = useState(isFuture ? "usd_m" : "spot");
  useEffect(() => {
    setFutureType(isFuture ? "usd_m" : "spot");
  }, [isFuture]);
  useEffect(() => {
    dispatch({
      type: CHANGE_FUTURE_TYPE,
      payload: futureType,
    });
  }, [futureType]);
  const {
    trading_volume,
    portfolio_value,
    portfolio_changed,
  } = useSelector((state) => state.portfolio);
  const changeUp = portfolio_changed && portfolio_changed > 0;
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

  useEffect(() => {
    getDashboardAssets(selectedExchangeId);
  }, [timeframe, selectedExchangeId, futureType]);

  const toggleGraph = () => {
    setShowGraph(!showGraph);
  };
  
  
  return (
    <>
      <div className="chart-container">
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="panel">
            <div className="panel-heading " role="tab" id="headingOne">
              <div className="panel-title">
                
                <div className="chart-arrow">
                <a
                 
                  role="button"
                  data-toggle="collapse"
                  data-target="#graphCollapse"
                  aria-expanded="false"
                  aria-controls="graphCollapse"
                  onClick={toggleGraph}
                ><i className="fas fa-chevron-down"></i></a>
                </div>
                
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-6 col-sm-8 v-pad">
                      <PnlData t={t}></PnlData>
                    </div>
                    
                     <BotTypeList></BotTypeList>
                    
                  </div>
                
              </div>
            </div>
            <div className={`transition-div ${showGraph ? "show" : ""}`} id="graphCollapse">
              <div className="panel-body">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 v-pad chart-graph">

                    <ProfitChart data={{t,timeframe,selectedExchangeId}}/>
                    <ChartTimeFrames timeframe={timeframe} setTimeframe={setTimeframe}/>
                    
                    <div className="graph-desc">
                      <ul className="green-bullet">
                        <li>Portfolio Value</li>
                      </ul>
                      <ul>
                        <li>Trading Volume</li>
                      </ul>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div className="col-xl-6 col-lg-6 v-pad chart-bar">
                    <DailyPnlChart data={{futureType,selectedExchangeId,theme}}/>
                    <p className="small-text">Daily PnL</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getDashboardAssets })(BotPageOverview);



