import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import EditBotTemplate from "../edit/EditBotTemplate";
import "./TemplateRow.scss"
import { TemplateStatus } from "../../../Const";
import { trimString } from "../../../utils/Utils";
const TemplateRow = (props)=>{
    const {template,onEditClick,clickDelete,viewSettings,t} = props;
    //console.log(clickEditTemplate)
    return (
        <>
                
                    <div className="marketplace-card">
                    
                    <div className="pos-relative marketplace-card-content">
                   
                        <img src={template.image} className=""></img>
                        <div className="marketplace-card-info-container">
                            <p className="marketplace-card-title">{trimString(template.title,50) }</p>
                            <div className="">
                                <div className="w-100 d-flex justify-content-between mt-2">
                                    <div className="mp-label">{t('ar')}</div>
                                    <div className="mp-label-value">{template.current_renter}</div>
                                </div>
                                <div className="w-100 d-flex justify-content-between mt-2">
                                    <div className="mp-label">{t("life-earn")}</div>
                                    <div className="mp-label-value">{template.lifetime_earn}</div>
                                </div>
                                <div className="w-100 d-flex justify-content-between mt-2">
                                    <div className="mp-label">{t("strategy")}</div>
                                    <div className="mp-label-value"><span className={template.strategy=="Long"?"long":"short"}>{template.strategy}</span></div>
                                </div>
                                <div className="w-100 d-flex justify-content-between mt-2 template-action-div ">
                                        <img src={`/images/icons/icon-eye-on.svg`} className="template-action-icons" onClick={()=>viewSettings(template)}></img>
                                        <img src={`/images/icons/icon-edit.svg`} className="template-action-icons" onClick={()=>onEditClick(template)}></img>
                                        <img src={`/images/icons/icon-trash.svg`} className="template-action-icons" onClick={()=>clickDelete(template.id)}></img>
                                </div>
                        </div>
                        {/* <span className="pull-right bold"><span className="bold action-menu-icon" onClick={showOptions}><i className="fas fa-ellipsis-h menu-icon"></i></span></span> */}
                        {/* <span className={`template-badge template-${template.status}`}>{t(TemplateStatus[template.status])}</span> */}
                    </div>
                        
                </div>
                </div>
         </>
    )
}
export default connect(null,{})(TemplateRow)