import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form, Table } from "react-bootstrap";

import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { decimalOf, formatDate, isLong, normalizeTime } from "../../../utils/Utils";
import { cancelRental } from "../../../actions/rent-bot-action";
import { toast } from "react-toastify";
import { getRentalBots } from "../../../actions/bot-action";
import { MaxBotPerRent } from "../../../Const";
import CancelRentalConfirm from "./CancelRentalConfirm";
import { Link } from "react-router-dom";
import RenewBotRentCryptoModal from "../subscription/RenewBotRentCryptoModal";




const RentBotViewDetail = (props) => {
  const {
    onHide,
    bot,
    cancelRental,
    getRentalBots,
    t
    
  } = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const rent =  useSelector((state) => state.bots.rent) || {};
  const [setLoading, bindLoading] = useLoading(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCryptoPayment, setShowCryptoPayment] = useState(false);
  const [network, setNetwork] = useState('ERC');

  const botItems= rent.bots.map((item,key)=>{
    return (
      <tr key={key}>
        <td>
          {key+1}
        </td>
        <td>
          {item.name}
        </td>
        <td><img src={item.exchange.image} width={20} height={20} className="mr-2"></img>{item.exchange.name}</td>
        <td>{item.pair.from +'/'+ item.pair.to}</td>
        <td> <span className={isLong(item.strategy) ? "text-success" : "text-danger"}>{item.strategy}</span></td>
        <td>{item.status}</td>
      </tr>
    )
  })

  const dispatch = useDispatch();
  const clickDeleteRental = ()=>{
    setLoading(true)
    cancelRental(bot.id).then(()=>{
      setLoading(false)
      onHide()
      getRentalBots()
      toast.success("Subscription Cancelled!");
    }).catch((err)=>{
      setLoading(false)
      toast.error(err);
    })
  }

  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
        
        <Modal.Body>
        <Modal.Header closeButton className="align-items-center">
        <Modal.Title>
           
           {bot.template.title}
          
        </Modal.Title>
        </Modal.Header>
        
        <Row>
          <Col lg={12}>
        <div className="mt-4 bot-tab">
          
         {/* <h2>{bot.template.title}</h2> */}
         <div className="skltbs-panel">
                      <div className="open-table-content otc">
                        <table className="table">
                        <tbody>
                        <tr>
                          <td>{t("return")}</td>
                          <td>{t("w-l-ratio")}</td>
                          <td>{t("total-deals")}</td>
                          <td>{t("bots-created")}</td>
                          <td>{t("sub-type")}</td>
                          <td>{t("sub-fee")}</td>
                          <td>{t("p-method")}</td>
                          <td>{t("ex-date")}</td>
                          
                        </tr>
                      <tr>
                        <td>
                          {rent.avg_return && rent.avg_return.toFixed(3)}%
                        </td>
                        <td>{rent.wl_ratio}%</td>
                        <td>{rent.total_deal}</td>
                        
                        <td>
                          {rent.bots && rent.bots.length} of {MaxBotPerRent}
                        </td>
                        <td>
                          {rent.type}
                        </td>
                        <td>
                          US${rent.fee}
                        </td>
                        <td>
                          {rent.payment_method}
                        </td>
                        <td>
                          {formatDate(rent.end)}
                        </td>
                        
                      </tr>
                </tbody>
              </table>
              </div>
              </div>
            </div>
            </Col>
            </Row>

            <Row>
              <Col lg={12}>
              {(rent.bots && rent.bots.length > 0) && (<>
                <div className="mt-4 bot-tab">
              
              <h2>Bots From this Rental</h2>
              <div className="skltbs-panel">
                  <div className="open-table-content otc">
                    <table className="table">
                      <thead>
                            <tr>
                            <td scope="col">No.</td>
                            <td scope="col">Bot Name</td>
                            <td scope="col">Exchange</td>
                            <td scope="col">Pair</td>
                            <td scope="col">Strategy</td>
                            <td scope="col">Status</td>
                            </tr>
                      </thead>
                      <tbody>
                          {botItems}
                      </tbody>
                      
                    </table>
                  </div>
              </div>
              </div>
              </>
            )}
              </Col>
            
            </Row>
            <div className="mdcp-btn text-center">
              {(rent.payment_method=='usdt' && (rent.status=='renting' || rent.status=='expired') )&& 
            <button variant="success" className="blue-button mr-20" onClick={()=>setShowCryptoPayment(true)}>
             Renew
            </button>}
              <button
                variant="danger"
                onClick={()=>setShowConfirm(true)}
                className="blue-button mr-4"
              >
                Cancel Rental
              </button>
            </div>
        </Modal.Body>
      </Modal>
      <RenewBotRentCryptoModal
          show={showCryptoPayment}
          monthly={true}
          plan={rent.type}
          template={bot.template}
          rent={rent}
          onHide={() => setShowCryptoPayment(false)}
          // sendEmail={() => setShowContactForm(true)}
      />
      {showConfirm && <CancelRentalConfirm onHide={()=>setShowConfirm(false)} onConfirm={clickDeleteRental} bot={bot}></CancelRentalConfirm> }
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {cancelRental,getRentalBots
})(RentBotViewDetail);
