import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { changePassword, resetUpdatePass } from "../../actions/auth-action";
import "./AccountPanel.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateFormula } from "../../actions/setting-action";
import TutorialPopup from "../help/TutorialPopup";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import { restrictAlphabets } from "../../utils/Utils";

const labelWidth = 3;

const FormulaPanel = (props) => {
  const { updateFormula} = props;
  const {t} = useTranslation()
  const rfr = useSelector((state) => state.settings.risk_free_rate);
  const updateProfileStart = useSelector((state) => state.settings.updateProfileStart);
  const [newRfr,updateRfr] = useState(rfr)

 
  const clickChangeName = () => {
    updateFormula({
        name:"risk_free_rate",
        value:newRfr
    })
      .then(() => {
        toast.success(t("update-success"))
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div className="setting-general-content">
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6>{t("rfr")}<TutorialPopup content={t("risk-free-help")} /></h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9 d-flex gap20">
                      <Form.Control type="text"  value={newRfr} onChange={(e)=>updateRfr(e.target.value)}  onKeyPress={(e) => {
                  restrictAlphabets(e);
                }} className="input-text"/>
              </div>
            </div>
            <br></br>
            <div className="row">
            <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  
                </div>
              </div>
              <div className="col-xl-10 col-md-9 d-flex gap20">
              <button onClick={clickChangeName} className="blue-button">
                {updateProfileStart ? t("updating") : t("update")}
              </button>
              </div>
            </div>
      </div>
  );
};

export default connect(null, {updateFormula })(FormulaPanel);
