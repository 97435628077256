import React from "react";
import { Form } from "react-bootstrap";

const FutureTypeSelector = (props)=>{
    const {setFutureType} = props;
    return(
        <>
            <Form.Control
                as="select"
                onChange={(e) => setFutureType(e.target.value)}
                //value={bot}
                className="select-normal futuretype-select"
            >
                <option value={"usd_m"}>USD(S)-M</option>
                <option value={"coin_m"}>Coin-M</option>
            </Form.Control>
        </>
    )
}

export default FutureTypeSelector;