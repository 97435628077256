import {
  GET_AFFILIATE_RANK,
  GET_AFFILIATE_REWARD_HISTORY,
  GET_AFFILIATE_POINT_HISTORY,
  GET_AFFILIATE_REWARD_REDEEM_HISTORY,
  GET_AFFILIATE_SOCIAL_POSTS,
  GET_AFFILIATE_POINT_REDEEM_HISTORY,
} from "../actions/types";

const initState = {
  ranks: [],
  rewardsHistories: [],
  pointHistories: [],
  socialPosts: [],
  rewardRedeemHistories: [],
  pointRedeemHistories: [],
  totalUsd: 0,
  redeemableUsd: 0,
  totalPoints: 0,
};

const affiliateReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_AFFILIATE_RANK:
    case GET_AFFILIATE_REWARD_HISTORY:
    case GET_AFFILIATE_POINT_HISTORY:
    case GET_AFFILIATE_REWARD_REDEEM_HISTORY:
    case GET_AFFILIATE_SOCIAL_POSTS:
    case GET_AFFILIATE_POINT_REDEEM_HISTORY:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default affiliateReducer;
