import React from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { MIN_PRICE_GAP_PROFIT } from "../../../actions/create-bot-type";
import SliderView from "./SliderView";

const MinimumPriceGapModule = (props) => {
  const {newBot,t}  = props;
  const formatDecimal = (value,decimals) => parseFloat(Number(value).toFixed(decimals));
  const dispatch = useDispatch();
  const minPriceGapChanged = (val)=>{
    dispatch({
      type: MIN_PRICE_GAP_PROFIT,
      payload: val?val:0,
    });
  }
  return (
    <>
      <SliderView
          title={t("min-price-gap-extra")}
          step={0.5}
          min={0}
          max={100}
          value={formatDecimal(newBot.minPriceGapProfit,2)}
          onChange={minPriceGapChanged}
          //formatLabel={formatTakeProfit}
          hideLabel={true}
          withInput={true}
        />
    </>
  );
};

export default MinimumPriceGapModule;
