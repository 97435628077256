import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

const TemplateSettingsInfo = (props)=>{
    const {template,t}=props
    const AuthorShortName = template.author_name && template.author_name.charAt(0).toUpperCase()
    return (
        <>
            <div className="round-bg">
            
                <Col xs={12} className="no-padding">
                    <Row className="center-align" xs={12}>
                    <Col xs={6}>
                        {/* <h3>Title</h3> */}
                        <h5>{template.title}</h5>
                        
                    </Col>
                    
                    <hr></hr>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h5>{t("description")}</h5>
                            <p>{template.description}</p>
                        </Col>
                        <hr></hr>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <h5>{t("Template Information")}</h5>
                            <ul className="template-performance-div">
                                <li>{t("curr-earnings")}:{template.current_earn && template.current_earn.toFixed(2)}</li>
                                <li>{t("ar")}: {template.current_renter}</li>
                                <li>{t("strategy")}: {template.strategy}</li>
                                <li>{t("total-renters")}: {template.total_sub}</li>
                                <li>{t("status")}: {template.status}</li>
                            </ul>
                        </Col>
                        <Col xs={4}>
                            <h5>{t("bot-performance")}</h5>
                            <ul className="template-performance-div">
                                <li>APY: {template.apy}%</li>
                                <li>{t("performance")}: {template.performance}%</li>
                                <li>{t("w-l-ratio")}: {template.wl_ratio}%</li>
                                <li>{t("total-deals")}: {template.total_deal}</li>
                                <li>{t("a-m-d")}: {template.avg_monthly_deal}</li>
                            </ul>
                        </Col>
                        <Col xs={4}>
                            <h5>{t("payment-sett")} </h5>
                            <ul className="template-performance-div">
                                <li>{t("m-fee")}: US${template.monthly_fee}</li>
                                <li>{t("q-fee")}: US${template.quarterly_fee}</li>
                                {/* <li>Half Yearly Fee: {template.half_yearly_fee}</li> */}
                                <li>{t("y-fee")}: US${template.yearly_fee}</li>
                                {/* <li>One Time Fee: US${template.onetime_fee}</li> */}
                            </ul>
                        </Col>
                    </Row>
                    
                    
                   
                </Col>
            </div>
        </>
    );
}
export default connect(null,{})(TemplateSettingsInfo)