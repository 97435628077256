import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const BotListEmpty  = (props)=>{
    const {t,selectedTab} = props
    const messages = {
      active:"You don't have any active bots at the moment",
      inactive:"You don't have any inactive bots at the moment",
      all:"You don't have any active/inactive bots at the moment"
    }
    return(
      <div className="dashboard-newuser-area">
       <div className="bot-create-area">
								<div className="bot-create-item">
									<h3>{messages[selectedTab]}</h3>
									<p></p>
                  <p>You can  visit our Strategy Marketplace to deploy proven strategies setup by other experienced users</p>
									<Link to={'/marketplace'}><button>Go to Strategy Marketplace</button></Link>
								</div>
							</div>
      </div>
    )
}

export default BotListEmpty;