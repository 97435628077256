import React from "react";
import { Table } from "react-bootstrap";
import { formatNumberToString, roundNumber } from "../../utils/Utils";
import { useSelector } from "react-redux";
import NoData from "../common/NoData";
const FutureAssetsTab = (props) => {
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const { coins, isTotal,t } = props;

  const coinItems =
    coins &&
    coins.map((item, index) => {
      return (
        <tr key={index} className={`theme-border ${theme}`}>
          <td className="text-left">
            <img
              src={item.image || require("../../images/coin_pl.png")}
              height={15}
            />
            <span className="ml-2">
              {item.name} ({item.symbol})
            </span>
          </td>
          <td>
            {formatNumberToString(item.amount)} {item.symbol}
          </td>
          <td>{roundNumber(item.total)} USD</td>
        </tr>
      );
    });
  return (
    <div className="position-table-area">
      <div className="row">
        <div className="col-lg-12">
          <div className="skltbs-panel-group">
            
            <div className="skltbs-panel">
              <div className="open-table-content otc Portfolio-table-content">
                <table className="table">
                      <tbody>
                          <tr valign="top">
                          <th>Asset</th>
                          <th>{t("balance")}</th>
                          <th>{t("margin-balance")}</th>
                          </tr>
                          {coinItems}
                      </tbody>
                  </table>
                  <NoData count={coinItems && coinItems.length} />
              </div>
        </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default FutureAssetsTab;
