import React from "react";
import { Row, Col } from "react-bootstrap";
import "./SegmentController.scss";

const SegmentController = (props) => {
  const { tabs, activeTab, changeTab } = props;
  const tabHeaders = tabs.map((item, index) => {
    return (
      <Col key={index}>
        <a
          onClick={() => changeTab(item)}
          className={item == activeTab ? "tab-active" : "tab-inactive"}
        >
          {item}
        </a>
      </Col>
    );
  });
  return (
    <div className="segment-controller" style={{ ...props.style }}>
      <Row noGutters>{tabHeaders}</Row>
    </div>
  );
};

export default SegmentController;
