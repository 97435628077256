import React from "react";

const CustomSwitch = (props)=>{
    const {checked,onChange} = props
    return(
        <div className="check-box custom-input-check">
            <input type="checkbox" checked={checked} onChange={(e)=>{onChange(e.target.checked)}}/>
        </div>
    )
}

export default CustomSwitch;