import React from "react";
import { useDispatch } from "react-redux";
import {
  CHANGE_DCA_NO_LIMIT_FUND,
  CHANGE_DCA_ORDER,
  CHANGE_FUND,
} from "../../../../actions/create-bot-type";
import LeftRightForm from "../modules/LeftRightForm";
import CustomCheckbox from "../modules/CustomCheckbox";
import { Form } from "react-bootstrap";
import { restrictAlphabets } from "../../../../utils/Utils";

const DcaParamsView = (props) => {
  const { newBot, currentCoin,t } = props;
  const dispatch = useDispatch();
  const changeDcaNoLimit = (val) => {
    dispatch({
      type: CHANGE_DCA_NO_LIMIT_FUND,
      payload: val,
    });
  };
  const changeDcaOrder = (val) =>
    dispatch({
      type: CHANGE_DCA_ORDER,
      payload: val,
    });
  return (
    <div>
      <br></br>
      <LeftRightForm
        left={<>{t("per-order-amount")}</>}
        right={<><label data-text={currentCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={newBot.dcaOrder}
            onChange={(e) => {
              changeDcaOrder(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <br></br>
      <CustomCheckbox 
             val={newBot.dcaNoLimitFund} 
             checked={newBot.dcaNoLimitFund} 
             callback={changeDcaNoLimit}
             label={t("no-limit")}
             id={t("no-limit")}
             />
      </>
      }
      />
    </div>
  );
};

export default DcaParamsView;
