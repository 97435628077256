import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { APP_LANGUAGE } from "../../actions/types";

const LanguageSelector = (props)=>{
    const cookies = new Cookies();
    const dispatch = useDispatch();
    var userLang = cookies.get("current_lang") || navigator.language || navigator.userLanguage; 
    let lang = userLang?userLang.split("-")[0]:"en";

    if(lang){
        cookies.set('current_lang', lang, { path: '/' });
    }
    const [language,setLanguage] = useState(lang)
    const changeLanguage = (lang)=>{
        dispatch({
            type:APP_LANGUAGE,
            payload:lang
        })
        setLanguage(lang)
        cookies.set('current_lang', lang, { path: '/',maxAge:60 * 60 * 24 * 365 * 10 });
        window.location.reload()
    }
    
    
    return (
        <>
            <NavDropdown title={<i className="fas fa-globe-africa" style={{fontSize:"23px"}}></i>} id="basic-nav-dropdown" className="lan-drop">
                <NavDropdown.Item className="d-flex justify-content-between language-label" onClick={()=>changeLanguage("en")}> English {language =="en" && <i className="far fa-check-circle globe-icon"></i>}</NavDropdown.Item>
                <NavDropdown.Item className="d-flex justify-content-between language-label" onClick={()=>changeLanguage("es")}>Spanish {language =="es" && <i className="far fa-check-circle globe-icon"></i>}</NavDropdown.Item>
                <NavDropdown.Item className="d-flex justify-content-between language-label" onClick={()=>changeLanguage("id")}>Bahasa Indonesia {language =="id" && <i className="far fa-check-circle globe-icon"></i>}</NavDropdown.Item>
            </NavDropdown>
        </>
        
    )
}
export default connect(null,{})(LanguageSelector)