import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  InputGroup,
} from "react-bootstrap";
import useInput from "../hooks/useInput";
import "./RepayModal.scss";
import { connect, useSelector } from "react-redux";
import { accountRepay, accountTransfer } from "../../actions/terminal-action";
import { toast } from "react-toastify";
import { accountRepayUrl } from "../../actions/const";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import { restrictAlphabets } from "../../utils/Utils";

const RepayModal = (props) => {
  const {
    show,
    onHide,
    baseCoin,
    quoteCoin,
    pairId,
    accountRepay,
    setLoading,
    account,
  } = props;
  const coins = [baseCoin, quoteCoin];

  const [coin, setCoin] = useState(baseCoin);
  const [amount, bindAmount, resetAmount, setAmount] = useInput("0");
  const [available, setAvailable] = useState(0);
  const [debt, setDebt] = useState(0);
  const base = useSelector((state) => state.terminal.base) || {};
  const quote = useSelector((state) => state.terminal.quote) || {};
  const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

  useEffect(() => {
    if (base.coin_name === coin) {
      setAvailable(base.asset_value || 0);
      setDebt(base.debt || 0);
    }
    if (quote.coin_name === coin) {
      setAvailable(quote.asset_value || 0);
      setDebt(quote.debt || 0);
    }
  }, [coin, show]);

  const clickMax = () => {
    setAmount(Math.min(available, debt));
  };

  const clickRepay = () => {
    setLoading(true);
    const params = {
      exchange_id: exchangeId,
      pair_id: pairId,
      account,
      coin,
      amount,
    };
    accountRepay(params)
      .then(() => {
        setLoading(false);
        toast.success("Repaid successfully");
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const coinItems = coins.map((item) => (
     <option key={item} value={item}>
     {item}
   </option>
  ));
  return (
    <Modal show={show} onHide={onHide} id="repay">
      <Modal.Header closeButton>
        <Modal.Title>Repay</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="repay-select">
          <Col>
            <span>Select coin:</span>
          </Col>
          <Col>
            
            <select
                onChange={(e) => setCoin(Number(e.target.value))}
                className="form-control select-normal "
              >
                {coinItems}
            </select>
          </Col>
        </Row>
        <Row className="repay-balance">
          <Col>Available Balance:</Col>
          <Col className="repay-balance-value">
            {available} {coin}
          </Col>
        </Row>
        <Row className="repay-debt">
          <Col>Total Debt:</Col>
          <Col className="repay-debt-value">
            {debt} {coin}
          </Col>
        </Row>
        <LeftRightForm
            left={<Form.Label>Amount</Form.Label>}
            right={  <label data-text={"Max"} className="input-gp-label" onClick={clickMax}>
            <Form.Control
                as="input"
                type="number"
                {...bindAmount}
                onKeyPress={(e) => {
                  restrictAlphabets(e);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
          </label>}
          ></LeftRightForm>
        <Row>
          <Col className="repay-confirm">
            <Button
              className="w-75"
              variant="outline-primary"
              onClick={clickRepay}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, { accountRepay })(RepayModal);
