import network from "./network";
import axios from "axios";
import { cjTrackingUrl } from "./const";

export const sendTrackingInfoCJ = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .get(cjTrackingUrl, params,dispatch, getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
