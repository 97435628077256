import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { getKeyByValue, roundNumber } from "../../utils/Utils";
import { AccountType, AccountName } from "../../Const";
import "./MarketPanel.scss";

const MarketPanel = (props) => {
  const { account, accountChanged } = props;
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const lastTickers = useSelector((state) => state.terminal.lastTickers) || [];
  const pair = selectedPair;
  const lastTicker = lastTickers.find((el) => el.s === selectedPair.s) || {
    s: selectedPair.s,
    c: 0,
  };
  const symbol = pair && pair.from + "/" + pair.to;
  const [activeTab, setActiveTab] = useState(0);
  const tabTitles = [AccountName.spot,AccountName.cross,AccountName.isolated];
  const changeTab = (item,index) => {
    setActiveTab(index);
    accountChanged(AccountType[getKeyByValue(AccountName,item)])
  };
  const tabHeaders = tabTitles.map((item, index) => {
    return (
        <a
            className={(index === activeTab ? "list-tab tab-active" : "list-tab tab-inactive") }
            onClick={() => changeTab(item,index)}
            key={index}
        >
            {item}
        </a>
    );
});

  return (
    <>
    <div className="chart-container">
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="panel">
            <div className="panel-heading " role="tab" id="headingOne">
              <div className="panel-title">
                  <div className="d-flex justify-content-around f-type-div"> 
                    <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">{symbol}</p>
                      <h2> <span
                      className={
                        lastTicker.c < pair.c
                          ? "mk-up"
                          : lastTicker.c > pair.c
                          ? "mk-down"
                          : "mk-value"
                      }
                    >
                      {pair && roundNumber(pair.c)}
                    </span></h2>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">24H Change</p>
                      <h2>  <span
                      className={pair.p > 0 ? "mk-up" : pair.p < 0 ? "mk-down" : "mk-value"}
                    >
                      {roundNumber(pair.p)} ({roundNumber(pair.P, 2)}%)
                    </span></h2>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">24H High</p>
                      <h2>  {roundNumber(pair.h)}</h2>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">24H Low</p>
                      <h2>  {roundNumber(pair.l)}</h2>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">24H Volume</p>
                      <h2>  {roundNumber(pair.v)}</h2>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div className="trading-marketpanel">
    <div className="bot-tabs mt-4">
            {tabHeaders}
    </div>
    </div>
    </>
  );
};

export default connect(null)(MarketPanel);
