import React, { useState, useEffect } from 'react';

const CustomDropDown = ({ toggleButtonId, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = (event) => {
    event.stopPropagation();
    toggleStatus()
    closeAllDropdowns()
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event) => {
    if (!event.target.closest(`#${toggleButtonId}`)) {
      const mruIcon = document.getElementById(toggleButtonId);
      mruIcon.classList.remove('white-text','primary-bg');
      setIsOpen(false);
    }
  };
  const toggleStatus = ()=>{
    const mruIcon = document.getElementById(toggleButtonId);
    mruIcon.classList.toggle('white-text');
    mruIcon.classList.toggle('primary-bg');
  }
  const closeAllDropdowns = () => {
    const openDropdowns = document.querySelectorAll('.mru-dblock');
    openDropdowns.forEach((dropdown) => {
      dropdown.classList.remove('mru-dblock');
    });
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  return (
    <>
       {React.cloneElement(children[0], { onClick: toggleDropdown })}
     
        <div className={`mr-user-dropdown3 ${isOpen ? 'mru-dblock' : ''}`}>
            {children[1]}
        </div>
    </>
    
   
  );
};

export default CustomDropDown;
