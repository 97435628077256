import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { connect, useSelector } from "react-redux";
import { getSummary } from "../../../actions/marketplace-action";
import TutorialPopup from "../../help/TutorialPopup";
import {Period, PortfolioColors, TimeWindow} from "../../../Const";
import EarningsChart from "./EarningsChart";
import ChartTimeFrames from "../../dashboard/ChartTimeFrames";
//import "./PortfolioPanel.scss";

const TimeFrames = ["3D", "1W", "1M", "3M", "1Y", "ALL"];

const EarningsPanel = (props) => {
  const { t} = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const marketplaceDashboard =  useSelector((state) => state.marketplace.marketplaceDashboard) || {};
  const [timeframe, setTimeframe] = useState("1M");
  const change = marketplaceDashboard && marketplaceDashboard.earn_change >=0?'up':'down';
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const btnItems = TimeFrames.map((item, index) => {
    return (
      <Col key={index}>
        <Button
          className={timeframe == item ? "button-active" : "button-inactive"}
          onClick={() => setTimeframe(item)}
        >
          {item}
        </Button>
      </Col>
    );
  });
  return (
    
        <div className="earnings-view-div chart-container">
          <Row>
            <Col>
                <div className="d-flex justify-content-between pr-20">
                    <div>
                      <div>
                        <p>{t("curr-earnings")}</p>
                      </div>
                      <div className="d-flex align-items-center">
                          <div><h5 className="bold">US${marketplaceDashboard.current_earn && marketplaceDashboard.current_earn.toFixed(2)}</h5></div>
                          <div><span className={`change-${change}`}><img
                          src={`images/icons/${change}.png`}
                          alt=""
                          style={{width:'15px',marginTop:'1px' }}
                        />{marketplaceDashboard.earn_change}%</span></div>
                      </div>
                    </div>
                    <div>
                      <p className="nmb">{t("total-renters")}<TutorialPopup content="Total lifetime renters = Active + Cancelled"/></p> 
                      <h5 className="bold">{marketplaceDashboard.total_renter}</h5>
                    </div>
                    <div>
                    <p className="nmb">{t("new-renters")}  <TutorialPopup content={timeframe=="ALL"?'New renters who rented bots in the entire period':`New renters who rented bots in the last ${Period[timeframe]}`}/></p> 
                        <h5 className="bold">{marketplaceDashboard.new_renter}</h5>
                    </div>
                </div>
            </Col>
          </Row>
          
            <Row className="earnings-chart-div">
              <Col xl={12} className="no-pad">
                  <EarningsChart data={{timeframe,selectedExchangeId}}/>
                  <ChartTimeFrames timeframe={timeframe} setTimeframe={setTimeframe}/>
              </Col>
            </Row>
        </div>
      
    
  );
};

export default connect(null, { getSummary })(EarningsPanel);

const dataMix = (portfolio, volume,theme) => ({
  datasets: [
    {
      type: "line",
      label: "Earnings",
      data: portfolio,
      backgroundColor: PortfolioColors[theme][1],
      borderColor: PortfolioColors[theme][1],
      yAxisID: "y-axis-1",
      lineTension: 0.3,
      borderWidth: 1.8,
    },
    {
      label: "Renters",
      type: "line",
      data: volume,
      borderColor: PortfolioColors[theme][2],
      backgroundColor: PortfolioColors[theme][2],
      yAxisID: "y-axis-2",
      lineTension: 0.3,
      borderWidth: 1.8,
    },
  ],
});

const optionsMix = (xLabel) => {
  const fontSize = 9;
  const fontColor = "#4c556a";
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontColor,
        boxWidth: 15,
        padding: 25,
      },
    },
    responsive: true,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 2,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          labels: xLabel,
          ticks: {
            fontColor,
            fontSize,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          labels: {
            show: true,
          },
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor,
            fontSize,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            display: false,
          },
          labels: {
            show: false,
          },
          ticks: {
            fontColor,
            fontSize,
          },
        },
      ],
    },
  };
};
