import {
  
  } from "./types";
  import { cancelRentalUrl } from "./const";
  import network from "./network";
 
  export const cancelRental = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .delete(cancelRentalUrl+id, {}, dispatch, getState)
        .then((res) => {
          const { code, message } = res.data;
          if (code == 200) {
            resolve();
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
  };
  