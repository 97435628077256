import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getDailyPnl } from "../../actions/dashboard-action";
import { getBotProfit } from "../../actions/bot-action";
import { currencyFormat } from "../../utils/Utils";

const BalanceInfo = (props)=>{
    const {t,selectedExchangeId, getBotProfit,getDailyPnl} = props
    const profit = useSelector((state) => state.bots.profit);
    const currency = useSelector((state) => state.settings.currency) || "USD";
    const changeUp = profit && profit.current_wl_ratio > 0;
    const dailyPnl = useSelector((state) => state.dashboard.dailyPnl) || {};
    const allPnL = (dailyPnl.pnl && dailyPnl.pnl.fiat) || "0";
    const fiatBalance = useSelector((state) => state.dashboard.assets.balance_fiat);
    const assets = useSelector((state) => state.dashboard.assets.assets||{});
    const usdmCoins = useSelector((state) => state.dashboard.assets.usd_m||{});
    const usdmAssets = usdmCoins && usdmCoins.assets||{};
    const isFuture = useSelector((state) => state.settings.isFuture);
    const getSumOfUSDTandUSDC = (assets) => {
      return assets.length > 0 && assets
        .filter(asset => asset.coin === 'USDT' || asset.coin === 'USDC')
        .reduce((sum, asset) => sum + asset.total, 0);
    };
    useEffect(() => {
      getDailyPnl(selectedExchangeId)
      }, [selectedExchangeId]);
    return (
      <div className="newuser-earning-area">
      <div className="newuser-cash-content">
        <button><img src="images/icon-cash.svg" alt="cash"/></button>
        <div>
          <span>USDT/USDC</span>
          <h5>${getSumOfUSDTandUSDC(isFuture?usdmAssets:assets)}</h5>
        </div>
      </div>
      <div className="newuser-cash-content">
        <button><img src="images/icon-trade.svg" alt="cash"/></button>
        <div>
          <span>Portfolio</span>
          <h5>${currencyFormat(fiatBalance)}</h5>
        </div>
      </div>
      <div className="newuser-cash-content">
        <button><img src="images/icon-quity.svg" alt="cash"/></button>
        <div>
          <span>Total Returns</span>
          <h5>${allPnL}</h5>
        </div>
      </div>
    </div>
    )
}

export default connect(null,{getBotProfit,getDailyPnl}) (BalanceInfo);