import React, {useState} from "react"
import { Row, Col, Card } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import {SELECT_LIBRARY_IMAGE} from "../../../actions/types"
import {TemplateImageUrl} from "../../../Const"
import CustomPagination from "../../common/CustomPagination";
import usePagination from "../../hooks/usePagination";
import { setLibraryImageSelect } from "../../../actions/image-upload-action";

const BotImageTemplateLibrary = (props)=>{
    const {setLibraryImageSelect} = props
    const [showSelectTick,setShowSelectTick] = useState(0)
    const [itemRange, bindPagination] = usePagination(1, 10);
    const [startItem, setStartItem] = useState( 0);
    const dispatch = useDispatch();
    const selectImage = (id)=>{
        setShowSelectTick(id)
        dispatch({
            type: SELECT_LIBRARY_IMAGE,
            payload: id,
          });
        setLibraryImageSelect(id)
    }
    const templateImages = Array(18).fill(0).map((item, id) => {
        return (
            <Col lg={2} key={id} className="mt-3 no-padding pos-relative">
                {showSelectTick==id+1 && <img src={`/images/icons/ticked.png`} className="check-img"/>}
                <img 
                src={`${TemplateImageUrl}/bot${id+1}.png`} 
                className="tm-lib-img"
                onClick={()=>selectImage(id+1)}></img>
            </Col>
        );
      });
   

    return (
        <>
        <Row className="template-image-gallery-div">
        {templateImages}
        </Row>
        
        
        </>
    )
}
export default connect(null,{setLibraryImageSelect})(BotImageTemplateLibrary);