import React from "react";
import {
  CHANGE_BO,
  CHANGE_BO_TYPE,
  CHANGE_EO,
  CHANGE_FREQUENCY,
} from "../../../../actions/create-bot-type";
import BooleanView from "../BooleanView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch, useSelector } from "react-redux";
import { isLong } from "../../../../utils/Utils";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub, isMaster, isPro } from "../../../../utils/SubUtils";
import LeverageSlider from "../LeverageSlider";
import MaxPositionSize from "../MaxPositionSize";
import MarginModeModule from "../futures/modules/MarginModeModule";
import OrderTypeModule from "../futures/modules/OrderTypeModule";
import PriceTypeModule from "../futures/modules/PriceTypeModule";

const FuturesPriceBotParamsView = (props) => {
  const { newBot,t,editing,balance,currentCoin} = props;
  const orderType = newBot.baseOrderType;
  const frequency = newBot.frequency;
  const baseOrder = newBot.baseOrder;
  const fund = newBot.initFund;
  
  const dispatch = useDispatch();
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const tradingFreqValues = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?FrequencyValues:FrequencyValuesPro):(newBot.botType==BotType.simple?FrequencyValuesSimple:FrequencyValues)
  const tradingFreqencies = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?Frequencies:FrequenciesPro):(newBot.botType==BotType.simple?FrequenciesSimple:Frequencies)
  

  

  // const baseOrderChanged = (val) => {
  //   dispatch({
  //     type: CHANGE_BO,
  //     payload: val,
  //   });
  // };

  // const orderTypeChanged = (val) => {
  //   dispatch({
  //     type: CHANGE_BO_TYPE,
  //     payload: val,
  //   });
  // };

  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const formartFrequency = (value) => tradingFreqencies[value];
  return (
    <div>
    <LeverageSlider 
    step={1}
    min={10}
    max={125}
    t={t}
    newBot={newBot}
    editing={editing}
    >
    </LeverageSlider>
    <div style={{ marginTop: 20 }}>
    <MaxPositionSize 
      newBot={newBot} 
      editing={editing} 
      fund={fund} 
      currentCoin={currentCoin} 
      balance={balance}
      t={t}>
    </MaxPositionSize>
    </div>
    <div style={{ marginTop: 20 }}>
      <MarginModeModule 
        newBot={newBot} 
        editing={editing} 
        t={t}></MarginModeModule>
    </div>
    <div style={{ marginTop: 20 }}>
      <OrderTypeModule 
        newBot={newBot} 
        editing={editing}  
        t={t}></OrderTypeModule>
    </div>
    <div style={{ marginTop: 20 }}>
      <PriceTypeModule 
        newBot={newBot} 
        editing={editing} 
        t={t}></PriceTypeModule>
    </div>
    <div style={{ marginTop: 20 }}>
      <SliderView
        title={t("tf")}
        max={tradingFreqValues.length - 1}
        value={tradingFreqValues.indexOf(frequency)}
        formatLabel={formartFrequency}
        onChange={(value) => frequencyChanged(tradingFreqValues[value])}
        tutorial={<TutorialPopup content={t("trading-freq-help")} />}
      />
    </div>
  </div>
  );
};

export default FuturesPriceBotParamsView;
