import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Table,
  Button,
  Alert,
} from "react-bootstrap";
import TVChartContainer from "../bots/TVChartContainer";
import {
  getDealDetail,
  closeDeal,
  resetDealDetail,
  deleteDeal,
} from "../../actions/deal-action";
import { connect, useSelector } from "react-redux";
import {
  formatDate,
  milisecOf,
  roundNumber,
  typeNameOrder,
} from "../../utils/Utils";
import { AllFrequencies, IncompletedDeals } from "../../Const";
import DealWarning from "./DealWarning";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import { BotType, IndicatorType } from "../../Const";
import { isLong } from "../../utils/Utils";
import GridBotDealTradeHistory from "./GridBotDealTradeHistory";
import { useTranslation } from "react-i18next";
import ContentContainer from "../common/ContentContainer";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BackButton from "../common/BackButton";
import DcaDealDetails from "./DcaDealDetails";
import InfoDisplay from "../bots/new-bot/modules/InfoDisplay";

const DealDetail = (props) => {
  const {
    show,
    onHide,
    getDealDetail,
    closeDeal,
    resetDealDetail,
    deleteDeal,
    selectedExchangeId,
    itemsPerPage,
    pageNum,
    selectedTab,
  } = props;
  const { dealId } = useParams();
  const {t} = useTranslation();
  const { deal, startCloseDeal, closeDealError, closeDealSuccess } =
    useSelector((state) => state.deals);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const theme = useSelector((state) => state.settings.selected_theme);
  const isFuture = useSelector((state) => state.settings.isFuture);
  const history = useHistory()
  const isCompleted = deal.status=="completed";

  useEffect(() => {
    if (dealId >= 0) {
      getDealDetail(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (closeDealSuccess) {
      setTimeout(() => {
        toast.info(t("Success"));
        history.push('/trades')
      }, 500);
    }
  }, [closeDealSuccess]);

  useEffect(() => {
    return () => {
      resetDealDetail();
    };
  }, [show]);

  const clickDelete = () => {
    setLoading(true);
    deleteDeal(dealId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)
      .then(() => {
        setLoading(false);
        toast.info(t("deal-deleted"));
        history.push('/trades')
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const indicators = (deal && deal.bot && deal.bot.bot_indicators) || [];
  const longStrategy = isLong(deal.strategy);
  const isAdvanced = deal.bot && deal.bot?.type=='advance'?true:false;
  const isGrid = deal.bot && deal.bot?.type=='grid'?true:false;
  const isDca = deal.bot && deal.bot?.type=='dca'?true:false;
  const entryIndicators = indicators
    .filter((el) => el.type === "entry")
    .map((indicator) =>{
      let config = "";
      if(isAdvanced){
        switch (indicator.indicator_id) {
          case IndicatorType.RSI:
          case IndicatorType.SRSI:
            if (longStrategy) {
              config = `${indicator.period_num}, ${indicator.value2}, ${
                indicator.value5
              }, ${indicator.value4 == 1}`
            } else {
              config = `${indicator.period_num}, ${indicator.value3}, ${
                indicator.value6
              }, ${indicator.value4 == 1}`;
            }
            break;
          case IndicatorType.BB:
            config = `${indicator.period_num}, ${
              longStrategy ? indicator.value2 : indicator.value3
            }`
            break;
          case IndicatorType.EMA:
            config = `${indicator.period_num}, ${indicator.value2}`;
            break;
          case IndicatorType.MACD:
            config = `${indicator.period_num}, ${indicator.value2}, ${indicator.value3}`;
            break;
          default:
            break;
          }
          return config?indicator.name+'('+config+')':indicator.name
      }
      return indicator.name
    } )
    .join(", ");
  const exitIndicators = indicators
    .filter((el) => el.type === "exit")
    .map((indicator) => {
      let config = "";
      if(isAdvanced){
        switch (indicator.indicator_id) {
          case IndicatorType.RSI:
          case IndicatorType.SRSI:
            if (longStrategy) {
              config = `${indicator.period_num}, ${indicator.value3}`
            } else {
              config = `${indicator.period_num}, ${indicator.value2}`;
            }
            break;
          case IndicatorType.BB:
            config = `${indicator.period_num}, ${
              !longStrategy ? indicator.value2 : indicator.value3
            }`
            break;
          case IndicatorType.EMA:
            config = `${indicator.period_num}, ${indicator.value2}`;
            break;
          case IndicatorType.MACD:
            config = `${indicator.period_num}, ${indicator.value2}, ${indicator.value3}`;
            break;
          default:
            break;
          }
          return config?indicator.name+'('+config+')':indicator.name
      }
      return indicator.name
    })
    .join(", ");
  //console.log(deal.deal_trade_histories)
  const orders =
    deal &&
    (deal.deal_trade_histories || [])
      .filter((el) => (el.status == "filled" || el.status == "partial_filled"))
      .map((item) => ({
        name: item.name,
        desc: `${item.filled} ${deal.quote_name} @ ${item.average} ${deal.base_name}`,
        created_at: item.status=="filled"?item.time:item.created_at,
        side: item.side,
        status: item.status,
      }));
  window.bot = orders && {
    strategy: deal.strategy,
    orders,
  };

  const tradeHistory = (deal && deal.deal_trade_histories) || [];
  tradeHistory.sort((order1, order2) => {
    return milisecOf(order1.time) - milisecOf(order2.time);
  });
  const tradeItems = tradeHistory.map((item, index) => {
    const isLong = deal.strategy == "Long";
    const dotClass = item.side=="BUY"?"dot-green":"dot-redshort";
    return (
      <tr key={index}>
        <td><span className={`dot ${dotClass}`}></span></td>
        <td className="date-data">{formatDate(item.time, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY HH:mm:ss")}</td>
        <td>
          <div className="side-data">
            <h6 >{item.side}</h6>
          </div>
        </td>
        <td>
          <div className="order-type-data"> 
            <h6>{typeNameOrder(item.name)}</h6>
          </div>
        </td>
        <td>
          <div className="amount-data">
            <h6>{deal.status === "working" ? `${item.filled}/${item.quantity}` : item.filled}{" "}
          {deal.quote_name}</h6>
          </div>
        </td>
        <td>
          <div className="price-data">
            <h6>{item.status === "ongoing" ? item.price.toFixed(2) : item.average.toFixed(2)}{" "}
          {deal.base_name}</h6>
          </div>
        </td>
        <td>
          <div className="totalValue-data">
            <h6>{(item.quantity * item.average).toFixed(2)} {deal.base_name}</h6>
          </div>
        </td>
      </tr>
    );
  });

  const entryOrders = tradeHistory.filter(
    (el) =>
      ["bo", "eo"].includes(el.name.toLowerCase()) && el.status === "filled"
  );
  const { cost, amount } = entryOrders.reduce(
    ({ cost, amount }, cur) => ({
      cost: cost + cur.quote_quantity,
      amount: amount + cur.filled,
    }),
    { cost: 0, amount: 0 }
  );
  const averagePrice =
    entryOrders.length === 1
      ? entryOrders[0].average
      : amount === 0
      ? 0
      : cost / amount;
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <ContentContainer url="deals" title={t("deals")} showExchange={true}>
        <div className="dashboard-newuser-area">
          <div className="deshboard-normal-allBots-area">
            <div className="main-right-content">
              <div className="bot-detail-header pos-relative">
								<span className={deal.net_profit>=0?"status-green":"status-red"}></span>
								<h4>{deal && deal.bot_name}</h4>
								<div className="bot-detail-header-btn">
                {deal && IncompletedDeals.includes(deal.status_key) ? <>
									<button className="pdheader-btn" onClick={() => closeDeal(dealId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)}>{startCloseDeal ? "Liquidating..." : "Liquidate"}</button>
									<button className="pdheader-btn" onClick={setShowConfirm}>Delete</button></>
                :deal && <button className="pdheader-btn" onClick={clickDelete}>Delete</button>}
									
								</div>
							</div>
              <div className="bot-detail-content-area positions-detail">
								<div className="row g-4">
									<div className="col-lg-6 col-md-6">
										<div className="positions-detail-right-header" style={{ height: "450px" }}>
                            {deal && 
                            <TVChartContainer
                              pairId={deal && deal.pair_id}
                              symbol={
                                (deal && `${deal.quote_name}/${deal.base_name}`) || "USDT"
                              }
                              interval={deal && deal.bot && deal.bot.frequency}
                            /> }
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="bot-detail-left pd-left-header">
											<div className="bot-detail-left-header">
												<label>
													<img src="/images/overview-icon.svg" alt=""/>
													Overview
												</label>
												<label>
                        <img
                            src={deal.exchange && deal.exchange.image}
                            width={20}
                            height={20}
                            style={{ marginRight: 10 }}
                          /> 
													{deal.exchange && deal.exchange.name}
												</label>
											</div>
											<div className="bot-detail-left-body">
												<div className="row">
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("pair")}</span>
															<h6>{deal && deal.pair && deal.pair.name}</h6>
														</div>
													</div>
                          {isDca ? <DcaDealDetails deal={deal} t={t}></DcaDealDetails>:
                            <>
													<div className="col-6">
														<div className="bdol-item">
															<span>{deal && IncompletedDeals.includes(deal.status_key)
                                ? t("upnl")
                                : t("real-pnl")}</span>
															<h6>{deal && deal.net_profit && deal.net_profit.toFixed(2)}{" "}
                                {deal && deal.base_name} (
                                {deal &&
                                  deal.profit_value &&
                                  deal.profit_value.toFixed(2)}
                                %) {deal.note && <DealWarning note={deal.note} />}</h6>
														</div>
													</div>
                          {deal && deal.bot && deal.bot.base_order_percentage && 
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("Base Order")}</span>
															<h6>{deal && deal.bot && deal.bot.base_order_percentage} %</h6>
														</div>
													</div> }
                          {deal && deal.bot && deal.bot.initial_fund && 
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("Allocated Fund")}</span>
															<h6>{deal && deal.bot && deal.bot.initial_fund}</h6>
														</div>
													</div>}
                          {deal && deal.bot && AllFrequencies[deal.bot.frequency] && 
													<div className="col-6">
														<div className="bdol-item" style={{marginBottom: "0"}}>
															<span>{t("Frequency")}</span>
															<h6>{deal && deal.bot && AllFrequencies[deal.bot.frequency]}</h6>
														</div>
													</div> }
                          <div className="col-6">
														<div className="bdol-item">
															<span>{t("tp")}</span>
															<h6>{deal && deal.profit?`${deal.profit}%`:"--"}</h6>
														</div>
													</div>
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("stoploss")}</span>
															<h6>{deal && deal.stop_loss} %</h6>
														</div>
													</div>
                          {!(deal && deal.bot && deal.bot.type == BotType.grid ) && 
                          <>
                            <div className="col-6">
                              <div className="bdol-item">
                                <span>{t("strategy")}</span>
                                <h6 className={deal.strategy=="Long"?"text-success":"text-danger"}>{deal && deal.strategy}</h6>
                              </div>
                            </div>
                            {entryIndicators.length > 0 && 
                            <div className="col-6">
                            <div className="bdol-item">
                              <span>{t("entry-indicators")}</span>
                              <h6>{entryIndicators}</h6>
                            </div>
                          </div>}
                          {exitIndicators.length > 0 && 
                          <div className="col-6">
                            <div className="bdol-item">
                              <span>{t("exit-indicators")}</span>
                              <h6>{exitIndicators}</h6>
                            </div>
                          </div>
                            }
                          </>
                          }
                          {isFuture?
                            <>
                             <InfoDisplay 
                              left={t("leverage")}
                              right={<>{deal.position && deal.position.leverage}X</>}
                              leftCol={4}
                              rightCol={8}
                            ></InfoDisplay>
                             <InfoDisplay 
                              left={t("margin-mode")}
                              right={<>{deal.position && deal.position.margin_mode}</>}
                              leftCol={4}
                              rightCol={8}
                            ></InfoDisplay>
                             <InfoDisplay 
                              left={t("entry-price")}
                              right={<>{isCompleted?"NA":deal.position && deal.position.entry_price}</>}
                              leftCol={4}
                              rightCol={8}
                            ></InfoDisplay>
                             <InfoDisplay 
                              left={t("liq-price")}
                              right={<>{isCompleted?"NA":deal.position && deal.position.liquidation_price}</>}
                              leftCol={4}
                              rightCol={8}
                            ></InfoDisplay>
                             <InfoDisplay 
                              left={t("margin-ratio")}
                              right={<>{isCompleted?"NA":deal.position && deal.position.margin_ratio}</>}
                              leftCol={4}
                              rightCol={8}
                            ></InfoDisplay>
                            </>
                            :<></>}
                          <div className="col-6">
														<div className="bdol-item">
															<span>{t("Marketplace Strategy")}</span>
															<h6>{deal && deal.bot && deal.bot.template_name?deal.bot.template_name:"None"}</h6>
														</div>
													</div>
												
													</>}
                          
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
              <Row className="justify-content-center mt-2">
                  <Alert
                    variant={closeDealError ? "danger" : "success"}
                    show={closeDealError != null || closeDealSuccess}
                  >
                    {closeDealError || "Liquidated successfully!"}
                  </Alert>
                </Row>
             
            {isGrid?<GridBotDealTradeHistory tradeItems={tradeHistory} deal={deal} t={t}></GridBotDealTradeHistory>:<>
            
              <div className="pd-trade-history-area">
								<div className="pd-th-header-content">
									<div className="row">
										<div className="col-sm-6">
											<div className="pd-th-header-left">
												<h4>Trade History</h4>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="pd-th-header-right">
												<label>
													<span>Average Price:</span>
													<h6>{roundNumber(averagePrice)} {deal.base_name}</h6>
												</label>
												
											</div>
										</div>
									</div>
								</div>
								<div className="pd-th-table-area">
									<div className="col-lg-12">
										<div className="pd-th-table-content">
											<div className="open-table-content otc">
												<table className="table">
													<tr>
														<td></td>
														<td>{t("date")}</td>
														<td>{t("side")}</td>
                            <td>{t("order-type")}</td>
                            <td>{deal.status === "working"?"Filled / Total":t("amount")}</td>
                            <td>{t("price")}</td>
                            <td>{t("total-val")}</td>
													</tr>	
                          <tbody>
                            {tradeItems}
                          </tbody>
													
													
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
              </>}
            
            <ConfirmDialog show={showConfirm} onHide={() => setShowConfirm(false)} delete={clickDelete}/>
            </div>
            </div>
            </div>
        </ContentContainer>
    </Loading>
  );
};

export default connect(null, {
  getDealDetail,
  closeDeal,
  resetDealDetail,
  deleteDeal,
})(DealDetail);
const ConfirmDialog = (props) => {
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Dialog</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please confirm that you would like to delete this deal by clicking the Delete button below.</Modal.Body>
      <Modal.Footer>
        <button className="blue-button" onClick={props.delete}>
          Delete
        </button>
        <button className="grey-button" onClick={clickCancel}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};
