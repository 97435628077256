import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getDeals } from "../../actions/deal-action";
import { DealStatus } from "../../Const";
import PnlText from "../common/PnlText";
import { isLong, roundNumber } from "../../utils/Utils";
import NoDeals from "./NoDeals";

const ActiveDeals = (props)=>{
    const { getDeals,t } = props;
    const itemsPerPage = 5;
    const [startItem, setStartItem] = useState(0);
    const deals = useSelector((state) => state.deals.deals) || [];
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const completedStatuses = [
        DealStatus.stopLoss,
        DealStatus.takeProfit,
        DealStatus.exited,
        DealStatus.liquidated,
        DealStatus.error,
      ];
      const filterDeals = deals
        .filter((item) => {
          return item.exchange_id === selectedExchangeId;
        })
        .filter((item) => !completedStatuses.includes(item.status_key));
    
      useEffect(() => {
        getDeals();
      }, []);
      const dealItems = filterDeals
      .slice(startItem, startItem + itemsPerPage)
      .map((item, index) => {
        return (<>
            <tr valign="top">
            <td><img src={`/images/icons/bots/svg/${item.bot && item.bot.type?item.bot.type:'rent'}.svg`} className="bot-icon-list"></img>{item.bot_name}</td>
                <td><img src={item.pair.base_image} width={20} className="mr-1"></img>{item.quote_name}/{item.base_name} &nbsp;&nbsp;<span className={isLong(item.strategy)?'green-text':'red-text'}>{item.strategy}</span></td>
                <td>{roundNumber(item.executed_vol)} {item.base_name}</td>
                <td>
                <PnlText value={item.net_profit} text={roundNumber(item.net_profit) + item.base_name + '('+
                roundNumber(item.profit_value)+'%)'}/>
                </td>
            </tr>
          </>
        );
      });
    return(
      <>
      {dealItems && dealItems.length > 0 ? 
      <>
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 v-pad pos-relative" >
            <h3>Deals in Progress</h3>
            <div className="viewall-btn"><a href="/deals">View all</a></div>
            <br/>
            <div className="deals-table">
                <table>
                    <tbody>
                        <tr valign="top">
                            <th>Bot Name</th>
                            <th>Symbol</th>
                            <th>Volume</th>
                            <th>Unrealized PnL</th>
                        </tr>
                        {dealItems}
                    </tbody>
                </table>
            </div>
        </div>
      </>:
      <>
        <NoDeals></NoDeals>
      </>
      }
    </>
    )
}

export default connect(null,{getDeals})(ActiveDeals)