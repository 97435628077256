import React from "react";
import { Col, Row } from "react-bootstrap";

const InfoDisplay = (props)=>{
    const { left, right,leftClass="",rightClass="",className="" } = props;
    return(
        
        <div className="col-6">
            <div className="bdol-item">
            <span>{left}</span>
            <h6>{right}</h6>
            </div>
        </div>
        
    )
}
export default InfoDisplay;