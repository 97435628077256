import { GET_REPORT, GET_REPORT_BOTS, GET_REPORT_STOCKS } from "../actions/types";

  
  const initialState = {
    returnChart:[],
    returnPercentageChart:[],
    tradingVolumeChart:[],
    cumulativeReturnChart:[],
    cumulativeReturnChartPercentage:[],
    apyChart:[],
    return:0,
    returnPercentage:0,
    tradingVolume:0,
    portfolio:0,
    apy:0,
    stocks:[],
    bots:[]
  }
  
  const ReportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_REPORT:
        return {
          ...state,
          returnChart: payload.return_chart,
          returnPercentageChart:payload.return_percentage_chart,
          tradingVolumeChart:payload.trading_volume_chart,
          cumulativeReturnChart:payload.return_chart_accumulative,
          cumulativeReturnChartPercentage:payload.return_percentage_chart_accumulative,
          apyChart:payload.apy_chart,
          return:payload.return,
          returnPercentage:payload.return_percentage,
          tradingVolume:payload.trading_volume,
          portfolio:payload.portfolio,
          apy:payload.apy
        };
      case GET_REPORT_BOTS:
        return{
          ...state,
          bots:payload
        }
      case GET_REPORT_STOCKS:
        return{
          ...state,
          stocks:payload
        }
      default:
        return state;
    }
  };
  
  export default ReportReducer;
  