import React, { useState, useEffect, useCallback } from "react";
import { Table, Badge } from "react-bootstrap";
import Pagination from "../common/CustomPagination";
import usePagination from "../hooks/usePagination";
import { connect, useSelector } from "react-redux";
import { roundNumber, formatDate } from "../../utils/Utils";
import { getOrderHistory } from "../../actions/terminal-action";
import Loading from "../common/Loading";

const OrderHistoryTab = (props) => {
  const { getOrderHistory, account,t } = props;
  const page=1
  const [range, bindPagination, setItemPerPage] = usePagination();
  const orderHistory =
    useSelector((state) => state.terminal.orderHistory) || {};
  const orders = orderHistory.data || [];
  const openOrders = useSelector((state) => state.terminal.openOrders) || [];
  const startItem = useSelector((state) => state.terminal.orderHistory.from) || 1;
  const selectedExchangeId = useSelector(
    (state) => state.settings.selectedExchangeId
  );
  const updateHistory = (selectedExchangeId,itemsPerPage,page) => {
    getOrderHistory({
      exchange_id: selectedExchangeId,
      account,
      page: page,
    }).then(console.log());
  };
  useEffect(() => {
    updateHistory(selectedExchangeId,10,page);
    // const interval = setInterval(() => {
    //   if (openOrders.length) updateHistory(selectedExchangeId,null,page);
    // }, 5000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [page, selectedExchangeId, account]);
  const rows = orders.map((item, index) => {
    return (
      <tr key={index} className="theme-border">
        <td>{item.symbol}</td>
        <td className={item.side}>{item.side}</td>
        <td>
          {roundNumber(item.average)} {item.pair.to}
        </td>
        <td>
          {roundNumber(item.quantity)} {item.pair.from}
        </td>
        <td>
          <Badge
            variant={
              item.status === "filled"
                ? "success"
                : item.status === "canceled"
                ? "danger"
                : "info"
            }
            className="w-75"
          >
            {item.status.toUpperCase()}
          </Badge>
        </td>
        <td>
          {formatDate(item.updated_at, undefined, "DD MMM YYYY HH:mm:ss")}
        </td>
      </tr>
    );
  });
  return (
    <div className="mt-4 bot-tab">
    <div className="" >
          <div className="deals-table">
              <table className="">
                  <tbody>
                      <tr valign="top">
                        <th>{t("pair")}</th>
                        <th>{t("side")}</th>
                        <th>{t("price")}</th>
                        <th>{t("amount")}</th>
                        <th>{t("status")}</th>
                        <th>{t("date")}</th>
                      </tr>
                      {rows}
                  </tbody>
              </table>
             
        </div>
    </div>
    <Pagination {...bindPagination} totalCount={orderHistory.total || 0} onChange={updateHistory}  selectedExchangeId={selectedExchangeId} startItem={startItem}/>
  </div>
  );
};

export default connect(null, { getOrderHistory })(OrderHistoryTab);
