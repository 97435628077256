import React, { useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import PaymentPanel from "./PaymentPanel";
import PremiumInfo from "./PremiumInfo";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./Subscription.scss";
import { useTranslation } from "react-i18next";
import ContentContainer from "../common/ContentContainer";

const Subscription = () => {
  const location = useLocation();
  const { search } = location;
  const query = queryString.parse(search);
  const monthly = query.plan;
  const plan = query.plan;
  const tier = query.tier;
  const {t}= useTranslation()

  const [selectMonthly, setSelectMonthly] = useState(monthly == "monthly");

  return (
    <ContentContainer fluid className="sub">
      <div className="main-right-content">
     
      <Row className="justify-content-around mt-5" noGutters>
        <div className="subs-plan-area">
        <div className="subs-plan-item">
            <div className="row g-0">
              <PremiumInfo readOnly isYearly={!selectMonthly} tier={tier} t={t} plan={plan} className={'spcc-1'}/>
              <Col
          xs={12}
          md={6}
          xl={8}
          className="px-0 px-sm-3 px-xl-5 mt-5 mt-md-0"
        >
          <div className="card-white">
          <div className="cw-body">
          <PaymentPanel
            monthly={selectMonthly}
            hideSelection={monthly != null}
            onChange={setSelectMonthly}
            subscriptionType={plan}
            tier={tier}
            plan={plan}
          />
          </div>
          </div>
        </Col>
            </div>
        </div>
        </div>
        
      </Row>
      </div>
    </ContentContainer>
  );
};

export default connect(null)(Subscription);
