export default [
  {
    link: "/affiliate",
    text: "The Program",
  },
  {
    link: "/refer-now",
    text: "Refer Now",
  },
  {
    link: "/my-referrals",
    text: "My Referrals",
  },
  {
    link: "/my-rewards",
    text: "My Rewards",
  },
  {
    link: "/leader-board",
    text: "Top 10",
  },
];
