import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useOnHoverOutside } from "../../../../utils/useOnHoverOutside";

const IndicatorDropdown = (props)=>{
    const { t ,indicatorOptions} = props
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
    const dropdownRef = useRef(null)
    const closeHoverMenu = () => {
        setMenuDropDownOpen(false);
    };
    useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook
    return(
        <div className="dropdown createBot-dropdown indicator-dropdown" ref={dropdownRef}>
          <button className="blue-button small"  type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false" onMouseOver={() => setMenuDropDownOpen(true)}>
          {t("add-indicator")}
          </button>
          <ul className={`dropdown-menu indicator ${isMenuDropDownOpen?"show":""}`} aria-labelledby="dropdownMenu2">
            {indicatorOptions}
          </ul>
        </div>
    )
}
export default IndicatorDropdown;