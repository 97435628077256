import React ,{useState} from "react";
import { connect, useSelector } from "react-redux";
import { Modal,Tabs,Col,Row } from "react-bootstrap";
import PayoutHistory from "./PayoutHistory";
import NoTransactionView from "./NoTransactionView";

const PayoutHistoryModal = (props)=>{
    const {
        onHide
    }=props;
    const paymentHistoryPending =  useSelector((state) => state.marketplace.paymentHistoryPending) || {};
    const paymentHistoryPaid =  useSelector((state) => state.marketplace.paymentHistoryPaid) || {};
    const [activeTab, setActiveTab] = useState(0);
    const tabTitles = ["Paid", "Pending"];
    const tabHeaders = tabTitles.map((item, index) => {
        return (
        <Col xs={4} key={index}>
            <a
            onClick={() => setActiveTab(index)}
            className={index == activeTab ? "tab-active" : "tab-inactive"}
            >
            {item}
            </a>
        </Col>
        );
    });
    const tabs = [
        paymentHistoryPaid.length > 0? <PayoutHistory  data={paymentHistoryPaid}/>:<NoTransactionView/>,
        paymentHistoryPending.length > 0?<PayoutHistory data={paymentHistoryPending}/>:<NoTransactionView/>,
    ];
    return (
        <>
        <Modal show={true} onHide={onHide} size="lg"  centered>
            <Modal.Header closeButton className="align-items-center no-bottom-border">
            </Modal.Header>
            <Modal.Body style={{ minHeight: "600px" }} className="payoutHistoryModal">
            <Row className="">{tabHeaders}</Row>
                <div className="payout-history-modal-content">
                {tabs[activeTab]}
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}
export default connect(null,{})(PayoutHistoryModal);