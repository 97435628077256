import React from "react";
import { Col, Row } from "react-bootstrap";
const PayoutHistoryCard = (props)=>{
    const {history}=props
    const items = (
        <>
        {history.data.map((data, id) => (
            <li key={id}>
                <ul className="paid-history-card-inner-list">
                    <li>Amount <span className="pull-right">{data.amount} USDT</span></li>
                    <li>Transaction ID <span className="pull-right">{data.transaction_id}</span></li>
                </ul>
            </li>
        ))}
         
        </>
    )
    return (
        <>
        <ul className="paid-history-card-list mt-2">
            <li className="paid-history-card-list-heading">{history.date}</li>
            {items}
        </ul>
        </>
    )
}
export default PayoutHistoryCard