import React from "react";
import AffiliateNavbar from "./AffiliateNavbar";
import Footer from "../../common/Footer";
const AffiliateLayout = (props) => {
  return (
    <div>
      <AffiliateNavbar/>
      {props.children}
      <Footer />
    </div>
  );
};

export default AffiliateLayout;
