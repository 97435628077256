import React from "react";
import { DropdownButton, Button, Dropdown } from "react-bootstrap";
import "./FilterPanel.scss";
import { TimeFrames, Frequencies, BaseCoins } from "../../../Const";

const Strategies = { All: "All Strategies", Short: "Short", Long: "Long" };
const Sorts = { apy: "APY", wl_ratio: "W/L" , like_count:"LIKES"};
const OrderBy = { desc: "DESC", asc: "ASC" };

const FilterPanel = (props) => {
  const {
    baseCoin,
    setBaseCoin,
    sort,
    setSort,
    orderBy,
    setOrderBy,
    strategy,
    setStrategy,
  } = props;
  
  // const coinItems = BaseCoins.map((item, index) => {
  //   return (
  //     <Dropdown.Item key={index} eventKey={item}>
  //       {item}
  //     </Dropdown.Item>
  //   );
  // });

  const strategyItems = Object.keys(Strategies).map((el) => {
    return (
      <Dropdown.Item key={el} eventKey={el} className="text-left">
        {Strategies[el]}
      </Dropdown.Item>
    );
  });
  const sortOptions = Object.keys(Sorts).map((el) => {
    return (
      <Dropdown.Item key={el} eventKey={el} className="text-left">
        {Sorts[el]}
      </Dropdown.Item>
    );
  });
  const orderByOptions = Object.keys(OrderBy).map((el, id) => {
    return (
      <Dropdown.Item key={el} eventKey={el} className="text-left">
        {OrderBy[el]}
      </Dropdown.Item>
    );
  });

  return (
    <div className="d-flex justify-content-between mt-20" >
      <div className="">
        <DropdownButton
          title={Sorts[sort]}
          className="dropdown-filter-btn"
          variant=""
          onSelect={(e) => setSort(e)}
        >
          {sortOptions}
        </DropdownButton>
      </div>
      
      <div className="">
      <DropdownButton
        title={OrderBy[orderBy]}
        className="dropdown-filter-btn"
        variant=""
        onSelect={(e) => setOrderBy(e)}
      >
        {orderByOptions}
      </DropdownButton>
      </div>
      <div className="">
      <DropdownButton
        title={Strategies[strategy]}
        //variant="outline-primary"
        onSelect={(e) => setStrategy(e)}
        className="dropdown-filter-btn ml-2"
        variant=""
      >
        {strategyItems}
      </DropdownButton>
      </div>
    </div>
  );
};

export default FilterPanel;
