import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import { signUp, resetSignUp } from "../../actions/auth-action";
import { Link, Redirect } from "react-router-dom";
import "./SignUp.scss";
import useInput from "../hooks/useInput";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";
import { TokoRefCode } from "../../Const";

const SignUp = (props) => {
  const { signUp, resetSignUp } = props;
  const { search } = useLocation();
  const query = queryString.parse(search);
  const {t} = useTranslation()

  const signUpError = useSelector((state) => state.auth.signUpError);
  const signUpRedirect = useSelector((state) => state.auth.signUpRedirect);
  const signUpStart = useSelector((state) => state.auth.signUpStart);
  const signUpSuccess = useSelector((state) => state.auth.signUpSuccess);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [referralCode, bindReferralCode] = useInput(query.ref || "");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    resetSignUp();
    return () => {
      resetSignUp();
    };
  }, []);

  const clickSignUp = (e) => {
    e.preventDefault();
    signUp({
      first_name: firstName,
      email,
      password,
      password_confirmation: confirmPass,
      referral_code: TokoRefCode,
      from: "web",
    });
  };
  if (signUpRedirect) {
    return <Redirect to="/verify-otp" />;
  }
  return (
    <>
     <GuestContainer>

     <Row className="justify-content-center auth-panel">
          <Col className="auth-panel-content">
            <div className="signup-panel text-center">
              <img src="/images/named-logo.png"  className="mb-3 auth-logo"></img>
              <img src="/images/toko.png" className="toko-image auth-logo" width="180" />
              <h6 className="header-text">{t("create-new-acc")}</h6>
              <div style={{ fontSize: 14 }}>
                Create an account now, and start using CryptoHero bot with your
                Tokocrypto API. Please use your Tokocrypto email to sign up.
              </div>
              <Form>
                <Form.Group controlId="formBasicEmail" className="ch-form pos-relative mt-4">
                <img src="/images/user-icon.png"  className="input-icon"></img>
                  <input
                    type="input"
                    placeholder={t("first-name")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-input input-with-icon auth-input"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="ch-form pos-relative mt-4">
                <img src="/images/email-icon.png"  className="input-icon"></img>
                  <input
                    type="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input input-with-icon auth-input"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/password-icon.png"  className="input-icon"></img>
                  <input
                    type="password"
                    placeholder={t("Password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/password-icon.png"  className="input-icon"></img>
                  <input
                    type="password"
                    placeholder={t("re-pass")}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>
               
               
                <input type="checkbox" id="myCheckbox" className="custom-checkbox"  
                  value={checked}
                  onChange={(e) => setChecked(e.target.checked)}>
                </input>
                <label for="myCheckbox" className="checkbox-label">{
                    <p>
                      <Trans i18nKey={"i-agree"}>
                      <a
                        href="https://cryptohero.ai/terms-of-service/"
                        target="_blank"
                      >
                      </a>
                      </Trans>
                     
                      
                    </p>
                  }</label>
               
                <div className="my-3">
                  <Button type="submit" onClick={clickSignUp} className={"ch-button capitalize"} disabled={!checked}>
                  {signUpStart ? "SUBMITTING..." : "SIGN UP"}
                  
                  </Button>
                </div>
                <Alert
                  show={signUpError != null || signUpSuccess}
                  variant={signUpSuccess ? "success" : "danger"}
                  className="mt-2"
                >
                 {signUpError || t("acc-created-msg")}
                </Alert>
              </Form>
              
              

              <div className="text-center mt-4">
                
              <span className="mr-3">{t("have-acc")}</span>
                <Link to="/tokocrypto" className="link-text">{t("login")}</Link>
              </div>
            </div>
          </Col>
        </Row>
     </GuestContainer>
    </>
  );
};

export default connect(null, { signUp, resetSignUp })(SignUp);
