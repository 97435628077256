import React from "react";
//import "./ExitBotIndicatorView.scss";
import {CHANGE_GRID_EXIT_HAS_TRADE, CHANGE_GRID_EXIT_NO_TRADE, CHANGE_GRID_EXIT_OUTER_RANGE, CHANGE_GRID_EXIT_WAIT_TIME, CHANGE_TAKE_PROFIT} from "../../../../actions/create-bot-type";
import { connect , useDispatch} from "react-redux";
import { Card,Accordion,Form, InputGroup } from "react-bootstrap";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import SliderView from "../SliderView";
import LeftRightForm from "../modules/LeftRightForm";
import CustomRadio from "../modules/CustomRadio";

const GridExitView = (props) => {
  const { newBot,t } = props;
  const isBuy = newBot.strategy=='Long';
  const side = isBuy ? "Sell" : "Buy";
  const actionTrigger = isBuy ? "more" : "less";
  const dispatch = useDispatch();
  const changePriceExit = (val) => {
    dispatch({
      type: CHANGE_TAKE_PROFIT,
      payload: val,
    });
  };
  const changeExitWaitTime = (val) => {
    if(val > 5000)
    return false
    dispatch({
      type: CHANGE_GRID_EXIT_WAIT_TIME,
      payload: val,
    });
  };
  const outerRangeChanged = (val) => {
    dispatch({
      type: CHANGE_GRID_EXIT_OUTER_RANGE,
      payload: val,
    });
  };
  const changeNoTradesAreFilled = (val) => {
    dispatch({
      type: CHANGE_GRID_EXIT_NO_TRADE,
      payload: val,
    });
  };
  const changeTradesAreFilled = (val) => {
    dispatch({
      type: CHANGE_GRID_EXIT_HAS_TRADE,
      payload: val,
    });
  };
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
  }
  return (
    <>
      
          <SliderView
              title={t("wait-time")}
              step={5}
              min={0}
              max={5000}
              value={newBot.gridExitWaitTime?newBot.gridExitWaitTime:0}
              onChange={changeExitWaitTime}
              //formatLabel={formatDcaExit}
              appendText="Minutes"
              withInputZero={true}
              hideLabel={true}
              tutorial={<TutorialPopup content={t("gridWaitTime")} />}
            />
            <LeftRightForm
              left={ <h6>
                {t("outer-range")}<TutorialPopup content={t("gridOuterRange")}></TutorialPopup>
                </h6>}
              right={<>
                 <label data-text={'%'} className=" input-gp-label">
                  <Form.Control
                      as="input"
                      type="number"
                      value={newBot.gridExitOuterRange}
                      onChange={(e) => {
                        outerRangeChanged(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                      className="input-text input-gp-tex"
                    ></Form.Control>
                </label>
              </>}
            >
            </LeftRightForm>
            <LeftRightForm
              left={<h6>
                {t("if-wait-time-reached")}
                </h6>}
              right={<div className="d-flex flex-column mt-4">
             <CustomRadio
                type="radio"
                name="no_trade"
                label={t("cancel-all-open-grids")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyNoTrade==="cancel_restart"}
                callback={() => changeNoTradesAreFilled("cancel_restart")}
              ></CustomRadio>
                <CustomRadio
                type="radio"
                name="no_trade"
                label={t("stop-bot")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyNoTrade==="restart_bot"}
                callback={() => changeNoTradesAreFilled("restart_bot")}
              ></CustomRadio>
                <CustomRadio
                type="radio"
                name="no_trade"
                label={t("leave-stop-bot")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyNoTrade==="stop_bot"}
                callback={() => changeNoTradesAreFilled("stop_bot")}
              ></CustomRadio>
              </div>
              }
            ></LeftRightForm>
            <LeftRightForm
              left={<h6>
                {t("if-wait-or-outer-reached")}
                </h6>}
              right={<div className="d-flex flex-column mt-4">
             <CustomRadio
                type="radio"
                name="has_trade"
                label={t("cancel-all-open")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyHasTrade==="cancel_restart"}
                callback={() => changeTradesAreFilled("cancel_restart")}
              ></CustomRadio>
                <CustomRadio
                type="radio"
                name="has_trade"
                label={t("do-nothing-restart")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyHasTrade==="restart_bot"}
                callback={() => changeTradesAreFilled("restart_bot")}
              ></CustomRadio>
                <CustomRadio
                type="radio"
                name="has_trade"
                label={t("do-nothing-stop")}
                val={"cancel_restart"}
                checked={newBot.gridExitStrategyHasTrade==="stop_bot"}
                callback={() => changeTradesAreFilled("stop_bot")}
              ></CustomRadio>
                <CustomRadio
                type="radio"
                name="has_trade"
                label={t("do-nothing-grid-running")}
                val={"keep_running"}
                checked={newBot.gridExitStrategyHasTrade==="keep_running"}
                callback={() => changeTradesAreFilled("keep_running")}
              ></CustomRadio>
              </div>
              }
            ></LeftRightForm>
            {/* <Form.Group>
              <Form.Label>
              {t("if-wait-or-outer-reached")}
              </Form.Label>
              <Form.Check 
                type="radio"
                name="has_trade"
                label={t("cancel-all-open")}
                onChange={() => changeTradesAreFilled("cancel_restart")}
                checked={newBot.gridExitStrategyHasTrade==="cancel_restart"}
                value="cancel_restart"
              />
              <Form.Check 
                type="radio"
                name="has_trade"
                label={t("do-nothing-restart")}
                onChange={() => changeTradesAreFilled("restart_bot")}
                checked={newBot.gridExitStrategyHasTrade==="restart_bot"}
                value="restart_bot"
              />
              <Form.Check 
                type="radio"
                name="has_trade"
                label={t("do-nothing-stop")}
                onChange={() => changeTradesAreFilled("stop_bot")}
                checked={newBot.gridExitStrategyHasTrade==="stop_bot"}
                value="stop_bot"
              />
              <Form.Check 
                type="radio"
                name="has_trade"
                label={t("do-nothing-grid-running")}
                onChange={() => changeTradesAreFilled("keep_running")}
                checked={newBot.gridExitStrategyHasTrade==="keep_running"}
                value="keep_running"
              />
            </Form.Group> */}
    </>
  );
};

export default connect(null)(GridExitView);
