import React, { useState } from "react";
import { Form, FormLabel, Row } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { CHANGE_EXIT_STRATEGY } from "../../../actions/create-bot-type";
import CustomCheckbox from "./modules/CustomCheckbox";
import CustomRadio from "./modules/CustomRadio";

const ArbitrageExitView = (props) => {
  const {t} = props;
  const newBot = useSelector((state) => state.createBot.newBot) || {};
  const exitStrategy = newBot.exitStrategy;
  const dispatch = useDispatch();
  const changeExitStrategy = (val) => {
    dispatch({
      type: CHANGE_EXIT_STRATEGY,
      payload: val,
    });
  };
  return (
    <>
    <FormLabel>After bot completes a deal,</FormLabel>
    <br></br>
    <CustomRadio
            type="radio"
            name="exit_strategy"
            label={t("restart-bot-arb")}
            val={"restart_bot"}
            checked={exitStrategy=="restart_bot"} 
            callback={() => changeExitStrategy("restart_bot")}
          ></CustomRadio>
    <br></br>
    <CustomRadio
            type="radio"
            name="exit_strategy"
            label={t("stop-bot-arb")}
            val={"stop_bot"}
            checked={exitStrategy=="stop_bot"} 
            callback={() => changeExitStrategy("stop_bot")}
          ></CustomRadio>
    </>
  );
};

export default connect(null)(ArbitrageExitView);
