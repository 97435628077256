import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import "./CustomPagination.scss";

const NewPagination = (props) => {
  const {t} = useTranslation()
  const { startItem, onChange, itemsCountPerPage, totalCount,selectedExchangeId,selectedTab,botId } = props;
  const activePage = ((startItem-1) / itemsCountPerPage) + 1;
  return (
    <>
      <div className="row justify-content-center mt-auto">
        {totalCount <= itemsCountPerPage ? (
          <></>
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage || 10}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={(num) => onChange(num)}
            innerClass="pagination"
            activeLinkClass="text-white bg-info"
            itemClass="page-item"
            linkClass="page-link"
            prevPageText={t("prev")}
            nextPageText={t("next")}
          />
        )}
      </div>
    </>
  );
};

export default NewPagination;
