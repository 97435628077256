import React from "react";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateBot } from "../../../../actions/create-bot-action";
import useLoading from "../../../hooks/useLoading";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SET_APPLY_CURRENT_DEAL } from "../../../../actions/create-bot-type";
import CustomCheckbox from "./CustomCheckbox";

const CreateBotSection = (props)=>{
    const {t} = useTranslation();
    const history = useHistory();
    const [setLoading, bindLoading] = useLoading(false);
    const {updateBot,newBot} = props
    const itemsPerPage = 10;
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const isEditing = 
    useSelector((state) => state.createBot.newBot.isEditing) || false;
    const selectedTab = 'active';
    const dispatch = useDispatch()
    const clickCreateBot = () => {
        setLoading(true);
        updateBot(itemsPerPage,1,selectedTab,selectedExchangeId)
          .then(() => {
            setLoading(false);
            toast.success(isEditing?t("bot-updated-msg"):t("bot-created-msg"));
            history.push('/bots')
          })
          .catch((err) => {
            setLoading(false);
            toast.error(t(err));
          });
      };
     
      
    return (<>
      <button type="button"   onClick={clickCreateBot}>{isEditing==true?t("update-bot"):t("create-bot")}</button>
      <br></br>
      
       
        <Loading {...bindLoading} />
    </>);
}

export default connect(null,{updateBot})(CreateBotSection)