import React, { useEffect, useState } from "react";
import { Card, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import {
  getCurrencies,
  updateCurrency,
  getSettings,
  updateNotificationStatus,
  updateUserRole,
  switchTheme,
  userSetting,
  hideIntroPopup
} from "../../actions/setting-action";
import { connect, useDispatch, useSelector } from "react-redux";
import { minimalTimezoneSet } from "compact-timezone-list";
import Switch from "react-switch";
import { UserRole,Themes, LanguageName } from "../../Const";
import { toast } from "react-toastify";
import "./GeneralPanel.scss";
import { useTranslation } from "react-i18next";
import { APP_LANGUAGE, NEW_USER_INTRO_POPUP } from "../../actions/types";
import Cookies from "universal-cookie";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import useInput from "../hooks/useInput";
import CustomSwitch from "../bots/new-bot/modules/CustomSwitch";

const GeneralPanel = (props) => {
  const {
    getCurrencies,
    updateCurrency,
    getSettings,
    updateNotificationStatus,
    updateUserRole,
    switchTheme,
    hideIntroPopup
  } = props;
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const currencies = useSelector((state) => state.settings.currencies);
  const currencySelected = useSelector((state) => state.settings.currencySelected);
  const language = useSelector((state) => state.settings.currentLang);
  const theme = useSelector((state) => state.settings.selected_theme);
  const themeChecked = '/images/icons/check.png'
  const userRole =
    useSelector((state) => state.settings.userRole) || UserRole.normal;
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);

  const [isOn, setIsOn] = useState(false);

  const notificationStatus = useSelector(
    (state) => state.settings.bot_notification_enabled
  );

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (notificationStatus != null) {
      setIsOn(notificationStatus == 1);
    }
  }, [notificationStatus]);

  const changeNotification = (isOn) => {
    setIsOn(isOn);
    updateNotificationStatus(isOn ? 1 : 0);
  };
  const changeLanguage = (lang)=>{
    dispatch({
        type:APP_LANGUAGE,
        payload:lang
    })
    cookies.set('current_lang', lang, { path: '/',maxAge:60 * 60 * 24 * 365 * 10 });
    dispatch(userSetting("language",lang));
    setTimeout(() => {
      window.location.reload()
    }, 2000);
    
}

  useEffect(() => {
    getCurrencies();
  }, []);

  const changeUserRole = (isOn) => {
    updateUserRole(isOn ? UserRole.beta : UserRole.normal)
      .then(() => {})
      .catch((err) => toast.error(err));
  };

  const currencyItems = currencies.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
  const themeItems = Object.keys(Themes).map((item, index) => {
    return (
      <div key={index} className={`color-switch theme-${item} ${theme==item?"active":""}`} onClick={() => switchTheme(item)}>
      </div>
    );
  });

  const timezone = localStorage.getItem("timezone");
  const [offset, setOffset] = useState(timezone || "+00:00");
  const currentZone =
    minimalTimezoneSet.find((item) => item.offset === offset) ||
    minimalTimezoneSet[0];

  const selectTimezone = (timezone) => {
    setOffset(timezone);
    localStorage.setItem("timezone", timezone);
  };

  const timezoneItems = minimalTimezoneSet.map((item, index) => {
    return (
      <option key={index} value={item.offset}>
        {item.label}
      </option>
    );
  });
  // const timezoneItems = minimalTimezoneSet.map((item, index) => {
  //   return (
  //     <Dropdown.Item key={index} eventKey={item.offset}>
  //       {item.label}
  //     </Dropdown.Item>
  //   );
  // });
  const languageItems = Object.keys(LanguageName).map(function(key, index) {
    return (
      <option key={index} value={key}>
        {LanguageName[key]}
      </option>
    );
  });
  const handleCheckboxChange = (isChecked) => {
    // Dispatch the action to update the Redux state
    dispatch({
      type:NEW_USER_INTRO_POPUP,
      payload:isChecked?0:1
    })
    hideIntroPopup(isChecked?0:1)
  };

  return (
    <>
      <div className="setting-general-content">
          
            {/* <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="general">
                
                </div>
              </div>
              <div className="col-xl-10 col-md-9 d-flex gap20">
                <div className="pwtc-heading">
                <div>
                <input type="checkbox" checked={newUserIntroPupup===0} id="one" onChange={(e)=>handleCheckboxChange(e.target.checked)}/>
                  <label htmlFor="one">
                    <span></span> Do not show Quick Start again
                  </label>
              </div>
              </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6>Theme</h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9 d-flex gap20">
                {themeItems}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6 className="mt-2">{t("ch-beta")}</h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9">
                <div className="sgc-right sgc-range">
                    <CustomSwitch checked={userRole !== UserRole.normal} onChange={changeUserRole}></CustomSwitch>
                  <p>{t("beta-prog")}</p>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row align-items-center">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6>{t("native-curr")}</h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9">
                <select
                  //value={currencySelected}
                  onChange={(e) => updateCurrency(Number(e.target.value))}
                  className="form-control select-normal darker  small-select"
                >
                  {currencyItems}
                </select>
              </div>
            </div>	
            <div className="row align-items-center">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6>{t("timezone")}</h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9">
                <select
                  value={timezone}
                  onChange={(e) => selectTimezone(e.target.value)}
                  className="form-control select-normal darker  small-select"
                >
                  {timezoneItems}
                </select>
              </div>
            </div>	
            <div className="row align-items-center">
              <div className="col-xl-2 col-md-3">
                <div className="sgc-left">
                  <h6>{t("language")}</h6>
                </div>
              </div>
              <div className="col-xl-10 col-md-9">
                <select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  className="form-control select-normal darker  small-select"
                >
                  {languageItems}
                </select>
              </div>
            </div>	
        </div>
      
    </>
  );
};

export default connect(null, {
  getCurrencies,
  updateCurrency,
  getSettings,
  updateNotificationStatus,
  updateUserRole,
  switchTheme,
  hideIntroPopup
})(GeneralPanel);
