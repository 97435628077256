import {TemplateImageUrl} from "../Const"
import { SET_DISPLAY_TEMPLATE_IMAGE, SET_LIBRARY_IMAGE, SET_UPLOADED_IMAGE, SHOW_IMAGE_MODAL } from "./types";

export const setShowUploadImageModal = (val)=>(dispatch)=>{
    dispatch({
        type: SHOW_IMAGE_MODAL,
        payload: val,
      });
}
export const setLibraryImageSelect = (imgSelected)=>(dispatch)=>{
    
    dispatch({
        type: SET_LIBRARY_IMAGE,
        payload: imgSelected,
      });
    dispatch({
        type: SET_DISPLAY_TEMPLATE_IMAGE,
        payload: TemplateImageUrl+'/bot'+imgSelected+'.png',
    });
    dispatch({
        type: SHOW_IMAGE_MODAL,
        payload: false,
      });
  }
export const setImageSelect = (imgUploaded)=>(dispatch)=>{
    dispatch({
        type: SET_UPLOADED_IMAGE,
        payload: imgUploaded,
      });
    dispatch({
        type: SET_DISPLAY_TEMPLATE_IMAGE,
        payload: URL.createObjectURL(imgUploaded),
    });
    dispatch({
      type: SHOW_IMAGE_MODAL,
      payload: false,
     });
  }
