import React from "react";
import {
  DcaTime,
  DcaType,
  DcaTypeName,
  DcaTypePercentage,
  Frequencies,
  FrequencyValues,
  WeekTime,
  WeekTimeName,
  BotTypeName,
  TakeProfitType,
  TakeProfitTypeName
} from "../../../Const";
import { Col, Row, Table } from "react-bootstrap";
import {
  capitalize,
  getKeyByValue,
  getMonthDay,
  isLong,
} from "../../../utils/Utils";
import LeftRightForm from "../new-bot/modules/LeftRightForm";

const DcaBotSetting = (props) => {
  const { bot,t } = props;
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const dcaSetting = bot.dca_setting || {};
  const limitOrder = bot.extra_order_percentage || 0;
  const initFund = bot.initial_fund || 0;
  let dcaType = dcaSetting.entry_type || DcaType.time;
  if (dcaType === DcaTypePercentage) {
    dcaType = isLong(bot.strategy) ? DcaType.buyDown : DcaType.sellUp;
  }
  const tps = bot.tps || [];
  let takeProfit = (bot.profit || 0).toFixed(2) + "%";
  if (tps.length) {
    takeProfit =
    tps[0].tp_type === TakeProfitType.fixedTP
        ? TakeProfitTypeName.fixedTP
        : TakeProfitTypeName.variableTP;
  }

  const dcaTypeName = DcaTypeName[getKeyByValue(DcaType, dcaType)];
  let dcaSettings;
  let dcaSettingValue;
  let martingale;
  switch (dcaType) {
    case DcaType.time:
     
      let time = dcaSetting.value;
      if (time === DcaTime.weekly) {
        time =
          "Every " + WeekTimeName[getKeyByValue(WeekTime, dcaSetting.value2)];
      } else if (time === DcaTime.monthly) {
        time = "Every " + getMonthDay(dcaSetting.value2) + " of month";
      } else {
        time = capitalize(time);
      }
      dcaSettings = (
         <div className="col-6">
         <div className="bdol-item">
           <span>Time Buy</span>
           <h6>{time}</h6>
         </div>
       </div>
      );
      break;
    case DcaType.indicator:
      const entryIndicators =
        indicators &&
        indicators
          .filter((item) => item.type == "entry")
          .map((item) => item.name)
          .join(", ");
      const frequency =
        Frequencies[getKeyByValue(FrequencyValues, bot.frequency)];
      martingale =
        dcaSetting.value3 == 0 ? "Disabled" : dcaSetting.value3 + "%";
      
      dcaSettings = (
        <>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("indicators")}</span>
            <h6>{entryIndicators?entryIndicators:"-"}</h6>
          </div>
        </div>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("frequency")}</span>
            <h6>{frequency}</h6>
          </div>
        </div>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("martingale")}</span>
            <h6>{martingale}</h6>
          </div>
        </div>
      </>
        
     );
      break;
    case DcaType.buyDown:
    case DcaType.sellUp:
      let side = dcaType === DcaType.buyDown ? "Buy" : "Sell";
      martingale =
        dcaSetting.value3 == 0 ? "Disabled" : dcaSetting.value3 + "%";
      
      dcaSettings = (
        <>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("trigger-price")}</span>
            <h6>{dcaSetting.value} {pair.to}</h6>
          </div>
        </div>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("subsequent")}</span>
            <h6>{dcaSetting.value2}%</h6>
          </div>
        </div>
        <div className="col-6">
          <div className="bdol-item">
            <span>{t("Martingale")}</span>
            <h6>{martingale}</h6>
          </div>
        </div>
      </>
        
     );
      break;
    default:
      break;
  }

  return (
    
    <div className="col-lg-6 col-md-6">
    <div className="bot-detail-left">
      <div className="bot-detail-left-header">
        <label>
          <img src="/images/overview-icon.svg" alt=""/>
          Overview
        </label>
        <label>
          <img src={exchange.image} alt="" width={20}/>
          {exchange.name}
        </label>
      </div>
      <div className="bot-detail-left-body">
        <div className="row">
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("pair")}</span>
              <h6>{pair && `${pair.from}/${pair.to}`}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("bot-type")}</span>
              <h6>{bot && BotTypeName[bot.type]}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("fund")}</span>
              <h6 >{bot && BotTypeName[bot.type]}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("ord-am")}</span>
              <h6>{bot.base_order_percentage} {pair.to}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("DCA Type")}</span>
              <h6>{dcaTypeName}</h6>
            </div>
          </div>
          {dcaSettings}
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("no-of-ord")}</span>
              <h6>{limitOrder === 0 ? t("no-limit") : limitOrder}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("tp")}</span>
              <h6>{takeProfit}</h6>
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  </div>
  );
};

export default DcaBotSetting;
