import React from "react";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch } from "react-redux";
import { CHANGE_FREQUENCY, CHANGE_GRID_RANGE_DIRECTION, CHANGE_GRID_RANGE_PERIOD, CHANGE_GRID_RANGE_VALUE } from "../../../../actions/create-bot-type";
import TutorialPopup from "../../../help/TutorialPopup";
import { Button, Col, Form, Row } from "react-bootstrap";
import SliderView from "../SliderView";
import { FrequenciesPro, FrequencyValues, FrequencyValuesPro } from "../../../../Const";
import { restrictAlphabets } from "../../../../utils/Utils";


const GridRangeView = (props) => {
  const { newBot,t } = props;
  const frequency = newBot.frequency;
  const dispatch = useDispatch();
  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const changeGridDirection = (val) => {
    dispatch({
      type: CHANGE_GRID_RANGE_DIRECTION,
      payload: val,
    });
  };
  const changeRangeValue = (val) => {
    dispatch({
      type: CHANGE_GRID_RANGE_VALUE,
      payload: val,
    });
  };
  const changeRangePeriod = (val) => {
    dispatch({
      type: CHANGE_GRID_RANGE_PERIOD,
      payload: val,
    });
  };
  const formartFrequency = (value) => FrequenciesPro[value];
  return (
    <div>
      
      <Row>
        <Col xl={4} className="cb-form-label">
        <Form.Label>
        Create Grid if the price difference <TutorialPopup content={t("gridDifference")}></TutorialPopup> is 
        </Form.Label>
        </Col>
        <Col xl={8}>
          <div className="d-flex justify-content-between bw-option-div">
            <span className={newBot.gridRangeKlineDirection==0?"danger":""} onClick={() => changeGridDirection(0)}> {t("lesser")}</span>
            <span className={newBot.gridRangeKlineDirection==1?"success":""} onClick={() => changeGridDirection(1)}>{t("greater")}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
        <div className="d-flex">
          <div>than</div>
          <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.gridRangeKlineValue}
                  onChange={(e) => changeRangeValue(e.target.value)}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
          <div>
          for the previous last
          </div>
          <div className="d-flex">
          <Form.Control type="number"  className="form-control input-text dca-subseq-text" onChange={(e) => changeRangePeriod(e.target.value)} value={newBot.gridRangeKlineNum}/> periods.
          </div>
      </div></Col>
      </Row>
     
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={4}
          value={FrequencyValuesPro.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(FrequencyValuesPro[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")}  />}
        />
      </div>
    </div>
  );
};

export default GridRangeView;
