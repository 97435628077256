import React from "react";
import { Card, Col } from "react-bootstrap";

const SocialCard = (props) => {
  const { icon, text, link,t } = props;
  return (
    <Col>
      <div>
        <a href={link} target="_blank" style={{ textDecoration: "none" }}>
          <div className="d-flex align-items-center round-bg">
            <i className={icon}></i>
            <span>{t(text)}</span>
          </div>
        </a>
      </div>
    </Col>
  );
};

export default SocialCard;
