import React from "react";
import Pagination from "react-js-pagination";
import "./CustomPagination.scss";

const DefaultPagination = (props) => {
  const { startItem, onChange, itemsCountPerPage, totalCount } = props;
  const activePage = startItem / itemsCountPerPage + 1;
  return (
    <>
      <div className="row justify-content-center mt-auto">
        {totalCount <= itemsCountPerPage ? (
          <></>
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage || 10}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={(num) => onChange((num - 1) * itemsCountPerPage)}
            innerClass="pagination"
            activeLinkClass="text-white bg-info"
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="Prev"
            nextPageText="Next"
          />
        )}
      </div>
    </>
  );
};

export default DefaultPagination;
