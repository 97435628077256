import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TradingInforPanel from "./TradingInforPanel";
import OrderPanel from "./OrderPanel";
import BalancePanel from "./BalancePanel";
import NewOrderPanel from "./NewOrderPanel";
import RepayModal from "./RepayModal";
import TransferModal from "./TransferModal";
import { getExchangePairs } from "../../actions/bot-action";
import { connect, useSelector, useDispatch } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import "./TradingTerminal.scss";
import WatchList from "./WatchList";
import MarketPanel from "./MarketPanel";
import { AccountType, TradeSide } from "../../Const";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import { CHANGE_ACCOUNT } from "../../actions/types";
import { getExecutedBotOrders } from "../../actions/terminal-action";
import { useTranslation } from "react-i18next";

const TradingTerminal = (props) => {
  const { getExchangePairs,getExecutedBotOrders } = props;
  const {t} = useTranslation()

  const dispatch = useDispatch();
  const [tradeSide, setTradeSide] = useState(TradeSide.buy);
  const [showRepay, setShowRepay] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [setLoading, bindLoading] = useLoading();
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const account =
    useSelector((state) => state.terminal.account) || AccountType.spot;
  const baseCoin = selectedPair.to;
  const quoteCoin = selectedPair.from;

  useEffect(() => {
    getExchangePairs(exchangeId);
  }, [exchangeId]);

  useEffect(() => {
    console.log(selectedPair)
    getExecutedBotOrders(selectedPair.pairId);
  }, [baseCoin,quoteCoin]);

  const changeAccount = (acc) => {
    dispatch({
      type: CHANGE_ACCOUNT,
      payload: acc,
    });
  };

  return (
    <ContentContainer url="dashboard" title={t("dashboard")} showExchange={true}>
    <div className="dashboard-content">
      <Row noGutters className="terminal">
        
        <Col xs={12} lg={12}>
        <MarketPanel account={account} accountChanged={changeAccount} />
          <Row >
            <Col className="watch-panel mt-4" xs={12} lg={4}>
            <div className="card-white">
                <div className="cw-body">
                <WatchList />
              </div>
              </div>
            </Col>
            <Col xs={12} lg={8} className="trading-chart-panel mt-4">
            <div className="card-white" style={{ height: "100%" }}>
                <div className="cw-body" style={{ height: "100%" }}>
                  <TradingInforPanel/>
                  </div>
            </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
            
            <OrderPanel account={account} t={t} />
            </Col>
            <Col lg={4} className="mt-4">
              <BalancePanel
                account={account}
                onShowRepay={() => setShowRepay(true)}
                onShowTransfer={() => setShowTransfer(true)}
                quoteCoin={baseCoin}
                baseCoin={quoteCoin}
                t={t}
              />
              <NewOrderPanel
              tradeSide={tradeSide}
              tradeSideChanged={setTradeSide}
              quoteCoin={quoteCoin}
              baseCoin={baseCoin}
              account={account}
              t={t}
            />
            </Col>
          </Row>
          <div>
            <hr />
            
          </div>
        </Col>
       
      </Row>
      <RepayModal
        show={showRepay}
        onHide={() => setShowRepay(false)}
        baseCoin={baseCoin}
        quoteCoin={quoteCoin}
        setLoading={setLoading}
        pairId={selectedPair.realPairId}
        account={account}
      />
      <TransferModal
        show={showTransfer}
        onHide={() => setShowTransfer(false)}
        baseCoin={baseCoin}
        quoteCoin={quoteCoin}
        pairId={selectedPair.realPairId}
        setLoading={setLoading}
        account={account}
        t={t}
      />
      <Loading {...bindLoading} />
      </div>
    </ContentContainer>
  );
};

export default connect(null, { getExchangePairs,getExecutedBotOrders })(TradingTerminal);
