import { createPriceAlertUrl, priceAlertList, updatePriceAlertUrl } from "./const";
import network from "./network";
import { PRICE_ALERT_LIST } from "./types";

export const getPriceAlerts = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .get(
            priceAlertList,
          {},
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message,alerts } = res.data;
          if (code === 200) {
            dispatch({
              type: PRICE_ALERT_LIST,
              payload: alerts,
            });
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };
export const createPriceAlert = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
            createPriceAlertUrl,
        params,
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message,alert } = res.data;
          if (code === 200) {
            // dispatch({
            //   type: PRICE_ALERT_LIST,
            //   payload: alerts,
            // });
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };
export const updatePriceAlert = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
            updatePriceAlertUrl+params.id,
        params,
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message,alert } = res.data;
          if (code === 200) {
            // dispatch({
            //   type: PRICE_ALERT_LIST,
            //   payload: alerts,
            // });
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };
export const deletePriceAlert = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .delete(
            updatePriceAlertUrl+id,
        {},
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message,alert } = res.data;
          if (code === 200) {
            // dispatch({
            //   type: PRICE_ALERT_LIST,
            //   payload: alerts,
            // });
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };



