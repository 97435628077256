import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTemplateDetail } from "../../../actions/marketplace-action";
import ContentContainer from "../../common/ContentContainer";
import TemplateImage from "./TemplateImage";
import TemplateInfoCard from "./TemplateInfoCard";
import TemplateRentDetails from "./TemplateRentDetails";
import BackButton from "../../common/BackButton";
import { formatDate } from "../../../utils/Utils";
import ToolTip from "../../help/ToolTip";
import TutorialPopup from "../../help/TutorialPopup";

const BotTemplateDetail = (props)=>{
    const template = useSelector((state)=>state.marketplace.template);
    const {getTemplateDetail} = props;
    let { id } = useParams();
    const {t} = useTranslation()
    const [performanceDuration, bindPerformanceDuration] = useState(1);
    useEffect(()=>{
        getTemplateDetail(id)
    },[id])
    return (
        <>
        <ContentContainer url="marketplace" title={t("marketplace")} showExchange={false}>
            <div className="main-right-content">
            <div className="marketplace-detail-area">
								<div className="md-title">
									<h1>{template.title}<a href="#" className={template.strategy=="Long"?"long":"short"}>{template.strategy}</a></h1>
									<label>
										<span>By {template.author_name}</span>
										<span>Listed on {formatDate(template.created_at)}</span>
										<span>Last Update on {formatDate(template.updated_at)}</span>
									</label>
								</div>
								<div className="md-content">
									<div className="row g-2 g-lg-3 g-xl-4">
										<div className="col-lg-5">
											<div className="md-content-left">
												<img src={template.image} alt=""/>
												<div className="mdcl-bottom">
                                                    <ToolTip content={t("num-of-views")}><label>
														<img src="/images/mlc-eye-icon.svg" alt=""/>
														{" "}{template.view_count}
													</label></ToolTip>
													<ToolTip content={`${template.total_thumbs_up} ${template.total_thumbs_up > 1?t("renters-like-bot"):t("renter-like-bot")}`}>
													<label><i className="fa-solid fa-heart heart"></i>
														{" "}{template.total_thumbs_up}
													</label></ToolTip>
												</div>
											</div>
										</div>
										<div className="col-lg-7">
											<div className="md-content-right">
												<div className="mdc-para">
													<p>{template.description}</p>
												</div>
												<div className="mdc-performance">
													<div className="row g-2 g-lg-3 g-xl-4">
														<div className="col-sm-6">
															<div className="mdc-performance-left">
																<h4> <img src="/images/performance-icon1.svg" alt=""/>{t('perf-on-listing')} <TutorialPopup content={t("template-performance-help")} /></h4>
																<ul>
																	<li>
																		<span>APY</span>
																		<h6>{template.apy}%</h6>
																	</li>
																	<li>
																		<span>{t("w-l-ratio")}</span>
																		<h6>{template.wl_ratio}%</h6>
																	</li>
																	{/* <li>
																		<span>{t("performance")}</span>
																		<h6>{template.performance}%</h6>
																	</li> */}
																</ul>
																<ul>
																	<li>
																		<span>{t("a-m-d")}</span>
																		<h6>{template.avg_monthly_deal}</h6>
																	</li>
																	<li>
																		<span>{t("total-deals")}</span>
																		<h6>{template.total_deal}</h6>
																	</li>
																</ul>
																<ul>
																	
																</ul>
															</div>
														</div>
														<div className="col-sm-6">
															<div className="mdc-performance-left mdc-performance-left ">
																<h4> <img src="/images/performance-icon2.svg" alt=""/> {t('live-trading-perfo')} <TutorialPopup content={t("live-trading-perfo-help")} /></h4>
																<div className="mdcp-btn">
																	<button onClick={()=>bindPerformanceDuration(1)} className={performanceDuration==1?"active":"inactive"}>All Time</button>
																	<button onClick={()=>bindPerformanceDuration(2)} className={performanceDuration==2?"active":"inactive"}>Last 3 Months</button>
																</div>
																<ul>
																	<li>
																		<span>{t("avg-w-l-ratio")}</span>
																		<h6>{performanceDuration==1?template.real_performances?.avg_wl:template.real_performances?.avg_wl_3_months}%</h6>
																	</li>
																	<li>
																		<span>{t("total-deals")}</span>
																		<h6>{performanceDuration==1?template.real_performances?.total_deal:template.real_performances?.total_deal_3_months}</h6>
																	</li>
																</ul>
															</div>
														</div>
                                                        
														{/* <BotTradedStocks template={template} t={t}></BotTradedStocks> */}
                                                        <TemplateRentDetails template={template} t={t}/>
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
            </div>
            
        </ContentContainer>
     </>
    );
}

export default connect(null,{getTemplateDetail})(BotTemplateDetail)