import {
    PaperBtcUsdt,
    PaperExchange,
  } from "../../../../Const";
  
export const adaptBotPreset = (
  exchange = PaperExchange,
  exchangePair = PaperBtcUsdt,
) => {
  const pair = exchangePair.pair || {};
  
  return {
    id: null,
    name: "New Bot",
    exchange: exchange,
    exchangePairId: exchangePair.id, //exchange_pair.id
    pair: exchangePair.pair,
    initFund: 100,
    baseCoin: pair.from,
    quoteCoin: pair.to,
    leverage:5
  };
};
export const adaptRentBotEdit = (
  bot
) => {
  const pair = bot.exchange_pair.pair || {};
  return {
    id: bot.id,
    rent_id: bot.rent_id,
    name: bot.name,
    exchange: bot.exchange_pair.exchange,
    exchangePairId: bot.exchange_pair_id, //exchange_pair.id
    pair: bot.exchange_pair.pair,
    initFund: bot.initial_fund,
    baseCoin: pair.from,
    quoteCoin: pair.to,
    strategy: bot.strategy,
    leverage:bot.configs?bot.configs.leverage:5,
    isEditing: true,
    createBotStart:true,
    tradeOrderType:bot.order_type,
    tradeOrderTypeExit:bot.order_type_exit,
    futureType:bot.exchange_pair.future_type,
    priceType: bot.configs?bot.configs.price_type:"",
    marginMode: bot.configs?bot.configs.margin_mode:""
  };
};
export const adaptRentBotDefault = (
  exchange = PaperExchange,
  exchangePair = PaperBtcUsdt,rental
) => {
  const pair = exchangePair.pair || {};
  return {
    id: null,
    name: "New Bot",
    exchange: exchange,
    exchangePairId: exchangePair.id, //exchange_pair.id
    pair: exchangePair.pair,
    initFund: 0,
    baseCoin: pair.from,
    quoteCoin: pair.to,
    rent_id: rental.id,
    leverage:5,
    isEditing: false
  };
};
  