import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import TutorialPopup from "../../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_SECOND_EXCHANGE, CHANGE_STRATEGY, STRATEGY_DIRTY } from "../../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../../Const";
import { getBalance, getPairBalance } from "../../../../actions/bot-action";
import { Trans } from "react-i18next";
import BooleanView from "../BooleanView";
import { CHANGE_DEFAULT_PARTNER_PAIRS } from "../../../../actions/types";
import LeftRightForm from "../modules/LeftRightForm";

const AssetViewIntraArbitrage = (props) => {
  const { newBot,getBalance,getPairBalance,t,editing } = props;
  const intraArbPairs = useSelector((state) => state.bots.intraArbitragePairs);
  const intraBot = newBot.botType === BotType.intra_arbitrage
  const strategy = newBot.strategy;
  const [baseCoin, setBaseCoin] = useState("usd");
  const dispatch = useDispatch();
  const selectedExchange = newBot.exchange;
  const pairList =  useMemo(() => {
    return intraArbPairs
  }, [])
  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
      }))) ||
    [];
  const pair = newBot.pair;
  const selectedPair = {
    value: newBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
  };

  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
    dispatch({
      type: CHANGE_PAIR,
      payload: newPair,
    });
  };
  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY,
      payload: strategy,
    });
    dispatch({
      type: STRATEGY_DIRTY,
      payload: true,
    });
  };
  useEffect(()=>{
    if(editing)
    {
      if(newBot.pair)
      getPairBalance(selectedExchange.id,newBot.pair.id,'spot');
    }
    else
    {
      if(pairList[0]){
        dispatch({
          type: CHANGE_PAIR,
          payload: pairList[0],
        });
        if(pairList[0].pair_id){
          getPairBalance(selectedExchange.id,pairList[0].pair_id,'spot');
        }
      }
    }
  },[])
  

  return (
    <div className="ba-asset-form-content">
          <div className="row gx-0 gy-3">
            <LeftRightForm
              left={<>{t("exchange")} <TutorialPopup content={t("exchange-help")} /></>}
              right={<><img
                src={selectedExchange.image}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {selectedExchange.name}
              </span>
              </>}
            ></LeftRightForm>
            <LeftRightForm
              left={<>{t("t-pair")} <TutorialPopup content={t("tradingpair-help")} /></>}
              right={<> <Select
                value={selectedPair}
                options={pairItems}
                onChange={handleSelectPair}
                className='react-select-container third-step'
                classNamePrefix='react-select'
              />
               <Form.Label className="mt-4">
                  <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
                </Form.Label>
              </>}
              rightClass="react-select-div"
            ></LeftRightForm>
            <LeftRightForm
              left={<> {t("triangle-arb-strategy")} <TutorialPopup content={t("triangle-arb-strategy-help")} /></>}
              right={<> <div className="d-flex justify-content-between bw-option-div-1 ba-asset-Strategy-btn">
                
              <a
               className={strategy == "Long" ?"success selected" : ""}
               onClick={() => strategyChanged("Long")}
             >
               LONG
             </a>
             <a
               className={strategy == "Short" ?"success selected" : ""}
               onClick={() => strategyChanged("Short")}
             >
               SHORT
             </a>
             <a
               className={strategy == "Both" ?"success selected" : ""}
               onClick={() => strategyChanged("Both")}
             >
               LONG/SHORT
             </a>
           </div>
              </>}
            ></LeftRightForm>
           
            
    </div>
    </div>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetViewIntraArbitrage);
