import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_ORDER_TYPE, CHANGE_ORDER_TYPE_RENT } from "../../../../../actions/create-bot-type";
import TutorialPopup from "../../../../help/TutorialPopup";
import BooleanView from "../../BooleanView";

const OrderTypeModule = (props)=>{
    const {t,newBot,editing,singleOption,rent} = props;
    const dispatch = useDispatch()
    const orderTypeChanged = (orderType) => {
        dispatch({
          type: rent?CHANGE_ORDER_TYPE_RENT:CHANGE_ORDER_TYPE,
          payload: singleOption?singleOption:orderType,
        });
      };
    useEffect(()=>{
      if(singleOption){
        dispatch({
          type: rent?CHANGE_ORDER_TYPE_RENT:CHANGE_ORDER_TYPE,
          payload: singleOption,
        });
      }
    },[singleOption])
    return(
    <>
    <BooleanView
        title={t("order-type")}
        firstButton={singleOption?"":"limit"}
        secondButton="market"
        selected={singleOption?singleOption:newBot.tradeOrderType}
        onSelect={orderTypeChanged}
        tutorial={<TutorialPopup content={t("orderType")} />}
        />
    </>
    )
}
export default OrderTypeModule

