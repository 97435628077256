import React from "react";
import { Form, InputGroup } from "react-bootstrap";
//import "./ExitBotIndicatorView.scss";
import {CHANGE_ENTRY_PRICE} from "../../../../actions/create-bot-type";
import { connect,useDispatch } from "react-redux";
import { Trans } from "react-i18next";
import LeftRightForm from "../modules/LeftRightForm";

const PriceEntryView = (props) => {
  const { newBot } = props;
  const isBuy = newBot.strategy=='Long';
  const side = isBuy ? "Buy" : "Sell";
  const actionTrigger = isBuy ? "less" : "more";
  const dispatch = useDispatch();
  const changePriceEntry = (val) => {
    dispatch({
      type: CHANGE_ENTRY_PRICE,
      payload: val,
    });
  };
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
  }
  return (
    <>
      <div>
         
            <LeftRightForm 
              left={ <Form.Label>
                <Trans i18nKey={"price-entry-cond"}>{{side}} {{actionTrigger}}</Trans>
                </Form.Label>}
              right={ <label data-text={newBot.quoteCoin} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.entryPrice}
                  onChange={(e) => {
                    changePriceEntry(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex"
                ></Form.Control>
            </label>}
            ></LeftRightForm>
      </div>
      
    </>
  );
};

export default connect(null)(PriceEntryView);
