import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { BotType } from "../../../Const";
import { formatDate, milisecOf, typeNameOrder } from "../../../utils/Utils";
import CustomPagination from "../../common/CustomPagination";

const TradeTab = (props) => {
  const { deals,startItem,botType } = props;
  const itemsPageNum = 2;
  //const [startItem, setStartItem] = useState(0);
  
  deals.forEach((item) => {
    item.closed_orders.sort((order1, order2) => {
      return milisecOf(order1.date) - milisecOf(order2.date);
    });
  });
  deals.sort((item1, item2) => {
    return (
      milisecOf(item2.closed_orders[0]&&item2.closed_orders[0].date) -
      milisecOf(item1.closed_orders[0]&&item1.closed_orders[0].date)
    );
  });
  // const paginate = (selectedExchangeId,itemsCountPerPage,num) => {
  //   setStartItem(num)
  // }
  //const pageItems = deals.slice(startItem, startItem + itemsPageNum);
  const pageItems = deals;
  const tradeItems = pageItems.map((item, index) => {
    const orderNum = item.closed_orders.length;
    return item.closed_orders.map((order, id) => {
      return (
        <tr key={index + " - " + id}>
          {id == 0 ? (
            <td rowSpan={orderNum}>{startItem + index }</td>
          ) : (
            <></>
          )}
          <td>
            {formatDate(order.date, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY HH:mm")}
          </td>
          <td>{typeNameOrder(order.name)}</td>
          <td style={{textAlign:'left'}}>
          {botType == BotType.inter_arbitrage && <><img src={order.exchange_pair.exchange.image}  height="20px" width="20px" style={{marginRight:10}}></img> {order.exchange_pair.exchange.name} | </>
              }
          {order.description}</td>
        </tr>
      );
    });
  });
  return (
    <div className="bdt-content">
									<div className="bdt-table-responsive">
										<table className="table">
                  <tbody>
                      <tr valign="top">
                      <td>#</td>
                      <td>Date</td>
                      <td>Type</td>
                      <td>Detail</td>
                      </tr>
                      {tradeItems}
                  </tbody>
              </table>
          </div>
    </div>
  );
};

export default TradeTab;
