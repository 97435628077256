import React, { useEffect } from "react";
import AffiliateLayout from "../common/AffliateLayout";
import "./MyRewards.scss";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { getAffiliateRewardHistory } from "../../../actions/affiliate-action";
import usePagination from "../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { hideEmail } from "../../../utils/Utils";
import { t } from "i18next";

const MyRewards = (props) => {
  const { getAffiliateRewardHistory } = props;
  const history = useHistory();
  const [itemRange, bindPagination] = usePagination(0, 10);
  const totalUsd = useSelector((state) => state.affiliate.totalUsd) || 0;
  const rewardsHistories =
    useSelector((state) => state.affiliate.rewardsHistories) || [];

  useEffect(() => {
    getAffiliateRewardHistory();
  }, []);
  const items = rewardsHistories.slice(...itemRange).map((item, index) => (
    <tr key={index}>
      <td>{item.date}</td>
      <td>{hideEmail(item.email)}</td>
      <td>{item.status}</td>
      <td>{item.point}</td>
    </tr>
  ));
  return (
    <AffiliateLayout>
      <div className="my-rewards">
        <div className="left">
          <p className="header1">
            {t("your-total-rewards")}: <span className="header2">US${totalUsd}</span>
          </p>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("email")}/ID</th>
                <th>{t("type")}</th>
                <th>{t("rewards")}</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
          <div className="mt-4">
            <CustomPagination
              {...bindPagination}
              totalCount={rewardsHistories.length}
            />
          </div>
        </div>
        <div className="right">
          <div>
            <button
              className="btn"
              onClick={() => history.push("/my-rewards/redeem")}
            >
              {t("redeem")}
            </button>
            <button
              className="btn history-btn"
              onClick={() => history.push("/my-rewards/history")}
            >
              {t("history")}
            </button>
          </div>
          <img src="/images/pp3.png" />
        </div>
      </div>
    </AffiliateLayout>
  );
};

export default connect(null, { getAffiliateRewardHistory })(MyRewards);
