import React from "react";
import AffiliateLayout from "../common/AffliateLayout";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ReferralsRedeem.scss";
import useInput from "../../hooks/useInput";
import useLoading from "../../hooks/useLoading";
import Loading from "../../common/Loading";
import { toast } from "react-toastify";
import { postAffiliatePointRedeem } from "../../../actions/affiliate-action";

const POINTS = [50, 100, 200, 500, 800, 1000, 2000, 5000];

const ReferralsRedeem = (props) => {
  const { postAffiliatePointRedeem } = props;
  const history = useHistory();
  const totalPoints = useSelector((state) => state.affiliate.totalPoints) || 0;
  const [point, bindPoint] = useInput("500");
  const [setLoading, bindLoading] = useLoading(false);

  const clickRedeem = () => {
    if (point < 500) {
      return toast.error("Minimum redemption amount is 10 DOGE");
    }
    if (point > totalPoints) {
      return toast.error("Exceed maximum redemption");
    }
    setLoading(true);
    postAffiliatePointRedeem(point)
      .then(() => {
        setLoading(false);
        toast.success("Redeem successfully");
        history.push("/my-referrals/history");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const options = POINTS.map((item, id) => {
    return (
      <option key={id} value={String(item)}>
        {item} Points - {item / 50} DOGE
      </option>
    );
  });

  return (
    <Loading {...bindLoading}>
      <AffiliateLayout>
        <div className="my-referrals">
          <div className="left">
            <p className="header1">
              Maximum Redemption: <span className="header2">{totalPoints}</span>
            </p>
            <div className="referrals-redeem-form">
              <label>Points to Redeem:</label>
              <select {...bindPoint}>{options}</select>

              <button className="btn btn-redeem" onClick={clickRedeem}>
                Redeem Now
              </button>

              <p>
                * Please make sure you have a{" "}
                <a href="/setting?loc=3">Binance connection</a> in CryptoHero.
                The minimum Dogecoins to be redeemed is 10 DOGE.
              </p>
              <p>
                * All gifts and their Points-exchange ratio are subject to
                change without notice.
              </p>
            </div>
          </div>
          <div className="right">
            <div className="text-left">
              <button
                className="btn"
                onClick={() => history.push("/my-referrals/history")}
              >
                History
              </button>
            </div>
            <img src="/images/hand1.png" />
          </div>
        </div>
      </AffiliateLayout>
    </Loading>
  );
};

export default connect(null, { postAffiliatePointRedeem })(ReferralsRedeem);
