import React, { useEffect, useRef, useState } from "react";
import { DropdownButton, Button, Dropdown } from "react-bootstrap";
import "./BotFilterPanel.scss";
import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT } from "../../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { TimeFrames, Frequencies, BaseCoins } from "../../../Const";



const BotFilterPanel = (props) => {
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const filterBots = (status)=>{
		dispatch({
			type:BOTS_ACTIVE_TAB,
			payload:status
		})
	}
	const setSort = (sort)=>{
		dispatch({
		  type:BOTS_SORT,
		  payload:sort
		})
	  }
	const setOrderBy = (sort)=>{
	dispatch({
		type:BOTS_ORDER_BY,
		payload:sort
	})
	}
	const sort = useSelector((state) => state.pagination.botSort) || "name";
    const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
	const Sorts = { name: t("NAME"),apy: "APY",created_at:t("DATE"),performance:t("PERFORMANCE"),profit:t("RETURN")};
	const sortOptions = Object.keys(Sorts).map((el) => {
		return (
		  <li key={el}>
		  <button className="dropdown-item" type="button" key={el} onClick={()=>setSort(el)}>
		  		{Sorts[el]}
		  </button>
	  	</li>
		);
	  });
	const dropdownRef = useRef(null)
	const [showFilterMenu,setShowFilterMenu] = useState(false)
	const handleClickOutside = (event) => {
		// Check if the click is outside of any bot's menu or button
		if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
		  return;
		}
		else{
			setShowFilterMenu(false)
		}
	  };
	  useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);
  
  return (
    <>
    <div className="mr-dropdown">
		<select className="select-normal bot-filter-dropdown" onChange={(e) => filterBots(e.target.value)} value={props.status}>
			<option value="all" >All Bots</option>
			<option value="active" >Active</option>
			<option value="inactive" >Inactive</option>
		</select>
	</div>
	<label>
		<input type="text" placeholder="Search" onChange={(e)=>props.setSearchBotName(e.target.value)} value={props.searchBotName}/>
		<div className="dropdown d-flex gap10" ref={dropdownRef}>
			<button className="dropdown-toggle"  data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setShowFilterMenu(showFilterMenu?false:true)}>
				<i className="fa-solid fa-filter text-white"></i>
				
			</button>
			<button className="dropdown-toggle"   onClick={()=>setOrderBy(orderBy=="asc"?"desc":"asc")}>
			<img src={`images/${orderBy}.svg`} alt=""/>
				
			</button>
			<ul className={`dropdown-menu ${showFilterMenu?"show":""}`} id="all-bots-dropdown" aria-labelledby="dropdownMenu2">
				{sortOptions}
			</ul>
		</div>
	</label>
</>
  );
};

export default BotFilterPanel;
