import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { BotType, StoplossType, TakeProfitTypeName, TakeProfitType } from "../../../../Const";
import { connect, useSelector, useDispatch } from "react-redux";
import { CHANGE_TP_TYPE } from "../../../../actions/create-bot-type";
import TutorialPopup from "../../../help/TutorialPopup";
import AbsoluteTP from "./AbsoluteTP";
import FixedTP from "./FixedTP";
import VariableTP from "./VariableTP";
import LeftRightForm from "../modules/LeftRightForm";

const TakeProfitView = (props) => {
  const {t} = props;
  const newBot = useSelector((state) => state.createBot.newBot) || {};
  const isPremiumBot = (newBot.botType === BotType.advance || newBot.botType === BotType.sell || newBot.botType === BotType.dca || newBot.botType === BotType.exit)
  const slType = newBot.takeProfitType;
  const dispatch = useDispatch();
  const changeSLType = (val) => {
    dispatch({
      type: CHANGE_TP_TYPE,
      payload: val,
    });
  };

  const options = Object.keys(TakeProfitType).map((item, id) => (
    <option key={id} value={TakeProfitType[item]}>
      {TakeProfitTypeName[item]}
    </option>
  ));
  let slView;
  switch (slType) {
    case TakeProfitType.absolute:
      slView = <AbsoluteTP min={0} max={100} value={newBot.takeProfit} hideLabel={true} isPremiumBot={isPremiumBot} t={t}/>;
      break;
    case TakeProfitType.fixedTP:
      slView = <FixedTP newBot={newBot} />;
      break;
    case TakeProfitType.variableTP:
      slView = <VariableTP newBot={newBot} />;
      break;
  }
  return (
    <div className="stoploss-view">
      {isPremiumBot &&
      <LeftRightForm 
        left={<h6>
          Take Profit {isPremiumBot && "Type:"}{slType !== TakeProfitType.absolute && (
          <TutorialPopup
            content={slType === TakeProfitType.fixedTP ? t("fixed-tp-msg") : t("variable-tp-msg")}
          />
        )}
        </h6>}
        right={<div>
          {isPremiumBot && (
            <select value={slType} onChange={(e) => changeSLType(e.target.value)} className="form-control select-normal">
              {options}
            </select>
          )}
          
          </div>}
      ></LeftRightForm> }
      {slView}
    </div>
  );
};

export default connect(null)(TakeProfitView);
