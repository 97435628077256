import React from "react";
import { connect } from "react-redux";
import CreateBotFromRentModal from "./CreateBotFromRentModal";


const EditRentBot = (props)=>{
    return (
        <>
       
        </>
    )
}

export default connect(null,{})(EditRentBot)