import React from "react"
import MenuItems from "./MenuItems";

const TopMenu = (props)=>{
    const {url} = props
    return (
        <>
            <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu"></input>
            <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                <div className="spinner diagonal part-1"></div>
                <div className="spinner horizontal"></div>
                <div className="spinner diagonal part-2"></div>
            </label>
            <ul id="menu">
                <MenuItems url={url}/>
            </ul>
        </>
        
    )
}

export default TopMenu;