import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./RentBotPaymentPanel.scss";
import { Form, Button } from "react-bootstrap";
import { STRIPE_PUBLIC } from "../../../config";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { connect } from "react-redux";
import { paypalSubscribe } from "../../../actions/setting-action";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../common/Loading";
import BotRentCryptoPayment from "./BotRentCryptoPayment";
import ContactForm from "../../help/ContactForm";
import { getPaypalPlan } from "../../../utils/SubUtils";
import RentBotCardForm from "./RentBotCardForm";

const stripePromise = loadStripe(STRIPE_PUBLIC);

const RentBotPaymentPanel = (props) => {
  const { template,plan, tier } = props;
  const [cardHolder, setCardHolder] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showCryptoPayment, setShowCryptoPayment] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    //setLoading(true);
  }, []);

  
  const showCryptoPaymentModal = (network,qr,address)=>{
    setShowCryptoPayment(true)
  }
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Elements stripe={stripePromise}>
        <div className="mb-4 mt-4">
          <h5>Credit or Debit card</h5>
          <img
            src={require("../../../images/visa.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../../images/mastercard.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../../images/amex.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../../images/jcb.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
        </div>
        <div>
          <Form.Group>
          <label className="font-weight-bold">
              Card Holder Name
            </label>
            <Form.Control
              className="input"
              value={cardHolder}
              onChange={(e) => setCardHolder(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </div>
        <RentBotCardForm plan={plan} cardHolder={cardHolder} template={template} setLoading={setLoading}/>
        <p className="text-secondary text-center my-3">OR</p>
        <div className="text-center">
        
        </div>

        
          <Button
            style={{ marginTop: 10, width: "100%" }}
            className="py-2"
            variant="secondary"
            onClick={() => showCryptoPaymentModal('ERC')}
          >
            <img
              src="/images/coins/USDT.png"
              width={18}
              style={{ marginRight: 10 }}
            />
            Pay With USDT
          </Button>
          
      </Elements>
      <BotRentCryptoPayment
        show={showCryptoPayment}
        template={template}
        onHide={() => setShowCryptoPayment(false)}
        sendEmail={() => setShowContactForm(true)}
        plan={plan}
      />
      {/* <ContactForm
        show={showContactForm}
        onHide={() => setShowContactForm(false)}
      /> */}
    </Loading>
  );
};

export default connect(null, { paypalSubscribe })(RentBotPaymentPanel);
