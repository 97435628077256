import { GET_DISCOVER } from "../actions/types";

const initialState = {};

const discoverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DISCOVER:
      return {
        ...state,
        configs: payload,
      };
    default:
      return state;
  }
};

export default discoverReducer;
