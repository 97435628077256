import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { getPaymentHistory } from "../../../actions/marketplace-action";
import PayoutHistoryModal from "./PayoutHistoryModal";
import RequestPaymentModal from "./RequestPaymentModal";

const PersonalBalancePanel = (props)=>{
    const {getPaymentHistory,t} = props;
    const [showPayoutModal,setShowPayoutModal] = useState(false)
    const [showRequestPaymentModal,setshowRequestPaymentModal] = useState(false)
    useEffect(()=>{
        getPaymentHistory()
    },[])
    const marketplaceDashboard =  useSelector((state) => state.marketplace.marketplaceDashboard) || {};
    return (
        <div className="personal-bal-div">
            <div className="card-white">
            <div className="cw-body">
            <div className="d-flex justify-content-between">
                <div><p>{t("pb")}</p></div>
                <div><h4 className="balance-colour bold">US${marketplaceDashboard.lifetime_earn}</h4></div>
            </div>
            <div className="d-flex justify-content-between">
                <div><p>{t("total-renters")}</p></div>
                <div>{marketplaceDashboard.total_renter}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div><p>{t("ar")}</p></div>
                <div>{marketplaceDashboard.active_renter}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div><p>{t("life-earn")}</p></div>
                <div>US${marketplaceDashboard.lifetime_earn && marketplaceDashboard.lifetime_earn.toFixed(2)}</div>
            </div>
           
            <Row className="text-title mt-4">
                <Col xs={12}>
                    <Button className="btn blue-btn" block={true}  onClick={() => setshowRequestPaymentModal(true)} disabled={marketplaceDashboard.current_earn<=20}>
                    {t("req-p")}
                    </Button>
                    <Button className="btn grey-btn" block={true} onClick={() => setShowPayoutModal(true)}>
                    {t("pay-his")}
                    </Button>
                </Col>
                <Col xs={12} className="mt-5">
                
                    <div className=" bot-tab">
                            <div className="deals-table">
                                <table className="">
                                    <tbody>
                                        <tr valign="top">
                                            <th>{t("")}</th>
                                            <th>{t("month")}</th>
                                            <th>{t("quarter")}</th>
                                            <th>{t("year")}</th>
                                        </tr>
                                        <tr>
                                        <th scope="row"> {t("t-rev")}</th>
                                            <td>{marketplaceDashboard.month_earn && marketplaceDashboard.month_earn.toFixed(2)}</td>
                                            <td>{marketplaceDashboard.quarter_earn && marketplaceDashboard.quarter_earn.toFixed(2)}</td>
                                            <td>{marketplaceDashboard.year_earn && marketplaceDashboard.year_earn.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{t("total-renters")}</th>
                                            <td>{marketplaceDashboard.month_renter}</td>
                                            <td>{marketplaceDashboard.quarter_renter}</td>
                                            <td>{marketplaceDashboard.year_renter}</td>
                                            </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </Col>
                
            </Row>
            </div>  
            </div> 
           
            
            {showPayoutModal && <PayoutHistoryModal onHide={()=>setShowPayoutModal(false)} /> }
            {showRequestPaymentModal && <RequestPaymentModal onHide={()=>setshowRequestPaymentModal(false)} defaultAmount={marketplaceDashboard.current_earn && marketplaceDashboard.current_earn.toFixed(2)}
        payOutHistoryPendingData={[]} t={t}/> }
        </div>
       
    );
}
export default connect(null,{getPaymentHistory})(PersonalBalancePanel)
