import React from "react";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";
import "./ExitBotIndicatorView.scss";
import { connect } from "react-redux";

const ExitBotIndicatorView = (props) => {
  const { newBot, isEntry,t } = props;
  return (
    <>
      <Form.Label>
      {t("exit-bot-entry")}
      </Form.Label>
    </>
  );
};

export default connect(null)(ExitBotIndicatorView);
