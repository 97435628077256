
import React, {  } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import './IntroPopup.scss';
import { Link } from "react-router-dom";





const UpgradeModal = (props) => {
  const {
    onHide
  } = props;
  return (
    <>
    
      <Modal show={true} onHide={onHide} size="xs" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "200px"}} className="text-center">
        <div className="text-center mt-3 mb-3"><h6>This feature is only available for 
Premium and Professional users</h6></div> 
        <Link to="/setting?loc=2" className="no-decoration"><button className="btn btn-primary mt-2 no-decoration">Upgrade</button></Link>
        </Modal.Body>
       
      </Modal>
      </>
  );
};

export default connect(null, {
})(UpgradeModal);
