import React from "react";
import { formatDate, typeNameOrder } from "../../utils/Utils";

const TradeHistoryTab = ((props)=>{
    const {trades,deal}=props
    const tradeItems = trades.map((item, index) => {
        const isLong = deal.strategy == "Long";
        return (
            
            <tr key={index} className={`grid-trade-${item.side}-${index}`}>
                <td>
                {formatDate(item.status === "ongoing" ?item.created_at:item.time, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY HH:mm:ss")}
                </td>
                <td><span className={item.side} >{item.side}</span></td>
                <td>{typeNameOrder(item.name)}</td>
                <td>{item.status.toUpperCase()}</td>
                <td>
                {item.status === "ongoing" ? item.quantity : item.filled}{" "}
                {deal.quote_name}
                </td>
                <td>
                {item.status === "ongoing" ? item.price : item.average}{" "}
                {deal.base_name}
                </td>
                <td>
                {(item.quantity * item.price).toFixed(4)} {deal.base_name}
                </td>
             </tr>
        );
    });
    return (
        <>
        {tradeItems}
        </>
        
    )
})

export default TradeHistoryTab;