import React, { useEffect } from "react";
import AffiliateLayout from "../common/AffliateLayout";
import { connect, useSelector } from "react-redux";
import { getAffiliateRewardRedeemHistory } from "../../../actions/affiliate-action";
import { useHistory } from "react-router-dom";

const RewardsHistory = (props) => {
  const { getAffiliateRewardRedeemHistory } = props;
  const history = useHistory();
  const rewardRedeemHistories =
    useSelector((state) => state.affiliate.rewardRedeemHistories) || [];
  useEffect(() => {
    getAffiliateRewardRedeemHistory();
  }, []);
  const items = rewardRedeemHistories.map((item, index) => (
    <tr key={index}>
      <td>{item.date}</td>
      <td>US${item.amount}</td>
      <td>{item.payment_method && item.payment_method.toUpperCase()}</td>
      <td>{item.identifier}</td>
      <td>{item.tnx_id}</td>
    </tr>
  ));
  return (
    <AffiliateLayout>
      <div className="my-rewards">
        <div className="left">
          <p className="header1">Rewards Redemption History</p>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount Redeemed</th>
                <th>Method</th>
                <th>Identifier</th>
                <th>Transaction ID</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </div>
        <div className="right">
          <div className="text-left">
            <button
              className="btn"
              onClick={() => history.push("/my-rewards/redeem")}
            >
              Redeem
            </button>
          </div>
          <img src="/images/pp3.png" />
        </div>
      </div>
    </AffiliateLayout>
  );
};

export default connect(null, { getAffiliateRewardRedeemHistory })(
  RewardsHistory
);
