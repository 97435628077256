import React, { Component } from 'react'
import BotImageUploadView from "./marketplace/create/BotImageUploadView"
import { setShowUploadImageModal } from "../actions/image-upload-action";
import { connect } from "react-redux";

//import "./editor.scss"
const TestComponent = (props)=>{
    const {setShowUploadImageModal}=props;
    setShowUploadImageModal(true)
    return (
        <><BotImageUploadView show={true} onHide={()=>setShowUploadImageModal(false)}></BotImageUploadView></>
       
    )
}
export default connect(null,{setShowUploadImageModal}) (TestComponent);