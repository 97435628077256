import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import TutorialPopup from "../../../help/TutorialPopup";
import useInput from "../../../hooks/useIndicatorInput";
import * as HelpContent from "../../../../utils/HelpContent";

const VolumeSettings = (props) => {
  const { indicator, updateIndicator, baseCoin,t } = props;

  const [avgVol, bindAvgVol, setAvgVol] = useInput(indicator.value2, 0);
  const [interval, bindInterval, setInterval] = useInput(indicator.period_num, 0);
  

  useEffect(() => {
    setAvgVol(indicator.value2);
    setInterval(indicator.period_num);
  }, [indicator]);

  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
    
  }

  useEffect(() => {
    let val  = interval>10?10:((interval<2 && interval!="") ?2:interval) 
    updateIndicator({
        ...indicator,
        period_num: val,
        value2: avgVol,
    });
   
  }, [avgVol, interval]);
  return (
    <div>
      <label> Minimum Average Volume</label>
      <label data-text={baseCoin} className="input-gp-label">
                  <Form.Control
                      as="input"
                      type="number"
                      {...bindAvgVol}
                      onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                      className="input-text input-gp-text"
                    ></Form.Control>
                </label>
      <label>for  previous <TutorialPopup content={t("volumeIndicator")}></TutorialPopup></label>
      <label data-text={'intervals'} className="input-gp-label">
                  <Form.Control
                      as="input"
                      type="number"
                      max={10} min={2}
                      {...bindInterval}
                      onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                      className="input-text input-gp-text"
                    ></Form.Control>
                </label>
    

    </div>
  );
};

export default VolumeSettings;
