import { Modal, Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import useInput from '../../hooks/useInput';
import { toast } from 'react-toastify';
import Loading from '../../common/Loading';
import useLoading from '../../hooks/useLoading';
import { requestPayment } from '../../../actions/marketplace-action';
import { restrictAlphabets } from '../../../utils/Utils';

const METHODS = [["ERC-20",'usdt-erc20'],["BEP-20",'usdt-bep20'],["TRC-20","usdt-trc-20"]]
const RequestPaymentModal = (props) => {
  const { show, onHide ,defaultAmount,requestPayment,t} =
    props;
  const [loading, setLoading] = useState(false);
  const [address, bindAddress, resetAddress] = useInput("");
  const [amount, bindAmount, resetAmount] = useInput(defaultAmount);
  const [method, bindMethod] = useInput("usdt-erc20");
  const [marketPlaceFee, setMarketPlaceFee] = useState(amount*20/100);
  const options = METHODS.map((item, id) => {
    return (
      <option key={id} value={String(item[1])}>
        {item[0]}
      </option>
    );
  });
  const amountChanged = ()=>{
    setMarketPlaceFee(amount*20/100)
  }

  const clickSubmit = () => {
    setLoading(true);
    requestPayment({
      method: method,
      address: address,
      amount: amount,
      fee: marketPlaceFee,
    })
      .then(() => {
        resetAddress();
        resetAmount();
        setLoading(false);
        onHide();
        toast.success(
          'Your payment request has been received. You will receive an email once payment has been approved.'
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("req-p")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t("p-method")}</Form.Label>
            <select {...bindMethod} className='form-control'>{options}</select>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("address")}</Form.Label>
            <Form.Control type="text" {...bindAddress} />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("net-amount")}</Form.Label>
            <Form.Control type="number" {...bindAmount} onKeyDown={()=>amountChanged()} onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}/>
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Deduct Marketplace Fee (20%) : ${marketPlaceFee}</Form.Label>
          </Form.Group> */}

          
          <div className="text-center">
            <Button
              variant="success"
              className="w-50 mt-3"
              onClick={clickSubmit}
              disabled={amount == '' || address == ''}
            >
              Submit
            </Button>
          </div>
         
        </Modal.Body>
      </Modal>
    </Loading>
  );
};

export default connect(null, { requestPayment })(RequestPaymentModal);
