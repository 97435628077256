import React from "react";
const TimeFrames = ["1D", "3D", "1W", "1M", "3M", "1Y", "ALL"];
const ChartTimeFrames = (props)=>{

    const {setTimeframe,timeframe} = props;

    const timeFrameButtons = TimeFrames.map((item, index) => {
        return (
            <button 
            className={`timeline-btn ${timeframe == item?'timeline-active':''}`} 
            key={index}
            onClick={() => setTimeframe(item)}
            >{item}</button>
        );
    });
    return(
        <div id="timeline">
            {timeFrameButtons}
        </div>
    )
}

export default ChartTimeFrames