import {
  GET_ACCOUNT,
  GET_ORDER_LIST_OPEN,
  GET_POSITIONS,
  GET_ORDER_HISTORY,
  GET_SPOT,
  GET_CROSS,
  GET_ISOLATED,
  GET_TICKERS,
  GET_EXECUTED_ORDERS,
} from "./types";
import {
  placeOrderUrl,
  accountUrl,
  positionsUrl,
  orderListOpenUrl,
  orderHistoryUrl,
  cancelOrderUrl,
  accountTransferUrl,
  accountRepayUrl,
  getWatchListUrl,
  updateWatchListUrl,
  executedBotOrders,
} from "./const";
import network from "./network";
import { AccountType, OrderType } from "../Const";
import { checkSub } from "../utils/SubUtils";

export const placeOrder = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { subPremium } = checkSub(getState().auth.user.user_subscription);
    if (!subPremium && params.order_type === OrderType.stoplossLimit) {
      return reject("Please upgrade your plan to place Stop Limit order.");
    }
    network
      .post(placeOrderUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(
            getOpenOrders({
              exchange_id: params.exchange_id,
              account: params.trade_type,
            })
          );
          dispatch(
            getOrderHistory({
              exchange_id: params.exchange_id,
              account: params.trade_type,
              page: 1,
            })
          );
          dispatch(
            getPositions({
              exchange_id: params.exchange_id,
              account: params.trade_type,
            })
          );
          dispatch(
            getAccount({
              exchange_id: params.exchange_id,
              pair_id: params.pair_id,
              account: params.trade_type,
            })
          );
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getAccount = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(accountUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message, base, quote, risk } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_ACCOUNT,
            payload: { base, quote, risk },
          });
          resolve("getAccount");
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getPositions = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(positionsUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message, positions } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_POSITIONS,
            payload: positions,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getOpenOrders = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(orderListOpenUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message, orders } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_ORDER_LIST_OPEN,
            payload: orders,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
export const getExecutedBotOrders = (pairId) => (dispatch, getState) => {
  let params = {
    exchange_pair_id:pairId
  }
  return new Promise((resolve, reject) => {
    network
      .get(executedBotOrders, params, dispatch, getState)
      .then((res) => {
        const { code, message, orders } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_EXECUTED_ORDERS,
            payload: orders,
          });
          resolve();
        } else {
          dispatch({
            type: GET_EXECUTED_ORDERS,
            payload: [],
          });
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getOrderHistory = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(orderHistoryUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message, orders } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_ORDER_HISTORY,
            payload: orders,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const cancelOrder = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(cancelOrderUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getAvailable = (exchange_id) => (dispatch, getState) => {
  network
    .get(
      positionsUrl,
      { exchange_id, account: AccountType.spot },
      dispatch,
      getState
    )
    .then((res) => {
      const { code, message, positions } = res.data;
      if (code === 200) {
        dispatch({
          type: GET_SPOT,
          payload: positions,
        });
      }
    })
    .catch((err) => {});
  network
    .get(
      positionsUrl,
      { exchange_id, account: AccountType.cross },
      dispatch,
      getState
    )
    .then((res) => {
      const { code, message, positions } = res.data;
      if (code === 200) {
        dispatch({
          type: GET_CROSS,
          payload: positions,
        });
      }
    })
    .catch((err) => {});
  network
    .get(
      positionsUrl,
      { exchange_id, account: AccountType.isolated },
      dispatch,
      getState
    )
    .then((res) => {
      const { code, message, positions } = res.data;
      if (code === 200) {
        dispatch({
          type: GET_ISOLATED,
          payload: positions,
        });
      }
    })
    .catch((err) => {});
};

export const accountTransfer = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(accountTransferUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(
            getAccount({
              exchange_id: params.exchange_id,
              pair_id: params.pair_id,
              account: params.account,
            })
          );

          dispatch(
            getPositions({
              exchange_id: params.exchange_id,
              account: params.account,
            })
          );

          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const accountRepay = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(accountRepayUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(
            getAccount({
              exchange_id: params.exchange_id,
              pair_id: params.pair_id,
              account: params.account,
            })
          );
          dispatch(
            getPositions({
              exchange_id: params.exchange_id,
              account: params.account,
            })
          );
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getWatchList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(getWatchListUrl, {}, dispatch, getState)
      .then((res) => {
        const { code, message, watch_list } = res.data;
        if (code === 200) {
          watch_list &&
            watch_list.forEach((el) => {
              dispatch({
                type: GET_TICKERS,
                payload: {
                  exchangeId: el.exchange_id,
                  s: el.pair_name,
                  star: el.status === 1,
                },
              });
            });
          resolve(watch_list);
        }
      })
      .catch((err) => {});
  });
};

export const updateWatchList = (params) => (dispatch, getState) => {
  dispatch({
    type: GET_TICKERS,
    payload: {
      exchangeId: params.exchange_id,
      s: params.pair_name,
      star: params.status === 1,
    },
  });
  return new Promise((resolve, reject) => {
    network
      .post(updateWatchListUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
