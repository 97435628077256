import React from "react";
import { RedeemMethod, RedeemMethodName } from "../../../Const";
import AffiliateLayout from "../common/AffliateLayout";
import "./RewardsRedeem.scss";
import useInput from "../../hooks/useInput";
import { connect, useSelector } from "react-redux";
import { postAffiliateRewardRedeem } from "../../../actions/affiliate-action";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { useHistory } from "react-router-dom";
import {
  validateEmail,
  validateERC20,
  validateTRC20,
} from "../../../utils/Validation";

const RewardsRedeem = (props) => {
  const { postAffiliateRewardRedeem } = props;
  const [amount, bindAmount] = useInput("10");
  const [method, bindMethod] = useInput(RedeemMethod.paypal);
  const [identifier, bindIdentifier] = useInput("");
  const [setLoading, bindLoading] = useLoading(false);
  const redeemableUsd =
    useSelector((state) => state.affiliate.redeemableUsd) || 0;
  const history = useHistory();

  const clickRedeem = () => {
    switch (method) {
      case RedeemMethod.paypal:
        if (!validateEmail(identifier)) {
          return toast.error("Please input a valid email");
        }
        break;
      case RedeemMethod.usdtErc20:
        if (!validateERC20(identifier))
          return toast.error("Please input a valid ERC20 address");
        break;
      case RedeemMethod.usdtTrc20:
        if (!validateTRC20(identifier))
          return toast.error("Please input a valid TRC20 address");
        break;
      default:
        break;
    }
    if (amount < 10) return toast.error("Minimum redemption amount is 10 USD");
    if (amount > redeemableUsd) return toast.error("Exceed maximum redemption");
    setLoading(true);
    postAffiliateRewardRedeem(amount, method, identifier)
      .then(() => {
        setLoading(false);
        toast.success("Redeem successfully");
        history.push("/my-rewards/history");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const options = Object.keys(RedeemMethod).map((item, index) => (
    <option key={index} value={RedeemMethod[item]}>
      {RedeemMethodName[item]}
    </option>
  ));
  return (
    <Loading {...bindLoading}>
      <AffiliateLayout>
        <div className="my-rewards rewards-redeem">
          <div className="left">
            <p className="header1">
              Maximum Redemption:{" "}
              <span className="header2">US${redeemableUsd}</span>
            </p>
            <div className="form-redeem">
              <label>Amount to Redeem:</label>
              <input type="number" {...bindAmount} />
              <label>Payment Method:</label>
              <select {...bindMethod}>{options}</select>
              <label>
                {method == RedeemMethod.paypal
                  ? "Paypal Email:"
                  : "USDT Address:"}
              </label>
              <input {...bindIdentifier} />
              <button className="btn" onClick={clickRedeem}>
                Redeem Now
              </button>
            </div>
            <p className="frag1">
              * Please verify the above payment information. We will not take
              responsibility if the information which you provide is wrong.
              Cancellation of redemption is not allowed. You will bear all
              transfer charges as charged.
            </p>
          </div>
          <div className="right" style={{ marginTop: -20 }}>
            <div className="text-left">
              <button
                className="btn"
                onClick={() => history.push("/my-rewards/history")}
              >
                History
              </button>
            </div>
            <img src="/images/pp3.png" width={500} />
          </div>
        </div>
      </AffiliateLayout>
    </Loading>
  );
};

export default connect(null, { postAffiliateRewardRedeem })(RewardsRedeem);
