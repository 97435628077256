import React from "react";
import MenuItems from "./MenuItems";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const SideMenu = (props)=>{
	const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
    const {url} = props;
	const isActive = (route) => {
        return route == url ? "active-sidenav" : "";
    };
    return (
    <div className="col-lg-2 main-left-col">
					<div className="main-side-nav-area">
						
						<div className="side-logo">
							<Link to="/bots"><img src={theme=="dark"?`/images/named-logo-dark.png`:"/images/named-logo.png"} alt="logo"/></Link>
						</div>
						
						<ul>
							<li>
							<Link
                      			to="/bots" className={isActive("bots")}>
									<img src="/images/side-icon1.svg" alt=""/>
									Bots
								</Link>
							</li>
							<li>
							<Link
                      			to="/trades" className={isActive("trades")}>
									<img src="/images/side-icon2.svg" alt=""/>
									Trades
								</Link>
							</li>
							<li>
							<Link
                      			to="/marketplace" className={isActive("marketplace")}>
									<img src="/images/side-icon3.svg" alt=""/>
									Marketplace
								</Link>
							</li>
							<li>
							<Link
                      			to="/portfolio" className={isActive("portfolio")}>
									<img src="/images/side-icon4.svg" alt=""/>
									Portfolio
								</Link>
							</li>
							<li>
							<Link to="/reports" className={isActive("reports")}>
									<img src="/images/side-icon5.svg" alt=""/>
									Reports
								</Link>
							</li>
							{/* <li>
								<Link to="/artificial-intelligence" className={isActive("artificial-intelligence")}>
									<img src="/images/side-icon6.svg" alt=""/>
									AI
								</Link>
							</li> */}
						</ul>
						
						{/* <div className="side-nav-promotion-area">
							<h4>Subscribe and get</h4>
							<h2>Free Strategies</h2>
							<img src="/images/promotions-banner.svg" alt="banner"/>
							<a href="https://cryptohero.ai/pricing" target="_blank">Check Promotions</a>
						</div> */}
					</div>
				</div>
    )
}
export default SideMenu;