import React from "react";
import AddIndicatorView from "../AddIndicatorView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch } from "react-redux";
import { CHANGE_FREQUENCY } from "../../../../actions/create-bot-type";
import { Frequencies, FrequenciesPro, FrequencyValues, FrequencyValuesPro } from "../../../../Const";
import { t } from "i18next";

const GridIndicatorView = (props) => {
  const { newBot,t } = props;
  const frequency = newBot.frequency;
  const dispatch = useDispatch();
  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const formartFrequency = (value) => FrequenciesPro[value];
  return (
    <div>
      <AddIndicatorView newBot={newBot} isEntry={true} t={t}/>
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={4}
          value={FrequencyValuesPro.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(FrequencyValuesPro[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")} />}
        />
      </div>
    </div>
  );
};

export default GridIndicatorView;
