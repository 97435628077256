import {
  BOT_LIST,
  BOT_DETAIL,
  GET_PORTFOLIO,
  GET_DASHBOARD,
  GET_SUBSCRIPTION,
  ACTIVE_BOT,
  GET_BALANCE,
  BOT_PROFIT,
  GET_PAIRS,
  GET_BACKTEST_SUCCESS,
  GET_INDICATORS,
  HIDE_GRAPH,
  BOT_RENT_LIST,
  RENT_BOT_DETAIL,
  GET_ACCOUNT_PAIR,
  SET_INTER_ARBITRAGE_PAIRS,
  SET_INTRA_ARBITRAGE_PAIRS,
  SET_EXCHANGE_PARTNERS,
  CHANGE_DEFAULT_PARTNER_PAIRS,
  SET_DAISY_CHAIN_BOTS,
  HIDE_BOT_INFO,
} from "../actions/types";

const initialState = {
  bots: [],
  bot: {},
  daisyBots:[],
  hideGraph: false,
  botInfo: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BOT_LIST:
      const totalBot = payload.bots.length;
      const activeBot = payload.bots.filter(
        (item) => item.status == "active"
      ).length;
      return {
        ...state,
        bots: payload.bots,
        totalBots: payload.totalBots,
        startItem: payload.startItem,
        dashboard: {
          active_bots: activeBot,
          total_bots: totalBot,
        },
      };
    case BOT_RENT_LIST:
      return {
        ...state,
        rents: payload.rents,
        totalRentBots: payload.totalBots,
        startItemRent: payload.startItem,
      };
    case BOT_DETAIL:
      return {
        ...state,
        bot: payload,
      };
    case RENT_BOT_DETAIL:
      return {
        ...state,
        rent: payload,
      };
    case GET_PORTFOLIO:
      return {
        ...state,
        portfolio: payload,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload,
      };
    case ACTIVE_BOT:
      const bots = state.bots;
      const { id, active } = payload;
      const updatedBots = bots.map((item) => {
        if (id === item.id) {
          return {
            ...item,
            status: active ? "active" : "inactive",
          };
        }
        return item;
      });
      const dashboard = state.dashboard;
      const updatedDashboard = {
        ...dashboard,
        active_bots: dashboard.active_bots + (active ? 1 : -1),
      };
      return {
        ...state,
        bots: updatedBots,
        dashboard: updatedDashboard,
      };
    case GET_PAIRS:
      return {
        ...state,
        usdPairs: payload.usdPairs,
        btcPairs: payload.btcPairs,
        ethPairs: payload.ethPairs,
        fiat: payload.fiat?payload.fiat:[],
        coinMDeliver: payload.coinMDeliver,
        coinMPerpetual: payload.coinMPerpetual,
        usdMDeliver: payload.usdMDeliver,
        usdMPerpetual: payload.usdMPerpetual,
      };
    case SET_INTER_ARBITRAGE_PAIRS:
      return {
        ...state,
        interArbitrageExchanges: payload.interArbitrageExchanges && payload.interArbitrageExchanges.shared_pairs,

      };
    case SET_EXCHANGE_PARTNERS:
      return {
        ...state,
        exchangePartners: payload.exchangePartners,
        defaultPartnerPairs:payload.exchangePartners[0]? payload.exchangePartners[0].shared_pairs:[]
      };
    case CHANGE_DEFAULT_PARTNER_PAIRS:
      return {
        ...state,
        defaultPartnerPairs:payload
      };
    case SET_INTRA_ARBITRAGE_PAIRS:
      return {
        ...state,
        intraArbitragePairs: payload.intraArbitragePairs,
      };
    case GET_INDICATORS:
      return {
        ...state,
        indicators: payload.indicators.map((el) => ({
          ...el,
          indicator_id: el.id,
          id: null,
        })),
      };
    case GET_BALANCE:
      return {
        ...state,
        balances: payload.spot,
        spot: payload.spot,
        usdMBalances: payload.usd_m,
        coinMBalances: payload.coin_m,
      };
    case GET_ACCOUNT_PAIR:
      return {
        ...state,
        pairData: payload,
      };
    case BOT_PROFIT:
      return {
        ...state,
        profit: payload,
      };
    case GET_BACKTEST_SUCCESS:
      return {
        ...state,
        backTest: payload,
        reloadTV: !state.reloadTV,
      };
    case SET_DAISY_CHAIN_BOTS:
      return {
        ...state,
        daisyBots: payload
      };
    case HIDE_GRAPH:
      return {
        ...state,
        hideGraph: payload,
      };
    case HIDE_BOT_INFO:
      return {
        ...state,
        botInfo: payload
      };
    default:
      return state;
  }
};

export default reducer;
