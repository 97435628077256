import historyProvider from "./historyProvider";
import moment from "moment";
import { typeNameOrder } from "../../../utils/Utils";
import binance from "./binance";


var lastBar;

const Datafeed = ((config)=>{
  return {
  onReady: (cb) => {
    setTimeout(() => cb(config), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {},
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    var split_data = symbolName.split(/[:/]/);
    var symbol_stub = {
      name: symbolName,
      description: "",
      type: "crypto",
      session: "24x7",
      //timezone: "Asia/Kolkata",
      ticker: symbolName,
      exchange: "Paper",
      minmov: 1,
      pricescale: config.pricePrecision?Math.pow(10, config.pricePrecision):1000000,
      has_intraday: true,
      intraday_multipliers: ["5","15", "60", "240"],
      supported_resolution: config.supportedResolutions,
      volume_precision: 8,
      data_status: "streaming",
    };

    if (split_data[1].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
      symbol_stub.pricescale = config.pricePrecision?Math.pow(10, config.pricePrecision):100;
    }
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },
  getBars: function (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) {
    historyProvider
      .getBars(window.pairId, resolution, periodParams)
      .then((bars) => {
        if (bars.length) {
          onHistoryCallback(bars, { noData: false });
          lastBar = bars[bars.length - 1];
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch((err) => {
        console.log({ err });
        onErrorCallback(err);
      });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    var interval = "1d";
    switch (resolution) {
      case "5":
        interval = "5m";
        break;
      case "15":
        interval = "15m";
        break;
      case "60":
        interval = "1h";
        break;
      case "240":
        interval = "4h";
        break;
      case "1D":
        interval = "1d";
        break;
      default:
        break;
    }
    // binance.connect(
    //   symbolInfo.name.split("/").join("").toLowerCase(),
    //   interval,
    //   (kline) => {
    //     onRealtimeCallback(kline);
    //   }
    // );
  },
  unsubscribeBars: (subscriberUID) => {
    //binance.disconnect();
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {},
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    const { strategy, orders } = window.bot || { strategy: "", orders: [] };
    const marks = orders.map((item) => {
      const timestamp = moment.utc(item.created_at).unix();
      var color = item.status=="partial_filled"?"orange":(item.side=="SELL"?"red":"green");
      var label = item.side=="SELL"?"S":"B";
      // if (
      //   (strategy.toLowerCase() === "long" &&
      //     ["tp", "sl", "de", "ie"].includes(item.name.toLowerCase())) ||
      //   (strategy.toLowerCase() === "short" &&
      //     ["bo", "eo"].includes(item.name.toLowerCase()))
      // ) {
      //   color = "red";
      //   label = "S";
      // }
      return {
        id: timestamp,
        time: timestamp,
        color: color,
        text: `${typeNameOrder(item.name)} ${item.desc}`,
        minSize: 10,
        label: label,
      };
    });
    onDataCallback(marks);
  },
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {},
  getServerTime: (cb) => {},
}
})
export default Datafeed;
