import React from "react";
import { Table } from "react-bootstrap";
import { formatNumberToString, roundNumber } from "../../utils/Utils";
import { useSelector } from "react-redux";
const CoinPanel = (props) => {
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const { coins, isTotal,t } = props;

  const coinItems =
    coins &&
    coins.map((item, index) => {
      return (
        <tr key={index}>
          <td className="coin-data">
          <img
              src={item.image || require("../../images/coin_pl.png")}
              height={15}
            />
            <h6>{item.symbol}</h6>
          </td>
          {isTotal &&<td className="holding-data">
            <h6> {roundNumber(item.holdings)} %</h6>
          </td>
          }
          <td  className={item.change >= 0 ? "change-data text-success" : "change-data text-danger"}>
            <span className={item.change >= 0 ?"long":"short"}>{roundNumber(item.change)}%</span>
          </td>
          <td className="price-area">
            <h6>{roundNumber(item.price)} USD</h6>
          </td>
          <td className="amount-data">
            <h6>{formatNumberToString(item.amount,2)} {item.symbol}</h6>
          </td>
          <td className="total-data">
            <h6>{roundNumber(item.total)} USD</h6>
          </td>
        </tr>
      );
    });
  return (
    <div className="position-table-area">
      <div className="row">
        <div className="col-lg-12">
          <div className="skltbs-panel-group">
            
            <div className="skltbs-panel">
              <div className="open-table-content otc Portfolio-table-content">
                <table className="table">
                <tbody>
                        <tr valign="top">
                          <td className="text-left">Coin</td>
                          {isTotal && <td>{t("holdings")}</td>}
                          <td>1D {t("change")}</td>
                          <td>{t("price")}</td>
                          <td>{t("amount")}</td>
                          <td>{t("total")}</td>
                        </tr>
                       {coinItems}
                       </tbody>
                    </table>
                </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinPanel;
