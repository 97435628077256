import React, { useState } from "react";

export default (initial) => {
  const [value, setValue] = useState(initial);
  const bind = {
    value,
    onChange: () => setValue(!value),
  };
  const reset = () => setValue(initial);
  return [value, bind, reset, setValue];
};
