import React from "react";
import { useDispatch } from "react-redux";
import {
  CHANGE_DCA_PERCENT,
  CHANGE_DCA_TRIGGER,
} from "../../../../actions/create-bot-type";
import DcaMartingaleView from "./DcaMartingaleView";
import LeftRightForm from "../modules/LeftRightForm";
import { Form } from "react-bootstrap";
import CustomRadio from "../modules/CustomRadio";
import { restrictAlphabets } from "../../../../utils/Utils";

const DcaPercentEntry = (props) => {
  const { newBot, isBuy,t } = props;
  const dcaTrigger = newBot.dcaTrigger;
  const side = isBuy ? "Buy" : "Sell";
  const dispatch = useDispatch();
  const changeDcaPercent = (val) => {
    dispatch({
      type: CHANGE_DCA_PERCENT,
      payload: val,
    });
  };

  const changeDcaTrigger = (val) => {
    dispatch({
      type: CHANGE_DCA_TRIGGER,
      payload: val,
    });
  };
  return (
    <div>
      <LeftRightForm 
        left={<Form.Label className="font-weight-bold">First {side}</Form.Label>}
        right={<>
          <CustomRadio
            type="radio"
            name="first_buy"
            label={<>{side} Immediately when Bot is Created</>}
            val={"0"}
            checked={dcaTrigger === "0"}
            callback={() => changeDcaTrigger("0")}
          ></CustomRadio>
          <div className="d-flex">
          <CustomRadio
            type="radio"
            name="first_buy"
            label={<>{side} When Price Reaches</>}
            val={dcaTrigger}
            checked={dcaTrigger !== "0"}
            callback={() => changeDcaTrigger("1")}
          ></CustomRadio>
          <label data-text={newBot.quoteCoin} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={dcaTrigger}
                  onChange={(e) => changeDcaTrigger(Number(e.target.value))}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-buydown-text"
                ></Form.Control>
          </label>
          </div>
        </>
        
       }
      
      ></LeftRightForm>
      <LeftRightForm
        left={<Form.Label className="font-weight-bold">Subsequent {side}s</Form.Label>}
        right={
          <div className="d-flex align-items-center">
          <div>{side} every time when price {isBuy ? "drops" : "rises"} by{" "}</div>
          <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.dcaPercent}
                  onChange={(e) => changeDcaPercent(e.target.value)}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
            <div>
            from last {side} Price
            </div>
          </div>
          
        }
      ></LeftRightForm>
      <DcaMartingaleView dcaMartingale={newBot.dcaMartingale} isBuy={isBuy} t={t} />
    </div>
  );
};

export default DcaPercentEntry;
