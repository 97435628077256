import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const OffCanvasMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {url} = props;
  
    const toggleOffCanvas = () => {
      setIsOpen(!isOpen);
    };
    const isActive = (route) => {
      return route == url ? "active-sidenav" : "";
  };
  
    return (
      <>
        <div className="hamburger-icon" onClick={toggleOffCanvas} data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
												<span></span>
												<span></span>
												<span></span>
											</div>
  
        <div className={`offcanvas offcanvas-start side-offcanvas ${isOpen ? 'show' : ''}`} 
             tabIndex="-1" 
             id="offcanvasExample" 
             aria-labelledby="offcanvasExampleLabel" 
             style={{ visibility: isOpen ? 'visible' : 'hidden' }}>
          
          <div className="offcanvas-header">
            <a href="/bots">
              <img src="/images/named-logo-dark.png" alt="Logo" />
            </a>
            <button 
              type="button" 
              className="btn-close text-reset" 
              onClick={toggleOffCanvas} 
              aria-label="Close"
            ></button>
          </div>
  
          <div className="offcanvas-body">
            <div className="main-side-nav-area">
              <ul>
                <li>
                  <Link to="/bots" className={isActive("bots")}>
                    <img  src="/images/side-icon1.svg" alt="Bots Icon" />
                    Bots
                  </Link>
                </li>
                <li>
                <Link to="/trades" className={isActive("trades")}>
                    <img src="/images/side-icon2.svg" alt="Positions Icon" />
                    Trades
                  </Link>
                </li>
                <li>
                <Link to="/marketplace" className={isActive("marketplace")}>
                    <img src="/images/side-icon3.svg" alt="Marketplace Icon" />
                    Marketplace
                  </Link>
                </li>
                <li>
                <Link to="/portfolio" className={isActive("portfolio")}>
                    <img src="/images/side-icon4.svg" alt="Portfolio Icon" />
                    Portfolio
                  </Link>
                </li>
                <li>
                <Link to="/reports" className={isActive("reports")}>
                    <img src="/images/side-icon5.svg" alt="Reports Icon" />
                    Reports
                  </Link>
                </li>
               
              </ul>
              {/* <div className="side-nav-promotion-area">
                <h4>Sign up and get a</h4>
                <h2>Free Upgrade</h2>
                <img src="/images/promotions-banner.svg" alt="Promotions Banner" />
                <a href="https://cryptohero.ai/pricing">Check Promotions</a>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default OffCanvasMenu;