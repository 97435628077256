import React from "react";
import { connect } from "react-redux";
import Cookies from 'universal-cookie';
import queryString from "query-string";
import { useLocation } from "react-router-dom";


const CjTracking = () => {
  const cookies = new Cookies();
  const { search } = useLocation();
  const query = queryString.parse(search);
  var now = new Date(Date.now()+ (86400*395000));
  if(query.cjevent){
    cookies.set('cje',query.cjevent, { expires:now,path: '/',secure:true,sameSite:'none' });
    window.location.href="https://cryptohero.ai"
  }
  
  return (
   <></>
  );
};

export default connect(null)(CjTracking);
