const tpsExportAdapter = (item, type) => {
  return {
    id: item.id,
    trigger_percentage: item.triggerAt,
    tp_percentage: item.stopAt,
    tp_type: type,
  };
};

export default tpsExportAdapter;
