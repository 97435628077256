import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";
import { PLAN_NAME } from "../../Const";

const FreeInfo = (props) => {
  const { isPremium,t ,subTier,
    tier,
    plan,
    className
  } = props;
  return (
    <div className="col-xl-3 col-md-6 no-padding premium_info_div">
        <div className={`sp-cart-content ${className}`}>
            <div className="sp-cart-header">
              <span> {PLAN_NAME[tier]}</span>
              <h4>{t("free")}</h4>
            </div>
            <div className="sp-cart-body">
              <ul>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("free-info-1")}</span>
                  </li>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("r-free-trade")}</span>
                  </li>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("unlimited-exg")}</span>
                  </li>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("un-trading-lim")}</span>
                  </li>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("un-positions")}</span>
                  </li>
                  <li>
                    <img src="/images/sp-icon.svg" alt=""/>
                    <span>{t("no-sharing")}</span>
                  </li>
                  </ul>
                  <a>
                  {isPremium ? "INCLUDED" : "CURRENT PLAN"}
                    </a>
            </div>
      </div>
    </div>
  );
};

export default FreeInfo;
