import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import Pagination from '../common/CustomPagination';
import usePagination from '../hooks/usePagination';
import { connect, useSelector } from 'react-redux';
import { roundNumber, formatDate } from '../../utils/Utils';
import { getOpenOrders, cancelOrder } from '../../actions/terminal-action';
import { toast } from 'react-toastify';
import Loading from '../common/Loading';
import useLoading from '../hooks/useLoading';
import './OpenOrderTab.scss';

const OpenOrderTab = (props) => {
  const { getOpenOrders, cancelOrder, account,t } = props;
  // const [range, bindPagination] = usePagination();
  const openOrders = useSelector((state) => state.terminal.openOrders) || [];
  const orderHistory =
    useSelector((state) => state.terminal.orderHistory) || {};
  const orders = orderHistory.data || [];
  const [setLoading, bindLoading] = useLoading(false);
  const selectedExchangeId = useSelector(
    (state) => state.settings.selectedExchangeId
  );
  const updateOpenOrders = useCallback(() => {
    getOpenOrders({ exchange_id: selectedExchangeId, account }).then(
      console.log
    );
  }, []);
  useEffect(() => {
    updateOpenOrders();
    const timer = setInterval(() => {
      if (openOrders.length) updateOpenOrders();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [account, selectedExchangeId, orders.length]);
  //const rows = openOrders.slice(...range).map((item, index) => {
  const rows = openOrders.map((item, index) => {
    const clickCancel = () => {
      setLoading(true);
      cancelOrder({
        exchange_id: selectedExchangeId,
        pair_id: item.pair_id,
        order_id: item.order_id,
        trade_type: account,
      })
        .then(() => {
          setLoading(false);
          updateOpenOrders();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    };
    return (
      <tr key={index} className="align-items-center theme-border">
        <td>{item.symbol}</td>
        <td className={item.side}>{item.side}</td>
        <td>
          {roundNumber(item.price)} {item.pair.to}
        </td>
        <td>
          {roundNumber(item.filled || 0)} / {roundNumber(item.quantity)}{' '}
          {item.pair.from}
        </td>
        <td>
          {formatDate(item.created_at, undefined, 'DD MMM YYYY HH:mm:ss')}
        </td>
        <td>
          <Button
            onClick={clickCancel}
            size="sm"
            className="cancel"
            variant="outline-danger"
          >
            Cancel
          </Button>
        </td>
      </tr>
    );
  });
  return (
     <div className="mt-4 bot-tab">
     <div className="" >
           <div className="deals-table">
               <table className="">
                   <tbody>
                       <tr valign="top">
                       <th>{t("pair")}</th>
                        <th>{t("side")}</th>
                        <th>{t("price")}</th>
                        <th>{t("filled")} / {t("total")}</th>
                        <th>{t("date")}</th>
                        <th></th>
                       </tr>
                       {rows}
                   </tbody>
               </table>
              
         </div>
     </div>
     <Loading {...bindLoading} />
   </div>
  );
};

export default connect(null, { getOpenOrders, cancelOrder })(OpenOrderTab);
