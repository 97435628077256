import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import BooleanView from "./BooleanView";
import Select from "react-select";
import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_STRATEGY } from "../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../Const";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import { Trans } from "react-i18next";
import CustomRadio from "./modules/CustomRadio";
import LeftRightForm from "./modules/LeftRightForm";
import AssetViewInterArbitrage from "./inter-arbitrage-bot/AssetViewInterArbitrage";
import AssetViewIntraArbitrage from "./intra-arbitrage-bot/AssetViewIntraArbitrage";
import AssetView from "./AssetView";
import AssetViewFutures from "./AssetViewFutures";

const AssetViewModule = (props) => {
  const { newBot,getBalance,getPairBalance,t,editing,step } = props;
  const isFuture = useSelector((state) => state.settings.isFuture);
  const getAssetView = ()=>{
    if(isFuture){
      return <AssetViewFutures newBot={newBot} t={t} editing={newBot.isEditing}/>
    }
    else{
      switch (newBot.botType) {
        case BotType.inter_arbitrage:
          return <AssetViewInterArbitrage newBot={newBot} t={t} editing={newBot.isEditing} step={step}/>
        case BotType.intra_arbitrage :
          return <AssetViewIntraArbitrage newBot={newBot} t={t} editing={newBot.isEditing} step={step}/>
        default:
          return <AssetView newBot={newBot} t={t} editing={newBot.isEditing} step={step}/>
      }
    }
    
  }

  return (
    <>
      {getAssetView()}
     </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetViewModule);
