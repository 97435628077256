import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import SpotOverview from "../dashboard/SpotOverview";
import PortfolioChart from "../dashboard/PortfolioChart";
import ChartTimeFrames from "../dashboard/ChartTimeFrames";
import DistributionPanel from "./DistributionPanel";
import FutureOverview from "../dashboard/FutureOverview";
import DistributionPanelFutures from "./DistributionPanelFutures";

const PortfolioOverviewFutures = (props)=>{

    const {t,currency,assets,totalCoinMCoins,totalUsdMCoins} = props;
    const [showGraph, setShowGraph] = useState(true);
    const [timeframe, setTimeframe] = useState("1M");
    const toggleGraph = () => {
        setShowGraph(!showGraph);
      };
    const [futureType, setFutureType] = useState("spot");
    const isFuture = false
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

    return (
        <>
            
                <div className="newuser-earning-area portfolio">
                    <div className="newuser-cash-content">
                        <button><img src="/images/icon-portfolio.svg" alt="cash"/></button>
                        <div>
                            <span>{t("usd-m-balance")}</span>
                            <h5>{assets && assets.usd_m && assets.usd_m.balance_fiat.toFixed(4)} {currency && currency.name}</h5>
                        </div>
                    </div>
                    <div className="newuser-cash-content">
                        <button><img src="/images/updown-icon.svg" alt="cash"/></button>
                        <div>
                            <span>{t("coin-m-balance")}</span>
                            
                            <h5>{assets && assets.coin_m && assets.coin_m.balance_fiat.toFixed(4)} {currency && currency.name}</h5>

                        </div>
                    </div>
                    {/* <div className="newuser-cash-content">
                        <button><img src="/images/quity-icon-white.svg" alt="cash"/></button>
                        <div>
                            <span>1M Trading Volume</span>
                            <h5>{currencyFormat(trading_volume && trading_volume.toFixed(2) || "0")} {currency && currency.name}</h5>
                        </div>
                    </div> */}
                </div>
        </>
    );

}
export default connect(null,{})(PortfolioOverviewFutures)