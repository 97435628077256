import React from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { RentPlans } from "../../../Const";

const RentCheckboxCard = (props)=>{
    const {planName,plan,template,paymentType,clickPaymentType,t} = props;
    return (
        <div className="rent-amount-checkbox mb-3 flex-vertical-center">
            <Form.Check
                label={
                <p>
                    US ${template[plan]} /{t(RentPlans[planName])}
                </p>
                }
                //bsPrefix="flex-vertical-center"
                type="radio"
                className="mr-3"
                value="monthly"
                name="rent_type"
                onChange={(e) => clickPaymentType(template[plan],planName)}
                checked={paymentType==planName?true:false}
            />
        </div>
    )
}
export default connect(null,{})(RentCheckboxCard)