import React, { useState } from "react";
import { Form, InputGroup, Modal, Button, Row, Col } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";


import useInput from "../hooks/useInput";
import useLoading from "../hooks/useLoading";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Loading from "../common/Loading";
import Cookies from "universal-cookie";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const ViewIpsModal = (props) => {
  
  const { show, onHide , ips} = props;
  const { search } = useLocation();
  const {t} = useTranslation()
  
  const [setLoading, bindLoading] = useLoading(false);
  const history = useHistory();
  

  const clickCopy = (address) => {
    copy(address);
    toast.success("Address copied.");
  };
  
  const hideModal = () => {
    onHide();
  };
  const ipsItems = ips && ips.map((item, index) => {
    return (
        <Row className="mx-1 p-1 text-center" key={index}>

        <Col xs={1} className="text-left">
         
          {index+1}.
        </Col>
        <Col xs={9} className="text-center text-truncate">
         
          {item}
        </Col>
        <Col xs={2} className="">
            <button className="blue-button small-blue-button" onClick={()=>clickCopy(item)}>
                Copy
              </button>
        </Col>
      </Row>
    );
  });
  
  return (
    <Loading {...bindLoading}>
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          IP Addresses
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ fontSize: 15 }}>
      <p>{t('add-ip-message-1')}</p>
      <p>{t('add-ip-message-2')}</p>
      <Row className="text-center text-title font-weight-bold p-1 mx-1 mt-4">
          <Col xs={1} className="text-left">
            {t("S.No")}
          </Col>
          <Col xs={9}>{t("IP Address")}</Col>
          <Col xs={2}>{t("action")}</Col>
        </Row>
      {ipsItems}
      </Modal.Body>
    </Modal>
    </Loading>
  );
};

export default connect(null, {  })(ViewIpsModal);
