import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Alert,
  Badge,
} from "react-bootstrap";
import {
  getExchanges,
  addExchange,
  deleteExchange,
  resetExchange,
} from "../../actions/setting-action";
import { connect, useSelector } from "react-redux";
import "./AddApiPanel.scss";
import { ExchangeStatus, ExchangeStatusName } from "../../Const";
import { capitalize } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { isPremium } from "../../utils/SubUtils";
import ViewIpsModal from "./ViewIpsModal";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import NoData from "../common/NoData";
import ConnectedExchangeCard from "./ConnectedExchangeCard";

const labelWidth = 3;
const AddFuturesApiPanel = (props) => {
  const { getExchanges, addExchange, deleteExchange, resetExchange } = props;
  const {t} = useTranslation()

  const detailCols = [3, 7, 2];
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [exchangeId, setExchangeId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [showIps, setShowIps] = useState(false);
  const [ips, setIps] = useState([]);
  const exchanges = useSelector((state) => state.settings.exchanges).filter((el)=>el.is_future == true) || [];
  const selectedExchange = exchanges.find((el) => el.id == exchangeId) || {};
  const addExchangeSuccess = useSelector(
    (state) => state.settings.addExchangeSuccess
  );
  const addExchangeError = useSelector(
    (state) => state.settings.addExchangeError
  );
  const addExchangeStart = useSelector(
    (state) => state.settings.addExchangeStart
  );
  const deleteExchangeSuccess = useSelector(
    (state) => state.settings.deleteExchangeSuccess
  );
  const deleteExchangeError = useSelector(
    (state) => state.settings.deleteExchangeError
  );

  const connectedExchanges = useMemo(
    () => exchanges.filter((item) => item.is_connected && item.id != 0),
    [exchanges]
  );
  const disconnectedExchanges = useMemo(
    () => exchanges.filter((item) => !item.is_connected && item.id != 0),
    [exchanges]
  );

  useEffect(() => {
    if (disconnectedExchanges.length) {
      setExchangeId(disconnectedExchanges[0].id);
    }
  }, []);

  useEffect(() => {
    getExchanges();
  }, []);

  useEffect(() => {
    resetExchange();
    return () => {
      resetExchange();
    };
  }, []);

  useEffect(() => {
    if (addExchangeSuccess) {
      setApiKey("");
      setApiSecret("");
      setPassphrase("");
    }
  }, [addExchangeSuccess]);

  const clickAdd = () => {
    const params = {
      id: String(exchangeId),
      api_key: apiKey,
      secret: apiSecret,
      account: passphrase,
    };
    addExchange(params);
  };

  const clickDelete = () => {
    deleteExchange(deleteId);
    setDeleteId(0);
  };
  const showIpsModal = (ipList) => {
    setIps(ipList)
    setShowIps(true)
  };
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );

  
  const connectedItems = connectedExchanges.map((item, index) => {
    return (
      <ConnectedExchangeCard key={index} exchange={item} t={t} subscription={subscription}></ConnectedExchangeCard>
    );
  });

  const disconnectedItems = disconnectedExchanges.map((item, index) => {
    return (
      <div className={`pointer col-md-12 col-xs-12 name-div ${item.id===exchangeId?"active":""}`} key={item.id} onClick={() => setExchangeId(item.id)}>
        
          <div className="col-md-2 col-xs-2 no-padding w-m-20"><img src={item.image} alt="icon" width={20} className="mr-10"/></div>
          <div className="col-md-10 col-xs-10 w-m-80">{item.name}</div>
      </div>
    );
  });
  return (
    <>
    <div className="col-sm-10 setting-right-col">
       <div className="setting-exchange-area"> 
       <div className="add-brokerage-area">
       <h4><a  href="#"><img src="/images/icon-exchange.svg" alt=""/> {t("add-new-futures-exch")}</a></h4>
           <div className="brokerage-logos">
              {disconnectedItems}
              
            </div>
            <div className="brokerage-footer">
            {selectedExchange.id !=16 ? (
                  <>
                   <LeftRightForm
                    left={t("api-key")}
                    right={<>
                      <Form.Control
                        as="input"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        className="input-text"
                      />
                      </>}
                  ></LeftRightForm>
                  <LeftRightForm
                    left= {t("api-secret")}
                    right={<>
                      <Form.Control
                        as="input"
                        value={apiSecret}
                        onChange={(e) => setApiSecret(e.target.value)}
                        className="input-text"
                      />
                      </>}
                  ></LeftRightForm> </>):(<></>)}
                   {selectedExchange.require_param ? (
                   <LeftRightForm
                   left= {<> <Form.Label>
                   {capitalize(selectedExchange.require_param || "")}
                 </Form.Label></>}
                   right={<>
                     <Form.Control
                        as="input"
                        value={passphrase}
                        onChange={(e) => setPassphrase(e.target.value)}
                        className="input-text"
                      />
                     </>}
                 ></LeftRightForm>
                  ) : (
                    <></>
                  )}
                 
                  <Row className="row justify-content-center mt-4">
                        <button href="#" className="blue-button" onClick={clickAdd}>{addExchangeStart ? "Updating..." : (selectedExchange.id ==16 ? t("authenticate"):t("add-futures-exchange"))}</button>
                      </Row>
                  {selectedExchange.id ==16 &&
                  <Row className="justify-content-center mt-2">
                    <p>{t("tradestation")}</p>
                  </Row>}
                  <Row className="justify-content-center mt-2">
                    <Alert
                      show={addExchangeSuccess || addExchangeError != null}
                      variant={addExchangeError ? "danger" : "success"}
                    >
                      {addExchangeError || "Exchange added!"}
                    </Alert>
                  </Row>
                  <div style={{ textAlign: "right", marginTop: 20 }}>
                    <a href={selectedExchange.doc_link} target="_blank">
                      * {t("how-to-connect")} {selectedExchange.name}?
                    </a>
                  </div>
            </div>
        </div>
        {connectedItems.length > 0 && 
          <div className="connected-brokerage-area mb-5">
            <h4>{t("connected-exgs")}</h4>
            <div className="row g-2">
              {connectedItems}
            </div>
          </div> }
        </div>
      </div>
      
      {/* <div className="round-bg mt-3">
        <h5>{t("exg-status")}</h5>

        {exchangeItems}
      </div> */}

      <Modal show={deleteId > 0} onHide={() => setDeleteId(0)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("delete-api-warning")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={clickDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ViewIpsModal show={showIps} onHide={()=>setShowIps(false)} ips={ips}></ViewIpsModal>
    </>
  );
};

export default connect(null, {
  getExchanges,
  addExchange,
  deleteExchange,
  resetExchange,
})(AddFuturesApiPanel);
