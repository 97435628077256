import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_BO } from "../../../../../actions/create-bot-type";
import TutorialPopup from "../../../../help/TutorialPopup";
import SliderView from "../../SliderView";

const BaseOrderLimitModule = (props)=>{
    const {t,newBot,editing,fund,currentCoin} = props;
    const baseOrder = newBot.baseOrder;
    const formatBaseOrder = (value) =>
    `${((fund * value) / 100).toFixed(4)} ${currentCoin} (${value}%)`;
    const dispatch = useDispatch();
    const baseOrderChanged = (val) => {
        dispatch({
          type: CHANGE_BO,
          payload: val,
        });
      };
    return(
    <>
    <SliderView
          title={t("bo-limit")}
          step={5}
          min={5}
          formatLabel={formatBaseOrder}
          onChange={baseOrderChanged}
          value={baseOrder}
          tutorial={<TutorialPopup content={t("baseOrder-help-future")} />}
        /> 
    </>
    )
}
export default BaseOrderLimitModule

