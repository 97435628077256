import React from "react";
import IndicatorSetting from "./IndicatorSetting";
import { useSelector } from "react-redux";

const IndicatorSettingModal = (props)=>{

    const editingIndicator = useSelector(
        (state) => state.createBot.editingIndicator
      );
    const newBot = useSelector((state) => state.createBot.newBot);
    const defaultIndicators = useSelector((state) => state.bots.indicators) || [];
    return (
        <>
        {editingIndicator && (
                <IndicatorSetting
                isEntry={editingIndicator && editingIndicator.isEntry}
                indicator={editingIndicator && editingIndicator.indicator}
                strategy={newBot.strategy}
                defaultIndicators={defaultIndicators}
                baseCoin={newBot.baseCoin}
                />
            )}
        </>
    )
}

export default IndicatorSettingModal;