import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";


const NoDeals = () => {
  const { t } = useTranslation()
  return (
    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 v-pad">
        <h3>Deals in Progress</h3>
        <br/>
        <div className="dashboard-col text-center">
          <div className="nodeals"><img src="/img/icon-nodeals.png" alt=""/></div>
          <br/>
          <br/>
          <h2>There are no deals in progress</h2>
          <br/>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit</p> */}
          <br/>
          <br/>
          <a href="/bots"><button className="btn blue-btn">CREATE BOT NOW</button></a>
        </div>
    </div>  
     
  );
};

export default connect(null)(NoDeals);
