export const adaptTemplateEdit = (template) => {
  if (!template) return null;
  return {
    id: template.id,
    title: template.title,
    description: template.description,
    monthlyFee:template.monthly_fee,
    quarterlyFee:template.quarterly_fee,
    halfYearlyFee:template.half_yearly_fee,
    annualFee:template.yearly_fee,
    oneTimeFee:template.onetime_fee,
    imageThumbnail:template.image,
    default_image:template.default_image,
    bot:template.bot,
  };
};
