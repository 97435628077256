import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const ProPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <ul>
     <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("free-info-1")}</span>
        </li>
        <li>
              <img src="/images/sp-icon.svg" alt=""/>
              <span>30 {t("active-bots")}</span>
         </li>
          
          <li>
            <img src="/images/sp-icon.svg" alt=""/> {t("grid-bot")}
         </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("five-minutes-basic-plan")}</span>
         </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("custom-backtest-period")}</span>
         </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>10 {t("price-alerts")}</span>
         </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("plus-premium-plan")}</span>
         </li>
    </ul>
  );
};

export default ProPlan;
