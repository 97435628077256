import React, { useEffect } from "react";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import { restrictAlphabets } from "../../../../utils/Utils";

const EmaEntrySetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;

  const [ema1, bindEma1, setEma1] = useInput(indicator.period_num, 0);
  const [ema2, bindEma2, setEma2] = useInput(indicator.value2, 0);

  useEffect(() => {
    setEma1(indicator.period_num);
    setEma2(indicator.value2);
  }, [indicator]);

  useEffect(() => {
    updateIndicator({
      ...indicator,
      period_num: ema1,
      value2: ema2,
    });
  }, [ema1, ema2]);
  return (
    <div>
      <h6>{isLong ? "Long" : "Short"} {t("strategy")}</h6>
      <div className="d-flex justify-content-between indicator-setting-form align-items-center above-buy-price above-buy-price2">
          <div><label className="indicator-setting-label">{t("long-period")}</label></div>
          <div> <input type="number" {...bindEma1} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <div className="d-flex justify-content-between indicator-setting-form align-items-center above-buy-price above-buy-price2">
          <div><label className="indicator-setting-label">{t("short-period")}</label></div>
          <div> <input type="number" {...bindEma2} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
    </div>
  );
};

export default EmaEntrySetting;
