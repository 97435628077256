import React, { useEffect, useState } from "react";
import { Container, Row, Table, Col, Button, Tooltip } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { TimeFrameValues, TimeFrames } from "../../Const";
import NoData from "../common/NoData";
import { roundNumber, trimString } from "../../utils/Utils";
import { getTopTemplates } from "../../actions/marketplace-action";
import { Link } from "react-router-dom";

const TopTemplatesPanel = (props) => {
  const { getTopTemplates,t } = props;
  const templates = useSelector((state) => state.marketplace.top_templates) || [];
  const topFeaturedTemplates = useSelector((state) => state.marketplace.topFeaturedTemplates) || [];
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [timeFrame, setTimeFrame] = useState("1W");

  

  useEffect(() => {
    getTopTemplates(5);
  }, []);
  const length = topFeaturedTemplates.length;
  const FeaturedTemplatesItems = topFeaturedTemplates.map((item, index) => {
   
    const link = `/marketplace-detail/${item.id}`;
    //const tutorial = (msg) => <Tooltip>{msg}</Tooltip>;
    return (
      <tr key={index} className={`theme-border text-center ${theme}`} >
        <td style={{textAlign:`left`,display:'flex'}}>
         <div className="top-bot-img"><img src={item.image}  className="top-template-img"></img></div> {trimString(item.title,50)}
        </td>
        <td>{roundNumber(item.apy, 1)}%</td>
        <td>
          <a href={link} target="_blank">
          <i className="fas fa-external-link-alt bot-icon" ></i>
          </a>
        </td>
      </tr>
    );
  });
  const templateItems = templates.slice(0, length>0?(5-length):5).map((item, index) => {
   
    const link = `/marketplace-detail/${item.id}`;
    //const tutorial = (msg) => <Tooltip>{msg}</Tooltip>;
    return (
      <tr key={index} className={`theme-border text-center ${theme}`} >
        <td style={{textAlign:`left`,display:'flex'}} className="text-left no-wrap-mob">
         <div className="top-bot-img"><img src={item.image}  className="top-template-img"></img></div> {trimString(item.title,50)}
        </td>
        <td>{roundNumber(item.apy, 1)}%</td>
        <td>
          <a href={link} target="_blank">
          <i className="fas fa-external-link-alt bot-icon" ></i>
          </a>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <h5>Bots Marketplace</h5>
      <div className="round-bg" style={{ marginTop: 30 }}>
        <Row>
          <Table responsive className={`theme-bg ${theme}`}>
            <thead className="table-heading text-center">
              <tr>
                <th style={{textAlign:`left`}}>{t("name")}</th>
                <th>APY</th>
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>{FeaturedTemplatesItems}{templateItems}</tbody>
          </Table>
          {(templates.length + length) >= 5 && 
          <Link className="text-center full-width" to={'/marketplace'}>{t("view-more-bots")}</Link> }
        </Row>
        <NoData count={templateItems.length} />
      </div>
    </div>
  );
};

export default connect(null, { getTopTemplates })(TopTemplatesPanel);
