import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmCancelDialog = (props) => {
  const { show, onHide, confirmed } = props;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Renewal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You will still have access to the current plan's features until your
          subscription expires.
        </p>
        <p>
          Immediately thereafter, all active bots will be disabled and all
          exchange connections removed.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={confirmed}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmCancelDialog;
