import React, { useState, useEffect } from "react";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { botCriteria } from "../../../actions/const";
import { SET_BOT_SELECTED } from "../../../actions/types";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";
const BotSelectionView = (props)=>{

    const {bots,t} = props;
    const botList = bots.map((bot,index)=>{
            return (
                <option
                    key={index}
                    value={index}
                    disabled={bot.is_submitted}
                >{bot.name + (bot.is_submitted?' (Submitted)':"")}</option>
            )
    })
    const dispatch = useDispatch();
    const handleChange = (index) =>{
        dispatch({
            type: SET_BOT_SELECTED,
            payload: bots[index],
          });
    }
    return (
        <>
            
            {/* <div className="botlist-scroll">
                {botList}
            </div> */}
            <LeftRightForm
                left={<>{t("pls-sel-bot")}<a href={botCriteria} target="_blank" className="link">{t("sub-criteria")}</a></>}
                right={<>
                         <Form.Control
                            as="select"
                            onChange={(e) => handleChange(e.target.value)}
                            //value={bot}
                            className="select-normal small-select"
                        >
                            <option>Select Bot</option>
                        {botList}
                      </Form.Control>
                </>}
            >

            </LeftRightForm>
        </>
    )
};
export default connect(null)(BotSelectionView)