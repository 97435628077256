import React from "react";
import AllRoute from "./route/route";
import PrivateRoute from "./route/PrivateRoute";
import { useSelector } from "react-redux";

const Main = () => {
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  document.body.className = theme;
  const RouteComponent = AllRoute.map((route, i) => (
    <PrivateRoute
      key={i}
      exact={route.exact}
      path={route.path}
      component={route.component}
    />
  ));
  return (
    <div>
      {RouteComponent}
    </div>
  );
};

export default Main;
