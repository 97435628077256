import React, { useEffect, useState } from "react";
import { Row, Table, Badge, Card } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { getDeals } from "../../actions/deal-action";
import { roundNumber } from "../../utils/Utils";
import CustomPagination from "../common/CustomPagination";
import NoData from "../common/NoData";
import { DealStatus } from "../../Const";
import "./DealPanel.scss";
import { Link } from "react-router-dom";

const DealPanel = (props) => {
  const { getDeals,t } = props;
  const itemsPerPage = 5;
  const [startItem, setStartItem] = useState(0);
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const deals = useSelector((state) => state.deals.deals) || [];
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

  const completedStatuses = [
    DealStatus.stopLoss,
    DealStatus.takeProfit,
    DealStatus.exited,
    DealStatus.liquidated,
    DealStatus.error,
  ];
  const filterDeals = deals
    .filter((item) => {
      return item.exchange_id === selectedExchangeId;
    })
    .filter((item) => !completedStatuses.includes(item.status_key));

  useEffect(() => {
    getDeals();
  }, []);

  const dealItems = filterDeals
    .slice(startItem, startItem + itemsPerPage)
    .map((item, index) => {
      return (
        <tr key={index} className={`theme-border ${theme}`}>
          <td className="text-left">{item.bot_name}</td>
          <td>
            <span className="mx-2">
              {item.quote_name}/{item.base_name}
            </span>
            <span className={item.strategy == "Long" ? "long" : "short"}>
              {item.strategy}
            </span>
          </td>
          <td>
            <img
              src={`images/icons/volume.png`}
              width={20}
              alt=""
              style={{ marginRight: 5 }}
            />
            {roundNumber(item.executed_vol)} {item.base_name}
          </td>
          <td>
            <span className={item.net_profit >= 0 ? "up" : "down"}>
              {roundNumber(item.net_profit)} {item.base_name} (
              {roundNumber(item.profit_value)}%)
            </span>
            <img
              src={`images/icons/${item.net_profit >= 0 ? "up" : "down"}.png`}
              alt=""
              style={{ marginLeft: 5, marginBottom: 3 }}
            />
          </td>
        </tr>
      );
    });

  return (
    <div className="deal-panel">
      <h5>{t("dip")}</h5>

      <div className="deal-list round-bg">
        <Row>
          <Table responsive className={`theme-bg ${theme}`}>
            <thead className="table-heading">
              <tr>
                <th className="text-left">{t("bn")}</th>
                <th>{t("pair")}</th>
                <th>{t("volume")}</th>
                <th>{t("upnl")}</th>
              </tr>
            </thead>
            <tbody>{dealItems}</tbody>
          </Table>
          {dealItems.length >= itemsPerPage && 
          <Link className="text-center full-width" to={'/deals'}>{t("view-more-deals")}</Link> }
        </Row>
        <NoData count={filterDeals.length} />

        {/* <div className="bottom-center">
          <CustomPagination
            startItem={startItem}
            onChange={setStartItem}
            itemsCountPerPage={itemsPerPage}
            totalCount={filterDeals.length}
          />
        </div> */}
      </div>
    </div>
  );
};

export default connect(null, { getDeals })(DealPanel);
