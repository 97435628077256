import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import ContentContainer from "../../common/ContentContainer";
import EarningsPanel from "./EarningsPanel";
import PersonalBalancePanel from "./PersonalBalancePanel";
import BotTemplateList from "./BotTemplateList";
import { getOwnTemplates, getTemplates } from "../../../actions/marketplace-action";
import useLoading from "../../hooks/useLoading";
import Loading from "../../common/Loading";
import CustomPagination from "../../common/CustomPagination";
import NewPagination from "../../common/NewPagination";
import NoBotSubmitted from "./NoBotSubmitted";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
//import "./Dashboard.scss";

const ManageBot = (props) => {
  const {t} = useTranslation()
  const pageNum=1;
  const itemsPerPage = 10;
  const tabs = ["active", "inactive"];
  const tabTitles = [t("active"), t("pending")];
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const { search } = useLocation();
  
  const query = queryString.parse(search);
  const [activeTab, setActiveTab] = useState(Number(query.tab) || 0);
  const [selectedTab, getSelectedTab] = useState(tabs[Number(query.tab)]|| tabs[activeTab]);
  useEffect(()=>{
    setLoading(true);
    getOwnTemplates(pageNum,itemsPerPage,selectedTab).then(()=>{
      setLoading(false)
    }).catch((err) => {
      setLoading(false);
      //toast.error(err);
    });
  },[]);
  const own_templates = useSelector((state) => state.marketplace.owner_templates.data) || [];
  const totalTemplates = useSelector((state) => state.marketplace.owner_templates.total) || [];
  const owner_templates_total = useSelector((state) => state.marketplace.owner_templates.my_template_count) || 0;
  const startItem = useSelector((state) => state.marketplace.owner_templates.startItem) || [];
  const {getOwnTemplates} = props;
  
  const [setLoading, bindLoading] = useLoading();
  
  const loadTemplates = (pageNum,tab)=>{
    setLoading(true);
    getOwnTemplates(pageNum,itemsPerPage,tab).then(()=>{
      setLoading(false)
    }).catch((err) => {
      setLoading(false);
      //toast.error(err);
    });
  }
  const changeTab = (index) => {
    setActiveTab(index);
    //getSelectedTab(tab)
    loadTemplates(pageNum,tabs[index])
  };
  const tabHeaders = tabTitles.map((item, index) => {
    return (
        <a
          className={index === activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
          onClick={() => changeTab(index)}
        >
          {item}
        </a>
    );
  });
  return (
    <ContentContainer url="managebot" title={t("m-templates")} showExchange={true}>
      { owner_templates_total > 0 ? ( <div className="dashboard-content">
        <Row >
          <Col xs={12} xl={6}>
            <div>
              <EarningsPanel t={t}/>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="personal-b-panel">
              <PersonalBalancePanel t={t}/>
            </div>
          </Col>
        </Row>
        <Loading {...bindLoading}>
        <Row className=" mt-4">
          <Col>
          <div className="d-flex jcb ac">
                  <div>
                    <h6>{t("template-list")}</h6>
                </div>
                <div className="bot-tabs">
                  {tabHeaders}
                </div>
          </div>
               
          </Col>
                
                
        </Row>
        <BotTemplateList own_templates={own_templates} getOwnTemplates={getOwnTemplates} tab={tabs[activeTab]} t={t}/>
        </Loading>
        <div className="marketplace bottom-center mt-5" style={{ width: "90%" }}>
          
          </div>
          <NewPagination
            itemsCountPerPage={itemsPerPage}
            selectedExchangeId={selectedExchangeId}
            startItem={startItem}
            selectedTab={selectedTab}
            totalCount={totalTemplates}
            onChange={loadTemplates}
            itemsPerPage={itemsPerPage}
          />
      </div> ) : (
        <div className="dashboard-content">
            {/* <div>
              <NoBotSubmitted />
            </div> */}
        </div>
      )}
      
    </ContentContainer>
  );
};

export default connect(null,{getOwnTemplates})(ManageBot);
