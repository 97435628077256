import React from "react";
import { useDispatch } from "react-redux";
import { UPDATE_FIXED_TSL } from "../../../../actions/create-bot-type";
import { isLong } from "../../../../utils/Utils";
import { Col, Form, Row } from "react-bootstrap";

const FixedTSL = (props) => {
  const { newBot } = props;
  const tsl = newBot.fixedTSL;
  const long  = isLong(newBot.strategy)
  const dispatch = useDispatch();
  if (!tsl) {
    updateTrailingSL({ triggerAt: 2, stopAt: 1 });
  }
  const updateTrailingSL = (tsl) => {
    dispatch({
      type: UPDATE_FIXED_TSL,
      payload: tsl,
    });
  };
  const trailing = tsl || { triggerAt: 2, stopAt: 1 };
  const changeStopAt = (val) => {
    updateTrailingSL({
      triggerAt: trailing.triggerAt,
      stopAt: val,
    });
  };
  const changeTriggerAt = (val) => {
    updateTrailingSL({
      triggerAt: val,
      stopAt: trailing.stopAt,
    });
  };
  return (
  
      <div className="trailing-item d-flex align-items-center above-buy-price above-buy-price2">
        <div> Set{" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={trailing.stopAt}
                  onChange={(e) => changeStopAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        
        <div>{long?"below":"above"} current price at every</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={trailing.triggerAt}
                  onChange={(e) => changeTriggerAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        <div> {long?"above":"below"} {long?"Buy":"Sell"}&nbsp; Price</div>
        
      </div>
  );
};

export default FixedTSL;
