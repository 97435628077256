import React from "react";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { Card, Accordion,Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_EXIT,SET_CLOSE_AFTER_DEAL,CHANGE_TAKE_PROFIT, SET_STOP_AFTER_DEAL } from "../../../../actions/create-bot-type";
import TakeProfitView from "../takeprofit/TakeProfitView";
import CustomCheckbox from "../modules/CustomCheckbox";

const DcaExitView = (props) => {
  const { newBot,t } = props;
  const maxVal = 100
  const dispatch = useDispatch();
  const closeBotAfterDeal = newBot.closeBotAfterDeal?true:false;
  const stopBotAfterDeal = newBot.stopBotAfterDeal?true:false;
  const changeDcaExit = (val) =>
    dispatch({
      type: CHANGE_DCA_EXIT,
      payload: val,
    });
  const setDealCloseValue = (val) => {
    dispatch({
      type: SET_CLOSE_AFTER_DEAL,
      payload: val,
    });
  };
  const setDealStopValue = (val) => {
    dispatch({
      type: SET_STOP_AFTER_DEAL,
      payload: val,
    });
  };
  const takeProfitChanged = (val) => {
    if(val > maxVal)
    return
    dispatch({
      type: CHANGE_TAKE_PROFIT,
      payload: val,
    });
  };
  const formatDcaExit = (val) => (val === 0 ? t("no-limit") : String(val));
  const formatTakeProfit = (value) => (value == 0 ? t("disable") : `${value}%`);
  const formatDecimal = (value,decimals) => parseFloat(Number(value).toFixed(decimals));
  return (
    <>
      
            <SliderView
              title={t("no-of-ext-order")}
              step={1}
              min={0}
              max={500}
              value={newBot.dcaExit}
              onChange={changeDcaExit}
              formatLabel={formatDcaExit}
            />
            <TakeProfitView t={t}></TakeProfitView>
            {/* <Form.Check
                  type="checkbox"
                  label={t("dis-after-deal-closes")}
                  value={closeBotAfterDeal}
                  checked={closeBotAfterDeal}
                  onChange={(e) => setDealCloseValue(Boolean(e.target.checked))}
            /> */}
          
             <CustomCheckbox 
                    val={stopBotAfterDeal} 
                    checked={stopBotAfterDeal} 
                    callback={setDealStopValue}
                    label={t("stop-bot-after-deal-ends")}
                    id={'stop-bot-after-deal-ends'}
                    ></CustomCheckbox>
         
    </>
  );
};

export default DcaExitView;
