import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getDailyPnl } from "../../actions/dashboard-action";
import { ChartColors } from "../../Const";
import { roundNumber } from "../../utils/Utils";
import ReactApexChart from "react-apexcharts";

const DailyPnlChart = (props)=>{
    
    const {data,getDailyPnl} = props;
    const {futureType,selectedExchangeId,theme}=data;
    const {
        equities,
        volumes,
      } = useSelector((state) => state.portfolio);
    useEffect(() => {
        getDailyPnl(selectedExchangeId, futureType);
    }, [selectedExchangeId, futureType]);
    const dailyPnl = useSelector((state) => state.dashboard.dailyPnl) || {};
    const chartData = dailyPnl.chart_data || [];
    const funds = chartData.map((item) =>
        roundNumber(Number((item.y || "0").replace(",", "")))
    );
    const labels = chartData.map((item) => item.x);
    //const minValue = Math.min(...funds);
    let series = [
        {
            name: "PnL",
            data: funds
        }
    ]
    let options =  {
        chart: {
          height: 300,
          type: 'bar',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
            fillSeriesColor: true,
            x: {
                show: false,
            }
        },
        colors: [function({ value, seriesIndex, w }) {
            if (value < 0) {
                return ChartColors[theme][2]
            } else {
                return ChartColors[theme][1]
            }
        }],
        dataLabels: {
          enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '25%',
              endingShape: 'rounded'
            },
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
                colors: '#fff',
            },
          }
        },
        yaxis: {
            labels: {
              style: {
                  colors: '#fff',
              },
            }
          }
      }
    return(
        <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={300} />
        </div>
    )
}

export default connect(null,{getDailyPnl})(DailyPnlChart)