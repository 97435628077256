import React from "react";
import { Form } from "react-bootstrap";
import { connect,useDispatch } from "react-redux";
import { 
    BOT_SUBSCRIPTION_MONTHLY,
    BOT_SUBSCRIPTION_QUARTERLY,
    BOT_SUBSCRIPTION_HALFYEARLY ,
    BOT_SUBSCRIPTION_ANNUAL,
    BOT_SUBSCRIPTION_ONETIME,
    BOT_TEMPLATE_DESCRIPTION,
    BOT_TEMPLATE_TITLE
} from "../../../actions/types";
import { restrictAlphabets } from "../../../utils/Utils";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";


const BotSubmitDescriptionView = (props)=>{
    const {bot,t} = props
    const monthlyRental = bot.frequency==5?'19.99':'9.99'
    const quarterlyRental = bot.frequency==5?'59.99':'29.99'
    const yearlyRental = bot.frequency==5?'199.99':'99.99'
    const dispatch = useDispatch()
    const botTitleChanged = (val)=>{
        dispatch({
            type: BOT_TEMPLATE_TITLE,
            payload: val,
        });
    }
    const botDescriptionChanged = (val)=>{
        dispatch({
            type: BOT_TEMPLATE_DESCRIPTION,
            payload: val,
        });
    }
    const botSubscriptionFeeChanged = (val,type)=>{
        dispatch({
            type: type,
            payload: val,
        });
    }
    return (
        <>
            <Form>
                <LeftRightForm 
                left={t("bt")}
                right={<Form.Control type="text"   className="mt-3 input-text" onChange={(e)=>botTitleChanged(e.target.value)}/>}>

                </LeftRightForm>
                <LeftRightForm 
                left={t("bot-desc")}
                right={<Form.Control as="textarea" rows={5}  className="mt-3 input-text-area" onChange={(e)=>botDescriptionChanged(e.target.value)} maxLength="500"/>}>

                </LeftRightForm>
                <LeftRightForm 
                left={t("m-sub-fee")}
                right={ <label data-text={'US$'} className="input-gp-label">
                <Form.Control
                    as="input"
                    type="number"
                    placeholder={`(Minimum is US$${monthlyRental})`}
                    onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_MONTHLY)} 
                    onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                    className="input-text input-gp-text"
                ></Form.Control>
                 </label>}>

                </LeftRightForm>
                
                <LeftRightForm 
                left={t("q-sub-fee")}
                right={ <label data-text={'US$'} className="input-gp-label">
                <Form.Control
                    as="input"
                    type="number"
                    placeholder={`(Minimum is US$${quarterlyRental})`}
                    onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_QUARTERLY)} 
                    onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                    className="input-text input-gp-text"
                ></Form.Control>
                 </label>}>

                </LeftRightForm>

                <LeftRightForm 
                left={t("a-sub-fee")}
                right={ <label data-text={'US$'} className="input-gp-label">
                <Form.Control
                    as="input"
                    type="number"
                    placeholder={`(Minimum is US$${yearlyRental})`}
                    onChange={(e)=>botSubscriptionFeeChanged(e.target.value,BOT_SUBSCRIPTION_ANNUAL)} 
                    onKeyDown={(e) => {
                        restrictAlphabets(e);
                    }}
                    className="input-text input-gp-text" 
                ></Form.Control>
                 </label>}>

                </LeftRightForm>
                
                
            </Form>
        </>
    );
}
export default connect (null,{})(BotSubmitDescriptionView)