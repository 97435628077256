import React, { useState } from "react";
import { Col, Form,InputGroup, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./SliderView.scss";

const SliderView = (props) => {
  const {
    title,
    formatLabel,
    value,
    max,
    min,
    step,
    onChange,
    disabled,
    hideLabel,
    withInput,
    appendText,
    withInputZero,
  } = props;
  
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
    
  }
  return (
    <div className="cb-form-div">
        <div className="col-lg-4">
            <div className="ba-asset-left">
              <h6>
                {title} {props.tutorial}
              </h6>
            </div>
        </div>
        <div className="col-lg-8">
              <div className="ba-asset-right">
                
                {!hideLabel && <div>{(formatLabel && formatLabel(value)) || value}</div>}
                  <InputRange
                      step={step || 1}
                      minValue={min || 0}
                      maxValue={max || 100}
                      value={value > max?max:value}
                      onChange={(value) => {
                        onChange && onChange(value);
                      }}
                      allowSameValues={true}
                      formatLabel={() => ""}
                      disabled={disabled || false}
                    />
                    <br></br>
                  {withInput && 
                  <>
                  <label data-text={appendText?appendText:'%'} className="input-gp-label">
                    <input
                        as="input"
                        type="number"
                        value={(value==0)?'':value}
                        onChange={(e) => {
                          onChange && onChange(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}
                        className="input-text input-gp-text"
                        min={0}
                      ></input>
                      </label>
                  </>
             }
            {withInputZero &&
            <>
            <label data-text={appendText?appendText:'%'} className="input-gp-label">
            <input
                as="input"
                type="text"
                value={value}
                onChange={(e) => {
                  onChange && onChange(e.target.value);
                }}
                onKeyPress={(e) => {
                  restrictAlphabets(e,e.target.value);
                }}
                className="input-text input-gp-text"
              ></input>
                </label>
            </>
             }
            
       </div>
       </div>
    </div>
  );
};

export default SliderView;
