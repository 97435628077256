import React from "react";
import { Form, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { CHANGE_AVG_ENTRY_PRICE } from "../../../../actions/create-bot-type";
import TutorialPopup from "../../../help/TutorialPopup";
import LeftRightForm from "../modules/LeftRightForm";

const SellBotEntryView = (props) => {
  const { newBot, isEntry,t } = props;
  const dispatch = useDispatch();
  const avgEntryPriceChanged = (val) => {
    dispatch({
      type: CHANGE_AVG_ENTRY_PRICE,
      payload: val,
    });
  };
  return (
    <>
    <Form.Label>
      {t("sell-bot-entry")}
      </Form.Label>
    <LeftRightForm
      left={<Form.Label>
        {t("avg-entry-price")}<TutorialPopup content={t("avg-entry-help")} />
        </Form.Label>}
      right={<label data-text={newBot.quoteCoin} className="input-gp-label">
      <Form.Control
          as="input"
          type="number"
          placeholder="0"
          value={newBot.avgEntryPrice}
          onChange={(e) => {
              avgEntryPriceChanged(e.target.value);
          }}
          className="input-text input-gp-tex"
        ></Form.Control>
  </label>}
    >
    </LeftRightForm>
             
    </>
  );
};

export default connect(null)(SellBotEntryView);
