import React from "react";
import { connect, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const gaConfirmed = useSelector((state) => state.auth.gaConfirmed);
  const gaEnabled = useSelector((state) => state.settings.gaEnabled);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return token &&
          user &&
          user.email_verified_at &&
          (gaConfirmed ||
            (!gaConfirmed && location.pathname == "/ga/confirm") ||
            !gaEnabled) || (location.pathname == "/test-pintura") ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
};

export default connect(null)(PrivateRoute);
