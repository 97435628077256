import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME } from "../../../actions/create-bot-type";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { updateBot } from "../../../actions/create-bot-action";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Tab3 = (props)=>{
    const {next,prev,updateBot} = props;
    const {t} = useTranslation()
    const newBot = useSelector((state) => state.createBot.newBot);
    const [setLoading, bindLoading] = useLoading(false);
    const dispatch = useDispatch()
    const setBotName = (val) => {
        dispatch({
            type: SET_BOT_NAME,
            payload: val,
        });
    };
    const clickCreateBot = () => {
        setLoading(true);
        updateBot()
            .then(() => {
            setLoading(false);
            next()
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t(err));
            });
    };
    return(<>
            <h3>Step 3: Creating Your First Bot</h3>
                <p>For your first bot, we will automatically set the following parameters:</p>
                <br></br>
                <div className="qs-3-params">
                <span>Trading Symbol: <b>{newBot && newBot.pair && newBot.pair.from +'/'+ newBot.pair.to }</b></span>
                <span>Amount allocated: <b>2000 USDT</b></span>
                <span>Paper exchange: <b>risk free and uses fake funds</b></span>
                </div>
                <br></br>
                <br></br>
                <p>This bot will aim to buy low and sell high for {newBot && newBot.pair && newBot.pair.from +'/'+ newBot.pair.to }.</p>
                <form action="">
                    <br></br>
                <label><span className="highlighted-text">Now, let's give a name to your bot</span></label>
                <input type="text" placeholder="Give your bot a name" value={newBot.name} onChange={(e) => setBotName(e.target.value)}/>
                <ul>
                        <li><a  className="qtrblue-btn pointer" onClick={prev}>Previous</a></li>
                        <li><a  className="qtrtrans-btn pointer" onClick={()=>clickCreateBot()}>Create my first bot now</a></li>
                    </ul>
                </form>
            
            <Loading {...bindLoading} />
        
    </>)
}
export default connect(null,{updateBot}) (Tab3);