import { reportsUrl, userBotList, userStockList } from "./const";
import network from "./network";
import axios from "axios";
import { GET_REPORT, GET_REPORT_BOTS, GET_REPORT_STOCKS } from "./types";
import { serverDateFormat } from "../utils/Utils";

export const getReports = (selectedExchangeId,startDate,endDate,type,currentTimeFrame,selectedBot,selectedStock) => (dispatch, getState) => {
    let params = {
        exchange_id:selectedExchangeId,
        start_date:serverDateFormat(startDate),
        end_date:serverDateFormat(endDate),
        type:type
    }
    if(currentTimeFrame=="all"){
        params.start_date="2020-01-01"
        params.end_date="2090-01-01"
    }
    if(type=="stocks"){
        params.pair_id = selectedStock
    }
    if(type=="bots"){
        params.bot_id = selectedBot
    }
    return new Promise((resolve, reject) => {
            network.get(reportsUrl,params , dispatch, getState).then((res) => {
                const { code, } = res.data;
                if (code == 200) {
                    dispatch({
                        type: GET_REPORT,
                        payload: res.data,
                    });
                    resolve(res.data)
                }
                else{
                    reject(res.data.message);
                }
            }).catch((err)=>{
                reject(String(err));
            });
    })
  };
export const getBotList = () => (dispatch, getState) => {
    network.get(userBotList,{} , dispatch, getState).then((res) => {
        const { code,bots } = res.data;
        if (code == 200) {
            dispatch({
                type: GET_REPORT_BOTS,
                payload: bots,
            });
        }
    });
 
  };
export const getStockList = () => (dispatch, getState) => {
    network.get(userStockList,{} , dispatch, getState).then((res) => {
        const { code,pairs } = res.data;
        if (code == 200) {
            dispatch({
                type: GET_REPORT_STOCKS,
                payload: pairs,
            });
        }
    });
 
  };