import {
  affiliateFriendDeleteUrl,
  affiliatePointHistoryUrl,
  affiliatePointRedeemHistoryUrl,
  affiliatePointRedeemUrl,
  affiliateRankUrl,
  affiliateRewardHistoryUrl,
  affiliateRewardRedeemHistoryUrl,
  affiliateRewardRedeemUrl,
  affiliateSendEmailUrl,
  affiliateSocialPostUrl,
} from "./const";
import {
  GET_AFFILIATE_POINT_HISTORY,
  GET_AFFILIATE_POINT_REDEEM_HISTORY,
  GET_AFFILIATE_REWARD_HISTORY,
  GET_AFFILIATE_SOCIAL_POSTS,
  GET_AFFILIATE_RANK,
  GET_AFFILIATE_REWARD_REDEEM_HISTORY,
} from "./types";
import network from "./network";

export const getAffiliateRewardHistory = () => (dispatch, getState) => {
  network
    .get(affiliateRewardHistoryUrl, {}, dispatch, getState)
    .then((res) => {
      const { redeemable_usd, earned_usd, histories } = res.data;
      dispatch({
        type: GET_AFFILIATE_REWARD_HISTORY,
        payload: {
          totalUsd: earned_usd,
          redeemableUsd: redeemable_usd,
          rewardsHistories: histories,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAffiliatePointHistory = () => (dispatch, getState) => {
  network
    .get(affiliatePointHistoryUrl, {}, dispatch, getState)
    .then((res) => {
      const { total_point, histories } = res.data;
      dispatch({
        type: GET_AFFILIATE_POINT_HISTORY,
        payload: {
          totalPoints: total_point,
          pointHistories: histories,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAffiliateRank = () => (dispatch, getState) => {
  network
    .get(affiliateRankUrl, {}, dispatch, getState)
    .then((res) => {
      const { ranks } = res.data;
      dispatch({
        type: GET_AFFILIATE_RANK,
        payload: {
          ranks,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAffiliateRewardRedeemHistory = () => (dispatch, getState) => {
  network
    .get(affiliateRewardRedeemHistoryUrl, {}, dispatch, getState)
    .then((res) => {
      const { histories } = res.data;
      dispatch({
        type: GET_AFFILIATE_REWARD_REDEEM_HISTORY,
        payload: {
          rewardRedeemHistories: histories,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAffiliatePointRedeemHistory = () => (dispatch, getState) => {
  network
    .get(affiliatePointRedeemHistoryUrl, {}, dispatch, getState)
    .then((res) => {
      const { histories } = res.data;
      dispatch({
        type: GET_AFFILIATE_POINT_REDEEM_HISTORY,
        payload: {
          pointRedeemHistories: histories,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAffiliateSocialPosts = () => (dispatch, getState) => {
  network
    .get(affiliateSocialPostUrl, {}, dispatch, getState)
    .then((res) => {
      const { posts } = res.data;
      dispatch({
        type: GET_AFFILIATE_SOCIAL_POSTS,
        payload: {
          socialPosts: posts,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendAffiliateInvitationEmail =
  (emails, message, is_resend = false) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
          affiliateSendEmailUrl,
          { emails, message, is_resend },
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message } = res.data;
          if (code != 200) {
            return reject(message);
          }
          resolve(message);
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };

export const postAffiliateFriendDelete = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(affiliateFriendDeleteUrl, { id }, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code != 200) {
          return reject(message);
        }
        resolve();
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const postAffiliateSocialPost = (url) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(affiliateSocialPostUrl, { url }, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code != 200) {
          return reject(message);
        }
        resolve();
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const postAffiliateRewardRedeem =
  (amount, payment_method, identifier) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
          affiliateRewardRedeemUrl,
          { amount, payment_method, identifier },
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message } = res.data;
          if (code != 200) {
            return reject(message);
          }
          resolve();
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };

export const postAffiliatePointRedeem = (amount) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(affiliatePointRedeemUrl, { amount }, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code != 200) {
          return reject(message);
        }
        resolve();
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
