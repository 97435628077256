import React from "react";
import { Alert } from "react-bootstrap";
import { BotType, IndicatorType } from "../../../../Const";
import { isLong } from "../../../../utils/Utils";

const TvIndicatorAlertView = (props) => {
  const {
    indicator,editTvAlert,removeTvAlert
  } = props;
  return (
    <label className="indicator-label">
      <span>
          Trading View Alert {indicator.order} order {indicator.valid_sec?"(Required)":""}
        </span>
        <div>
           <img src="/images/icon-configure 1.svg" alt="" onClick={() => {
                editTvAlert(indicator);
                }}/>
            <img src="/images/icon-cross 2.png" alt="" onClick={() => removeTvAlert(indicator.alert_id)}/>
        </div>
    </label>
  );
};

export default TvIndicatorAlertView;
