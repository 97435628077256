import React from "react";
import { Line } from "react-chartjs-2";

const ReportChart = (props) => {
  const { chartData, label } = props;
  let dataSet = [];
  let labels = [];

  // Populate dataSet and labels from chartData
  if (chartData) {
    chartData.forEach((item) => {
      labels.push(item.time || "");
      dataSet.push(item.value);
    });
  }

  // Define chart options for Chart.js v2.x
  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: false,
          display:false
        },
        gridLines: {
          color: "#999",
        },
      }],
      yAxes: [{
        ticks: {
          fontColor: "#999",
        },
        gridLines: {
          color: "#999",
        },
      }],
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
  };

  // Set a minimum width and dynamic width based on the number of labels
  const chartWidth = Math.max(labels.length * 11, 1000); 
  const chartHeight = 400; // Fixed height

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <div style={{ width: `${chartWidth}px`, height: `${chartHeight}px`, position: 'relative' }}>
        <Line
          data={{
            labels: labels,
            datasets: [{
              label: label,
              data: dataSet,
              fill: false,
              backgroundColor: "#007DFF",
              borderColor: "#007DFF",
              hoverBackgroundColor: "#007DFF",
              hoverBorderColor: "#007DFF",
              cubicInterpolationMode: 'monotone',
            }],
          }}
          options={options}
          height={chartHeight}
        />
      </div>
    </div>
  );
};

export default ReportChart;
