import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";
import BotTab from "./BotCard";
import BotTabRent from "./BotTabRent";
import useLoading from "../hooks/useLoading";
import { getBotDetail, getBots, getIndicators, getRentalBots } from "../../actions/bot-action";
import { resetBotConfig, updateCopyBotConfig, updateEditBotConfig, updateEditRentBotConfig } from "../../actions/create-bot-action";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_BOT_START } from "../../actions/create-bot-type";
import queryString from "query-string";
import ReactStatePagination from "../common/ReactStatePagination";
import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT } from "../../actions/types";
import { toast } from "react-toastify";
import BotCard from "./BotCard";
import BotListEmpty from "./BotListEmpty";


const BotList  = (props)=>{
    const {t,getBots,getBotDetail,updateCopyBotConfig,updateEditBotConfig,getIndicators,resetBotConfig,updateEditRentBotConfig,searchBotName} = props
    const { search } = useLocation();
    const query = queryString.parse(search)
    const history = useHistory()
    const dispatch = useDispatch()
    const [setLoading, bindLoading] = useLoading(false);
   
    var bots = useSelector((state) => state.bots.bots) || [];
    const totalBots = useSelector((state) => state.bots.totalBots) || 0;
    const startItem = useSelector((state) => state.bots.startItem) || 1;
    const selectedExchangeId = useSelector((state) => state.settings.selectedExchangeId) || 0;
    const status = useSelector((state) => state.pagination.botsActiveTab) || "all";
    const sort = useSelector((state) => state.pagination.botSort) || "name";
    const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
    const pageNumbers = {
      "active":useSelector((state) => state.pagination.botsActivePage),
      "inactive":useSelector((state) => state.pagination.botsInactivePage),
      "all":useSelector((state) => state.pagination.botsPageAll),
    }
    bots = bots.filter((item) => {
        return selectedExchangeId == item.exchange_id;
      });
    const itemsPerPage = 12;
    
    
    
   
    
  const paginationCallback = (pageNum)=>{
    getBots(selectedExchangeId,itemsPerPage,pageNum,status,orderBy,sort);
  }
    const botAction = (botId,action) => {
      getBotDetail(botId)
        .then((res) => {
          if(!res.rent_id){
            if(action==="edit"){
                updateEditBotConfig();
            }
            if(action==="copy"){
              updateCopyBotConfig(botId);
            }
            dispatch({
              type: CREATE_BOT_START,
              payload: true,
            });
            history.push(`/bots/create`);
          }
          else{
            if(action==="edit"){
              dispatch({
                type: CREATE_BOT_START,
                payload: true,
              });
              updateEditRentBotConfig()
              history.push(`/rent/create`);
            }
            else{
              toast.error("Not available for rental bots");
            }
          }
            setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          //toast.error(err);
        });
    };
    useEffect(() => {
      getBots(selectedExchangeId,itemsPerPage,pageNumbers[status],status,orderBy,sort,searchBotName);
      getIndicators();
      resetBotConfig();
    }, [status,sort,orderBy,selectedExchangeId,searchBotName]);
    
    const rows = bots
    //.slice(startItem, startItem + itemsPerPage)
    .map((bot, index) => {
      bot.statusVariant =
        bot.deal_status === 1
          ? "active"
          : (bot.deal_status === 2 && bot.status==="active")
          ? "waiting"
          : "inactive";
      const clickBotAction = (type) => {
        botAction(bot.id,type);
      };
      return (
       <BotCard key={bot.id} bot={bot} clickBotAction={clickBotAction} itemsPerPage={itemsPerPage} pageNum={pageNumbers[status]} selectedTab={status} ></BotCard>
      );
    });
    return(
        <>
        {rows.length > 0 ? rows:<BotListEmpty selectedTab={status}></BotListEmpty>}
       
        <div className="bottom-center">
          <ReactStatePagination
            itemsCountPerPage={itemsPerPage}
            forPage="bots"
            startItem={startItem}
            selectedTab={status}
            totalCount={totalBots}
            onChange={paginationCallback}
            itemsPerPage={itemsPerPage}
          /> 
        </div>
        
        </>
    )
}

export default connect(null,{getBots,getRentalBots,getBotDetail,updateCopyBotConfig,updateEditBotConfig,resetBotConfig,getIndicators,updateEditRentBotConfig}) (BotList);