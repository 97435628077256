import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import { signUp, resetSignUp } from "../../actions/auth-action";
import { Link, Redirect } from "react-router-dom";
import "./SignUp.scss";
import useInput from "../hooks/useInput";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";

const SignUp = (props) => {
  const { signUp, resetSignUp } = props;
  const { search } = useLocation();
  const query = queryString.parse(search);
  const {t} = useTranslation()

  const signUpError = useSelector((state) => state.auth.signUpError);
  const signUpRedirect = useSelector((state) => state.auth.signUpRedirect);
  const signUpStart = useSelector((state) => state.auth.signUpStart);
  const signUpSuccess = useSelector((state) => state.auth.signUpSuccess);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [referralCode, bindReferralCode] = useState(query.ref || "");
  const [checked, setChecked] = useState(false);
  const [appSumoCode, setAppSumoCode] = useState(query.app_sumo_code || "");

  useEffect(() => {
    resetSignUp();
    return () => {
      resetSignUp();
    };
  }, []);

  const handleLength = (callback,value,maxLength)=>{
    return callback(value.slice(0, maxLength));
  }

  const clickSignUp = (e) => {
    e.preventDefault();
    signUp({
      first_name: firstName,
      email,
      password,
      password_confirmation: confirmPass,
      referral_code: referralCode,
      app_sumo_code:appSumoCode,
      from: "web",
    });
  };
  if (signUpRedirect) {
    return <Redirect to="/verify-otp" />;
  }
  return (
    <>
     <GuestContainer>

     <div className="login-content-wrapper signup-content-wrapper">
          <div className="login-content signup-content">
            
          <a href="#"><img src="/images/named-logo.png"></img></a>
              <h4 >{t("create-new-acc")}</h4>
              <Form>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-user.svg"  className="input-icon"></img>
                  <input
                    type="input"
                    placeholder={t("first-name")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyDown={(e)=>handleLength(setFirstName,e.target.value,100)}
                    className="form-input input-with-icon auth-input"
                  />
                </div>
                
               
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-email.svg"  className="input-icon"></img>
                  <input
                    type="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e)=>handleLength(setEmail,e.target.value,200)}
                    className="form-input input-with-icon auth-input"
                  />
                </div>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-lock.svg"  className="input-icon"></img>
                <input
                    type="password"
                    placeholder={t("Password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e)=>handleLength(setPassword,e.target.value,50)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </div>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-lock.svg"  className="input-icon"></img>
                <input
                    type="password"
                    placeholder={t("re-pass")}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    onKeyDown={(e)=>handleLength(setConfirmPass,e.target.value,50)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </div>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-referral.svg"  className="input-icon"></img>
                <input
                    type="input"
                    placeholder={t("ref-code")}
                    value={referralCode}
                    onChange={(e) => bindReferralCode(e.target.value)}
                    disabled={query.ref != null}
                    onKeyDown={(e)=>handleLength(bindReferralCode,e.target.value,10)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </div>
                
                <div className="signup-checkbox m-0-auto w-80">
                <input type="checkbox" id="one" className=""  
                  value={checked}
                  onChange={(e) => setChecked(e.target.checked)}>
                </input>
                <label for="one" className="">{
                  <><span></span>
                    <Trans i18nKey={"i-agree"}>
                    <a
                     href="https://cryptohero.ai/terms-of-service/"
                      target="_blank"
                    >
                    </a>
                    </Trans>
                  </>
                  
                  }</label>
                </div>
               
                
               
                <button type="submit" onClick={clickSignUp} className={" capitalize"} disabled={!checked}>
                  {signUpStart ? "SUBMITTING..." : "SIGN UP"}
                  
                  </button>
                  
                <Alert
                  show={signUpError != null || signUpSuccess}
                  variant={signUpSuccess ? "success" : "danger"}
                  className="mt-2"
                >
                 {signUpError || t("acc-created-msg")}
                </Alert>
              </Form>
              <div className="pb-4"></div>
              
              

             
          </div>
          <div className="login-content-bottom-link">
					<ul>
						<li>{t("have-acc")} {appSumoCode?<><Link to={`/login?app_sumo_code=${appSumoCode}`} className="link-text">{t("login")}</Link></>:<><Link to="/login" className="link-text">{t("login")}</Link></>}</li>
            
					</ul>
				</div>
        </div>
     </GuestContainer>
    </>
  );
};

export default connect(null, { signUp, resetSignUp })(SignUp);
