import React, { useState } from "react";

export default (initial, decimal = 4) => {
  const [value, setValue] = useState(initial);
  const onChange = (e) => {
    const val = limitDecimalNum(e.target.value, decimal);
    setValue(val);
  };
  const bind = {
    value,
    onChange,
  };
  const reset = () => setValue(initial);
  return [value, bind, reset, setValue];
};

const limitDecimalNum = (val, decimal = 8) => {
  for (const separator of [".", ","]) {
    const parts = val.split(separator);
    if (decimal === 0) {
      return parts[0];
    }
    if (parts && parts.length > 1) {
      const decimals = parts[1];

      const limitDecimals = decimals.slice(0, decimal);
      return parts[0] + separator + limitDecimals;
    }
  }
  return val;
};
