import React from "react";
import DcaExitView from "../dca-bot/DcaExitView";
import PriceExitView from "../price-bot/PriceExitView";
import GridExitView from "../grid-bot/GridExitView";
import SellBotExitView from "../sell-bot/SellBotExitView";
import ExitConditionsView from "../ExitConditionsView";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BotType } from "../../../../Const";
import CreateBotSection from "./CreateBotSection";
import CustomCheckbox from "./CustomCheckbox";
import { SET_APPLY_CURRENT_DEAL } from "../../../../actions/create-bot-type";

const ExitView = (props)=>{
  const {step} = props;
    const newBot = useSelector((state) => state.createBot.newBot);
    const isEditing = newBot.isEditing
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const setApplyCurrentDeal = (val) => {
      dispatch({
        type: SET_APPLY_CURRENT_DEAL,
        payload: val,
      });
    };
    const getExitView = ()=>{
        switch (newBot.botType) {
          case BotType.dca:
            return <DcaExitView newBot={newBot} t={t} />
          case BotType.price :
            return <PriceExitView newBot={newBot} t={t}/>
          case BotType.grid :
            return <GridExitView newBot={newBot} t={t}/>
          case BotType.sell :
            return <SellBotExitView newBot={newBot} t={t}/>
          default:
            return <ExitConditionsView newBot={newBot} t={t} editing={newBot.isEditing}/>
        }
      }
    return(
    <div className="ba-asset-form-content">
          <div className="row gx-0 gy-3">
            {getExitView()}
            {isEditing && 
        
              <CustomCheckbox 
                  val={newBot && newBot.applyCurrentDeal} 
                  checked={newBot && newBot.applyCurrentDeal} 
                  callback={setApplyCurrentDeal}
                  label={t("apply-changes-curr-deal")}
                  id={'apply-changes-curr-deal'}
                  >
              </CustomCheckbox>
            }
            <div className="col-lg-12">
							<div className="bas-asset-submit bas-asset-btn2 bas-asset-btn3">
              <button onClick={()=>step('prev')}>Previous</button>
              <CreateBotSection></CreateBotSection>
            </div>
            <div className="col-lg-12 mt-2">
            
            </div>
            
          </div>
    </div>
    </div>
    )
}

export default ExitView;