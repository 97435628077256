import { SUBSCRIBE_TEMPLATE } from "../actions/types";

  
  const initialState = {
  }
  
  const SubscriptionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SUBSCRIBE_TEMPLATE:
        return {
          ...state,
          template: payload,
        };
  
      default:
        return state;
    }
  };
  
  export default SubscriptionReducer;
  