  import {
    deleteTemplateUrl,
    templateRentUrl,
    templateSubmitUrl,
    templateUpdateUrl
  } from "./const";
  import network from "./network";
  import { checkSub } from "../utils/SubUtils";
  import FormData from "form-data";
  
  
  const getParams = (newTemplate,subPremium,update,botId) =>{
    if (!subPremium )
      return { err: "Please upgrade your plan to use this feature." };
    const data = new FormData();
    const params = update?( {
      id:newTemplate.id,
      title:newTemplate.title,
      description:newTemplate.description?newTemplate.description:'',
      monthly_fee:newTemplate.monthlyFee,
      quarterly_fee:newTemplate.quarterlyFee,
      yearly_fee:newTemplate.annualFee,
      default_image:newTemplate.default_image?newTemplate.default_image:null,
      upload_image:newTemplate.upload_image?newTemplate.upload_image:null
    }):({
      bot_id:botId,
      title:newTemplate.title,
      description:newTemplate.description?newTemplate.description:'',
      monthly_fee:newTemplate.monthlyFee,
      quarterly_fee:newTemplate.quarterlyFee,
      yearly_fee:newTemplate.annualFee,
      default_image:newTemplate.default_image,
      upload_image:newTemplate.upload_image
    })
    if (!params.default_image) {
      delete params.default_image
    }
    if (!params.upload_image) {
      delete params.upload_image
    }
    for ( var key in params ) {
      data.append(key, params[key]);
    }
    return { err: null, data };
  };
  export const submitForReview = () => (dispatch, getState) => {
  
    return new Promise((resolve, reject) => {
      const state = getState();
      const newTemplate = state.newTemplate;
      const { subPremium } = checkSub(state.auth.user.user_subscription);
  
      const { err, data } = getParams(newTemplate.newTemplate, subPremium,false,newTemplate.botSelected.id);
      if (err) {
        return reject(err);
      }
      network
        .post(templateSubmitUrl, data, dispatch, getState,true)
        .then((res) => {
          const { code, message } = res.data;
          if (code == 200) {
            resolve();
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
  };
  export const updateBotTemplate = (botId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const newTemplate = state.newTemplate.newTemplate;
      const { subPremium } = checkSub(state.auth.user.user_subscription);
  
      const { err, data } = getParams(newTemplate, subPremium,true);
      if (err) {
        return reject(err);
      }
      network
        .post(templateUpdateUrl, data, dispatch, getState,true)
        .then((res) => {
          const { code, message } = res.data;
          if (code == 200) {
            resolve();
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
  };
  export const deleteBotTemplate = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .delete(deleteTemplateUrl+id, {}, dispatch, getState)
        .then((res) => {
          const { code, message } = res.data;
          if (code == 200) {
            resolve();
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
  };