import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import {
  getSettings,
  updateNotificationStatus,
  updateEmailAlertStatus
} from "../../actions/setting-action";
import {  SUB_TIER } from "../../Const";
import { checkSub } from "../../utils/SubUtils";
import { restrictAlphabets } from "../../utils/Utils";
import CustomSwitch from "../bots/new-bot/modules/CustomSwitch";

const NotificationsPanel = (props) => {
  const { getSettings, updateNotificationStatus,updateEmailAlertStatus } = props;
  const {t} = useTranslation()
  const countryName = useSelector(
    (state) => state.settings.order_filled_sms_alert_country_name
  );
  const countryCode = useSelector(
    (state) => state.settings.order_filled_sms_alert_country_code
  );
  const [isOn, setIsOn] = useState(false);
  const [isOrderFilledAlertOn, setIsOrderFilledAlertOn] = useState(false);
  const [isSmsAlertOn, setSmsAlertOn] = useState(false);
  const [countryNameDefault, setCountryName] = useState(countryName?countryName:"australia");
  const updateProfileStart = useSelector((state) => state.settings.updateProfileStart);
  const smsPhoneNumber = useSelector(
    (state) => state.settings.order_filled_sms_alert_phone?.replace(countryCode,'')
  );
  const [phoneNumber, setPhoneNumber] = useState(smsPhoneNumber);
  const notificationStatus = useSelector(
    (state) => state.settings.bot_notification_enabled
  );
  
  const orderFilledAlertStatus = useSelector(
    (state) => state.settings.order_filled_email_alert
  );
  const orderFilledSmsAlertStatus = useSelector(
    (state) => state.settings.order_filled_sms_alert
  );
  const orderFilledSmsAlert = useSelector(
    (state) => state.settings.order_filled_sms_alert
  );
  
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const { subPremium, subTier } = checkSub(subscription);
  const pro = subPremium && subTier === SUB_TIER.pro
  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (notificationStatus != null) {
      setIsOn(notificationStatus == 1);
    }
    if (orderFilledAlertStatus != null) {
      setIsOrderFilledAlertOn(orderFilledAlertStatus == 1);
    }
    if (orderFilledSmsAlertStatus != null) {
      setSmsAlertOn(orderFilledSmsAlertStatus == 1);
    }
    if (countryName != null) {
      setCountryName(countryName);
    }
    if (smsPhoneNumber != null) {
      setPhoneNumber(smsPhoneNumber);
    }
   
  }, [notificationStatus,orderFilledAlertStatus,orderFilledSmsAlertStatus,smsPhoneNumber,countryName]);

  const changeNotification = (isOn) => {
    setIsOn(isOn);
    updateNotificationStatus(isOn ? 1 : 0);
  };
  const changeEmailAlert = (isOn) => {
    setIsOrderFilledAlertOn(isOn);
    updateEmailAlertStatus(isOn ? 1 : 0);
  };
  
  

  return (
    <>
      <div className="setting-general-content">
          <Row>
            <Col>
            <h5>{t("turn-off-notif")}</h5>
              <div className="card-white">
                    <div className="cw-body settings-paper-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                            {t("turn-off-notif-desc")}
                          </p>
                        </div>
                        <div>
                        <CustomSwitch checked={isOn} onChange={changeNotification}></CustomSwitch>
                        </div>
                      </div>
                </div>
                </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col>
            <h5>{t("turn-off-filled-emails")}</h5>
              <div className="card-white">
                    <div className="cw-body settings-paper-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                          {t("turn-off-filled-emails-desc")}
                          </p>
                        </div>
                        <div>
                          <CustomSwitch checked={isOrderFilledAlertOn} onChange={changeEmailAlert}></CustomSwitch>
                        </div>
                      </div>
                </div>
                </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          </div>
    </>
  );
};

export default connect(null, {
  getSettings,
  updateNotificationStatus,
  updateEmailAlertStatus,
})(NotificationsPanel);
