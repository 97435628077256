import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../common/ContentContainer";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { CREATE_BOT_START, SET_BOT_NAME } from "../../../actions/create-bot-type";
import BotTitle from "./modules/BotTitle";
import { useHistory, useLocation } from "react-router-dom";
import IndicatorSettingModal from "./indicator-setting/IndicatorSettingModal";
import AssetParamRent from "./modules/AssetParamRent";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import CreateRentBotSection from "./modules/CreateRentBotSection";
import CreateBotStepsRent from "./CreateBotStepsRent";

const CreateRentBot = (props)=>{
    const {getBalance,getPairBalance} = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const createBotStart =  useSelector((state) => state.createBot.rentBot.createBotStart);
    const exchangeId = rentBot && rentBot.exchange.id;
    useEffect(() => {
        getBalance(exchangeId);
      }, [exchangeId]);
    useEffect(()=>{
        if(createBotStart){
            dispatch({
                type: CREATE_BOT_START,
                payload: false,
            });
        }
        else{
            history.push('/rented-strategies')
        }
    },[])
    useEffect(() => {
        getPairBalance(exchangeId,rentBot.pair.id,'spot');
      }, [exchangeId]);
    
    return(<div>
       <ContentContainer url="bots" title={t("bots")} showExchange={true}>
            <div className="bot-advance-asset-area">
                <CreateBotStepsRent></CreateBotStepsRent>
                
            </div>
            <IndicatorSettingModal></IndicatorSettingModal>
        </ContentContainer>
      </div>);
}

export default connect(null,{getBalance,getPairBalance})(CreateRentBot)