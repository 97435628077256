import React from "react";
import {
  StoplossType,
  StoplossTypeName,
  Frequencies,
  FrequencyValues,
  BotType,
  BotTypeName,
  TakeProfitTypeName,
  TakeProfitType
} from "../../../Const";
import {useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import LeftRightForm from "../new-bot/modules/LeftRightForm";
import InfoDisplay from "../new-bot/modules/InfoDisplay";
import { capitalize } from "../../../utils/Utils";

const AdvanceBotSetting = (props) => {
  const { bot,t } = props;
  const isExitBot = bot.type==BotType.exit;
  const theme = useSelector((state) => state.settings.selected_theme);
  const isFuture = useSelector((state) => state.settings.isFuture);
  const tsls = bot.tsls || [];
  let stoploss = (bot.stop_loss || 0).toFixed(2) + "%";
  if (tsls.length) {
    stoploss =
      tsls[0].sl_type === StoplossType.fixedTsl
        ? StoplossTypeName.fixedTsl
        : StoplossTypeName.variableTsl;
  }
  const tps = bot.tps || [];
  let takeProfit = (bot.profit || 0).toFixed(2) + "%";
  if (tps.length) {
    takeProfit =
    tps[0].tp_type === TakeProfitType.fixedTP
        ? TakeProfitTypeName.fixedTP
        : TakeProfitTypeName.variableTP;
  }
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const entryIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "entry")
      .map((item) => item.name)
      .join(", ");
  const exitIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "exit")
      .map((item) => item.name)
      .join(", ");
  return (
    <div className="col-lg-6 col-md-6">
    <div className="bot-detail-left">
      <div className="bot-detail-left-header">
        <label>
          <img src="/images/overview-icon.svg" alt=""/>
          Overview
        </label>
        <label>
          <img src={exchange.image} alt="" width={20}
                height={20}/>
          {exchange.name}
        </label>
      </div>
      <div className="bot-detail-left-body">
        <div className="row">
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("pair")}</span>
              <h6>{pair && `${pair.from}/${pair.to}`}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("bot-type")}</span>
              <h6>{bot && BotTypeName[bot.type]}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("strategy")}</span>
              <h6 >{bot && bot.strategy}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("Frequency")}</span>
              <h6>{bot && Frequencies[FrequencyValues.indexOf(bot.frequency)]}</h6>
            </div>
          </div>
          {!isExitBot &&<div className="col-6">
            <div className="bdol-item" >
              <span>{t("entry-indicators")}</span>
              <h6>{entryIndicators}</h6>
            </div>
          </div>}
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("exit-indicators")}</span>
              <h6>{exitIndicators}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("tp")}</span>
              <h6>{bot && takeProfit} {bot.type == BotType.price?'':''}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("stoploss")}</span>
              <h6>{stoploss}</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("bo-limit")}</span>
              <h6>{bot && bot.base_order_percentage} %</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("Extra Order")}</span>
              <h6>{bot && bot.extra_order_percentage} %</h6>
            </div>
          </div>
          <div className="col-6">
            <div className="bdol-item">
              <span>{t("min-price-gap-extra")}</span>
              <h6>{bot && bot.extra_price_gap_min} %</h6>
            </div>
          </div>
          {isFuture?<><InfoDisplay 
            left={t("leverage")}
            right={<>{bot.configs && bot.configs.leverage}X</>}
            leftCol={4}
            rightCol={8}
          ></InfoDisplay>
        <InfoDisplay 
            left={t("margin-mode")}
            right={<>{bot.configs && capitalize(bot.configs.margin_mode)}</>}
            leftCol={4}
            rightCol={8}
          ></InfoDisplay>
        <InfoDisplay 
            left={t("price-type")}
            right={<>{bot.configs && capitalize(bot.configs.price_type)}</>}
            leftCol={4}
            rightCol={8}
          ></InfoDisplay></>:<></>}
          
        </div>
      </div>
    </div>
  </div>
      
  );
};

export default AdvanceBotSetting;
