import React from "react";
import { useMediaPredicate } from "react-media-hook";
import { ScreenSize } from "../../Const";

export default () => {
  const isMediumSize = useMediaPredicate("(min-width: 1440px)");
  const isLargeSize = useMediaPredicate("(min-width: 1920px)");
  const isXLargeSize = useMediaPredicate("(min-width: 2560px)");
  if (isMediumSize) {
    return ScreenSize.medium;
  } else if (isLargeSize) {
    return ScreenSize.large;
  } else if (isXLargeSize) {
    return ScreenSize.xlarge;
  }

  return ScreenSize.small;
};
