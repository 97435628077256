import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import * as HelpContent from "../../utils/HelpContent"
import { connect, useDispatch, useSelector } from "react-redux";
import TutorialPopup from "../help/TutorialPopup";
import Select from "react-select";
import { PRICE_ALERT_PAIR } from "../../actions/types";
import useInput from "../hooks/useInput";
import { createPriceAlert } from "../../actions/price-alert-action";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import CustomRadio from "../bots/new-bot/modules/CustomRadio";
const PriceAlertCreateModal = (props)=>{
    const {onHide,createPriceAlert,t} = props
    const selectedExchange = useSelector((state) => state.settings.selectedExchange) || {};
    const newAlert = useSelector((state) => state.priceAlert.newAlert) || {};
    const [loading, setLoading] = useState(false);
   
    const [baseCoin, setBaseCoin] = useState("usd");
    const dispatch = useDispatch();
    const usdPairs = useSelector((state) => state.bots.usdPairs);
    const btcPairs = useSelector((state) => state.bots.btcPairs);
    const ethPairs = useSelector((state) => state.bots.ethPairs);
    const [condition, bindCondition, setCondition] = useInput(newAlert.condition?newAlert.condition:"le");
    const [price, bindPrice, setPrice] = useInput(newAlert.price?newAlert.price:0);
    
    const pairList = useMemo(() => {
        return baseCoin === "usd"
          ? usdPairs
          : baseCoin === "btc"
          ? btcPairs
          : ethPairs;
      }, [baseCoin]);
    const pairItems =
    (pairList &&
    pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
    }))) ||
    [];
    const pair = newAlert.pair;
    const selectedPair = {
        value: newAlert.exchangePairId,
        label: `${pair.from}/${pair.to}`,
        from: pair.from,
        to: pair.to,
    };
    const handleSelectPair = (item) => {
        const newPair = pairList.find((el) => item.value == el.id) || {};
        dispatch({
          type: PRICE_ALERT_PAIR,
          payload: newPair,
        });
      };
    const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
        e.preventDefault()
    
    }
    const submitPriceAlert = ()=>{
        setLoading(true)
        let params = {
            exchange_id:selectedExchange.id,
            pair_id:pair.id,
            price:price,
            condition:condition,
        }
        createPriceAlert(params).then((res)=>{
            setLoading(false)
            toast.success(t("success"))
            onHide()
        }).catch((err)=>{
            toast.error(err.message)
            setLoading(false)
        })
    }
    useEffect(()=>{
          if(pairList[0]){
            dispatch({
              type: PRICE_ALERT_PAIR,
              payload: pairList[0],
            });
          }
      },[baseCoin])
    return (
        <Loading show={loading} onHide={() => setLoading(false)}>
        <Modal show={true} onHide={onHide} size="lg" dialogClassName="modal-90w">
            <Modal.Header closeButton className="align-items-center">
              <Modal.Title>
               {t("create-p-alert")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                  <Col>
                  <Form.Group>
                    <Form.Label>
                        {t("exchange")} <TutorialPopup content={t("exchange-help")} />
                    </Form.Label>
                    <div className="mt-1 mb-3">
                        <img
                        src={selectedExchange.image}
                        width={20}
                        height={20}
                        style={{ marginRight: 10 }}
                        />
                        <span style={{ fontWeight: "bold", fontSize: 16 }}>
                        {selectedExchange.name}
                        </span>
                    </div>
                    </Form.Group>
                    <Form.Group style={{ marginTop: 20 }}>
                    <Form.Label>
                        Trading Pair 
                    </Form.Label>
                    <Row>
                        <Col style={{ maxWidth: "100%" }}>
                        <Select
                            value={selectedPair}
                            options={pairItems}
                            onChange={handleSelectPair}
                            className='react-select-container'
                            classNamePrefix='react-select'
                        />
                        </Col>

                        <Col style={{ maxWidth: "100%" }}>
                        <div   className="mt-2 d-flex jcb">
                            <CustomRadio
                            type="radio"
                            label="(S)tablecoin"
                            name="filter"
                            value="usd"
                            className="mr-3"
                            checked={baseCoin=="usd"}
                            callback={() => setBaseCoin("usd")}
                            />
                            <CustomRadio
                            type="radio"
                            label="BTC"
                            name="filter"
                            value="btc"
                            className="mr-3"
                            checked={baseCoin=="btc"}
                            callback={() => setBaseCoin("btc")}
                            />
                            <CustomRadio
                            type="radio"
                            label="ETH"
                            name="filter"
                            value="eth"
                            checked={baseCoin=="eth"}
                            callback={() => setBaseCoin("eth")}
                            />
                            
                        </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        <div className="d-flex align-items-center justify-content-between price-alert-form mt-3">
                            <div>
                                <p>{t("alert-me-when-price")}</p>
                            </div>
                            <div>
                                <select {...bindCondition} className="form-control select-normal price-a-sele">
                                    <option value={`le`}>{t("less-than")}</option>
                                    <option value={`ge`}>{t("greater-than")}</option>
                                </select>
                            </div>
                            <div>
                            
                                    <label data-text={pair.to} className="input-gp-label">
                                        <Form.Control
                                            as="input"
                                            type="number"
                                            {...bindPrice} onKeyPress={(e) => {
                                            restrictAlphabets(e,e.target.value);
                                            }}
                                            className="input-text input-gp-text"
                                            ></Form.Control>
                                    </label>
                            </div>
                            
                        </div>
                        </Col>
                        </Row>
                       
                    </Form.Group>
                    <div className="d-flex justify-content-around mt-4">
                        <Button onClick={()=>onHide()}  className=" grey-button">
                        {t('cancel')}
                        </Button>
                        <Button onClick={()=>submitPriceAlert()}  className=" blue-button">
                        {t("save")}
                        </Button>
                        </div>
                  </Col>
              </Row>
              </Modal.Body>
            </Modal>
            </Loading>
    )

}
export default connect(null,{createPriceAlert})(PriceAlertCreateModal)
