import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { setShowUploadImageModal } from "../../../actions/image-upload-action";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import BotImageUploadView from "../create/BotImageUploadView";
import EditBotTemplateView from "./EditBotTemplateView";
const EditBotTemplate = (props)=>{
    const {onHide,template,setShowUploadImageModal,tab} = props;
    const dispatch = useDispatch();
    const [setLoading, bindLoading] = useLoading(false);
    //const [showUploadImageModal, setShowUploadImageModal] = useState(false);
    const diplayTemplateImage = useSelector((state) => state.newTemplate.displayTemplateImg) || '';
    const editTemplate = useSelector((state)=>state.newTemplate.newTemplate)
    // useEffect(()=>{
    //   dispatch({
    //     type: SET_DISPLAY_TEMPLATE_IMAGE,
    //     payload: editTemplate.image,
    //   });
    // },[editTemplate])
    return (
        <>
          <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
            <Modal.Header closeButton className="align-items-center no-bottom-border">
                <h5 className="edit-template-heading">Edit Bot</h5>
            </Modal.Header>
            <Modal.Body style={{padding:"10px 50px",height: "700px"}}>
              
            <Row>
                <Col xl={3} className="mt-3">
                <img src={editTemplate.imageThumbnail } width="200px" onClick={()=>setShowUploadImageModal(true)}></img>
                </Col>
                <Col xl={9}>
                <EditBotTemplateView onHide={onHide} template={editTemplate} tab={tab}/>
                </Col>
                
            </Row>
            <Row>
                
            </Row>
            </Modal.Body>
          </Modal>
           <BotImageUploadView 
            onHide={()=>setShowUploadImageModal(false)} 
            //onLibraryImageSelect={()=>setLibraryImageSelect()}
            //onImageSelect={()=>setImageSelect()}
            /> 
          <Loading {...bindLoading} />
        </>
      );
};
export default connect(null,{setShowUploadImageModal})(EditBotTemplate)