import React from "react";
import { connect, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { formatDateTime, normalizeTime } from "../../../../utils/Utils";
import "./Summary.scss";

const Summary = (props) => {
  const { baseCoin,t } = props;
  const backtest = useSelector((state) => state.bots.backTest);
  const theme = useSelector((state) => state.settings.selected_theme);
  const equities =
  (backtest && backtest.positions && Array.isArray(backtest.positions) && [
    //parseFloat(backtest.initialFund).toFixed(2),
    ...backtest.positions.map((position) => position.accumulated_asset && parseFloat(position.accumulated_asset).toFixed(2)),
  ]) ||
  [];
  const labelTime =
  (backtest && backtest.positions && Array.isArray(backtest.positions) && [
    ...backtest.positions.map((position) => position.deal_trade_histories && position.deal_trade_histories[0].created_at),
  ]) ||
  [];
  const totalTrade = (backtest && backtest.total_position) || 0;
  const winTrade = (backtest && backtest.profitable_position) || 0;
  const wlRatio = totalTrade > 0 ? (winTrade * 100) / totalTrade : 0;
  const performance = (backtest && parseFloat(backtest.total_performance)) || 0;
  const returnValue = (backtest && parseFloat(backtest.return_value_avg)) || 0;
  const avgPerformance = totalTrade > 0 ? performance / totalTrade : 0;
  const avgReturn = totalTrade > 0 ? returnValue / totalTrade : 0;
  const decimalNumber = baseCoin.toLowerCase().includes("usd") ? 2 : 4;
  const tradeInterval = normalizeTime(
    (backtest && backtest.trade_interval) || 0
  );
  const dealDuration = normalizeTime(
    (backtest && backtest.avg_deal_duration) || 0
  );
  const maxDrawdown = (backtest && backtest.max_drawdown) || 0;
  const sharpeRatio = (backtest && backtest.sharpe_ratio) || 0;
  return (
    <>
    <div className="ba-summary-content">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="ba-summary-col1">
              <ul>
                <li>{t("return")}</li>
                <li className={returnValue>=0?"text-success":"text-danger"}>{parseFloat(performance).toFixed(decimalNumber)} {baseCoin} (
                              {returnValue.toFixed(2)}
                              %)</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="ba-summary-col2 ba-summary-col1">
              <ul>
                <li>{t("w-l-ratio")}</li>
                <li>{wlRatio.toFixed(2)}%</li>
              </ul>
              {/* <ul>
                <li>{t("a-d-inter")}</li>
                <li>{tradeInterval.time < 1
                                ? "-"
                                : tradeInterval.time.toFixed(1) + " " + tradeInterval.unit}</li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="ba-summary-col3 ba-summary-col1">
              <ul>
                <li>{t("deals")}</li>
                <li>{totalTrade}</li>
              </ul>
              <ul>
                <li>{t("Max Drawdown")}</li>
                <li>{maxDrawdown}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="ba-summary-col4 ba-summary-col1">
              <ul>
                <li>{t("a-deal-prof")}</li>
                <li>{avgPerformance.toFixed(decimalNumber)} {baseCoin} (
                              {avgReturn.toFixed(2)}%)</li>
              </ul>
              <ul>
                <li>{t("Sharpe Ratio")}</li>
                <li>{sharpeRatio}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bot-detail-chart-area mb-4">
        <Line data={data(equities,labelTime)} options={options} height={60} />
      </div>
      </>
  );
};

export default connect(null)(Summary);

const data = (returns,labelTime) => ({
  labels: labelTime.map((item) => formatDateTime(item)),
  datasets: [
    {
      data: returns,
      //type: "line",
      fill: false,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      label: "Performance",
    },
  ],
});

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false //this will remove only the label
        },
        gridLines: {
          color: "#999"
        }
    }],
    yAxes: [{
        ticks: {
          fontColor: "#999",
        },
        gridLines: {
          color: "#999"
        }
    }]
}
 
};
