import React from "react";
import { Row } from "react-bootstrap";
import TradingView from "../bots/TVChartContainer";
import { connect, useSelector } from "react-redux";
import "./TradingInforPanel.scss";

const TradingInforPanel = (props) => {
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const pair = selectedPair;
  const executedOrders = useSelector((state) => state.terminal.executed_orders);
  const orders =
  executedOrders &&
    (executedOrders || [])
      .filter((el) => (el.status == "filled" || el.status == "partial_filled"))
      .map((item) => ({
        name: item.name,
        desc: `${item.filled}  @ ${item.average}`,
        created_at: item.status=="filled"?item.time:item.created_at,
        side: item.side,
        status: item.status,
      }));
  window.bot = orders && {
    orders,
  };
  const symbol = pair && pair.from + "/" + pair.to;
  return (
    <div style={{ height: "100%" }}>
      <Row className="m-1" style={{ height: "100%" }}>
        {pair && (
          <TradingView
            pairId={pair.realPairId}
            symbol={symbol}
            interval="1D"
            reload={true}
          />
        )}
      </Row>
    </div>
  );
};

export default connect(null)(TradingInforPanel);
