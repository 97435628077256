import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  CHANGE_DCA_NO_LIMIT_FUND,
  CHANGE_DCA_ORDER,
  CHANGE_FUND,
} from "../../../../actions/create-bot-type";
import BaseOrderLimitModule from "../futures/modules/BaseOrderLimitModule";
import MarginModeModule from "../futures/modules/MarginModeModule";
import OrderTypeModule from "../futures/modules/OrderTypeModule";
import PriceTypeModule from "../futures/modules/PriceTypeModule";
import LeverageSlider from "../LeverageSlider";
import MaxPositionSize from "../MaxPositionSize";
import LeftRightForm from "../modules/LeftRightForm";
import { restrictAlphabets } from "../../../../utils/Utils";

const FuturesDcaParamsView = (props) => {
  const { newBot,t,editing,balance,currentCoin} = props;
  const fund = newBot.initFund;
  const dispatch = useDispatch();
  const changeDcaNoLimit = (val) => {
    dispatch({
      type: CHANGE_DCA_NO_LIMIT_FUND,
      payload: val,
    });
  };
  const changeDcaOrder = (val) =>
    dispatch({
      type: CHANGE_DCA_ORDER,
      payload: val,
    });
    
   
  return (
    <div>
      <LeverageSlider
        step={1}
        min={10}
        max={125}
        t={t}
        newBot={newBot}
        editing={editing}
      >
      </LeverageSlider>
      <div style={{ marginTop: 20 }}>
      <MaxPositionSize 
        newBot={newBot} 
        editing={editing} 
        fund={fund} 
        currentCoin={currentCoin} 
        balance={balance}
        t={t}>
      </MaxPositionSize>
      </div>
      <div style={{ marginTop: 20 }}>
        <MarginModeModule 
          newBot={newBot} 
          editing={editing} 
          t={t}></MarginModeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <OrderTypeModule 
          newBot={newBot} 
          editing={editing} 
          t={t}></OrderTypeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <PriceTypeModule
          newBot={newBot} 
          editing={editing} 
          t={t}></PriceTypeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <BaseOrderLimitModule
          newBot={newBot} 
          editing={editing} 
          fund={fund}
          currentCoin={currentCoin}
          t={t}></BaseOrderLimitModule>
      </div>
      <div style={{ marginTop: 13 }}>
        
      <LeftRightForm
      left={<>{t("per-position-size")}</>}
      right={<>
        <label data-text={currentCoin} className="input-gp-label">
            <Form.Control
                as="input"
                type="number"
                value={newBot.dcaOrder}
                onChange={(e) => {
                  changeDcaOrder(e.target.value);
                }}
                onKeyPress={(e) => {
                  restrictAlphabets(e);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
          </label>
      </>}
      ></LeftRightForm>
      </div>
    </div>
  );
};

export default FuturesDcaParamsView;
