import React from "react";
import { connect } from "react-redux";
import PayoutHistoryCard from "./PayoutHistoryCard";

const PayoutHistory = (props)=>{
    const {data}=props;
    const items = (
        <>
        {data.map((history, id) => (
            <PayoutHistoryCard history={history} key={id}/>
        ))}
         
        </>
    )
    return (
        <>
            {items}
        </>
    )
       
   
   
}
export default connect(null,{})(PayoutHistory)