import React from "react";
import DcaEntryView from "../dca-bot/DcaEntryView";
import { useTranslation } from "react-i18next";
import EntryConditionsView from "../EntryConditionsView";
import { useSelector } from "react-redux";
import { BotType } from "../../../../Const";

const EntryView = (props)=>{
    const {t} = useTranslation()
    const newBot = useSelector((state) => state.createBot.newBot);
    const getEntryView = ()=>{
        switch (newBot.botType) {
          case BotType.dca:
            return <DcaEntryView newBot={newBot} isEntry={true} t={t}/>
          default:
            return <EntryConditionsView newBot={newBot} isEntry={true} t={t}/>
        }
      }
    return(<>{getEntryView()}</>)
}

export default EntryView;