import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PRICE_TYPE, PRICE_TYPE_RENT } from "../../../../../actions/create-bot-type";
import BooleanView from "../../BooleanView";

const PriceTypeModule = (props)=>{
    const {t,newBot,editing,onChange,rent} = props;
    const dispatch = useDispatch()
    const priceTypeChanged = (val) => {
        dispatch({
          type: rent?PRICE_TYPE_RENT:PRICE_TYPE,
          payload: val,
        });
      };
    return(
    <>
    <BooleanView
        title={t("price-type")}
        firstButton="mark"
        secondButton="last"
        label1="MARK"
        label2="LAST PRICE"
        selected={newBot.priceType}
        onSelect={priceTypeChanged}
        //tutorial={<TutorialPopup content={t("orderType")} />}
        />
    </>
    )
}
export default PriceTypeModule

