import React from "react";

const PnlText = (props)=>{
    const {value,text} = props;
    return(<>
        {value <= 0 ?
        <>
            <div className="bear-arrow"></div>
            <span className="downward-change">{text}</span>
        </>:
        <>
            <div className="bull-arrow"></div><span className="upward-change">{text}</span>
        </>
        }
        
    </>)
}

export default PnlText;