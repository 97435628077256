import ReactGA from "react-ga";

export const signUpSuccess = "Success SignUp";
export const subscribeSuccess = "Success Subscribe";
export const openTerminal = "Open Terminal";

export const sendEvent = (action) => {
  ReactGA.event({
    category: "Web-App",
    action: action,
  });
};
