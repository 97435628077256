import React from "react";
import { Col, Row } from "react-bootstrap";

const Tab2 = (props)=>{
    const {next,prev} = props;
    return(<>
        <h2>Step 2: Creating Your First Bot</h2>
        <br/>
        <p>For this Quick Start Wizard, we will build a Simple bot for you. This bot will trade on our Paper exchange. Trading on our Paper exchange is risk-free and utilizes our Paper (virtual) funds. The goal is to give you a feel for how CryptoHero monitors and executes trades for you using bots. When you are comfortable with your bots, you can create them to run on your live exchange.</p>
        <br></br>
        <p><b>What is a Paper Exchange?</b></p>
        <p className="mt-2">It is a simulated or virtual exchange that monitors real-time crypto fluctuations. All bots set up for trading on the Paper exchange do not use actual money. Instead, they use virtual (false) funds that may be easily changed on the Settings page.</p>
        <br/>
        <ul>
            <li><a  className="qtrblue-btn pointer" onClick={prev}>Previous</a></li>
            <li><a  className="qtrtrans-btn pointer" onClick={next}>Next</a></li>
        </ul>
        
    </>)
}
export default Tab2;