import React, { useEffect, useState } from "react";
import { Table, Badge, Button, Modal, Tooltip } from "react-bootstrap";
import { formatDate, formatDateMinute, formatDateTime, isLong, milisecOf } from "../../utils/Utils";
import {connect, useSelector } from "react-redux";
import { BotType, DealStatus, IncompletedDeals } from "../../Const";
import CustomPagination from "../common/CustomPagination";
import NoData from "../common/NoData";
import "./DealTab.scss";
import DealWarning from "./DealWarning";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { closeDeal, deleteDeal } from "../../actions/deal-action";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TutorialPopup from "../help/TutorialPopup";
import ToolTip from "../help/ToolTip";

const DealTab = (props) => {
  const { deals, completed, onShowDetail,refreshDeal,deleteDeal,closeDeal } = props;
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { deal, startCloseDeal, closeDealError, closeDealSuccess } =
    useSelector((state) => state.deals);
  const [refreshId, setRefreshId] = useState('');
  const history = useHistory()
  const clickRefresh = (id)=>{
    setShowLoading(true)
    setRefreshId(id)
    refreshDeal(id).then(()=>{
      setShowLoading(false)
    }).catch((error)=>{
      setShowLoading(false)
    })
  }
  const clickDelete = (dealId) => {
    setLoading(true);
    deleteDeal(dealId)
      .then(() => {
        setLoading(false);
        toast.info(t("deal-deleted"));
        history.push('/trades')
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  useEffect(() => {
    if (closeDealSuccess && !completed) {
      setTimeout(() => {
        toast.info(t("Success"));
        history.push('/trades')
      }, 500);
    }
  }, [closeDealSuccess]);
  //const [rangeItem, bindPagination] = usePagination(0, 2);
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  deals.forEach((item) => {
    item.closed_orders.sort((order1, order2) => {
      return milisecOf(order1.date) - milisecOf(order2.date);
    });
  });
  deals.sort((item1, item2) => {
    if (
      item1.closed_orders &&
      item2.closed_orders &&
      item1.closed_orders.length &&
      item2.closed_orders.length
    ) {
      const lastIndex1 = item1.closed_orders.length - 1;
      const lastIndex2 = item2.closed_orders.length - 1;
      return (
        milisecOf(item2.closed_orders[lastIndex2].date) -
        milisecOf(item1.closed_orders[lastIndex1].date)
      );
    } else {
      return -1;
    }
  });
  const dealItems = deals.map((item, index) => {
    const status = item.status_key.toLowerCase();
    let statusVariant = item.strategy=="Long"?"green long-dot":"redshort short-dot";
    let isLong = item.strategy=="Long";
    const numberOrder = item.closed_orders.length;
    return (
      
      
      <tr key={item.id}>
      <td className="deal-tab"><span className={`dot dot-${statusVariant}`}></span></td>
      <td className="bot-data"><Link to={`/deal-detail/${item.id}`}>{item.bot_name}</Link></td>
      <td>
        <div className="pair-data">
          {/* <img src="/images/coin-icon.svg" alt=""/> */}
          <h6 className="symbol-name">{item.quote_name}/{item.base_name}</h6>
          {/* <a className={item.strategy=="Long"?"long":"short"}>{item.strategy}</a> */}
        </div>
      </td>
      {/* <td>
        <div className="pair-data">
          <h6>{item.quantity?item.quantity.toFixed(6):0} </h6>
        </div>
      </td> */}
      <td>
        <div className="valume-data">
          <h6>{item.executed_vol?item.executed_vol.toFixed(2):0} {item.base_name}</h6>
        </div>
      </td>
      <td>
        <div  className="unr-return">
          <h6 className={item.net_profit >= 0 ? " unr-return text-success" : "unr-return text-danger"}>{item.net_profit?item.net_profit.toFixed(2):0} {item.base_name}</h6>
          <a href="#" className={item.net_profit >= 0?"long":"short"}> {item.profit_value?item.profit_value.toFixed(2):0}%{" "} </a>
          {item.note && <DealWarning note={item.note} />}
        </div>
      </td>
      <td>
        <div className="valume-data">
          <p>{formatDateMinute(item.updated_at)}</p>
        </div>
      </td>
      {completed &&
      <td>
        <div className="valume-data">
          <p>{item.status_name}</p>
        </div>
      </td>
      }
      <td>
        <div className="action-data">
        {!(showLoading && refreshId==item.id) && !completed  &&
        <ToolTip content={t("Get latest price and recalculate return value")}><img onClick={() => clickRefresh(item.id)} src="/images/icon-refresh.svg" alt="" className="pointer"/></ToolTip>
        }
        { (showLoading && refreshId==item.id) && <i className="fas fa-sync fa-spin reload-icon"></i> }
        {item && IncompletedDeals.includes(item.status_key) ?<ToolTip content={t("Sell all shares bought by the bot for this current trade")}><img src="/images/icon-liquidata.svg"alt="" onClick={() => closeDeal(item.id)}/></ToolTip>:<></>}
        <Link to={`/deal-detail/${item.id}`}>View details</Link>
        </div>
      </td>
    </tr>
   
    
    );
  });
  return (
    <div className="skltbs-panel">
    <div className="open-table-content otc">
      <table className="table">
        <thead>
        <tr>
          <td></td>
          <td>Bot</td>
          <td>Pair</td>
          {/* <td>Amount</td> */}
          <td>Volume</td>
          <td>{completed ?"Realized":"Unrealized"} Return</td>
          <td>Date</td>
          {completed && <td>Reason</td> }
          <td>Action</td>
        </tr>	
        </thead>
       
        <tbody>
        {dealItems}
        </tbody>
        
        
      </table>
    </div>
    
    <ConfirmDialog show={showConfirm} onHide={() => setShowConfirm(false)} delete={clickDelete} t={t}/>
  </div>
   
    
  );
};

export default connect(null,{deleteDeal,closeDeal}) (DealTab);

const ConfirmDialog = (props) => {
  const {t} = props
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>{t("confirm-dialog")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("deal-close-warn")}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={clickClose}>
          {t("close")}
        </Button>
        <Button variant="info" onClick={clickCancel}>
        {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
