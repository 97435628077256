import { renewRentUrl, subscribeBotUrl, templateRentUrl } from "./const";
import network from "./network";

export const sendCryptoPaymentConfirmRequest = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .post(templateRentUrl, params, dispatch, getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
export const sendRenewRentRequest = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .post(renewRentUrl, params, dispatch, getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
export const subscribeBot =
  (template,method,plan,paymentMethodId,cardHolder) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
          subscribeBotUrl,
          {
            template_id: template.id,
            payment_method_id: paymentMethodId,
            payment_method: method,
            card_holder: cardHolder,
            type:plan,
          },
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message, user_subscription } = res.data;
          if (code == 200) {
            // dispatch({
            //   type: UPDATE_SUBSCRIPTION,
            //   payload: user_subscription,
            // });
            resolve();
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };