import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME, SET_RENT_BOT_NAME } from "../../../../actions/create-bot-type";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackButton from "../../../common/BackButton";

const BotTitle = (props)=>{
    const {rent} = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newBot = useSelector((state) => state.createBot.newBot);
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const botName = rent?rentBot.name:newBot.name;
    const setBotName = (val) => {
        dispatch({
          type: rent?SET_RENT_BOT_NAME: SET_BOT_NAME,
          payload: val,
        });
      };
    
    return(
        <div className="cb-form-div">
         <div className="col-lg-4">
              <div className="ba-asset-left">
                <h6>Bot Name</h6>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ba-asset-right">
              <input
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        className=""
                        type="text"
                    />
              </div>
            </div>
        </div>
    )
}

export default BotTitle;