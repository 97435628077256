import React from "react";
import AddIndicatorView from "../AddIndicatorView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_FREQUENCY } from "../../../../actions/create-bot-type";
import { Frequencies, FrequenciesProDca, FrequencyValues, FrequencyValuesProDca, SUB_TIER } from "../../../../Const";
import DcaMartingaleView from "./DcaMartingaleView";
import { checkSub } from "../../../../utils/SubUtils";

const DcaIndicatorEntry = (props) => {
  const { newBot,isEntry,t } = props;
  const frequency = newBot.frequency;
  const tradingViewCheck = isEntry ?newBot.tvAlertEntryStatus:newBot.tvAlertExitStatus;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const isPro = subPremium && subTier === SUB_TIER.pro || subPremium && subTier === SUB_TIER.master;
  const dispatch = useDispatch();
  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const formartFrequency = (value) => isPro?FrequenciesProDca[value]:Frequencies[value];
  return (
    <div>
      <AddIndicatorView newBot={newBot} isEntry={true} t={t}/>
      {!tradingViewCheck && <>
      <div style={{ marginTop: 20 }}>
        {isPro?<> 
        <SliderView
          title={t("tf")}
          max={4}
          value={FrequencyValues.indexOf(frequency) < 0 ? FrequencyValues.indexOf(15) : FrequencyValues.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(FrequencyValues[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")}  />}
        />
        </>:<>
        <SliderView
          title={t("tf")}
          max={4}
          value={FrequencyValuesProDca.indexOf(frequency) < 0 ? FrequencyValuesProDca.indexOf(5) : FrequencyValuesProDca.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(FrequencyValuesProDca[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")}  />}
        />
        </>}
      </div>
      <DcaMartingaleView dcaMartingale={newBot.dcaMartingale} t={t}/> </>}
    </div> 
  );
};

export default DcaIndicatorEntry;
