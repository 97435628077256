import React, { useEffect } from "react";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import { restrictAlphabets } from "../../../../utils/Utils";

const MacdSetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;

  const [fast, bindFast, setFast] = useInput(indicator.period_num, 0);
  const [slow, bindSlow, setSlow] = useInput(indicator.value2, 0);
  const [signal, bindSignal, setSignal] = useInput(indicator.value3, 0);

  useEffect(() => {
    setFast(indicator.period_num);
    setSlow(indicator.value2);
    setSignal(indicator.value3);
  }, [indicator]);

  useEffect(() => {
    updateIndicator({
      ...indicator,
      period_num: fast,
      value2: slow,
      value3: signal,
    });
  }, [fast, slow, signal]);
  return (
    <div>
      <h6>{isLong ? "Long" : "Short"} Strategy</h6>
      <div className="d-flex justify-content-between indicator-setting-form align-items-center above-buy-price above-buy-price2">
          <div><label className="indicator-setting-label">{t("Fast Period")}</label></div>
          <div> <input type="number" {...bindFast} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
     
      <div className="d-flex justify-content-between indicator-setting-form align-items-center above-buy-price above-buy-price2">
          <div><label className="indicator-setting-label">{t("Slow Period")}</label></div>
          <div> <input type="number" {...bindSlow} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      
      <div className="d-flex justify-content-between indicator-setting-form align-items-center mb-2 above-buy-price above-buy-price2">
          <div><label className="indicator-setting-label">{t("Signal Period")}</label></div>
          <div> <input type="number" {...bindSignal} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
    </div>
  );
};

export default MacdSetting;
