import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { formatDate } from "../../../utils/Utils";
const TemplateImage = (props)=>{
    const {template,t} = props;
    return (
        <>
            <Col xs={12}>
                <img src={template.image} alt="" className="template-detail-image"/>
            </Col>
            
        </>
    )
}
export default connect(null,{})(TemplateImage)