import React, { useEffect, useState } from "react";
import {
  Modal,
  DropdownButton,
  Form,
  Button,
  Dropdown,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import "./TransferModal.scss";
import { connect, useSelector } from "react-redux";
import { getAvailable, accountTransfer } from "../../actions/terminal-action";
import { AccountType, AccountName } from "../../Const";
import useInput from "../hooks/useInput";
import { roundNumber } from "../../utils/Utils";
import { toast } from "react-toastify";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";

const Accounts = [AccountName.spot, AccountName.cross, AccountName.isolated];
const Types = [AccountType.spot, AccountType.cross, AccountType.isolated];
const TransferModal = (props) => {
  const {
    show,
    onHide,
    baseCoin,
    quoteCoin,
    getAvailable,
    pairId,
    accountTransfer,
    setLoading,
    account,
    t
  } = props;
  const coins = [baseCoin, quoteCoin];

  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(1);
  const [coin, setCoin] = useState(baseCoin);
  const [available, setAvailable] = useState(0);
  const [amount, bindAmount, resetAmount, setAmount] = useInput("0");
  const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const spot = useSelector((state) => state.terminal.spot) || [];
  const cross = useSelector((state) => state.terminal.cross) || [];
  const isolated = useSelector((state) => state.terminal.isolated) || [];
  const balances = [spot, cross, isolated];

  useEffect(() => {
    getAvailable(exchangeId);
  }, [exchangeId, show]);

  useEffect(() => {
    const positions = balances[from];
    const balance = positions.find((el) => el.coin_name === coin);
    if (balance) {
      setAvailable(balance.amount);
    } else {
      setAvailable(0);
    }
  }, [coin, from, balances]);

  const clickMax = () => {
    setAmount(available);
  };

  const clickTransfer = () => {
    setLoading(true);
    const params = {
      exchange_id: exchangeId,
      pair_id: pairId,
      from: Types[from],
      to: Types[to],
      coin,
      amount,
      account,
    };
    accountTransfer(params)
      .then(() => {
        setLoading(false);
        toast.success("Transfer success");
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const swapAccount = () => {
    const prevFrom = from;
    setFrom(to);
    setTo(prevFrom);
  };

  // const fromItems = Accounts.map((item, index) => {
  //   return (
  //     <Dropdown.Item key={index} eventKey={index}>
  //       {item}
  //     </Dropdown.Item>
  //   );
  // });
  const fromItems = Accounts.map((item, index) => {
    return (
      <option key={index} value={index} selected={item===Accounts[from]?"selected":""}>
        {item}
      </option>
    );
  });

  const toItems = Accounts.map((item, index) => {
    return (
      <option key={index} value={index} selected={item===Accounts[to]?"selected":""}>
        {item}
      </option>
    );
  });

  const coinItems = coins.map((item) => {
    return (
      <option key={item} value={item} selected={item===Accounts[to]?"selected":""}>
      {item}
    </option>
    );
  });

  return (
    <Modal show={show} onHide={onHide} id="trans">
      <Modal.Header closeButton>
        <Modal.Title>{t("transfer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2 mb-2">
          <Col className="trans-from">{t("from")}</Col>
          <Col className="trans-icon"></Col>
          <Col className="trans-to">{t("to")}</Col>
        </Row>
        <Row>
          <Col className="trans-from">
            <select
                onChange={(e) => setFrom(Number(e.target.value))}
                className="form-control select-normal  mb-4"
              >
                {fromItems}
              </select>
          </Col>
          <Col className="trans-icon">
            <i className="fas fa-exchange-alt" onClick={swapAccount}></i>
          </Col>
          <Col className="trans-to">
            <select
                onChange={(e) => setTo(Number(e.target.value))}
                className="form-control select-normal  mb-4"
              >
                {toItems}
              </select>
          </Col>
        </Row>
          <LeftRightForm
            left={<div className="">{t("sel-coin")}:</div>}
            right={ <div className="">
            <select
                onChange={(e) => setCoin(e.target.value)}
                className="form-control select-normal"
              >
                {coinItems}
              </select>
          </div>}
          ></LeftRightForm>
          <LeftRightForm
            left={<Form.Label>{t("amount")}</Form.Label>}
            right={ <div className="">
              <Form.Control as="input" type="number" {...bindAmount} className="input-text"/>
              <p>{t("available")}: {roundNumber(available)} {coin}</p>
          </div>}
          ></LeftRightForm>
        
        
        <div className="text-center m-4">
          <Button
            variant="outline-primary"
            className="w-75"
            onClick={clickTransfer}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, { getAvailable, accountTransfer })(TransferModal);
