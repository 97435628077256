import React from "react";
import { Col, Row } from "react-bootstrap";

const LeftRightForm = (props)=>{
    const { left, right,leftClass="",rightClass="",className="" } = props;
    return(
        <>
            <div className={`cb-form-div ${className}` }>
                <div className="col-lg-4">
                    <div  className={`ba-asset-left ${leftClass}`}>
                        {left}
                    </div>
                </div>
                <div className="col-lg-8">
                    <div  className={`ba-asset-right ${rightClass}`}>
                        {right}
                    </div>
                </div>
            </div>
        </>
    )
}
export default LeftRightForm;