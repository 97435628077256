import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
//import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_PAIR, CHANGE_RENT_PAIR, FUTURE_TYPE_RENT } from "../../../actions/create-bot-type";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import { futurePairName } from "../../../utils/Utils";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";
import CustomRadio from "../../bots/new-bot/modules/CustomRadio";
import { Trans } from "react-i18next";

const AssetViewRentFutures = (props) => {
  const { getBalance ,selectedExchange,t} = props;
  const rentBot = useSelector((state) => state.createBot.rentBot);
  const [baseCoin, setBaseCoin] = useState("perpetual");
  const coinMDeliver = useSelector((state) => state.bots.coinMDeliver);
  const coinMPerpetual = useSelector((state) => state.bots.coinMPerpetual);
  
  const usdMDeliver = useSelector((state) => state.bots.usdMDeliver);
  const usdMPerpetual = useSelector((state) => state.bots.usdMPerpetual);
  const [changeBase, setClickChangeBase] = useState(false);
  const dispatch = useDispatch();
  const futureType = rentBot.futureType?rentBot.futureType:"usd_m";
  //const selectedExchange = rentBot.exchange;
  const pairList = useMemo(() => {
    return (baseCoin === "deliver" && futureType === "coin_m")
      ? coinMDeliver
      : (baseCoin === "perpetual" && futureType === "coin_m")
      ? coinMPerpetual
      : (baseCoin === "deliver" && futureType === "usd_m")
      ?usdMDeliver:usdMPerpetual;
  }, [baseCoin,futureType]);
  const pairItems =
  (pairList &&
    pairList.map((item) => ({
      value: item.id,
      label: `${futurePairName(item.pair)}`,
      from: item.pair.from,
      to: item.pair.to,
    }))) ||
  [];
  const pair = rentBot.pair;
  const selectedPair = {
    value: rentBot.exchangePairId,
    label: `${futurePairName(pair)}`,
    from: pair.from,
    to: pair.to,
  };

  useEffect(()=>{
     getBalance(selectedExchange.id)
      if(changeBase){
        if(pairList[0]){
          dispatch({
            type: CHANGE_RENT_PAIR,
            payload: pairList[0],
          });
        }
      }
  },[baseCoin,futureType])

  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,futureType);
    dispatch({
      type: CHANGE_RENT_PAIR,
      payload: newPair,
    });
  };
  const futureTypeChanged = (value) => {
    dispatch({
      type: FUTURE_TYPE_RENT,
      payload: value,
    });
  };


  return (
    <>
      
            <LeftRightForm
              left={<> {t("exchange")} <TutorialPopup content={t("exchange-help")} /></>}
              right={<><img
                src={selectedExchange.image}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {selectedExchange.name}
              </span></>}
            >
            </LeftRightForm>
            
            <LeftRightForm
              left={<> {t("future-type")} </>}
              right={<>
               <Row>
                <Col  xs={12} lg={6} className="mt-4 d-flex jcb">
                      <CustomRadio
                      type="radio"
                      name="future_type"
                      label={t("USD(S)-M")}
                      val={"usd_m"}
                      checked={futureType==="usd_m"}
                      callback={() => futureTypeChanged("usd_m")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="future_type"
                      label={t("COIN-M")}
                      val={"coin_m"}
                      checked={futureType==="coin_m"}
                      callback={() => futureTypeChanged("coin_m")}
                    ></CustomRadio>
                    </Col>
                  </Row>
                </>}
            >
            </LeftRightForm>
            <br></br>
            <LeftRightForm
              left={<> {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} /> </>}
              right={<>
              <Select
                    value={selectedPair}
                    options={pairItems}
                    onChange={handleSelectPair}
                    className='react-select-container third-step'
                    classNamePrefix='react-select'
                  />
                  <div className="mt-4 d-flex jcb future-type-div">
                  <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Perpetual")}
                      val={"perpetual"}
                      checked={baseCoin==="perpetual"}
                      callback={() => setBaseCoin("perpetual")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Delivery")}
                      val={"deliver"}
                      checked={baseCoin==="deliver"}
                      callback={() => setBaseCoin("deliver")}
                    ></CustomRadio>
                  </div>
                  <Form.Label>
                    <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
                  </Form.Label>
                </>}
            >
            </LeftRightForm>
         
    </>
  );
};

export default connect(null,{getBalance})(AssetViewRentFutures);
