import React, { useEffect } from "react";
import { Card, Accordion, Form } from "react-bootstrap";
import AddIndicatorView from "./AddIndicatorView";
import ExitBotIndicatorView from "./exit-bot/ExitBotIndicatorView";
import PriceEntryView from "./price-bot/PriceEntryView";
import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { BotType } from "../../../Const";
import GridEntryView from "./grid-bot/GridEntryView";
import SellBotEntryView from "./sell-bot/SellBotEntryView";
import InterArbitrageEntryView from "./inter-arbitrage-bot/InterArbitrageEntryView";
import IntraArbitrageEntryView from "./intra-arbitrage-bot/IntraArbitrageEntryView";
import { DAISY_CHAIN, FIRST_IN_DAISY_CHAIN } from "../../../actions/create-bot-type";
import { useDispatch, useSelector } from "react-redux";
import { getDaisyChainBots } from "../../../actions/bot-action";
import Switch from "react-switch";
import LeftRightForm from "./modules/LeftRightForm";
import CustomSwitch from "./modules/CustomSwitch";

const EntryConditionsView = (props) => {
  const { newBot,t } = props;
  const isExitBot = newBot.botType==BotType.exit;
  const priceBot = newBot.botType==BotType.price;
  const daisyChain = newBot.daisyChain;
  const firstInDaisyChain = newBot.firstInDaisyChain;
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const isAdvanced = newBot.botType==BotType.advance;
  const dispatch = useDispatch();
  const changeDaisyChain = (val) => {
    dispatch({
      type: DAISY_CHAIN,
      payload: val,
    });
    if(val==1){
      dispatch(getDaisyChainBots(selectedExchangeId))
    }
  };
  useEffect(() => {
    if(daisyChain==1){
      dispatch(getDaisyChainBots(selectedExchangeId))
    }
  }, [daisyChain]);
  const changeFirstInDaisyChain = (val) => {
    dispatch({
      type: FIRST_IN_DAISY_CHAIN,
      payload: val,
    });
  };
  const getEntryView = ()=>{
    switch (newBot.botType) {
      case BotType.exit:
        return <ExitBotIndicatorView newBot={newBot} isEntry={true} t={t}/>
      case BotType.price :
        return <PriceEntryView newBot={newBot}  isEntry={true} t={t}/>
      case BotType.grid :
        return <GridEntryView newBot={newBot}  isEntry={true} t={t}/>
      case BotType.sell :
        return <SellBotEntryView newBot={newBot}  isEntry={true} t={t}/>
      case BotType.inter_arbitrage :
        return <InterArbitrageEntryView newBot={newBot}  isEntry={true} t={t}/>
      case BotType.intra_arbitrage :
        return <IntraArbitrageEntryView newBot={newBot}  isEntry={true} t={t}/>
      default:
        return <AddIndicatorView newBot={newBot} isEntry={true} t={t}/>
    }
  }
  const getEntryTitleHelp = ()=>{
    switch (newBot.botType) {
      case BotType.exit:
        return t("entryConditionExitBot-help")
      case BotType.price :
        return t("entryConditionPriceBot-help")
      case BotType.inter_arbitrage :
        return t("arb-entry-help")
      case BotType.intra_arbitrage :
        return t("arb-entry-help")
      default:
        return t("entryCondition-help")
    }
  }
  return (
    <div className="ba-asset-form-content">
          <div className="row gx-0 gy-3">
      
          {isAdvanced &&<>
            <LeftRightForm 
              left={<h6>Daisy-Chain</h6>}
              right={
                    <CustomSwitch checked={daisyChain==1} onChange={changeDaisyChain}></CustomSwitch>
                    }>
              </LeftRightForm>
              {daisyChain==1 && 
                <div className="mt-4">
                <LeftRightForm 
                  left={<h6>First in Daisy-Chain</h6>}
                  right={<CustomSwitch checked={firstInDaisyChain==1} onChange={changeFirstInDaisyChain}></CustomSwitch>}>
                  </LeftRightForm></div>}
            </>
            }
            { getEntryView()}
          
    </div>
    </div>
  );
};

export default EntryConditionsView;
