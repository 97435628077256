import React from "react";
//import "./ExitBotIndicatorView.scss";
import {CHANGE_MIN_TP, CHANGE_SELL_PRICE, CHANGE_TAKE_PROFIT} from "../../../../actions/create-bot-type";
import { connect , useDispatch} from "react-redux";
import { Card,Accordion,Form, InputGroup } from "react-bootstrap";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { Trans } from "react-i18next";
import AddIndicatorView from "../AddIndicatorView";
import SliderView from "../SliderView";
import LeftRightForm from "../modules/LeftRightForm";

const SellBotExitView = (props) => {
  const { newBot,t } = props;
  const isBuy = newBot.strategy=='Long';
  const side = isBuy ? t("sell") : t("buy");
  const actionTrigger = isBuy ? t("more") : t("less");
  const maxVal = 100
  const dispatch = useDispatch();
  const changeSellPrice = (val) => {
    dispatch({
      type: CHANGE_SELL_PRICE,
      payload: val,
    });
  };
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
  }
  const minTpChanged = (val) => {
    if(val > maxVal)
    return
    dispatch({
      type: CHANGE_MIN_TP,
      payload: val,
    });
  };
  const formatTakeProfit = (value) => (value == 0 ? t("disable") : `${value}%`);
  const formatDecimal = (value,decimals) => parseFloat(Number(value).toFixed(decimals));
  return (
    <>
      
      
          <LeftRightForm 
          left={ <h6>
            <Trans i18nKey={"sell-if-price"}></Trans>
            </h6>}
          right={<label data-text={newBot.quoteCoin} className="input-gp-label">
          <Form.Control
              as="input"
              type="number"
              placeholder="0"
              value={newBot.sellPrice}
              onChange={(e) => {
                changeSellPrice(e.target.value);
              }}
              onKeyPress={(e) => {
                restrictAlphabets(e);
              }}
              className="input-text input-gp-tex"
            ></Form.Control>
      </label>}
          >
          </LeftRightForm>
          <div style={{ marginTop: 20 }}>
              <AddIndicatorView newBot={newBot} isEntry={false} t={t}/>
          </div>
          <div style={{ marginTop: 20 }}>
          <SliderView
            title={t("min-profit-indiactor")}
            step={0.5}
            min={0}
            max={maxVal}
            value={formatDecimal(newBot.minTp,2)}
            onChange={minTpChanged}
            formatLabel={formatTakeProfit}
            hideLabel={true}
            withInput={true}
          />
          </div>
            
    </>
  );
};

export default connect(null)(SellBotExitView);
