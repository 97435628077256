import React, { useEffect, useState } from "react";
import "./SendEmail.scss";
import { sendAffiliateInvitationEmail } from "../../../actions/affiliate-action";
import useInput from "../../hooks/useInput";
import { validateEmailList } from "../../../utils/Validation";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

const SendEmail = (props) => {
  const { sendAffiliateInvitationEmail, setLoading, email, done, isResend } =
    props;
  const [emails, bindEmails, resetEmails] = useInput(email || "");
  const [message, bindMessage, _, setMessage] = useInput("");
  const [disableSendEmail, setDisableSendEmail] = useState(true);
  const code = useSelector((state) => state.auth.user.referral_code);
  const link = "https://pro.cryptohero.ai/referral?ref=" + code;
  const shareMessage = `Hey friend,

I am using CryptoHero to help me trade the crypto market 24/7. Signup with this link to get US$5 off your subscription (credited as a $5 Reward after your subscription):
  
${link}
  
To know more, go to https://www.cryptohero.ai
  
Cheers.`;
  useEffect(() => {
    setMessage(shareMessage);
  }, [code]);
  const clickSendEmails = (e) => {
    e.preventDefault();
    if (emails.length == 0) return toast.error("Please enter emails");
    if (message.length == 0) return toast.error("Please enter a message");
    const emailList = emails.split(",").map((el) => el.trim());
    if (!validateEmailList(emailList))
      return toast.error("Please enter a valid email");
    setLoading(true);
    sendAffiliateInvitationEmail(emailList, message, isResend || false)
      .then((msg) => {
        setLoading(false);
        resetEmails();
        toast.success(msg.length == 0 || !msg ? "Email sent" : msg);
        done && done();
      })
      .catch((err) => {
        setLoading(false);
        toast.success(err);
      });
  };
  return (
    <form className="send-email">
      <div>
        <label>Email</label>
        <br />
        <input {...bindEmails} />
      </div>
      <div style={{ marginBottom: 30 }}>
        <label>Message</label>
        <br />
        <textarea {...bindMessage} />
      </div>
      <ReCAPTCHA
        sitekey="6LergcUbAAAAAHAr-R9sqSPKEH6IhCBB7L1nIcU9"
        onChange={() => setDisableSendEmail(false)}
      />
      <button
        className="btn"
        onClick={clickSendEmails}
        disabled={disableSendEmail}
      >
        Send
      </button>
    </form>
  );
};
export default connect(null, { sendAffiliateInvitationEmail })(SendEmail);
