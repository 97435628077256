import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { changePassword, resetUpdatePass } from "../../actions/auth-action";
import { changeName } from "../../actions/setting-action";
import GADialog from "../common/GADialog";
import "./AccountPanel.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";

const labelWidth = 3;

const AccountPanel = (props) => {
  const { changePassword, resetUpdatePass,changeName } = props;
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [showGADialog, setShowGADialog] = useState(false);
  const {t} = useTranslation()
  
  

  const email = useSelector((state) => state.auth.user.email);
  const name = useSelector((state) => state.settings.user.name?state.settings.user.name:state.auth.user.name);
  const hasPassword = useSelector((state) => state.auth.user.has_password);
  const updatePassStart = useSelector((state) => state.auth.updatePassStart);
  const updateProfileStart = useSelector((state) => state.settings.updateProfileStart);
  const gaEnabled = useSelector((state) => state.settings.gaEnabled) || false;
  const updatePassSuccess = useSelector(
    (state) => state.auth.updatePassSuccess
  );
  const [newName,updateName] = useState(name)
  const updatePassError = useSelector((state) => state.auth.updatePassError);

  useEffect(() => {
    resetUpdatePass();
    return () => {
      resetUpdatePass();
    };
  }, []);

  const clickChangePass = () => {
    if (gaEnabled) {
      setShowGADialog(true);
    } else {
      saveChange();
    }
  };

  const saveChange = () => {
    changePassword({
      old_password: currPass,
      password: newPass,
      password_confirmation: repeatPass,
    });
  };
  const clickChangeName = () => {
    changeName({
      name: newName
    })
      .then(() => {
        toast.success(t("update-success"))
      })
      .catch((err) => toast.error(err));
  };

  return (
    <>
    <div className="setting-general-content">
    <div className="row">
      <Col xl={6}>
        <h5>Your Details</h5>
        <div className="">
          <div className="cw-body settings-body account-panel-card">
              <div className="">
            <LeftRightForm 
              left= {t("email")}
              right={<Form.Control plaintext readOnly defaultValue={email} />}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
            <LeftRightForm 
              left=  {t("name")}
              right={<Form.Control type="text"  value={newName} onChange={(e)=>updateName(e.target.value)} className="input-text"/>}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
            <LeftRightForm 
              left= {<></>}
              right={ <button onClick={clickChangeName} className="blue-button">
              {updateProfileStart ? t("updating") : t("update")}
            </button>}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
            
           
            </div>
          </div>
        </div>
      </Col>
      <Col xl={6}>
      <h5>Update Password</h5>
      <div className="">
          <div className="cw-body settings-body account-panel-card">
      <div className="account-panel round-bg" style={{marginTop:'10px'}}>
        {hasPassword == 1 ? (
          <>
          <LeftRightForm 
              left=  {t("current-p")}
              right={  <Form.Control
                type="password"
                value={currPass}
                onChange={(e) => setCurrPass(e.target.value)}
                className="input-text"
              />}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
          <LeftRightForm 
              left=  {t("new-pass")}
              right={   <Form.Control
                type="password"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                className="input-text"
              />}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
          <LeftRightForm 
              left=   {t("re-pass")}
              right={   <Form.Control
                type="password"
                value={repeatPass}
                onChange={(e) => setRepeatPass(e.target.value)}
                className="input-text"
              />}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
             <LeftRightForm 
              left= {<></>}
              right={   <button onClick={clickChangePass} className="blue-button">
              {updatePassStart ? t("updating") : t("change-pass")}
            </button>}
              leftCol={4}
              rightCol={8}
              className={"settings-panel"}
            ></LeftRightForm>
            
           
            <Row className="justify-content-center mt-2">
              <Alert
                show={updatePassError || updatePassSuccess}
                variant={updatePassError ? "danger" : "success"}
              >
                {updatePassError || t("update-success")}
              </Alert>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
      </div>
      </div>
      </Col>
      </div>
    </div>
    
      
        
     
      <GADialog
        show={showGADialog}
        onHide={() => setShowGADialog(false)}
        success={saveChange}
      />
    </>
  );
};

export default connect(null, { changePassword, resetUpdatePass,changeName })(AccountPanel);
