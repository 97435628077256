import axios from "axios";
import { host } from "../config";
import { signOut } from "./auth-action";

const get = async (url, params, dispatch, getState) => {
  const token = getState().auth.token;
  return new Promise((resolve, reject) => {
    axios
      .get(host + url, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          return dispatch(signOut());
        }
        reject(String(err));
      });
  });
};

const post = (url, body, dispatch, getState,image) => {
  
  //: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
 
  const token = getState().auth.token;
  const headers= image?{ Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }:{ Authorization: `Bearer ${token}` }
  return new Promise((resolve, reject) => {
    axios
      .post(host + url, body, {
        headers: headers
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err && err.response && err.response.status == 401) {
          return dispatch(signOut());
        }
        reject(String(err));
      });
  });
};
const Delete = (url, body, dispatch, getState) => {
  const token = getState().auth.token;
  return new Promise((resolve, reject) => {
    axios
      (
        {
          url:host + url,
          data:body,
          method:'delete',
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err && err.response && err.response.status == 401) {
          return dispatch(signOut());
        }
        reject(String(err));
      });
  });
};

export default {
  get: get,
  post: post,
  delete: Delete
};
