import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getBotDetail, deleteBot, forceEnterOrder } from "../../../actions/bot-action";
import { getDeals } from "../../../actions/deal-action";
import { Row, Col, Modal, Table, Container, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import BotDealTab from "./BotDealTab";
import TradeTab from "./TradeTab";
import { capitalize, decimalOf, normalizeTime } from "../../../utils/Utils";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { toast } from "react-toastify";
import SimpleBotSetting from "./SimpleBotSetting";
import { BotType } from "../../../Const";
import AdvanceBotSetting from "./AdvanceBotSetting";
import DcaBotSetting from "./DcaBotSetting";
import CustomPagination from "../../common/CustomPagination";
import RentBotSetting from "./RentBotSetting";
import GridBotSetting from "./GridBotSetting";
import InterArbitrageBotSetting from "./InterArbitrageBotSetting";
import IntraArbitrageBotSetting from "./IntraArbitrageBotSetting";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../common/ContentContainer";
import BackButton from "../../common/BackButton";
import LeftRightForm from "../new-bot/modules/LeftRightForm";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const BotDetail = (props) => {
  const { show, getBotDetail, getDeals, deleteBot,itemsPerPage,pageNum,selectedTab,selectedExchangeId,forceEnterOrder } = props;

  const [activeTab, setActiveTab] = useState(0);
  const { botId } = useParams();
  const history = useHistory()
  const {t} = useTranslation()
  const dealPerPage = 50;
  const [setLoading, bindLoading] = useLoading(false);
  const bot = useSelector((state) => state.bots.bot) || {};
  const deals = useSelector((state) => state.deals.deals) || [];
  const startItem = useSelector((state) => state.deals.startItem) || 1;
  const totalDeals = useSelector((state) => state.deals.totalDeals);
  const theme = useSelector((state) => state.settings.selected_theme);
  const isFuture = useSelector((state) => state.settings.isFuture);
  const botType = bot.type;
  const rfr = useSelector((state) => state.settings.risk_free_rate) || 4;

  //const filterDeals = (deals || []).filter((item) => item.bot_id == botId);
  const filterDeals = (deals || []);
  const exchangePair = bot && bot.exchange_pair;
  const pair = exchangePair && exchangePair.pair;
  const backtest = bot && bot.deals_analysis;
  const totalTrade = (backtest && backtest.total_position) || 0;
  const winTrade = (backtest && backtest.profitable_position) || 0;
  const wlRatio = totalTrade > 0 ? (winTrade * 100) / totalTrade : 0;
  const performance = (backtest && parseFloat(backtest.total_performance)) || 0;
  const returnValue = (backtest && parseFloat(backtest.return_value_avg)) || 0;
  const avgPerformance = totalTrade > 0 ? performance / totalTrade : 0;
  const avgReturn = totalTrade > 0 ? returnValue / totalTrade : 0;
  const strategy = (bot && bot.strategy) || "Long";
  const baseCoin =
    strategy.toLowerCase() == "long" ? pair && pair.to : pair && pair.from;
  const decimalNumber = decimalOf(baseCoin || "USDT");
  const tradeInterval = normalizeTime(
    (backtest && backtest.trade_interval) || 0
  );
  const dealDuration = normalizeTime(
    (backtest && backtest.avg_deal_duration) || 0
  );

  var equities = [0];
  var labels = [""];
  //var equities = [(bot && bot.initial_fund) || 0];
  backtest &&
    backtest.positions.forEach((item) => {
      labels.push(item.deal_trade_histories[0]?.time || "");
      equities.push(item.return_value);
    });

  if (equities.length == 1) {
    equities.push(equities[0]);
  }
  const onHide = ()=>{
    history.goBack()
  }

  useEffect(() => {
    if (botId != -1) {
      setLoading(true);
      getBotDetail(botId)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  }, [botId]);

  useEffect(() => {
    getDeals(selectedExchangeId,50,pageNum,null,botId);
  }, []);

  const clickDeleteBot = () => {
    setLoading(true);
    deleteBot(botId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)
      .then(() => {
        setLoading(false);
        onHide();
        toast.success(t("bot-deleted-msg"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const clickForceEnterOrder = (type) => {
    setLoading(true);
    forceEnterOrder(botId,type)
      .then(() => {
        setLoading(false);
        onHide();
        toast.success(t("success"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const tabTitles = [t("deals"), t("th")];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
       <a
          onClick={() => setActiveTab(index)}
          key={index}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
        >
          {item}
      </a>
    );
  });
  const getSettingsView = ()=>{
    switch (botType) {
      case BotType.simple:
        return <SimpleBotSetting bot={bot} t={t}/>
      case BotType.advance:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.exit:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.price:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.dca :
        return <DcaBotSetting bot={bot} t={t}/>
      case BotType.grid :
        return <GridBotSetting bot={bot} t={t}/>
      case BotType.inter_arbitrage :
        return <InterArbitrageBotSetting bot={bot} t={t}/>
      case BotType.intra_arbitrage :
        return <IntraArbitrageBotSetting bot={bot} t={t}/>
      default:
        return <SimpleBotSetting bot={bot} t={t}/>
    }
  }
  const tabItems = [
    <BotDealTab 
    deals={filterDeals} 
    startItem={startItem}
    itemsPerPage={dealPerPage}
    selectedExchangeId={selectedExchangeId}
    pageNum={pageNum}
    selectedTab={selectedTab}
    />,
    <TradeTab deals={filterDeals} 
    startItem={startItem}
    itemsPerPage={dealPerPage}
    selectedExchangeId={selectedExchangeId}
    pageNum={pageNum}
    selectedTab={selectedTab}
    botType={bot.type}
    />,
  ];

  return (
    <Loading {...bindLoading}>
      <ContentContainer url="bots" title={t("bots")} showExchange={true}>
      <div className="main-right-content">
            <div className="bot-detail-header">
                <h4>{bot.name}</h4>
                <div className="bot-detail-header-btn">
                {bot.force_enter_order =="base" && 
                  <button onClick={()=>clickForceEnterOrder("base")}>{t("enter-b-order")}</button>}
                  <button onClick={clickDeleteBot}>{t("Delete")}</button>
                  {bot.force_enter_order =="extra" && 
                  <button onClick={()=>clickForceEnterOrder("extra")}>{t("enter-extra-order")}</button>}
                </div>
            </div>
            <div className="bot-detail-content-area">
								<div className="row g-4">
                {!bot.rent_id ? (
                      getSettingsView()
                      )  : (
                        <RentBotSetting bot={bot} />
                      )}
									<div className="col-lg-6 col-md-6">
										<div className="bot-detail-left">
											<div className="bot-detail-left-header">
												<label>
													<img src="/images/return-icon.svg" alt=""/>
													Performance
												</label>
												<label>
													<span className={performance>=0?"text-success":"text-danger"}>{isFuture?<> {performance.toFixed(decimalNumber)} {exchangePair && exchangePair.future_type==="usd_m"?"USD":baseCoin} (
                                      {returnValue.toFixed(2)}%)</>:<>{performance.toFixed(decimalNumber)} {baseCoin} (
                                      {returnValue.toFixed(2)}%)</>}</span>
												</label>
											</div>
											<div className="bot-detail-left-body">
												<div className="row">
													<div className="col-6">
														<div className="bdol-item">
															<span >{t("APY")}</span>
															<h6 className={bot.deals_analysis && bot.deals_analysis.apy>=0?"text-success":"text-danger"}>{bot.deals_analysis && bot.deals_analysis.apy} %</h6>
														</div>
													</div>
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("w-l-ratio")}</span>
															<h6 className={wlRatio>=0?"text-success":"text-danger"}>{wlRatio.toFixed(2)}%</h6>
														</div>
													</div>
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("deals")}</span>
															<h6>{totalDeals}</h6>
														</div>
													</div>
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("a-deal-prof")}</span>
															<h6 className={avgPerformance>=0?"text-success":"text-danger"}>{avgPerformance.toFixed(decimalNumber)} {baseCoin} (
                                      {avgReturn.toFixed(2)}%)</h6>
														</div>
													</div>
													
													<div className="col-6">
														<div className="bdol-item">
															<span>{t("sharpe-ratio")}</span>
															<h6 className={bot.deals_analysis && bot.deals_analysis.sharpe_ratio>=0?"text-success":"text-danger"}>{bot.deals_analysis && bot.deals_analysis.sharpe_ratio}</h6>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
              <div className="bot-detail-chart-area">
                <div className="bot-detail-left-header"> <label>
                <img src="/images/return-icon.svg" alt=""/>
                Returns
              </label></div>
             
              <Line height={70} data={data(equities, labels)} options={options} />
							</div>
              <div className="bot-detail-table-area">
							  <ul className="skltbs-tab-group">
												{tabHeaders}
								</ul>
                {tabItems[activeTab]}
                  
                  <CustomPagination
                    startItem={startItem}
                    botId={botId}
                    onChange={getDeals}
                    totalCount={totalDeals}
                    selectedTab={"completed"}
                    itemsCountPerPage={dealPerPage}
                  />
								
							</div>
            </div>
            </ContentContainer>
    </Loading>
  );
};

export default connect(null, {
  getBotDetail,
  getDeals,
  deleteBot,
  forceEnterOrder
})(BotDetail);

const data = (equities,labels) => {
  return {
    labels: labels, 
    datasets: [
      {
        type: "line",
        label: "Returns $",
        data: equities,
        fill: false,
        backgroundColor: "#9381FF",
        borderColor: "#9381FF",
        hoverBackgroundColor: "#9381FF",
        hoverBorderColor: "#9381FF",
      },
    ],
  };
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false //this will remove only the label
        },
        gridLines: {
          color: "#999"
        }
    }],
    yAxes: [{
        ticks: {
          fontColor: "#999",
        },
        gridLines: {
          color: "#999"
        }
    }]
}
 
};
