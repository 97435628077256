import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import GridBotParamsView from "./grid-bot/GridBotParamsView";
import TradeParamsView from "./TradeParamsView";
import { BotType } from "../../../Const";
import EntryView from "./modules/EntryView";
import ExitView from "./modules/ExitView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AssetViewModule from "./AssetViewModule";
import TradeParamsViewModule from "./TradeParamsViewModule";

const CreateBotSteps = (props)=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const newBot = useSelector((state) => state.createBot.newBot);
    const isEditing  = newBot.isEditing;
    const [activeTab,setActiveTab] = useState(0);
    const stepTitles = ['Asset','Trade Parameters','Entry Conditions','Exit Conditions',isEditing?'Update Bot':'Create Bot'];
    const changeTab = (type)=>{
        if(type=="next"){
            setActiveTab(activeTab+1)
        }
        if(type=="prev"){
            setActiveTab(activeTab-1)
        }
    }
    const steps = stepTitles.map((item,index)=>{
        return (
            <li key={index}>
                <a onClick={()=>setActiveTab(index>3?3:index)} className={`pointer`}>
                    <label className={activeTab>=index||activeTab==3?'active':''}><img src={`/images/cb-step-${index}.svg`} alt=""/></label>
                    {item}
                </a>
            </li>
        )
    })
    const tabs = [
        <AssetViewModule newBot={newBot} t={t} step={changeTab}/>,
        <TradeParamsViewModule newBot={newBot} t={t} step={changeTab}/>,
          <EntryView step={changeTab}></EntryView>,
          <ExitView step={changeTab}></ExitView>
        
    ];
    return(
        <>
            <div className={`bot-advance-asset-tab ba-tab${activeTab}`}>
            <Link to="/bots" className="cb-quit"><i className="fa-sharp-duotone fa-solid fa-xmark"></i></Link>
                <ul className="bc-step-icons">
                    {steps}
                </ul>
            </div>
            <div className="ba-asset-form-area ec-from">
                {tabs[activeTab]}
            </div>
        </>
      );
}

export default connect(null,{})(CreateBotSteps)