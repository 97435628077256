import React, { useEffect } from "react";
import useCheckbox from "../../../hooks/useCheckbox";
const IsRequiredModule = (props) => {
  const { indicator, updateIndicator,t } = props;
  const [isRequired,bindIsRequired, setIsRequired] = useCheckbox(
    indicator.is_compulsory?indicator.is_compulsory:false
  );

  useEffect(() => {
    updateIndicator({
      ...indicator,
      is_compulsory: isRequired?1:0,
    });
  }, [isRequired]);

  useEffect(() => {
    setIsRequired(indicator.is_compulsory);
  }, [indicator]);

  return (
      <div>
        <input type="checkbox" id="required_check" {...bindIsRequired} className="custom-checkbox"  
                checked={isRequired}>
            </input>
            <label for="required_check" className="checkbox-label">{t("required")}</label>
      </div>
      
  );
};

export default IsRequiredModule;
