import {
  GET_ACCOUNT,
  GET_TICKERS,
  SELECT_PAIR,
  GET_POSITIONS,
  GET_ORDER_LIST_OPEN,
  GET_ORDER_HISTORY,
  GET_SPOT,
  GET_CROSS,
  GET_ISOLATED,
  CHANGE_ACCOUNT,
  RESET_TICKERS,
  GET_EXECUTED_ORDERS,
} from "../actions/types";
import { AccountType } from "../Const";

const initState = {
  selectedPair: {
    realPairId: 11,
    from: "BTC",
    to: "USDT",
    s: "BTCUSDT",
  },
  tickers: [],
  lastTickers: [],
  base: {},
  quote: {},
  positions: [],
  openOrders: [],
  orderHistory: {},
  spot: [],
  cross: [],
  isolated: [],
  risk: 999,
  account: AccountType.spot,
  executed_orders: [],
};

const terminalReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_TICKERS:
      if (payload.id) {
        return state;
      }
      const tickers = [...state.tickers];
      const lastTickers = [...(state.lastTickers || [])];
      // console.log(tickers);
      
      const index = tickers.findIndex(
        (el) => el.s === payload.s && el.exchangeId == payload.exchangeId
      );

      if (index >= 0) {
        const ticker = { ...tickers[index] };
        tickers[index] = {
          ...ticker,
          ...payload,
        };
        const lastTickerIndex = lastTickers.findIndex(
          (el) => el.s === ticker.s && el.exchangeId === ticker.exchangeId
        );
        if (lastTickerIndex >= 0) {
          const lastTicker = { ...lastTickers[lastTickerIndex] };
          lastTickers[lastTickerIndex] = {
            ...lastTicker,
            c: ticker.c,
          };
        } else {
          lastTickers.push({
            s: payload.from+payload.to,
            c: payload.c,
            exchangeId: ticker.exchangeId,
          });
        }
      } else {
        tickers.push(payload);
        lastTickers.push(payload);
      }

      const selectedPair = { ...state.selectedPair };

      return {
        ...state,
        tickers,
        lastTickers,
        selectedPair:
          selectedPair.s === payload.s
            ? { ...selectedPair, ...payload }
            : selectedPair,
      };
    case RESET_TICKERS:
      return {
        ...state,
        tickers: [],
        lastTickers: [],
      };
    case SELECT_PAIR:
      return {
        ...state,
        selectedPair: payload,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        ...payload,
      };
    case GET_POSITIONS:
      return {
        ...state,
        positions: payload,
      };
    case GET_ORDER_LIST_OPEN:
      return {
        ...state,
        openOrders: payload,
      };
    case GET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: payload,
      };
    case GET_SPOT:
      return {
        ...state,
        spot: payload,
      };
    case GET_CROSS:
      return {
        ...state,
        cross: payload,
      };
    case GET_ISOLATED:
      return {
        ...state,
        isolated: payload,
      };
    case CHANGE_ACCOUNT:
      return {
        ...state,
        account: payload,
      };
    case GET_EXECUTED_ORDERS:
      return {
        ...state,
        executed_orders: payload,
      };
    default:
      return state;
  }
};

export default terminalReducer;
