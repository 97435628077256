import React from "react"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom";
import { signOut } from "../../actions/auth-action";
import { checkSub, isPremium, isPremiumOrPro, myCurrentPlan } from "../../utils/SubUtils";
import CustomDropDown from "../../utils/CustomDropDown";

const SettingsDropDown = (props)=>{
    const {signOut}=props
    const email = useSelector((state) => state.auth.user.email);
    const name = useSelector((state) => state.auth.user.name) || email || "User";
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const { subPremium, subTier } = checkSub(subscription);
    const isPaid = isPremium(subscription);
    const upperName = name && name.toUpperCase();
    return (
        <>
            
            <CustomDropDown toggleButtonId="mru-icon1">
                <a href="#" className="mr-user-icon" id="mru-icon1"><i className="fa-solid fa-user"></i></a>
                <>
                    <div className="mr-user-dropdown1-title">
                        <h4>Hi, {upperName} !</h4>
                        <p>Current Plan<br></br> <span>{myCurrentPlan(subscription)}</span></p>
                        {!isPaid && <Link to="/setting?loc=2">Upgrade</Link> }
                    </div>
                    <div className="mr-user-dropdown3-Navitem">
                        <ul>
                            <li>
                            <Link to="/setting?loc=1" className="mr-user-dropdown3-active">
                                    <img src="images/hprofile-icon1.png" alt=""/>
                                    Account
                                </Link>
                            </li>
                            <li>
                                <a href="https://blog.cryptohero.ai/" target="_blank">
                                    <img src="images/hprofile-icon2.png" alt=""/>
                                    Blog
                                </a> 
                            </li>
                            <li>
                                <a href="https://docs.cryptohero.ai/" target="_blank">
                                    <img src="images/hprofile-icon3.png" alt=""/>
                                    User Guide
                                </a>
                            </li>
                            <li>
                                <Link to="/affiliate">
                                    <img src="images/hprofile-icon4.png" alt=""/>
                                    Affiliate
                                </Link>
                            </li>
                            <li>
                                <Link to="/price-alert">
                                    <img src="images/hprofile-icon4.png" alt=""/>
                                    Price Alert
                                </Link>
                            </li>
                            <li>
                                <Link to="/setting">
                                    <img src="images/hprofile-icon5.png" alt=""/>
                                    Settings
                                </Link>
                            </li>
                            <li>
                                <a onClick={() => signOut()} className="pointer" href="#">
                                    <img src="images/hprofile-icon6.png" alt=""/>
                                    Log Out
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
                        </CustomDropDown>
        </>
    )
}

export default connect(null,{signOut})(SettingsDropDown)