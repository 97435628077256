import React, { useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteBotTemplate } from "../../../actions/bot-template-action";
import { SET_BOT_TEMPLATE_EDIT } from "../../../actions/types";
import { adaptTemplateEdit } from "../../bots/new-bot/bot-adapter/template-edit-adapter";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import EditBotTemplate from "../edit/EditBotTemplate";
import TemplateRow from "./TemplateRow";
import ViewTemplateSettings from "./ViewTemplateSettings";

const BotTemplateList = (props)=>{
    const {own_templates,deleteBotTemplate,getOwnTemplates,tab,t} = props;
    //console.log(templates)
    
    const [showEditTemplateModal,setShowEditTemplateModal] = useState(false)
    const [showTemplateSettingsModal,setShowTemplateSettingsModal] = useState(false)
    const [selectedTemplate,setSelectedTemplate] = useState(null)
    const dispatch = useDispatch();
    const [setLoading, bindLoading] = useLoading();
    const clickEditTemplate  = (template)=>{
        //alert('dasd')
        const editTemplate = adaptTemplateEdit(template)
        dispatch({
            type: SET_BOT_TEMPLATE_EDIT,
            payload: editTemplate,
        });
        setShowEditTemplateModal(true)
    }
    const clickViewSettings  = (template)=>{
       
        // dispatch({
        //     type: SET_BOT_TEMPLATE_EDIT,
        //     payload: editTemplate,
        // });
        setSelectedTemplate(template)
        setShowTemplateSettingsModal(true)
    }
    const clickDelete  = (id)=>{
        setLoading(true)
        deleteBotTemplate(id).then(()=>{
            setLoading(false)
            getOwnTemplates()
            toast.success(t("temp-deleted-succ"))
        }).catch((err)=>{
            setLoading(false)
            toast.error(err)
        })
    }
    const botTemplateListItems = (
       
        <Row noGutters className="no-padding">
            {own_templates.map((item, id) => (
                    <TemplateRow template={item}  onEditClick={clickEditTemplate} clickDelete={clickDelete} viewSettings={clickViewSettings} t={t}/>
            ))}
        </Row>
        
    )
    return (
        <>
        <Loading {...bindLoading}>
        <div className="mt-3">
            {own_templates && own_templates.length > 0 ?
            <>{botTemplateListItems}</>
            :
            (<h5 className="mt-5">{tab=="inactive"?t("no-bot-pending-rev"):t("no-bots-active")}</h5>)}
        </div>
        </Loading>
         {showEditTemplateModal && <EditBotTemplate onHide={()=>setShowEditTemplateModal(false)} tab={tab}/>}
         {showTemplateSettingsModal && <ViewTemplateSettings onHide={()=>setShowTemplateSettingsModal(false)} onEditClick={clickEditTemplate} template={selectedTemplate}/>}
        </>
    )
}
export default connect(null,{deleteBotTemplate})(BotTemplateList)