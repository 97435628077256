import React from "react"
import { connect } from "react-redux"
import CustomDropDown from "../../utils/CustomDropDown"

const HelpDropDown = (props)=>{
    return (
        <>
            <CustomDropDown toggleButtonId="mru-icon2">
												<a href="#" className="mr-user-icon" id="mru-icon2"><i className="fa-solid fa-circle-question"></i></a>
												<>
                        <div className="mr-user-dropdown3-Navitem">
                        <ul>
                            <li>
                                <a href="mailto:admin@cryptohero.ai" target="_blank">
                                    <img src="/img/icon-email.svg" alt=""/> &nbsp;&nbsp;&nbsp;Email
                                </a>
                            </li>
                            <li>
                                <a href="https://wa.me/message/XNLAUGBUB2IRJ1" target="_blank">
                                    <img src="/img/icon-wa.svg" alt=""/> &nbsp;&nbsp;&nbsp;Whatsapp
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/novumgroup" target="_blank">
                                    <img src="/img/icon-telegram.svg" alt=""/> &nbsp;&nbsp;&nbsp;Telegram
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.cryptohero.ai/" target="_blank">
                                    <img src="/img/icon-userguide.svg" alt=""/> &nbsp;&nbsp;&nbsp;User Guide
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.cryptohero.ai/faq/frequently-asked-questions" target="_blank">
                                    <img src="/img/icon-faqs.svg" alt=""/> &nbsp;&nbsp;&nbsp;FAQ
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@TradeHeroes_ai/videos" target="_blank">
                                    <img src="/img/icon-instagram.svg" alt=""/> &nbsp;&nbsp;&nbsp;Video
                                </a>
                            </li>
                        </ul>

                        </div>
                        </>
                      </CustomDropDown>
        </>
    )
}

export default connect(null,{})(HelpDropDown)