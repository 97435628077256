import React from "react";
import { Col, Row } from "react-bootstrap";

const Tab1 = (props)=>{
    const {next} = props;
    return(<>
    <p><span className="highlighted-text">Welcome to CryptoHero</span>, the trusted automated crypto trading software used by thousands around the globe! We're excited to have you on board. To help you get started, we've created a simple 4-step Quick Start guide. By the end of it, you'll have your very first trading bot up and running on our risk-free paper exchange —and don't worry, you can always modify or delete it later if needed. Let’s get trading!</p>
     <br></br>
     <h3>Step 1: Connect Your Exchange (optional)</h3>
     <br/>
        <p>In order for CryptoHero to run automated trades, you need to connect your exchange with CryptoHero.</p>
        <p>Please go to our <a href="/setting?loc=3" className="link"><b>Exchange API</b></a> page to add your exchange. After you have added your exchange, return to this Quick Start wizard.</p>
        <br/>
        <ul>
            <li><a  className="qtrblue-btn pointer" onClick={next}>I have connected CryptoHero to my exchange</a></li>
            <li><a  className="qtrtrans-btn pointer" onClick={next}>Skip this step</a></li>
        </ul>
    </>)
}
export default Tab1;