import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Dropdown,DropdownButton } from "react-bootstrap";
import DealTab from "./DealTab";
import DealTradesTab from "./DealTradesTab";
import DealDetail from "./DealDetail";
import { getDeals,getDealTrades, refreshDealStatus } from "../../actions/deal-action";
import { DealStatus,TradeListStatuses,TradeListSides,TradeOrders,TradeOrderNames } from "../../Const";
import ContentContainer from "../common/ContentContainer";
import {getKeyByValue} from "../../utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactStatePagination from "../common/ReactStatePagination";
import { DEALS_ACTIVE_TAB } from "../../actions/types";
import CustomPagination from "../common/CustomPagination";
import BackButton from "../common/BackButton";

const Deals = (props) => {
  const { getDeals,getDealTrades ,refreshDealStatus} = props;
  const [showDetail, setShowDetail] = useState(-1);
  const [completed, setCompleted] = useState(false);
  const [tradeStatus, setTradeStatus] = useState('');
  const [tradeOrderName, setTradeOrderName] = useState('');
  const [tradeSide, setTradeSide] = useState('');
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const tabTitles = [t("open-deals"), t("c-deals"),t("trade-log")];
  const tourClasses = ["open-deals-tour", "completed-deals-tour","trade-logs-tour"];
  const statusArray = ['open','close'] 
  const activeTab = useSelector((state) => state.pagination.dealsActiveTab) || 0;
  const pageNumbers = {
    "open":useSelector((state) => state.pagination.dealsOpenPage),
    "close":useSelector((state) => state.pagination.dealsClosePage),
    "trade":1,
  }
  var deals = useSelector((state) => state.deals.deals) || [];
  var trades = useSelector((state) => state.deals.trades) || [];
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const filters = useSelector((state) => state.settings.filters) || [];
  deals = deals.filter((el) => el.exchange_id == selectedExchangeId);
  const completedStatuses = [
    DealStatus.stopLoss,
    DealStatus.takeProfit,
    DealStatus.exited,
    DealStatus.liquidated,
    DealStatus.error,
  ];
  const status = statusArray[activeTab]
  const itemsPerPage = 10;
  const pageNum=1;
  const completedDeals = (deals || []).filter((deal) =>
    completedStatuses.includes(deal.status_key)
  );
  const incompletedDeals = (deals || []).filter(
    (deal) => !completedStatuses.includes(deal.status_key)
  );
  const totalDeals = useSelector((state) => state.deals.totalDeals) || 0;
  const totalTrades = useSelector((state) => state.deals.totalTrades) || 0;
  const startItem = useSelector((state) => state.deals.startItem) || 1;
  const tradeStartItem = useSelector((state) => state.deals.tradeStartItem) || 1;
  useEffect(() => {
    getDeals(selectedExchangeId,itemsPerPage,pageNumbers[status],status);
    //getDealTrades(selectedExchangeId,itemsPerPage,pageNum,tradeStatus,tradeSide,tradeOrderName);
  }, [selectedExchangeId]);

  useEffect(() => {
    getDealTrades(selectedExchangeId,itemsPerPage,pageNum,tradeStatus,tradeSide,tradeOrderName);
    getDealTrades(selectedExchangeId,itemsPerPage,pageNum,tradeStatus,tradeSide,tradeOrderName);
  }, [selectedExchangeId,tradeStatus,tradeSide,tradeOrderName]);
  
  
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      
        <a
          onClick={() => changeTab(index,index==0?'open':'close')}
          className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )+ " "+ tourClasses[index]}
          key={index}
        >
          {item}
        </a>
      
    );
  });
  const tradeStatusItems = Object.keys(TradeListStatuses).map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={item}>
        {t(TradeListStatuses[item])}
      </Dropdown.Item>
    );
  });
  const tradeSideItems = Object.keys(TradeListSides).map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={item}>
        {t(TradeListSides[item])}
      </Dropdown.Item>
    );
  });
  const tradeOrderNameItems = Object.keys(TradeOrders).map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={index}>
        {t(TradeOrderNames[item])}
      </Dropdown.Item>
    );
  });
  const setActiveTab = (index)=>{
    dispatch({
      type:DEALS_ACTIVE_TAB,
      payload:index
    })
  }
  const changeTab = (index,tab) => {
    setActiveTab(index);
    getDeals(selectedExchangeId,itemsPerPage,pageNum,tab)
  };
  const paginationCallback = (pageNum)=>{
    getDeals(selectedExchangeId,itemsPerPage,pageNum,status);
  }
  const dealTabs = [
    <DealTab
      deals={incompletedDeals}
      t={t}
      completed={false}
      onShowDetail={(id) => {
        setShowDetail(id);
        setCompleted(false);
      }}
      refreshDeal={(id) => {
        return new Promise((resolve,reject)=>{
          refreshDealStatus(id,selectedExchangeId,itemsPerPage,pageNum,status).then((message)=>{
            toast.success(message)
            resolve(message)
          }).catch((error)=>{
            toast.error(error)
            reject(error)
          })
        })
        
      }}
    />,
    <DealTab
      deals={completedDeals}
      t={t}
      completed={true}
      onShowDetail={(id) => {
        setShowDetail(id);
        setCompleted(true);
      }}
    />,
    <DealTradesTab
      t={t}
      trades={trades}
    />
  ];

  const filterItem = filters.map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={index}>
        {item.name}
      </Dropdown.Item>
    );
  });

  return (
    <ContentContainer url="deals" title={t("deals")} showExchange={true}>
      <div className="dashboard-content">
     
        <div className="d-flex justify-content-between">
            <div className="bot-tabs mt-4">
            {tabHeaders}
            </div>
            <div>
            {activeTab == 2 && (<div className="d-flex justify-content-between mt-20">
                  <div className="" >
                  <DropdownButton
                    title={tradeStatus?TradeListStatuses[tradeStatus]:'Status'}
                    onSelect={(index) => setTradeStatus(index)}
                    variant=""
                    className="deals-filter-btn"
                  >
                    {tradeStatusItems}
                  </DropdownButton>
                  </div>
                <div className="" >
                  <DropdownButton
                    title={tradeSide?TradeListSides[tradeSide]:'Side'}
                    onSelect={(index) => setTradeSide(index)}
                    variant=""
                    className="deals-filter-btn"
                  >
                    {tradeSideItems}
                  </DropdownButton>
                </div>
                <div className="">
                  <DropdownButton
                    title={tradeOrderName?TradeOrderNames[getKeyByValue(TradeOrders,tradeOrderName)]:'Order'}
                    onSelect={(index) => setTradeOrderName(TradeOrders[index])}
                    variant=""
                    className="deals-filter-btn"
                  >
                    {tradeOrderNameItems}
                  </DropdownButton>
                </div>
                </div>)}
            </div>
        </div>
      
            <div className="deals-list">
              {dealTabs[activeTab]}
            </div>
            <div className="bottom-center">
                {activeTab != 2 ? ( <ReactStatePagination
                  itemsCountPerPage={itemsPerPage}
                  startItem={startItem}
                  selectedTab={status}
                  forPage="deals"
                  totalCount={totalDeals}
                  onChange={paginationCallback}
                  selectedExchangeId={selectedExchangeId}
                  itemsPerPage={itemsPerPage}
                /> ) : 
                (<CustomPagination
                  itemsCountPerPage={itemsPerPage}
                  startItem={tradeStartItem}
                  totalCount={totalTrades}
                  onChange={getDealTrades}
                  selectedExchangeId={selectedExchangeId}
                  itemsPerPage={itemsPerPage}
                />) }
              </div>
      </div>
    </ContentContainer>
  );
};

export default connect(null, { getDeals,getDealTrades,refreshDealStatus })(Deals);
