import {
  GET_DEALS,
  DEAL_DETAIL,
  CLOSE_DEAL_ERROR,
  CLOSE_DEAL_SUCCESS,
  START_CLOSE_DEAL,
  RESET_DEAL_DETAIL,
  GET_DEAL_TRADES
} from "../actions/types";

const initialState = {
  deals: [],
  deal: {},
  trades: [],
  closeDealSuccess: false,
  closeDealError: null,
  startCloseDeal: false,
  totalTrades: 0,
  tradeStartItem: 1,
};

const dealReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DEALS:
      return {
        ...state,
        deals: payload.deals,
        totalDeals: payload.totalDeals,
        startItem: payload.startItem,
      };
    case GET_DEAL_TRADES:
      return {
        ...state,
        trades: payload.trade_list,
        totalTrades: payload.totalTrades,
        tradeStartItem: payload.startItem,
      };
    case DEAL_DETAIL:
      return {
        ...state,
        deal: payload,
      };
    case START_CLOSE_DEAL:
      return {
        ...state,
        startCloseDeal: true,
        closeDealError: null,
        closeDealSuccess: false,
      };
    case CLOSE_DEAL_SUCCESS:
      return {
        ...state,
        startCloseDeal: false,
        closeDealError: null,
        closeDealSuccess: true,
      };
    case CLOSE_DEAL_ERROR:
      return {
        ...state,
        startCloseDeal: false,
        closeDealError: payload,
        closeDealSuccess: false,
      };
    case RESET_DEAL_DETAIL:
      return {
        ...state,
        startCloseDeal: false,
        closeDealError: null,
        closeDealSuccess: false,
      };
    default:
      return state;
  }
};

export default dealReducer;
