import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_FUND, CHANGE_FUND_SECONDARY, CHANGE_IS_HOLD_CURRENCY } from "../../../../actions/create-bot-type";
import { BotType } from "../../../../Const";
import { formatNumberToString, isBoth, isLong, restrictAlphabets } from "../../../../utils/Utils";
import TutorialPopup from "../../../help/TutorialPopup";
import useCheckbox from "../../../hooks/useCheckbox";
import LeftRightForm from "../modules/LeftRightForm";

const LongFundView = (props)=>{
    const {t,newBot,editing,label} = props;
    const fund = newBot.initFund;
    const strategy = newBot.strategy
    const isStrategyDirty = newBot.isStrategyDirty
    const pairData = useSelector((state) => state.bots.pairData);
    const balances = useSelector((state) => state.bots.balances);
    const balance =
    balances && balances.find((item) => item.coin === newBot.quoteCoin);
    const shortBalance =
    balances && balances.find((item) => item.coin === newBot.baseCoin);
    const calculateHoldFund = (val)=>{
      if(pairData && pairData.current_price){
        setHoldFund((val/pairData.current_price).toFixed(8))
      }
    }
    const dispatch = useDispatch()
    const [holdFund, setHoldFund] = useState(0)
    useEffect(() => {
      calculateHoldFund(fund)
    }, [fund]);
    const fundChanged = (val) => {
      dispatch({
        type: CHANGE_FUND,
        payload: val,
      });
      dispatch({
        type: CHANGE_FUND_SECONDARY,
        payload: 0,
      });
      calculateHoldFund(fund)
    };
    // console.log(calculateHoldFund(fund))
    useEffect(() => {
        if (balance && !editing || (editing && isStrategyDirty)) {
          fundChanged(balance.free);
        }
    }, [strategy]);

    
    
    return(
      <>
      <LeftRightForm
        left={t('fund-alloc')}
        right={<><label data-text={newBot.quoteCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={fund}
            onChange={(e) => {
              fundChanged(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
          {t("available")}: {formatNumberToString(balance && balance.free)}{" "}
          {newBot.quoteCoin}
        </p>
      </>}
      />
      <LeftRightForm
        left={t('fund-alloc')}
        right={<><label data-text={newBot.baseCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={holdFund}
            disabled={true}
            
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
          {t("available")}: {formatNumberToString(shortBalance && shortBalance.free)}{" "}
          {newBot.baseCoin}
        </p>
      </>}
      />
      
      </>
     
    )
}
export default LongFundView

