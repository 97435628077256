import { DcaTime, DcaType } from "../../../../Const";

const dcaExportAdapter = (newBot) => {
  let dcaType = newBot.dcaType;
  let dcaValue = newBot.dcaTime.type;
  let dcaValue2 = newBot.dcaTime.weekValue;
  let dcaValue3 = newBot.dcaMartingale;
  switch (dcaType) {
    case DcaType.time:
      if (dcaValue === DcaTime.weekly) {
        dcaValue2 = newBot.dcaTime.weekValue;
      } else if (dcaValue === DcaTime.monthly) {
        dcaValue2 = newBot.dcaTime.monthValue;
      }
      break;
    case DcaType.indicator:
      break;
    case DcaType.buyDown:
    case DcaType.sellUp:
      dcaType = "percentage";
      dcaValue = newBot.dcaTrigger;
      dcaValue2 = newBot.dcaPercent;
      break;
    default:
      break;
  }
  return {
    dcaType,
    dcaValue,
    dcaValue2,
    dcaValue3,
  };
};

export default dcaExportAdapter;
