import React ,{useState} from "react";
import { connect, useSelector } from "react-redux";
import { Modal,Tabs,Col,Row } from "react-bootstrap";


const TemplateSubmitSuccess = (props)=>{
    const {
        onHide,
    }=props;
    const newTemplate =  useSelector((state) => state.newTemplate.newTemplate)
    return (
        <>
        <Modal show={true} onHide={onHide} size="lg" dialogClassName="" centered>
            <Modal.Header closeButton className="align-items-center no-bottom-border">
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "400px" }}>
                <Row>
                    <Col xs={12} className="text-center"><img src="/images/thanks-for-submitting.png" width={300}></img></Col>
                    
                </Row>
                <Row>
                <Col xs={12} className="text-center thanks-for-submit-text" >
                    <p>Thank you for submitting your Bot “{newTemplate.title}” for inclusion in the CryptoHero Marketplace. Please note that this submission does not guarantee your submission request will be approved. Please give us about three to ten days to review your submission.
                </p>
                </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-center thanks-for-submit-email-info" >
                        <p>We will notify you via email if your bot is approved for the Marketplace</p>
                    </Col>
                </Row>
                        
            </Modal.Body>
        </Modal>
        </>
    )
}
export default connect(null,{})(TemplateSubmitSuccess);