import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import useInput from "../hooks/useInput";
import { deleteExchange, getExchanges } from "../../actions/setting-action";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { isPremium } from "../../utils/SubUtils";
import ViewIpsModal from "./ViewIpsModal";

const ConnectedExchangeCard = (props)=>{
    const {exchange,getExchanges,deleteExchange,t,subscription} = props
    const dropdownRef = useRef(null)
    const [openMenu, setOpenMenu] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [showIps, setShowIps] = useState(false);
    const [ips, setIps] = useState([]);
    const handleClickOutside = (event) => {
        // Check if the click is outside of any bot's menu or button
        if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
        return;
        }
        else{
        setOpenMenu(false)
        }
    };
   
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
   
     const clickDelete = () => {
        deleteExchange(deleteId);
        setDeleteId(0);
      };
      const showIpsModal = (ipList) => {
        setIps(ipList)
        setShowIps(true)
      };
    return(
        <div className="col-xl-4 col-md-6">
					<div className="main-dna-cart main-brokerage-cart">
						<div className="dna-cart-header">
							<ul>
								<li>
									<img src={exchange.image} alt="icon" width={20}/>
									<h6>{exchange.name}</h6>
								</li>
								<li>
									<div className="dropdown" ref={dropdownRef}>
										<i className="fa-solid fa-ellipsis-vertical" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setOpenMenu(exchange.id)}></i>
										<ul className={`dropdown-menu ${openMenu==exchange.id?"show":""} dna-cart-dropdown`} aria-labelledby="dropdownMenuButton1">
											  
											
											<li>
												<a className="dropdown-item" href="#" onClick={() => {
                            setDeleteId(exchange.id);
                          }}>
													<img src="/images/se-cart-icon3.png" alt=""/>
													Delete
												</a>
											</li>
                                            {(exchange.ip_list.length > 0 && isPremium(subscription)) &&
											<li>
                                                <a className="dropdown-item" href="#"  onClick={() => showIpsModal(exchange.ip_list)}>View IPs</a>
												
											</li>
                                            }

											{/* <div className="dna-cart-dropdown-footer">
												<span>Active</span>
												<div className="check-box">
													<input type="checkbox" checked/>
												</div>
											</div> */}
										</ul>
									</div>
								</li>
							</ul>
						</div>
						<div className="dna-cart-body">
							<span>API Key</span>
							<p>{exchange.id==16?"********************":exchange.api_key}</p>
                           
						</div>
						
					</div>
                    <Modal show={deleteId > 0} onHide={() => setDeleteId(0)}>
                        <Modal.Header closeButton>
                        <Modal.Title>{t("warning")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {t("delete-api-warning")}
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="blue-button" onClick={clickDelete}>
                            {t("delete")}
                        </button>
                        </Modal.Footer>
                    </Modal>
                    <ViewIpsModal show={showIps} onHide={()=>setShowIps(false)} ips={ips}></ViewIpsModal>
				</div>
    )
}

export default connect(null,{getExchanges,deleteExchange})(ConnectedExchangeCard)