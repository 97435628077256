import { PRICE_ALERT_LIST, PRICE_ALERT_PAIR } from "../actions/types";

  
  const initialState = {
    newAlert:{
        condition:"le"
    }
  }
  
  const PriceAlertReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case PRICE_ALERT_LIST:
        return {
          ...state,
          alerts: payload,
        };
      case PRICE_ALERT_PAIR:
        return {
            ...state,
            newAlert: {
              ...state.newBot,
              exchangePairId: payload.id,
              pair: payload.pair,
              baseCoin: payload.pair.from,
              quoteCoin: payload.pair.to,
            },
        };
  
      default:
        return state;
    }
  };
  
  export default PriceAlertReducer;
  