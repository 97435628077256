import React from "react";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_MARTINGALE } from "../../../../actions/create-bot-type";

const DcaMartingaleView = (props) => {
  const { dcaMartingale, isBuy,t } = props;
  const dispatch = useDispatch();
  const formatPercent = (val) => (val == 0 ? "Disabled" : `${val}%`);
  const changeVal = (val) => {
    dispatch({
      type: CHANGE_DCA_MARTINGALE,
      payload: val,
    });
  };
  return (
    <div style={{ marginTop: 25 }}>
      <SliderView
        title={`Increase from Last ${isBuy ? "Purchase" : "Sale"} (Martingale)`}
        max={100}
        value={dcaMartingale}
        formatLabel={formatPercent}
        onChange={changeVal}
        tutorial={<TutorialPopup content={t("dcaMartingale-help")} />}
      />
    </div>
  );
};

export default DcaMartingaleView;
