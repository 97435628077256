
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form, Carousel } from "react-bootstrap";
import './IntroPopup.scss';
import { SET_TUTORIAL_POPUP } from "../../actions/types";





const IntroPopup = (props) => {
  const {
    onHide
  } = props;
  
  const dispatch = useDispatch();
  const setShow=()=>{ 
    dispatch({
        type: SET_TUTORIAL_POPUP,
        payload:1
    });
  }
   
  
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  return (
    <>
    
      <Modal show={true} onHide={()=>setShow(false)} size="lg" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "600px"}}>
        <div className="text-center mt-3 mb-3"><h4>Welcome to CryptoHero's Bots Marketplace</h4></div> 
          
        <Carousel variant="dark" className="carousel-div" interval={100000} nextIcon={<i class="fas fa-chevron-right"></i>} prevIcon={<i class="fas fa-chevron-left"></i>}>
            <Carousel.Item>
                <div
                className="d-block text-center carousel-div-img"
                >
                <img
                
                src="/images/sliders/slide1.png"
                alt="First slide" width={400} 
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <p className="text-title">Our Bots Marketplace offers many bots created by our experienced traders. Each bot will contain important performance metrics such as APY and W/L Ratio. Understanding the metrics is the first step towards choosing a bot that is right for you.</p>
                </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div
                className="d-block text-center carousel-div-img"
                >
                <img
                
                src="/images/sliders/slide2.png" className="carousel-img"
                alt="First slide" width={400} 
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <h3>APY</h3>
                <p className="text-title">Annual Percentage Yield. The actual return measured over a one year period. If a bot has been operating for less than a year, the APY will be extrapolated based on a one year period using its Performance figure.</p>
                </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div
                className="d-block text-center carousel-div-img carousel-div-flex"
                >
                <img
                
                src="/images/sliders/img1.png"
                alt="First slide" 
                />
                <img
                
                src="/images/sliders/img2.png"
                alt="First slide" 
                />
                <img
                
                src="/images/sliders/img3.png"
                alt="First slide" 
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <h3>W/L Ratio</h3>
                <p className="text-title"> Win Loss Ratio. The number of profitable deals over losses. The higher the ratio the better.</p>
                </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div
                className="d-block text-center carousel-div-img"
                >
                <img
                
                src="/images/sliders/slide3.png" className="carousel-img"
                alt="First slide" height={300} 
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <h3>Deal</h3>
                <p className="text-title"> A deal consists of a series of trades (entry to exit) performed by a bot. If a bot executes a buy order and sells at a profit, the deal is considered profitable.</p>
                </Carousel.Caption>
                </div>
            </Carousel.Item>
            </Carousel>
          
        </Modal.Body>
        <Modal.Footer className="text-center justify-center no-border"><a href="#" onClick={onHide}>Don’t show this again</a></Modal.Footer>
      </Modal>
      </>
  );
};

export default connect(null, {
})(IntroPopup);
