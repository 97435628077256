import React from "react";
import {
  CHANGE_BO,
  CHANGE_BO_TYPE,
  CHANGE_EO,
  CHANGE_FREQUENCY,
} from "../../../../actions/create-bot-type";
import BooleanView from "../BooleanView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch, useSelector } from "react-redux";
import { isLong } from "../../../../utils/Utils";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub, isMaster, isPro } from "../../../../utils/SubUtils";

const PriceBotParamsView = (props) => {
  const { newBot ,t} = props;
  const orderType = newBot.baseOrderType;
  const frequency = newBot.frequency;
  const baseOrder = newBot.baseOrder;
  const fund = newBot.initFund;
  
  const dispatch = useDispatch();
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const tradingFreqValues = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?FrequencyValues:FrequencyValuesPro):(newBot.botType==BotType.simple?FrequencyValuesSimple:FrequencyValues)
  const tradingFreqencies = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?Frequencies:FrequenciesPro):(newBot.botType==BotType.simple?FrequenciesSimple:Frequencies)
  

  const currentCoin = isLong(newBot.strategy)
    ? newBot.quoteCoin
    : newBot.baseCoin;

  const baseOrderChanged = (val) => {
    dispatch({
      type: CHANGE_BO,
      payload: val,
    });
  };

  const orderTypeChanged = (val) => {
    dispatch({
      type: CHANGE_BO_TYPE,
      payload: val,
    });
  };

  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const formatBaseOrder = (value) =>
    `${((fund * value) / 100).toFixed(4)} ${currentCoin} (${value}%)`;
  const formartFrequency = (value) => tradingFreqencies[value];
  return (
    <div>
      <div style={{ marginTop: 20 }}>
         <SliderView
          title={t("bo-limit")}
          step={5}
          min={5}
          formatLabel={formatBaseOrder}
          onChange={baseOrderChanged}
          value={baseOrder}
          tutorial={<TutorialPopup content={t("baseOrder-help")} />}
        /> 
      </div>
      <div style={{ marginTop: 20 }}>
      <BooleanView
          title={t("bo-type")}
          firstButton="static"
          secondButton="dynamic"
          selected={orderType}
          onSelect={orderTypeChanged}
          tutorial={<TutorialPopup content={t("base-order-type-help")} />}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={tradingFreqValues.length - 1}
          value={tradingFreqValues.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(tradingFreqValues[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")}  />}
        />
      </div>
    </div>
  );
};

export default PriceBotParamsView;
