import React from "react";
const CustomCheckbox = (props)=>{
    
    return(
        <>
            <input type="checkbox" id={props.id} className="custom-checkbox"  
                value={props.val}
                onChange={(e) => props.callback(e.target.checked)}
                checked={props.checked}>
            </input>
            <label htmlFor={props.id} className="checkbox-label">{props.label}</label>
        </>
    )
}

export default CustomCheckbox;