import React, { useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useSelector, connect } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import queryString from "query-string";
import Cookies from "universal-cookie";
import { CJ_TYPE, SUBSCRIPTION_PLAN } from "../../Const";
import { sendTrackingInfoCJ } from "../../actions/cj-action";

const SubscriptionSuccess = (props) => {
  const { sendTrackingInfoCJ } = props;
  const history = useHistory();
  const location = useLocation();
  const cookies = new Cookies();
  const cjevent=cookies.get('cje');
  const { search } = useLocation();
  const query = queryString.parse(search);
  const tier = query.tier
  const plan = query.plan
  const orderId = query.order
  const pathname =
    location.state && location.state.from && location.state.from.pathname;
  console.log(pathname)
  useEffect(() => {
    setTimeout(() => {
      history.push("/setting?loc=2");
    }, 5000);
    
  }, []);
  if(cjevent && pathname && (pathname && pathname == "/subscription") && tier && plan){
    console.log('Cj tracking')
    var price = 0;
    
    if(tier && plan){
      price=SUBSCRIPTION_PLAN[plan][tier]
    }
    const params = {
      CID: 1564371,
      TYPE: CJ_TYPE[query.tier],
      METHOD: 'S2S',
      SIGNATURE: 'ALmbaM2SuKFUjFJGnGAv7iMQ',
      CJEVENT:cookies.get('cje'),
      eventTime:new Date().toISOString(),
      OID:orderId,
      CURRENCY:'USD',
      ITEM1:query.tier,
      AMT1:price,
      QTY1:1,
    }; 
    sendTrackingInfoCJ(params).then((res)=>{
      console.log(res)
    }).catch((err)=>{
      console.log('Error sending CJ data')
      console.log(err)
    })
  }
  if (!pathname || (pathname && pathname !== "/subscription")) {
     var time = new Date();
      console.log(new Date().toISOString())
      return <Redirect to="/setting?loc=2" />;
    //
  }
  return (
    <Container fluid>
      <Row
        className="bg-white py-2 justify-content-between"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        <a href="/" style={{ textDecoration: "none" }} className="ml-5">
          <span
            className="text-primary font-weight-bold"
            style={{ fontSize: "20px" }}
          >
            <img src="/images/logo.png" width="55" className="mr-2" />
            CryptoHero
          </span>
        </a>
      </Row>

      <Row className="justify-content-center signup-panel">
        <Col xs={12} md={6} xl={4}>
          <Card>
            <Card.Body className="text-center">
              <h4 className="text-center">Your subscription is successful!</h4>
              <p>Redirecting...</p>
              <i
                className="far fa-check-circle my-3 text-success"
                style={{ fontSize: 150 }}
              ></i>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(null,{sendTrackingInfoCJ})(SubscriptionSuccess);
