import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import TutorialPopup from "../help/TutorialPopup";

const FutureOverview = (props) => {
  const { timeframe,t } = props;
  const {
    trading_volume
  } = useSelector((state) => state.portfolio);
  const currency = useSelector((state) => state.settings.currency);
  const assets = useSelector((state) => state.dashboard.assets);
  const futureTypeState = useSelector((state) => state.settings.futureType);
  return (
   
    <>
        {futureTypeState == "usd_m"? 
        <>
         <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 v-pad">
            <p className="small-text">{t("usd-m-balance")}</p>
            <h2>
            {assets && assets.usd_m && assets.usd_m.balance_fiat.toFixed(4)} {currency && currency.name}
            </h2>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 v-pad">
            <p className="small-text">
            {timeframe} {t("usd-m-vol")}{" "} <TutorialPopup content="Cumulative" />
            
            </p>
            <h2>
            {trading_volume && trading_volume.toFixed(2)}{" "}
            {currency && currency.name}
            </h2>
        </div>
        </>:
        <>
        <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 v-pad">
            <p className="small-text">{t("coin-m-balance")}</p>
            <h2>
            {assets && assets.coin_m && assets.coin_m.balance_fiat.toFixed(4)} {currency && currency.name}
            </h2>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 v-pad">
            <p className="small-text">
            {timeframe} {t("coin-m-vol")}
            <img src="img/icon-question-small.png" width="15" height="15" alt="" />
            </p>
            <h2>
            {trading_volume && trading_volume.toFixed(2)}{" "}
             {currency && currency.name}
            </h2>
        </div>
        </> }
      </>
   
  );
};

export default connect(null, {  })(FutureOverview);
