import React from "react";
import InputRange from "react-input-range";
import { Form,InputGroup } from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import { useDispatch } from "react-redux";
import { CHANGE_ABSOLUTE_SL } from "../../../../actions/create-bot-type";
import SliderView from "../SliderView";

const AbsoluteSL = (props) => {
  const { value, max, min ,hideLabel,isPremiumBot,t} = props;
  const formatLabel = (value) => (value == 0 ? "Disable" : `${value}%`);
  const formatDecimal = (value,decimals) => Number(Number(value).toFixed(decimals));
  const dispatch = useDispatch();
  const onChange = (val) => {
    if(val > max)
    return
    dispatch({
      type: CHANGE_ABSOLUTE_SL,
      payload: val,
    });
  };
  return (
    <div>
      <SliderView
              title={isPremiumBot?"":t("Stoploss")}
              step={0.5}
              min={0}
              max={max}
              value={formatDecimal(value,2)}
              onChange={(value) => {
                onChange && onChange(value);
              }}
              hideLabel={hideLabel}
              withInput={true}
         />
    </div>
  );
};

export default AbsoluteSL;
