import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import PortfolioPanel from "../dashboard/PortfolioPanel";
import DistributionPanel from "./DistributionPanel";
import CoinPanel from "./CoinPanel";
import ContentContainer from "../common/ContentContainer";
import { getPortfolioDetail } from "../../actions/portfolio-action";
import "./Portfolio.scss";
import { useTranslation } from "react-i18next";
import DistributionPanelFutures from "./DistributionPanelFutures";
import FutureAssetsTab from "./FutureAssetsTab";
import PortfolioOverviewFutures from "./PortfolioOverviewFutures";

const FuturesPortfolio = (props) => {
  const { getPortfolioDetail } = props;
  const {t} = useTranslation()

  const [activeTab, setActiveTab] = useState(0);

  const tabTitles = [t("USD(S)-M Assets"), t("COIN-M Assets")];
  const [timeframe, setTimeframe] = useState("1M");
  const selectedExchange =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const usdMCoins = useSelector((state) => state.portfolio.usdM);
  const coinMCoins = useSelector((state) => state.portfolio.coinM);
  const currency = useSelector((state) => state.settings.currency);
  const assets = useSelector((state) => state.dashboard.assets);
  useEffect(() => {
    getPortfolioDetail(selectedExchange,"usd_m");
  }, [selectedExchange]);

  const usdMtotalBalance =
  usdMCoins && usdMCoins.total &&
    usdMCoins.total.reduce((balance, item) => {
      return balance + item.balance_usd;
    }, 0);
  const coinMtotalBalance =
  coinMCoins && coinMCoins.total &&
  coinMCoins.total.reduce((balance, item) => {
      return balance + item.balance_usd;
    }, 0);
  const totalUsdMCoins =
  usdMCoins &&  usdMCoins.total &&
  usdMCoins.total
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings:
            usdMtotalBalance === 0 ? 0 : (item.balance_usd * 100) / usdMtotalBalance,
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });
  const totalCoinMCoins =
  coinMCoins && coinMCoins.total &&
  coinMCoins.total
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings:
          coinMtotalBalance === 0 ? 0 : (item.balance_usd * 100) / coinMtotalBalance,
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });
  const tabHeaders = tabTitles.map((item, index) => {
    return (
         <li className="skltbs-tab-item" onClick={() => setActiveTab(index)} key={index}>
          <button  className={index == activeTab ? "skltbs-tab skltbs-active" : "skltbs-tab"}>{item}</button>
        </li>
    );
  });

  const coinTabs = [
    <FutureAssetsTab coins={totalUsdMCoins} isTotal={true} t={t}/>,
    <FutureAssetsTab coins={totalCoinMCoins} isTotal={false} t={t}/>,
  ];

  return (
    <ContentContainer url="portfolio" title={t("portfolio")} showExchange={true}>
      <div className="main-right-content">
        <div class="dashboard-newuser-area">
        <PortfolioOverviewFutures t={t} assets={assets} currency={currency} totalUsdMCoins={totalUsdMCoins} totalCoinMCoins={totalCoinMCoins}></PortfolioOverviewFutures>
				</div>
        <div class="position-tab-area" id="tab-wrap">
          <div class="row">
            <div class="col-10 col-sm-8 col-lg-6">
                <div>
                  <ul class="skltbs-tab-group">
                  {tabHeaders}
                  </ul>
                </div>
              </div>
            </div>
            <div class="position-table-area">
                        <div class="row">
                          <div class="col-lg-12">
                              {coinTabs[activeTab]}
                          </div>
                        </div>
                  </div>
          </div>
      </div>
    </ContentContainer>
  );
};

export default connect(null, { getPortfolioDetail })(FuturesPortfolio);
