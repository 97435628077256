import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT, BOT_PAGE_ACTIVE, BOT_PAGE_INACTIVE, DEALS_ACTIVE_TAB, DEALS_PAGE_CLOSE, DEALS_PAGE_OPEN, RESET_ALL_PAGINATION } from "../actions/types";

const initialState = {
  botsActivePage:1,
  botsInactivePage:1,
  botsActiveTab:0,
  botSort:"apy",
  botsOrderBy:"desc",
  dealsOpenPage:1,
  dealsClosePage:1,
  dealsActiveTab:0,
};

const paginationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BOT_PAGE_ACTIVE:
      return {
        ...state,
        botsActivePage: payload,
      };
    case BOT_PAGE_INACTIVE:
      return {
        ...state,
        botsInactivePage: payload,
      };
    case BOTS_ACTIVE_TAB:
      return {
        ...state,
        botsActiveTab: payload,
      };
    case BOTS_SORT:
      return {
        ...state,
        botSort: payload,
      };
    case BOTS_ORDER_BY:
      return {
        ...state,
        botsOrderBy: payload,
      };
    case RESET_ALL_PAGINATION:
      return {
        initialState
      };
    case DEALS_ACTIVE_TAB:
      return {
        ...state,
        dealsActiveTab: payload,
      };
    case DEALS_PAGE_OPEN:
      return {
        ...state,
        dealsOpenPage: payload,
      };
    case DEALS_PAGE_CLOSE:
      return {
        ...state,
        dealsClosePage: payload,
      };
    default:
      return state;
  }
};

export default paginationReducer;
