import React, { useEffect } from "react";
import AffiliateLayout from "../common/AffliateLayout";
import "./LeaderBoard.scss";
import { connect, useSelector } from "react-redux";
import { getAffiliateRank } from "../../../actions/affiliate-action";
import { hideEmail } from "../../../utils/Utils";
import { t } from "i18next";

const LeaderBoard = (props) => {
  const { getAffiliateRank } = props;
  const ranks = useSelector((state) => state.affiliate.ranks) || [];

  useEffect(() => {
    getAffiliateRank();
  }, []);

  const top3 = ranks
    .slice(0, 3)
    .map((rank) => {
      const user = rank.user;
      const name = user.name || hideEmail(user.email);
      switch (rank.rank) {
        case 1:
          return {
            image: "gold",
            title: t("super"),
            points: rank.point,
            name,
          };
        case 2:
          return {
            image: "silver",
            title: t("awsome"),
            points: rank.point,
            name,
          };
        case 3:
          return {
            image: "bronze",
            title: t("incredible"),
            points: rank.point,
            name,
          };
        default:
          return {};
      }
    })
    .map((item, id) => (
      <div className="medal" key={id}>
        <img src={`/images/${item.image}.png`} />
        <p className="frag1">{item.title} Affiliate </p>
        <p className="frag2">{item.name.toUpperCase()}</p>
        <p className="frag2">{item.points} {t("points")} </p>
      </div>
    ));
  const items = ranks
    .slice(3, 10)
    .map((rank) => {
      const user = rank.user || {};
      return {
        rank: rank.rank,
        points: rank.point,
        name: user.name || hideEmail(user.email),
      };
    })
    .map((item, id) => (
      <p className="frag3" key={id}>
        {" "}
        {item.rank}. <b>{(item.name && item.name.toUpperCase()) || "John"}</b> -{" "}
        {item.points} {t("points")}
      </p>
    ));
  return (
    <AffiliateLayout>
      <div className="leader-board">
        <div className="left">
          <p className="header1">{t("leaderboard")}</p>
          <p className="header2">{t("top10affiliate")}</p>
          <div className="medal-row">{top3}</div>
          <div>{items}</div>
        </div>

        <div className="right">
          <img src="/images/pp4.png" width={600} />
        </div>
      </div>
    </AffiliateLayout>
  );
};

export default connect(null, { getAffiliateRank })(LeaderBoard);
