import React from "react";
import { Card, Col } from "react-bootstrap";
import "./SupportCard.scss";

const SupportCard = (props) => {
  const { icon, text, link,t } = props;
  return (
    <Col>
      <div>
        <a href={link} target="_blank">
          <div className="round-bg support-card">
            <img
              src={`/images/icons/${icon}.png`}
              alt=""
              width={80}
              height={80}
            />

            <p>{t(text)}</p>
          </div>
        </a>
      </div>
    </Col>
  );
};

export default SupportCard;
