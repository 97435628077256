import React from "react";
import TutorialPopup from "../help/TutorialPopup";
import { useSelector } from "react-redux";

const SpotOverview = (props)=>{

    const {data} = props
    const {
        t,
        timeframe,
        
    } = data

    const {
        trading_volume,
        portfolio_value,
        portfolio_changed,
      } = useSelector((state) => state.portfolio);
    const changeUp = portfolio_changed && portfolio_changed > 0;
    const currency = useSelector((state) => state.settings.currency);

    return(
        <>
        <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 v-pad">
            <p className="small-text">{t("portf-val")}</p>
            <h2>
            {portfolio_value && portfolio_value.toFixed(2)} {currency && currency.name}
            </h2>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 v-pad">
            <p className="small-text">{timeframe} Change</p>
            <h2 className={changeUp ? "upward-change" : "downward-change"}>
            <img
                src={changeUp ? "/img/icon-arrow-up.png" : "/img/icon-arrow-down.png"}
                width="14"
                height="14"
                alt=""
            />{" "}
            {portfolio_changed && portfolio_changed.toFixed(2)}%
            </h2>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 v-pad">
            <p className="small-text">
            {timeframe} {t("t-volume")}{" "}<TutorialPopup content="Cumulative" />
            </p>
            <h2>
            {trading_volume && trading_volume.toFixed(2)} {currency && currency.name}
            </h2>
        </div>
        </>
    )
}

export default SpotOverview;