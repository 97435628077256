import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TradeHistoryTab from "./TradeHistoryTab";

const GridBotDealTradeHistory = ((props)=>{
    const [activeTab, setActiveTab] = useState(0);
    const {tradeItems,deal,t} =props 
    const SellTrades = tradeItems.filter((item)=>{
        return item.side ==t("SELL")
    }).sort((a,b)=>{
      return b.price-a.price
    })
    const BuyTrades = tradeItems.filter((item)=>{
        return item.side ==t("BUY")
    }).sort((a,b)=>{
      return b.price-a.price
    })
    
    //const tabItems = [<TradeHistoryTab trades={SellTrades} deal={deal}/>,<TradeHistoryTab trades={BuyTrades} deal={deal}/>];
    return (
        <Row className="mt-5">
              <h5>
                Grid Trades
              </h5>
              <Table className="mt-3" responsive>
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("side")}</th>
                    <th>{t("order-type")}</th>
                    <th>{t("status")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("price")}</th>
                    <th>{t("total-val")}</th>
                  </tr>
                </thead>
                <tbody>
                  <TradeHistoryTab trades={SellTrades} deal={deal}/>
                  <tr>
                    <td colSpan={7} style={{borderTop:'none',borderBottom:'none'}}><hr></hr></td>
                  </tr>
                  <TradeHistoryTab trades={BuyTrades} deal={deal}/></tbody>
              </Table>
            </Row>
    )
})
export default connect(null, {
  })(GridBotDealTradeHistory);