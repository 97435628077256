import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { verifyGaCode } from "../../actions/setting-action";
import Loading from "./Loading";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";

const GADialog = (props) => {
  const { show, onHide, success, verifyGaCode } = props;
  const [loading, setLoading] = useState(false);
  const [code, bindCode, resetCode] = useInput("");

  useEffect(() => {
    if (show) {
      resetCode();
    }
  }, [show]);

  const clickConfirm = () => {
    setLoading(true);
    verifyGaCode(code)
      .then(() => {
        setLoading(false);
        onHide();
        success();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Google Authenticator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mt-3">
            Input Google Authenticator code from your phone
          </p>
          <Form.Control {...bindCode} />
          <div className="text-center my-3">
            <Button
              onClick={clickConfirm}
              variant="success"
              className="w-50 mt-3"
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Loading>
  );
};

export default connect(null, { verifyGaCode })(GADialog);
