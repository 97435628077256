import React from "react";
import { Badge, Container } from "react-bootstrap";
import "./NavPanel.scss";
import { connect, useSelector } from "react-redux";
import { UserRole } from "../../Const";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const NavPanel = (props) => {
  const { selectedCom, onSelectCom,t } = props;
  const links = [
    {
      name: t("general"),
      icon: "general",
    },
    {
      name: t("account"),
      icon: "account",
    },
    {
      name: t("subscription"),
      icon: "subscription",
    },
  
    {
      name: t("exch-api"),
      icon: "exchange",
    },
    {
      name: t("futures-api"),
      icon: "exchange",
    },
    {
      name: t("formula"),
      icon: "formula",
    },
    {
      name: t("paper-wallet"),
      icon: "wallet",
    },
    {
      name: t("security"),
      icon: "security",
    },
    {
      name: t("notifications"),
      icon: "notification",
    },
  ];

  const clickLink = (e, index) => {
    e.preventDefault();
    onSelectCom(index);
  };
  const isActive = (i) => {
    return i === selectedCom ? "active" : "";
  };
  const linkItems = links.map((item, index) => {
    return (
      <li key={index} >
      <Link
        className={isActive(index)}
        to={'/setting?loc='+index}
        onClick={(e) => clickLink(e, index)}
      >
        <img src={`/images/settings/${item.icon}.svg`}/>
        {item.name}
      </Link>
    </li>
    );
  });
  return (
    <div className="setting-nav-item">
				<ul>
         {linkItems}
         </ul>
    </div>
  );
};

export default connect(null)(NavPanel);
