import React from "react";
import { GridEntryTypes, GridEntryTypeNames } from "../../../../Const";
import { useDispatch } from "react-redux";
import { CHANGE_GRID_ENTRY_TYPE } from "../../../../actions/create-bot-type";
import GridIndicatorView from "./GridIndicatorView";
import GridRangeView from "./GridRangeView";
import LeftRightForm from "../modules/LeftRightForm";
import { Form } from "react-bootstrap";


const GridEntryView = (props) => {
  const { newBot,t } = props;

  const dispatch = useDispatch();
  const changeEntryType = (type) =>
    dispatch({
      type: CHANGE_GRID_ENTRY_TYPE,
      payload: type,
    });

  const options = Object.keys(GridEntryTypes).map((el, id) => (
    <option key={id} value={GridEntryTypes[el]}>
      {GridEntryTypeNames[el]}
    </option>
  ));

  let entryComp;
  switch (newBot.gridEntryType) {
    case GridEntryTypes.indicator:
      entryComp = <GridIndicatorView newBot={newBot} t={t}/>;
      break;
    case GridEntryTypes.range:
      entryComp = <GridRangeView newBot={newBot} isBuy={true} t={t}/>;
      break;
    default:
      break;
  }
  return (
    <>
    <LeftRightForm
      left={<Form.Label>Select Entry Type</Form.Label>}
      right={<><select
        value={newBot.gridEntryType}
        onChange={(e) => changeEntryType(e.target.value)}
        style={{ marginLeft: 15, marginBottom: 20 }}
        className="form-control select-normal"
        >
        {options}
        </select></>}
    ></LeftRightForm>
    {entryComp}
    </>
  );
};

export default GridEntryView;