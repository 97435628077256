import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import NavPanel from "./NavPanel";
import AccountPanel from "./AccountPanel";
import AddApiPanel from "./AddApiPanel";
import GeneralPanel from "./GeneralPanel";
import SubscriptionPanel from "./SubscriptionPanel";
import NotificationPanel from "./NotificationsPanel";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ContentContainer from "../common/ContentContainer";
import "./Setting.scss";
import PaperWalletPanel from "./PaperWalletPanel";
import SecurityPanel from "./SecurityPanel";
import { useTranslation } from "react-i18next";
import AddFuturesApiPanel from "./AddFuturesApiPanel";
import FormulaPanel from "./FormulaPanel";

const modules = [
  GeneralPanel,
  AccountPanel,
  SubscriptionPanel,
  AddApiPanel,
  AddFuturesApiPanel,
  FormulaPanel,
  PaperWalletPanel,
  SecurityPanel,
  NotificationPanel
];

const Setting = (props) => {
  const { search } = useLocation();
  const location = useLocation();
  const {t} = useTranslation()
  const query = queryString.parse(search);
  const [selectedCom, setSelectedCom] = useState(Number(query.loc) || 0);
  useEffect(() => {
    // This will trigger every time the query params change
    setSelectedCom(Number(query.loc)|| 0)
    
  }, [location.search]);

  const SelectedCom =
    selectedCom >= modules.length ? modules[0] : modules[selectedCom];
  return (
    <ContentContainer url="setting" title={t("settings")} showChat={true}>
    <div className="setting-general-area">
         <div className="row g-0">
           <div className="col-sm-2 setting-left-col">
             <NavPanel selectedCom={selectedCom} onSelectCom={setSelectedCom} t={t}/>
           </div>
           <div className="col-sm-10 setting-right-col">
                 <SelectedCom />
           </div>
           </div>
       </div>
   </ContentContainer>
  );
};

export default connect(null)(Setting);
