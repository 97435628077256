import React, { useMemo, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import CustomPagination from "../common/CustomPagination";
import "./PaperSpotTab.scss";
import useInput from "../hooks/useInput";
import usePagination from "../hooks/usePagination";
import DefaultPagination from "../common/DefaultPagination";
import CustomCheckbox from "../bots/new-bot/modules/CustomCheckbox";
import { useTranslation } from "react-i18next";

const PaperIsolatedTab = (props) => {
  const { totalIsolated, totalEquityIsolated, totalDebtIsolated } = props;
  const {t} = useTranslation()
  const pairs = props.pairs || [];
  const [rangeItems, bindPagination] = usePagination(0, 5);
  const [hideSmall, setHideSmall] = useState(false);
  const [search, bindSearch] = useInput("");
  const filterPairs = useMemo(() => {
    return pairs
      .filter(
        (el) =>
          !search.length || el.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((el) => {
        if (!hideSmall) return true;
        const { base, quote } = el;
        return base.total != 0 || quote.total != 0;
      });
  }, [search, hideSmall, pairs]);
  const coinItems = filterPairs.slice(...rangeItems).flatMap((el, id) => {
    return [el.base, el.quote].map((coin, index) => (
      <tr key={id + coin.coin} >
        {index === 0 ? (
          <td rowSpan={2} className="text-left">
            {el.name}
          </td>
        ) : (
          <></>
        )}
        {index === 0 ? <td rowSpan={2}>{el.risk || 0}</td> : <></>}
        <td className="text-left">
          <img src={coin.image} width={20} />{" "}
          <span className="ml-2">{coin.coin}</span>
        </td>
        <td>{(coin.total || 0).toFixed(6)}</td>
        <td>{(coin.free || 0).toFixed(6)}</td>
        <td>{(coin.used || 0).toFixed(6)}</td>
        <td>{(coin.borrowed || 0).toFixed(6)}</td>
      </tr>
    ));
  });
  return (
    <>
    <div className="paperwallet-table-content">
    <div className="pwtc-heading">
      <div>
        <ul>
          <li><span>Total Balance</span></li>
          <li><h6>{(totalIsolated || 0).toFixed(6)} BTC</h6></li>
          <li><span>Total Equity</span></li>
          <li><h6>{(totalEquityIsolated || 0).toFixed(6)} BTC</h6></li>
          <li><span>Total Debt</span></li>
          <li><h6>{(totalDebtIsolated || 0).toFixed(6)} BTC</h6></li>
          
        </ul>
        <ul>
         
        </ul>
       
       
        <br></br>
        <CustomCheckbox 
          val={hideSmall} 
          checked={hideSmall} 
          callback={setHideSmall}
          label={t("Hide Small Balance")}
          id={'hidesmall'}
          >
      </CustomCheckbox>
      </div>
      
      
    </div>
    <div className="pwtc-cross">
    <Form.Control placeholder="Search Coin" {...bindSearch} className="input-text mb-2 float-end"/>
    </div>
    <div className="paperwallet-main-table">
      <div className="open-table-content otc Portfolio-table-content">
        <table className="table paperwallet-table  backtest-trades">
        <tr valign="top">
            <th>Pair</th>
            <th>Risk</th>
            <th>Coin</th>
            <th>Total</th>
            <th>Available</th>
            <th>Reserved</th>
            <th>Borrowed</th>
        </tr>
          {coinItems}
         
        </table>
      </div>
    </div>
   
  </div>
    <DefaultPagination {...bindPagination} totalCount={filterPairs.length} />
  </>
    
  );
};

export default PaperIsolatedTab;
