import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../common/ContentContainer";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { CREATE_BOT_START, SET_BOT_NAME } from "../../../actions/create-bot-type";
import BotTitle from "./modules/BotTitle";
import AssetParamSection from "./modules/AssetParamSection";
import EntryExitSection from "./modules/EntryExitSection";
import BackTestSection from "./modules/BackTestSection";
import { useHistory, useLocation } from "react-router-dom";
import IndicatorSetting from "./indicator-setting/IndicatorSetting";
import IndicatorSettingModal from "./indicator-setting/IndicatorSettingModal";
import CreateBotSection from "./modules/CreateBotSection";
import Loading from "../../common/Loading";
import AssetView from "./AssetView";
import GridBotParamsView from "./grid-bot/GridBotParamsView";
import TradeParamsView from "./TradeParamsView";
import { BotType } from "../../../Const";
import EntryView from "./modules/EntryView";
import ExitView from "./modules/ExitView";
import AssetViewRent from "../../marketplace/bots/AssetViewRent";
import TradeParamsViewRent from "../../marketplace/bots/TradeParamsViewRent";

const CreateBotStepsRent = (props)=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const isEditing = useSelector((state) => state.createBot.rentBot.isEditing);
    const [activeTab,setActiveTab] = useState(0);
    const Exchange = useSelector((state) => state.settings.selectedExchange) || 0;
    const selectedExchange = isEditing?rentBot.exchange:Exchange;
    const stepTitles = ['Asset','Trade Parameters',isEditing?'Update Bot':'Create Bot'];
    const changeTab = (type)=>{
        if(type=="next"){
            setActiveTab(activeTab+1)
        }
        if(type=="prev"){
            setActiveTab(activeTab-1)
        }
    }
    const steps = stepTitles.map((item,index)=>{
        return (
            <li key={index}>
                <a onClick={()=>setActiveTab(index>1?1:index)} className={`pointer`}>
                    <label className={activeTab>=index||activeTab >= 1?'active':''}><img src={`/images/cb-step-${index}.svg`} alt=""/></label>
                    {item}
                </a>
            </li>
        )
    })
    const tabs = [
        <AssetViewRent rentBot={rentBot} selectedExchange={selectedExchange} t={t} isEditing={isEditing} step={changeTab}/>,
        <TradeParamsViewRent
            editing={isEditing}
            rentBot={rentBot}
            t={t}
            step={changeTab}
        />
        
    ];
    return(
        <>
            <div className={`bot-advance-asset-tab ba-tab${activeTab} rent`}>
                <ul className="bc-step-icons rent">
                    {steps}
                </ul>
            </div>
            <div className="ba-asset-form-area ec-from">
                {tabs[activeTab]}
            </div>
        </>
      );
}

export default connect(null,{})(CreateBotStepsRent)