import React from "react";
import { Alert } from "react-bootstrap";
import { BotType, IndicatorType } from "../../../../Const";
import { isLong } from "../../../../utils/Utils";

const IndicatorView = (props) => {
  const {
    indicator,
    strategy,
    isEntry,
    removeIndicator,
    editIndicator,
    botType,
  } = props;
  const showSetting = botType !== BotType.simple;
  let config = "";
  const longStrategy = isLong(strategy);
  switch (indicator.indicator_id) {
    case IndicatorType.RSI:
    case IndicatorType.SRSI:
      if (longStrategy) {
        config = isEntry
          ? `${indicator.period_num}, ${indicator.value2}, ${
              indicator.value5
            }, ${indicator.value4 == 1}`
          : `${indicator.period_num}, ${indicator.value3}`;
      } else {
        config = isEntry
          ? `${indicator.period_num}, ${indicator.value3}, ${
              indicator.value6
            }, ${indicator.value4 == 1}`
          : `${indicator.period_num}, ${indicator.value2}`;
      }
      break;
    case IndicatorType.BB:
      config = isEntry
        ? `${indicator.period_num}, ${
            longStrategy ? indicator.value2 : indicator.value3
          }`
        : `${indicator.period_num}, ${
            !longStrategy ? indicator.value2 : indicator.value3
          }`;
      break;
    case IndicatorType.EMA:
      config = `${indicator.period_num}, ${indicator.value2}`;
      break;
    case IndicatorType.MACD:
      config = `${indicator.period_num}, ${indicator.value2}, ${indicator.value3}`;
      break;
    case IndicatorType.VOL:
      config = `${indicator.period_num}, ${indicator.value2}`;
      break;
    default:
      break;
  }

  return (
    <label className="indicator-label">
      
        <span>
        {indicator.name} {indicator.is_compulsory?"(Required)":""}

          {showSetting && (
            <>
              {" - "} {config}
            </>
          )}
        </span>
        <div>
          {showSetting && (
               <img src="/images/icon-configure 1.svg" alt="" onClick={() => {
                  editIndicator(indicator);
                }}/>
          )}
            <img src="/images/icon-cross 2.png" alt="" onClick={() => {
              removeIndicator(indicator.id?indicator.id:indicator.ind_key);
            }}/>
        </div>
     </label>
  );
};

export default IndicatorView;
