import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ContinueSubscriptionDialog = (props) => {
    const {show, onHide, confirmed} = props;
    return ( 
        <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Resume Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your subscription will be renewed at the end of period.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={confirmed}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
     );
}
 
export default ContinueSubscriptionDialog;