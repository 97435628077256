import {
  GET_DATA_CURRENCIES,
  GET_DATA_EXCHANGE,
  GET_DATA_SYMBOL,
} from '../actions/types';

const initState = {
  exchanges: [],
  currencies: [],
  symbols: [],
};

const dataReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_DATA_EXCHANGE:
      return {
        ...state,
        exchanges: payload,
      };
    case GET_DATA_CURRENCIES:
      return {
        ...state,
        currencies: payload,
      };
    case GET_DATA_SYMBOL:
      return {
        ...state,
        symbols: payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
