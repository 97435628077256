const tpsImportAdapter = (item) => {
  return {
    id: item.id,
    triggerAt: item.trigger_percentage,
    stopAt: item.tp_percentage,
    type: item.tp_type,
  };
};

export default tpsImportAdapter;
