import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import Loading from "../common/Loading";
import { getBotList, getReports, getStockList } from "../../actions/report-action";
import { capitalize, currencyFormat } from "../../utils/Utils";
import ReportChart from "./charts/ReportChart";
import ReactDatePicker from "react-datepicker";
import useLoading from "../hooks/useLoading";
import { toast } from "react-toastify";
import { checkSub, isPremium } from "../../utils/SubUtils";
import { SUB_TIER } from "../../Const";
import PremiumAccess from "../common/PremiumAccess";



const Report = (props) => {
  const {getBotList,getStockList} = props;
  const reportTypesTitle = {total:'Total',stocks:'Coins',bots:'Bots'};
  const reportTypes = ['total','stocks','bots'];
  const graphTitles = {return:'Return ($)',returnPercentage:'Return (%)',cumulativeReturn:'Cumulative Return ($)',cumulativeReturnPercentage:"Cumulative Return (%)",tradingVolume:'Trading Volume',};
  const graphTitleKeys = ['return','returnPercentage','cumulativeReturn','cumulativeReturnPercentage','tradingVolume'];
  const [setLoading, bindLoading] = useLoading(false);
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const isPaid = isPremium(subscription);
  const { subPremium, subTier } = checkSub(subscription);
  const eligible = isPaid && subTier !== SUB_TIER.lite
  const graphPeriodLabels = {
	1: "1 Day",
	7: "1 Week",
	30: "1 Month",
	90: "3 Months",
	365: "1 Year",
	"all": "All Time",
	"custom": "Custom",
  }
  const [activeTab,setActiveTab] = useState(0);
  const [activeGraphTab,setActiveGraphTab] = useState(0);
  const [activeGraphTabName,setActiveGraphTabName] = useState('return');
  const [selectedStock,setSelectedStock] = useState('');
  const [selectedBot,setSelectedBot] = useState('');
  const now  = new Date()
  const [currentTimeFrame, setCurrentTimeFrame] = useState(1);
  const [startDate,setStartDate] = useState(new Date());
  const [endDate,setEndDate] = useState(new Date(now.setDate(now.getDate() - currentTimeFrame)));

  useEffect(()=>{
	setCurrentTimeFrame(1)
  },[selectedExchangeId])
  //const now = Date.now();
  const variableMap = {
	 return:useSelector((state)=>state.report.return),
	 returnPercentage:useSelector((state)=>state.report.returnPercentage),
	 tradingVolume:useSelector((state)=>state.report.tradingVolume),
	 portfolio:useSelector((state)=>state.report.portfolio),
	 cumulativeReturn:useSelector((state)=>state.report.return),
	 cumulativeReturnPercentage:useSelector((state)=>state.report.returnPercentage),
	 //apy:useSelector((state)=>state.report.apy)
  }
  const chartMap = {
	 return:useSelector((state)=>state.report.returnChart),
	 returnPercentage:useSelector((state)=>state.report.returnPercentageChart),
	 cumulativeReturn:useSelector((state)=>state.report.cumulativeReturnChart),
	 cumulativeReturnPercentage:useSelector((state)=>state.report.cumulativeReturnChartPercentage),
	 tradingVolume:useSelector((state)=>state.report.tradingVolumeChart),
	 //portfolio:useSelector((state)=>state.report.portfolioChart),
	 //apy:useSelector((state)=>state.report.apyChart)
  }
  const stocks = useSelector((state)=>state.report.stocks);
  const bots = useSelector((state)=>state.report.bots);
  const timFrames = {
	1: "1D",
	7: "1W",
	30: "1M",
	90: "3M",
	180: "6M",
	270: "9M",
	365: "1Y",
	"all": "ALL",
	"custom": "CUSTOM",
  };
  const switchTimeFrame = (timeframe)=>{
	setCurrentTimeFrame(timeframe)
	var timeframeNumber = Number(timeframe)
	if (!Number.isNaN(timeframeNumber)) {
		setEndDate(new Date())
		setStartDate(new Date(new Date().setDate(new Date().getDate() - timeframe)))
	}
	else{
		setEndDate(new Date())
		setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
	}
  }
  const checkEndDate = (date)=>{
	if(date > startDate){
		setEndDate(date)
	}
	else{
		return false
	}
  }
  const checkStartDate = (date)=>{
	if(date > endDate){
		return false
	}
	else{
		setStartDate(date)
	}
  }
  const timeFrameButtons = Object.entries(timFrames).map(([key, value]) => {
	const buttonClass = `pointer ${
	  key == currentTimeFrame? "active" : ""
	}`;
	return (
		<a  key={key} onClick={() => switchTimeFrame(key)} className={buttonClass}>{value}</a>
	);
  });
  //console.log(now.getTime(),oneMonthBack.getTime())
  const changeTab = (index,tab) => {
    setActiveTab(index);
  };
  const changeGraphTab = (index,tab) => {
    setActiveGraphTab(index);
    setActiveGraphTabName(graphTitleKeys[index]);
  };
  const getGraphTabValue = (val,currency=true) => {
	if(currency){
		return currencyFormat(variableMap[val],2);
	}
	else{
		return variableMap[val];
	}
    
  };
  useEffect(()=>{
	updateChart()
	
  },[currentTimeFrame,selectedBot,selectedStock])
  useEffect(()=>{
	if(eligible){
		getBotList()
		getStockList()
	}
	
  },[])

  const updateChart = ()=>{
	if(eligible){
		props.getReports(selectedExchangeId,startDate,endDate,reportTypes[activeTab],currentTimeFrame,selectedBot,selectedStock).then((res)=>{
			setLoading(false)
		}).catch((error)=>{
			toast.error(error)
		})
	}
  }
  const clickUpdateChart = ()=>{
	setLoading(true)
	updateChart()
  }
  
  const reportTypesButtons = reportTypes.map((item, index) => {
    return (
      <button className={(index == activeTab ? "active" : "" )} onClick={() => changeTab(index)} key={item}>{reportTypesTitle[item]}</button>
      
    );
  });
  const graphHeaders = graphTitleKeys.map((item, index) => {
    return (
      <a className={`pointer ${(index == activeGraphTab ? "active-rctop-btn" : "" )}`} onClick={() => changeGraphTab(index)} key={index}>
		<span>{graphTitles[item]}</span>
		{/* <h6>{getGraphTabValue(item)}</h6> */}
	</a>
    );
  });
  const stockList = stocks && stocks.map((item, index) => {
    return (
      <option key={index} value={item.id}>
        {item.from}
      </option>
    );
  });
  const botList = bots && bots.map((item, index) => {
    return (
      <option key={index} value={item.id}>
        {item.name}
      </option>
    );
  });
  const getChart= (props)=>{
	return <ReportChart chartData={chartMap[activeGraphTabName]} label={graphTitles[activeGraphTabName]}></ReportChart>
  }
  
  return (
    <ContentContainer url="reports" title={"Report"} showExchange={true}>
    {eligible ?<>
		<div className="report-main-wrapper">
								<div className="reports-header">
          {reportTypesButtons}
          {activeTab == 1 && (
                  <select className="select-normal stock-select" onChange={(e)=>setSelectedStock(e.target.value)}>
                    <option>Select Coin</option>
                    {stockList}
                  </select>
              )}
          <div className="">
            
            {activeTab == 2 && ( <select className="select-normal bot-select" onChange={(e)=>setSelectedBot(e.target.value)}>
                    <option>Select Bot</option>
                    {botList}
                  </select>
              )}
            </div>
      </div>
	  <Loading {...bindLoading}>
      <div className="reports-chart-area">
			<div className="rc-top">
				{graphHeaders}
			</div>
			<div className="reports-chart-content">
				
				<div className="rc-header">
					<h4><img src="/images/icon-detail2.svg" alt=""/> Period: {graphPeriodLabels[currentTimeFrame]}</h4>
					 <div className="rc-detail-content">
						<div className="row g-0">
							<div className="col-md-3 col-6">
								<div className="rc-detail-item">
									<span>Total Return ($)</span>
									<h6>${getGraphTabValue("return")}</h6>
								</div>
							</div>
							<div className="col-md-3 col-6">
								<div className="rc-detail-item">
									<span>Total Return (%)</span>
									<h6>{getGraphTabValue("returnPercentage")}</h6>
								</div>
							</div>
							<div className="col-md-3 col-6">
								<div className="rc-detail-item">
									<span>Total Trading Volume</span>
									<h6>{getGraphTabValue("tradingVolume")}</h6>
								</div>
							</div>
							{/* <div className="col-md-3 col-6">
								<div className="rc-detail-item">
									<span>Average APY</span>
									<h6>{getGraphTabValue("apy")}</h6>
								</div>
							</div> */}
						</div>
					</div>
				</div> 
				<div style={{  }}>
				<div className="">
					{getChart()}
				</div>
				</div>
				
				<div className="rc-footer">
					<div className="row align-items-center rc-footer-row g-0">
						<div className="col-xl-5">
							<div className="rc-footer-left">
								{timeFrameButtons}
							</div>
						</div>
						{currentTimeFrame=="custom"?<>
						<div className="col-xl-7">
							
								<div className="rc-footer-right">
									<h6>Date Range</h6>
									<label>
										From
										<ReactDatePicker  selected={startDate} onChange={(date) => checkStartDate(date)} className="form-control input-text"/>
									</label>
									<label>
										To
										<ReactDatePicker  selected={endDate} onChange={(date) => checkEndDate(date)} className="form-control input-text"/>
									</label>
									<button onClick={()=>clickUpdateChart()}><img src="/images/arrow-right.svg" /></button>
								</div>
						</div>
						</>:<></>}
					</div>
				</div>
			</div>
								</div>
								</Loading>
     </div>
	</>:<>
	<PremiumAccess name="Report"></PremiumAccess>
	</>}
    
     
    </ContentContainer>
  );
};

export default connect(null,{getReports,getBotList,getStockList})(Report);
