import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MARGIN_MODE, MARGIN_MODE_RENT } from "../../../../../actions/create-bot-type";
import BooleanView from "../../BooleanView";

const MarginModeModule = (props)=>{
    const {t,newBot,editing,onChange,rent} = props;
    const dispatch = useDispatch()
    const marginModeChanged = (val) => {
        dispatch({
          type: rent?MARGIN_MODE_RENT: MARGIN_MODE,
          payload: val,
        });
      };
    return(
        <>
        <BooleanView
            title={t("margin-mode")}
            firstButton="isolated"
            secondButton="cross"
            selected={newBot.marginMode}
            onSelect={marginModeChanged}
            //tutorial={<TutorialPopup content={t("orderType")} />}
            />
        </>
    )
}
export default MarginModeModule

