import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SpotOverview from "../dashboard/SpotOverview";
import PortfolioChart from "../dashboard/PortfolioChart";
import ChartTimeFrames from "../dashboard/ChartTimeFrames";
import DistributionPanel from "./DistributionPanel";
import { currencyFormat } from "../../utils/Utils";
import { getPortfolio } from "../../actions/portfolio-action";

const PortfolioOverview = (props)=>{

    const {t,coins,getPortfolio} = props;
    const [showGraph, setShowGraph] = useState(false);
    const [timeframe, setTimeframe] = useState("1M");
    const toggleGraph = () => {
        setShowGraph(!showGraph);
      };
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    useEffect(() => {
        getPortfolio(timeframe, selectedExchangeId);
    }, [timeframe, selectedExchangeId]);
    const {
        trading_volume,
        portfolio_value,
        portfolio_changed,
      } = useSelector((state) => state.portfolio);
    const changeUp = portfolio_changed && portfolio_changed > 0;
    const currency = useSelector((state) => state.settings.currency);
    return (
        <>
            <div className="dashboard-newuser-area">
								<div className="newuser-earning-area">
									<div className="newuser-cash-content">
										<button><img src="/images/icon-portfolio.svg" alt="cash"/></button>
										<div>
											<span>Portfolio Value</span>
											<h5>{currencyFormat(portfolio_value && portfolio_value.toFixed(2) || "0")} {currency && currency.name}</h5>
										</div>
									</div>
									<div className="newuser-cash-content">
										<button><img src="/images/updown-icon.svg" alt="cash"/></button>
										<div>
											<span>1M Change</span>
											
                                            <h5>{portfolio_changed && portfolio_changed.toFixed(2) || "0"}% {changeUp?<img className="ct-arrow2" src="/images/arrow-up.svg" alt=""/>:<img className="ct-arrow2" src="/images/arrow-down-1.svg" alt=""/>}</h5>
            
										</div>
									</div>
									<div className="newuser-cash-content">
										<button><img src="/images/quity-icon-white.svg" alt="cash"/></button>
										<div>
											<span>1M Trading Volume</span>
											<h5>{currencyFormat(trading_volume && trading_volume.toFixed(2) || "0")} {currency && currency.name}</h5>
										</div>
									</div>
								</div>
							</div>
        </>
    );

}
export default connect(null,{getPortfolio})(PortfolioOverview)