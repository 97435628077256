import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT,
  SIGN_UP_ERROR,
  SIGN_UP_REDIRECT,
  SIGN_UP_SUCCESS,
  RESET_SIGNUP,
  RESET_SIGNIN,
  UPDATE_PASS_ERROR,
  UPDATE_PASS_START,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_RESET,
  GET_OTP_ERROR,
  GET_OTP_RESET,
  GET_OTP_START,
  GET_OTP_SUCCESS,
  RESET_PASS_ERROR,
  RESET_PASS_START,
  RESET_PASS_SUCCESS,
  RESET_PASS_RESET,
  UPDATE_SUBSCRIPTION,
  SIGN_IN_START,
  SIGN_UP_START,
  VERIFY_OTP_SUCCESS,
  GA_CONFIRM,
} from "../actions/types";
const initialState = {
  token: null,
  errorMessage: null,
  user: {},
  updatePassSuccess: false,
  updatePassError: null,
  updatePassStart: false,
  getOtpStart: false,
  getOtpSuccess: false,
  getOtpError: null,
  resetPassStart: false,
  resetPassSuccess: false,
  resetPassError: null,
  tempEmail: null,
  signInStart: false,
  signUpStart: false,
  gaConfirmed: false,
};
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_START:
      return {
        ...state,
        signInStart: true,
        errorMessage: null,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: payload.access_token,
        user: payload.user,
        errorMessage: null,
        signInStart: false,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        token: null,
        errorMessage: payload,
        signInStart: false,
      };
    case RESET_SIGNIN:
      return {
        ...state,
        errorMessage: null,
        signInStart: false,
      };
    case SIGN_OUT:
      return {
        ...state,
        token: null,
        errorMessage: null,
      };
    case SIGN_UP_START:
      return {
        ...state,
        signUpStart: true,
        signUpError: null,
      };
    case SIGN_UP_ERROR:
      console.log("error sign up");
      return {
        ...state,
        signUpError: payload,
        signUpSuccess: null,
        signUpRedirect: null,
        signUpStart: false,
      };
    case RESET_SIGNUP:
      console.log("reset sign up");
      return {
        ...state,
        signUpError: null,
        signUpSuccess: null,
        signUpRedirect: null,
        signUpStart: false,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpError: null,
        signUpSuccess: true,
        signUpStart: false,
        signUpRedirect: null,
        token: payload.access_token,
        user: payload.user,
      };
    case SIGN_UP_REDIRECT:
      return {
        ...state,
        signUpError: null,
        signUpSuccess: null,
        signUpRedirect: true,
        signUpStart: false,
      };
    case UPDATE_PASS_START:
      return {
        ...state,
        updatePassStart: true,
        updatePassError: null,
        updatePassSuccess: false,
      };
    case UPDATE_PASS_SUCCESS:
      return {
        ...state,
        updatePassStart: false,
        updatePassError: null,
        updatePassSuccess: true,
      };
    case UPDATE_PASS_ERROR:
      return {
        ...state,
        updatePassStart: false,
        updatePassError: payload,
        updatePassSuccess: false,
      };
    case UPDATE_PASS_RESET:
      return {
        ...state,
        updatePassStart: false,
        updatePassError: null,
        updatePassSuccess: false,
      };
    case GET_OTP_RESET:
      return {
        ...state,
        getOtpStart: false,
        getOtpSuccess: false,
        getOtpError: null,
      };
    case GET_OTP_START:
      return {
        ...state,
        getOtpStart: true,
        getOtpSuccess: false,
        getOtpError: null,
      };
    case GET_OTP_SUCCESS:
      return {
        ...state,
        getOtpStart: false,
        getOtpSuccess: true,
        getOtpError: null,
        tempEmail: payload,
      };
    case GET_OTP_ERROR:
      return {
        ...state,
        getOtpStart: false,
        getOtpSuccess: false,
        getOtpError: payload,
      };
    case RESET_PASS_RESET:
      return {
        ...state,
        resetPassStart: false,
        resetPassSuccess: false,
        resetPassError: null,
      };
    case RESET_PASS_START:
      return {
        ...state,
        resetPassStart: true,
        resetPassSuccess: false,
        resetPassError: null,
      };
    case RESET_PASS_SUCCESS:
      return {
        ...state,
        resetPassStart: false,
        resetPassSuccess: true,
        resetPassError: null,
      };
    case RESET_PASS_ERROR:
      return {
        ...state,
        resetPassStart: false,
        resetPassSuccess: false,
        resetPassError: payload,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        user: {
          ...state.user,
          user_subscription: payload,
        },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case GA_CONFIRM:
      return {
        ...state,
        gaConfirmed: true,
      };
    default:
      return state;
  }
};

export default authReducer;
