import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_FUND } from "../../../../actions/create-bot-type";
import { BotType } from "../../../../Const";
import { formatNumberToString, isLong, restrictAlphabets } from "../../../../utils/Utils";
import LeftRightForm from "./LeftRightForm";

const FundAllocationModule = (props)=>{
    const {t,newBot,editing,label} = props;
    const fund = newBot.initFund;
    const isSellBot = newBot.botType == BotType.sell
    const strategy = isSellBot?"Short":newBot.strategy
    const currentCoin = isLong(strategy)
        ? newBot.quoteCoin
        : newBot.baseCoin;
    const balances = useSelector((state) => state.bots.balances);
    const balance =
    balances && balances.find((item) => item.coin === currentCoin);
    const dispatch = useDispatch()
    const fundChanged = (val) => {
        dispatch({
          type: CHANGE_FUND,
          payload: val,
        });
    };
    useEffect(() => {
        if (balance && !editing) {
          fundChanged(balance.free);
        }
      }, [balance]);
    
    return(
        
      <LeftRightForm
        left={t(label)}
        right={<><label data-text={currentCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={fund}
            onChange={(e) => {
              fundChanged(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
          {t("available")}: {formatNumberToString(balance && balance.free)}{" "}
          {currentCoin}
        </p>
      </>}
      />
    )
}
export default FundAllocationModule

