import React, { useEffect } from "react";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import { restrictAlphabets } from "../../../../utils/Utils";
const BbExitSetting = (props) => {
  const { indicator, updateIndicator, isLong ,t} = props;
  const [upperLong, bindUpperLong, setUpperLong] = useInput(indicator.value3);
  const [period, bindPeriod, setPeriod] = useInput(indicator.period_num, 0);
  const [lowerShort, bindLowerShort, setLowerShort] = useInput(
    indicator.value2
  );
  useEffect(() => {
    updateIndicator({
      ...indicator,
      value3: upperLong,
      period_num: period,
      value2: lowerShort,
    });
  }, [upperLong, period, lowerShort]);

  useEffect(() => {
    setUpperLong(indicator.value3);
    setPeriod(indicator.period_num);
    setLowerShort(indicator.value2);
  }, [indicator]);
  return (
    <div>
      {isLong ? (
        <>
          <h6>{t("long-strategy")}</h6>
          <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("upper-band-dev")}</label></div>
          <div> <input type="number" {...bindUpperLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      ) : (
        <>
          <h6>{t("short-strategy")}</h6>
          <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("lower-band-dev")}</label></div>
          <div> <input type="number" {...bindLowerShort} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      )}
      <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("period")}</label></div>
          <div> <input type="number" {...bindPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
    </div>
  );
};

export default BbExitSetting;
