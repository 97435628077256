let websocket;
const connect = (pairs, callback) => {
  console.log("New Binance connect");
  const validPairs = pairs.map((el) => `${el.from}${el.to}`.toUpperCase());
  websocket = new WebSocket("wss://stream.binance.com:9443/ws");
  websocket.addEventListener("message", (ev) => {
    const data = JSON.parse(ev.data);
    if (data.id == null) {
      const tickers = data
        .map(adaptTicker)
        .filter((el) => validPairs.includes(el.s));
      callback(tickers);
    }
  });
  websocket.addEventListener("open", () => {
    const sub = {
      method: "SUBSCRIBE",
      params: ["!ticker@arr"],
      id: 1,
    };
    websocket.send(JSON.stringify(sub));
  });
};

const adaptTicker = (ticker) => {
  return {
    s: ticker.s,
    c: ticker.c,
    p: ticker.p,
    P: ticker.P,
    v: ticker.v,
    l: ticker.l,
    h: ticker.h,
  };
};

const disconnect = () => {
  websocket && websocket.close();
  websocket = null;
  console.log("Disconnected Binance");
};

export default { connect, disconnect };
