import React, { useEffect, useState } from "react";
import AffiliateLayout from "../common/AffliateLayout";
import "./MyReferrals.scss";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAffiliatePointHistory,
  postAffiliateFriendDelete,
} from "../../../actions/affiliate-action";
import { ReferType } from "../../../Const";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import ResendMail from "./ResendMail";
import { toast } from "react-toastify";
import usePagination from "../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { hideEmail } from "../../../utils/Utils";
import { t } from "i18next";

const MyReferrals = (props) => {
  const { getAffiliatePointHistory, postAffiliateFriendDelete } = props;
  const history = useHistory();
  const [setLoading, bindLoading] = useLoading(false);
  const [setShowResendMail, bindShowResendMail] = useLoading(false);
  const [email, setEmail] = useState("");
  const [itemRange, bindPagination] = usePagination(0, 10);
  const totalPoints = useSelector((state) => state.affiliate.totalPoints) || 0;
  const pointHistories =
    useSelector((state) => state.affiliate.pointHistories) || [];
  useEffect(() => {
    getAffiliatePointHistory();
  }, []);

  const clickResend = (email) => {
    setEmail(email);
    setShowResendMail(true);
  };

  const clickDelete = (id) => {
    setLoading(true);
    postAffiliateFriendDelete(id)
      .then(() => {
        setLoading(false);
        toast.success("Item deleted");
        getAffiliatePointHistory();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const items = pointHistories.slice(...itemRange).map((item, index) => {
    let content;
    let status = item.status;
    switch (item.type) {
      case ReferType.TYPE_POINT_SOCIAL:
        content = "Social Post";
        status = "Verified";
        break;
      case ReferType.TYPE_POINT_SENT_MAIL:
        content = item.email;
        break;
      default:
        content = hideEmail(item.email);
        break;
    }
    return (
      <tr key={index}>
        <td>{item.date}</td>
        <td>{content}</td>
        <td>{status}</td>
        <td>{item.point}</td>
        <td>
          {item.type === ReferType.TYPE_POINT_SENT_MAIL && (
            <>
              <button
                className="btn-action"
                onClick={() => clickResend(item.email)}
              >
                {t("resend")}
              </button>{" "}
              |{" "}
              <button
                className="btn-action"
                onClick={() => clickDelete(item.id)}
              >
                {t("delete")}
              </button>
            </>
          )}
        </td>
      </tr>
    );
  });
  return (
    <Loading {...bindLoading}>
      <AffiliateLayout>
        <div className="my-referrals">
          <div className="left">
            <p className="header1">
              {t("your-curr-points")}:{" "}
              <span className="header2">{totalPoints}</span>
            </p>
            <table>
              <thead>
                <tr>
                  <th>{t("date")}</th>
                  <th>{t("email")}/ID</th>
                  <th>{t("status")}</th>
                  <th>{t("points")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
            </table>
            <div className="mt-4">
              <CustomPagination
                {...bindPagination}
                totalCount={pointHistories.length}
              />
            </div>
          </div>
          <div className="right">
            <div>
              <button
                className="btn"
                onClick={() => history.push("/my-referrals/redeem")}
              >
                {t("redeem")}
              </button>
              <button
                className="btn history-btn"
                onClick={() => history.push("/my-referrals/history")}
              >
                {t("history")}
              </button>
            </div>
            <img src="/images/hand1.png" />
          </div>
        </div>
        <ResendMail
          bindVisibility={bindShowResendMail}
          setLoading={setLoading}
          email={email}
        />
      </AffiliateLayout>
    </Loading>
  );
};

export default connect(null, {
  getAffiliatePointHistory,
  postAffiliateFriendDelete,
})(MyReferrals);
