import React from "react";
//import "./ExitBotIndicatorView.scss";
import {CHANGE_TAKE_PROFIT} from "../../../../actions/create-bot-type";
import { connect , useDispatch} from "react-redux";
import { Card,Accordion,Form, InputGroup } from "react-bootstrap";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { Trans } from "react-i18next";
import LeftRightForm from "../modules/LeftRightForm";

const PriceExitView = (props) => {
  const { newBot,t } = props;
  const isBuy = newBot.strategy=='Long';
  const side = isBuy ? t("sell") : t("buy");
  const actionTrigger = isBuy ? t("more") : t("less");
  const dispatch = useDispatch();
  const changePriceExit = (val) => {
    dispatch({
      type: CHANGE_TAKE_PROFIT,
      payload: val,
    });
  };
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
  }
  return (
    <>
            <LeftRightForm 
              left={ <Form.Label>
                <Trans i18nKey={"price-cond"}>{{side}} {{actionTrigger}}</Trans>
                </Form.Label>}
              right={ <label data-text={newBot.quoteCoin} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.takeProfit==0?"":newBot.takeProfit}
                      onChange={(e) => {
                        changePriceExit(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                  className="input-text input-gp-tex"
                ></Form.Control>
            </label>}
            ></LeftRightForm>
    </>
  );
};

export default connect(null)(PriceExitView);
