import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useHistory } from "react-router-dom";
import "./SignUpSuccess.scss";
import ContentContainer from "../common/ContentContainer";

const SignUpSuccess = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  }, []);
  return (
    <>
      <ContentContainer >
      <div className="dashboard-content">
        <Row className="justify-content-center signup-success">
          <Col className="signup-success-col">
            <Card>
              <Card.Body className="text-center">
                <h4 className="text-center">Your account has been verified!</h4>
                <p>Redirecting...</p>
                <i
                  className="far fa-check-circle my-3"
                  style={{ fontSize: 150 }}
                ></i>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </div>
      </ContentContainer>
    </>
  );
};

export default SignUpSuccess;
