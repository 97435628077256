import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const PremiumAccess  = (props)=>{
    const {t,} = props
    return(
      <div className="dashboard-newuser-area">
       <div className="bot-create-area">
								<div className="bot-create-item">
									<h3>This {props.name} feature is only available to Premium and Professional users</h3>
									<p></p>
                  <p>Upgrade your subscription to gain access to this feature and enjoy exclusive benefits</p>
									<Link to={'/setting?loc=2'}><button>Subscribe Now</button></Link>
								</div>
							</div>
      </div>
    )
}

export default PremiumAccess;