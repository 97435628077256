import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { BotType, DcaType, SUB_TIER, TimeWindow } from "../../../../Const";
import { getTvTimeframe } from "../../../../utils/TvUtils";
import TVChartContainer from "../../TVChartContainer";
import { checkSub, isPremium } from "../../../../utils/SubUtils";
import { CHANGE_BACKTEST_CUSTOM, CHANGE_TIME_FRAME, CHANGE_TRASACTION_FEE, SET_APPLY_CURRENT_DEAL } from "../../../../actions/create-bot-type";
import { resetBotConfig, runBackTest } from "../../../../actions/create-bot-action";
import { GET_BACKTEST_SUCCESS } from "../../../../actions/types";
import { restrictAlphabets } from "../../../../utils/Utils";
import useLoading from "../../../hooks/useLoading";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import BotPerformance from "../backtest/BotPerformance";
import CreateBotSection from "./CreateBotSection";
import CustomBackTest from "../backtest/CustomBackTest";
import CustomCheckbox from "./CustomCheckbox";

const BackTestSection = (props)=>{
    const {resetBotConfig,runBackTest} = props;
    const TimeFrame_1D = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const TimeFrame_1H = ["1D", "1W", "1M", "2M", "3M", "6M"];
    const TimeFrame_15m = ["1D", "1W", "2W", "4W", "5W", "6W"];
    const proTImeframe = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const Frequencies = {
        15: "15",
        60: "60",
        120: "120",
        240: "240",
        1440: "1D",
      };
      const FrequenciesPro = {
        1:"1",
        5: "5",
        15: "15",
        60: "60",
        120: "120",
        240: "240",
        1440: "1D",
      };
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const newBot = useSelector((state) => state.createBot.newBot);
    const [isCustomInterval, setIsCustomInterval] = useState(false);
    const [setLoading, bindLoading] = useLoading(false);
    const baseCoin = newBot.baseCoin;
    const quoteCoin = newBot.quoteCoin;
    const frequency = newBot.frequency;
    const strategy = newBot.strategy;
    const isPercentDca = newBot.dcaType === DcaType.sellUp || newBot.dcaType === DcaType.buyDown
    const arbitrageBot = (newBot.botType==BotType.inter_arbitrage || newBot.botType==BotType.intra_arbitrage);
    const pair = newBot.pair;
    const reloadTV = useSelector((state) => state.bots.reloadTV);
    const [timeFrames, setTimeFrames] = useState(TimeFrame_1D);
    const [interval, setInterval] = useState(timeFrames[timeFrames.length - 1]);
    const subscription = useSelector(
        (state) => state.auth.user.user_subscription
      );
    const { subPremium, subTier } = checkSub(subscription);
    const frequencyVal= (subPremium && (subTier === SUB_TIER.pro || subTier === SUB_TIER.master))?FrequenciesPro:Frequencies;
    const formatDecimal = (value,decimals) =>{
      if(value=="")
      return
      return Number(Number(value).toFixed(decimals));
    } 
    const timeFrameButtons = timeFrames.map((item, index) => {
      const buttonClass = ` ${
        (item == interval &&  !isCustomInterval)? "pacp-active" : ""
      }`;
      return (
        <button key={index} className={buttonClass} onClick={() => clickInterval(item)}>{item}</button>
       
      );
    });
    useEffect(() => {
        switch (frequency) {
          case 15:
            let timeframeFreq = subPremium?proTImeframe:TimeFrame_15m;
            setTimeFrames(timeframeFreq);
            setInterval(timeframeFreq[timeframeFreq.length - 1]);
            break;
          case 60:
            let timeframeFreq1 = subPremium?proTImeframe:TimeFrame_1H;
            setTimeFrames(timeframeFreq1);
            setInterval(timeframeFreq1[timeframeFreq1.length - 1]);
            break;
          default:
            setTimeFrames(TimeFrame_1D);
            setInterval(TimeFrame_1D[TimeFrame_1D.length - 1]);
        }
      }, [frequency]);
      const clickInterval = (val) => {
        setIsCustomInterval(false)
        setInterval(val);
      };
      const changeTimeFrame = (val) => {
        dispatch({
          type: CHANGE_TIME_FRAME,
          payload: val,
        });
      };
      useEffect(() => {
        changeTimeFrame(TimeWindow[interval]);
      }, [interval]);
      const changeBacktestTimeframe = (val) => {
        setIsCustomInterval(true)
        if(frequency <=15 && val > 180){
          changeTimeFrame(180);
          return false;
        }
        changeTimeFrame(val);
      };
      useEffect(() => {
        window.bot = { strategy, orders: [] };
        return () => {
          resetBotConfig();
          dispatch({
            type: GET_BACKTEST_SUCCESS,
            payload: null,
          });
        };
      }, []);
    
      const setApplyCurrentDeal = (val) => {
        dispatch({
          type: SET_APPLY_CURRENT_DEAL,
          payload: val,
        });
      };
      const setCustomBacktest = (val) => {
        dispatch({
          type: CHANGE_BACKTEST_CUSTOM,
          payload: val,
        });
      };
      const changeTransactionFee = (val) => {
        console.log(val)
        console.log(newBot.transactionFee)
        dispatch({
          type: CHANGE_TRASACTION_FEE,
          payload: val,
        });
       
        
      };
      const clickBackTest = () => {
        setLoading(true);
        runBackTest()
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(t(err));
          });
      };
    return(<>
       <div className="ba-asset-middle-chart-item" style={{ height: "525px" }}>
            <TVChartContainer
            pairId={pair.id}
            pricePrecision={newBot.pricePrecision}
            symbol={`${baseCoin}/${quoteCoin}`}
            interval={frequencyVal[frequency]}
            reload={reloadTV}
            timeframe={getTvTimeframe(interval)}
          />
      </div>
      <div className="ba-asset-chart-pag">
          {timeFrameButtons}
      </div>
      <div className="ba-asset-backtest-area">
          <div className="row">
              <div className="col-lg-6">
                  <div className="ba-asset-backtest-left">
                  {(isPremium(subscription) && (newBot.botType !=BotType.grid && newBot.botType !=BotType.sell && !arbitrageBot)) && 
                          <label>
                          {t("backtest-from")}    
                          <input
                                type="number"
                                value={newBot.timeFrame}
                                onChange={(e) => {
                                  changeBacktestTimeframe(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                  restrictAlphabets(e);
                                }}
                                className=""
                              />
                            {t("backtest-days-ago")}
                          </label>}
                          <label>
                            {t("transaction-fee")}
                            <Form.Control
                                        as="input"
                                        type="number"
                                        value={formatDecimal(newBot.transactionFee?newBot.transactionFee:"",2)}
                                        onChange={(e) => {
                                          changeTransactionFee(e.target.value);
                                        }}
                                        onKeyPress={(e) => {
                                          restrictAlphabets(e);
                                        }}
                                        className=""
                                      ></Form.Control>
                              %
                            </label>
                  </div>
              </div>

              {(isPremium(subscription)  && !arbitrageBot) ?
                    <div className="col-lg-6">
												<div className="ba-asset-backtest-right">
                            <CustomCheckbox 
                                val={newBot.backtestCustom} 
                                checked={newBot.backtestCustom==1} 
                                callback={setCustomBacktest}
                                label={"Use Custom Date"}
                                id={'sccc'}
                                >
                            </CustomCheckbox>
												</div>
                        {newBot.backtestCustom &&
                            <CustomBackTest newBot={newBot}></CustomBackTest>
                            }
										</div>
                    :<></>}
                <div className="col-lg-12">
                  <div className="backtest-btn">
                  {(newBot.botType !=BotType.grid && newBot.botType !=BotType.sell && !arbitrageBot) && 
                    <button onClick={clickBackTest}>{t("run-backtest")}</button>
                  }
                  </div>
                </div>
          </div>
        </div>
               
               
           
        {/* <Col xl={12}>
          <BotPerformance
            baseCoin={baseCoin}
            quoteCoin={quoteCoin}
            strategy={strategy}
            t={t}
          />
        </Col> */}
        <Loading {...bindLoading} />
        </>)
}

export default connect(null,{resetBotConfig,runBackTest}) (BackTestSection);