import React, { useMemo, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import CustomPagination from "../common/CustomPagination";
import "./PaperSpotTab.scss";
import useInput from "../hooks/useInput";
import usePagination from "../hooks/usePagination";
import { roundNumber } from "../../utils/Utils";
import DefaultPagination from "../common/DefaultPagination";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../bots/new-bot/modules/CustomCheckbox";

const PaperSpotTab = (props) => {
  const {t}= useTranslation()
  const { totalSpot } = props;
  const coins = props.coins || [];
  const [rangeItems, bindPagination] = usePagination(0, 10);
  const [hideSmall, setHideSmall] = useState(false);
  const [search, bindSearch] = useInput("");
  const filterCoins = useMemo(() => {
    return coins
      .filter(
        (el) =>
          !search.length || el.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((el) => !hideSmall || (hideSmall && el.balance_usd > 10));
  }, [search, hideSmall, coins]);
  const coinItems = filterCoins.slice(...rangeItems).map((el, id) => {
    return (
      <tr key={id} className="text-left">
        <td className="text-left">
        <div  className="coin-data">
              <img src={el.image} width={20} /> 
              <h6>{el.coin}</h6>
              {/* <span>{el.name}</span> */}
          </div>
        </td>
        <td className="total-data"><h6>{(el.total || 0).toFixed(6)}</h6></td>
        <td className="avaiable-data"><h6>{(el.free || 0).toFixed(6)}</h6></td>
        <td className="reserved-data"><h6>{(el.used || 0).toFixed(6)}</h6></td>
        <td className="usdt-data"><h6>{(el.balance_usd || 0).toFixed(6)}</h6></td>
      </tr>
    );
  });
  return (
    <>
    <div className="paperwallet-table-content">
    <div className="pwtc-heading">
      <div>
        <ul>
          <li><span>Total Balance</span></li>
          <li><h6>{(totalSpot || 0).toFixed(6)} BTC</h6></li>
        </ul>
       
        <br></br>
        <CustomCheckbox 
          val={hideSmall} 
          checked={hideSmall} 
          callback={setHideSmall}
          label={t("Hide Small Balance")}
          id={'hidesmall'}
          >
      </CustomCheckbox>
      </div>
      <Form.Control placeholder="Search Coin" {...bindSearch} className="input-text mb-2"/>
      
    </div>
    <div className="paperwallet-main-table">
      <div className="open-table-content otc Portfolio-table-content">
        <table className="table paperwallet-table">
          <thead>
          <tr valign="top">
            <th style={{width: "30%"}}>{t("Coin")}</th>
            <th>{t("Total Amount")}</th>
            <th>{t("Available")}</th>
            <th>{t("Reserved")}</th>
            <th>{t("USDT Balance")}</th>
        </tr>
          </thead>
          <tbody>{coinItems}</tbody>
       
          
         
        </table>
      </div>
    </div>
   
  </div>
   <DefaultPagination {...bindPagination} totalCount={filterCoins.length} />
   </>
    
    
    
  );
};

export default PaperSpotTab;
