import React, { useState } from "react";

import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Summary from "./Summary";
import Trades from "./Trades";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BotPerformance = (props) => {
  const {t} = useTranslation()
  const newBot = useSelector((state) => state.createBot.newBot);
  const baseCoin = newBot.baseCoin;
  const quoteCoin = newBot.quoteCoin;
  const strategy = newBot.strategy;
  const lowerCaseStrategy = (strategy && strategy.toLowerCase()) || "long";
  const [activeTab, setActiveTab] = useState(0);
  const changeTab = (event,index)=>{
    event.preventDefault();
    setActiveTab(index);
  }
  const tabTitles = [t("summary"), t("trades")];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      <a
          onClick={(e) => changeTab(e,index)}
          key={index}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
          href="#a"
        >
          {item}
        </a>
    );
  });
  const tabs = [
    <Summary baseCoin={ quoteCoin} t={t}/>,
    <Trades
      baseCoin={quoteCoin || "USDT"}
      quoteCoin={baseCoin || "BTC"}
      isLong={lowerCaseStrategy == "long"}
      t={t}
    />,
  ];
  return (
    <div className="ba-summary-area">
    <div className="bot-detail-table-area">
        <ul className="skltbs-tab-group">
          {tabHeaders}
          </ul>
    </div>
    <div className="mt-4">{tabs[activeTab]}</div>
    
    </div>
  );
};

export default BotPerformance;
