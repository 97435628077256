const tslImportAdapter = (item) => {
  return {
    id: item.id,
    triggerAt: item.trigger_percentage,
    stopAt: item.sl_percentage,
    type: item.sl_type,
  };
};

export default tslImportAdapter;
