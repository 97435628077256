export const ANALYTIC_ID = "UA-185659622-1";

// --- Stage --- //
// export const STRIPE_ID = {
//   premium_monthly: "price_1LUoPRAlY9R4Wa1pA1bRGcqI",
//   premium_quarterly: "price_1LUoPRAlY9R4Wa1pnedEpbFN",
//   premium_yearly: "price_1LUoPRAlY9R4Wa1pQVlcuvQt",
//   pro_monthly: "price_1LUoQqAlY9R4Wa1pCHtuCRyL",
//   pro_quarterly: "price_1LUoQqAlY9R4Wa1pHXgn0WtD",
//   pro_yearly: "price_1LUoQqAlY9R4Wa1pLQpfu5ht",
//   master_monthly: "price_1M0KiIAlY9R4Wa1plVLG6Jsc",
//   master_quarterly: "price_1M0KiIAlY9R4Wa1pG7omlB0s",
//   master_yearly: "price_1M0KiIAlY9R4Wa1pBgotPSuf",
// };
// export const STRIPE_PUBLIC =
//   "pk_test_51HWbXkAlY9R4Wa1pNNTATqAnlvpeeXyJ9I85E1B0QYUsIUPs84z84uQM9pVzm7hesVq2w6KQTmxu3tIXoEf0adRl0043MvXJnW";
// export const PAYPAL_CLIENT_ID =
//   "AdyEXlxP7oRawx9Np29KNveKNda4sUXi1knI1lGJjQA-Ko-Awlgdsxwg_MokjKMKOAwwDbgaw6oKinRb";
// export const PAYPAL_ID = {
//   premium_monthly: "P-9CX32107U7680961CMCXR63I",
//   premium_quarterly: "P-38W09204WV216413JMMWT4EY",
//   premium_yearly: "P-76B0268383535172GMCXR7HQ",
//   pro_monthly: "P-74U918610A5719342MCXR7TY",
//   pro_quarterly: "P-9Y575476XH237445JMMWT4QA",
//   pro_yearly: "P-52E196148S466304WMCXR74Y",
//   master_monthly: "P-74U918610A5719342MCXR7TY",
//   master_quarterly: "P-9Y575476XH237445JMMWT4QA",
//   master_yearly: "P-52E196148S466304WMCXR74Y",
// };
// export const host = "https://web-stage.cryptohero.ai/api";
// export const ws = "wss://app-stage.cryptohero.ai";
// export const klineUrl = "https://web-stage.cryptohero.ai/api/klines";




//--- Live --- //
export const STRIPE_ID = {
  premium_monthly: "price_1IuWxRAlY9R4Wa1pchgrXRDG",
  premium_quarterly: "price_1LUnDJAlY9R4Wa1pG9ZkAt1p",
  premium_yearly: "price_1IuWukAlY9R4Wa1pOAQwISOn",
  pro_monthly: "price_1IvcbmAlY9R4Wa1pxvL5DApe",
  pro_quarterly: "price_1LUnEpAlY9R4Wa1pBnHSKSBY",
  pro_yearly: "price_1IvcdiAlY9R4Wa1pG5XGksN8",
  master_monthly: "price_1M0KfdAlY9R4Wa1pgdxe87LR",
  master_quarterly: "price_1M0KfdAlY9R4Wa1pEzwvyqJK",
  master_yearly: "price_1M0KfdAlY9R4Wa1psjl23mun",
};

export const STRIPE_PUBLIC =
  "pk_live_51HWbXkAlY9R4Wa1pGcd1HUcYxePvFEvdo7kktBbLHVdzXzwdk6huL41PTb6KhV8ozYtEv4I95lWZ5yJ4U1u4AXDx00yKzkVoI2";
export const PAYPAL_CLIENT_ID =
  "AXiiuwDKX_IHOPCeiHfTMvvrMl56Q3W9M2UHYWY6cuA0ycOe21qknO-UToK1LZmSFjEIWgXQ56Xjrwfk";

export const PAYPAL_ID = {
  premium_monthly: "P-69E3315724530063GMCVSD2A",
  premium_quarterly: "P-50680998VR8168005ML54DXQ",
  premium_yearly: "P-7KK090381E9269200MCVSDLA",
  pro_monthly: "P-5BE38801UU119194EMCXWZXA",
  pro_quarterly: "P-9DW05622MM5817459ML54ECQ",
  pro_yearly: "P-47546154KU7387117MCXW2MI",
  master_monthly: "P-9TP03342XK691740CMNSNADI",
  master_quarterly: "P-4FB345541S678824LMNSNAYA",
  master_yearly: "P-33N08304UW649823EMNSNBFY",
};
export const host = "https://alb-api.cryptohero.ai/api";

export const ws = "wss://app-stage.cryptohero.ai";
export const klineUrl = "https://alb-api.cryptohero.ai/api/klines";


// --- Local --- //
// export const STRIPE_ID = {
//   premium_monthly: "price_1LUoLvAlY9R4Wa1pW57ogfAf",
//   premium_quarterly: "price_1LUoLvAlY9R4Wa1pvUCJUKq8",
//   premium_yearly: "price_1LUoLvAlY9R4Wa1p63xnrDzq",
//   pro_monthly: "price_1LUoNVAlY9R4Wa1pasDLUkbq",
//   pro_quarterly: "price_1LUoNVAlY9R4Wa1prrRYzPlY",
//   pro_yearly: "price_1LUoNVAlY9R4Wa1poNKXsdh0",
//   lite_monthly: "price_1LUoKeAlY9R4Wa1pKWnFH3Pt",
//   lite_quarterly: "price_1LUoKeAlY9R4Wa1pjHhKUvSz",
//   lite_yearly: "price_1LUoKeAlY9R4Wa1pzJ3t9LGo",
// };
// export const STRIPE_PUBLIC =
//   "pk_test_51HWbXkAlY9R4Wa1pNNTATqAnlvpeeXyJ9I85E1B0QYUsIUPs84z84uQM9pVzm7hesVq2w6KQTmxu3tIXoEf0adRl0043MvXJnW";
// export const PAYPAL_CLIENT_ID =
//   "AQ58lfbo34MvYYhVOxBiLjziBQvAnOO5bN4JUdj9nxjiOKGkDn_03xqacAC8DWEj2vxCUh_8RSm6X_Pp";
// export const PAYPAL_ID = {
//   lite_monthly: "P-72J29231CS4680612ML7BARA",
//   lite_quarterly: "P-5M205282KR506954EML7BBEI",
//   lite_yearly: "P-4GF757066W302213UMMHP3GI",
//   premium_monthly: "P-76B0268383535172GMCXR7HQ",
//   premium_quarterly: "P-76B0268383535172GMCXR7HQ",
//   premium_yearly: "P-76B0268383535172GMCXR7HQ",
//   pro_monthly: "P-74U918610A5719342MCXR7TY",
//   pro_quarterly: "P-72J29231CS4680612ML7BARA",
//   pro_yearly: "P-52E196148S466304WMCXR74Y",
// };
// export const host = "https://cryptohero-api.test/api";
// export const ws = "wss://api-stage.stockhero.ai";
// export const klineUrl = "https://cryptohero-api.test/api/klines";
