import { useSelector } from 'react-redux';
import {
    SET_LIBRARY_IMAGE,
    SELECT_LIBRARY_IMAGE,
    SET_DISPLAY_TEMPLATE_IMAGE,
    GET_RENT_BOTS_AVAILABLE,
    SET_PREVIEW_UPLOAD_IMAGE,
    RESET_TEMPLATE_MODAL,
    SET_BOT_SELECTED,
    BOT_TEMPLATE_TITLE,
    BOT_TEMPLATE_DESCRIPTION,
    BOT_SUBSCRIPTION_MONTHLY,
    BOT_SUBSCRIPTION_QUARTERLY,
    BOT_SUBSCRIPTION_HALFYEARLY,
    BOT_SUBSCRIPTION_ANNUAL,
    BOT_SUBSCRIPTION_ONETIME,
    SET_UPLOADED_IMAGE,
    SHOW_IMAGE_MODAL,
    SET_BOT_TEMPLATE_EDIT
} from '../actions/types';
const initState = {
    libraryImage:'bot1.png',
    selectLibraryImage:'bot1.png',
    displayTemplateImg:'',
    image:null,
    newTemplate:{
    },
    botSelected:{},
    show:false
}
const createTemplateReducer = (state = initState,{type,payload})=>{
    switch (type) {
        case SET_LIBRARY_IMAGE:
            return {
                ...state,
                libraryImage: 'bot'+payload+'.png',
                newTemplate:{
                    ...state.newTemplate,
                    default_image: 'bot'+payload+'.png'
                },
            };
        case SET_UPLOADED_IMAGE:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    upload_image: payload
                },
            };
        case SELECT_LIBRARY_IMAGE:
            return {
                ...state,
                selectLibraryImage: payload,
            };
        case SET_DISPLAY_TEMPLATE_IMAGE:
            return {
                ...state,
                displayTemplateImg: payload,
                newTemplate:{
                    ...state.newTemplate,
                    imageThumbnail: payload
                },
            };
        case GET_RENT_BOTS_AVAILABLE:
            return {
                ...state,
                botsAvailable: payload,
            };
        case SET_PREVIEW_UPLOAD_IMAGE:
            return {
                ...state,
                uploadPreview: payload,
            };
        case RESET_TEMPLATE_MODAL:
            return {
                ...state,
                newTemplate:{},
                botSelected:{},
                displayTemplateImg:'',

            };
        case SET_BOT_SELECTED:
            return {
                ...state,
                botSelected: payload,
                newTemplate:{
                    ...state.newTemplate,
                    botId: payload.id
                },
            };
        case BOT_TEMPLATE_TITLE:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    title: payload
                },
            };
        case BOT_TEMPLATE_DESCRIPTION:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    description: payload
                },
            };
        case BOT_SUBSCRIPTION_MONTHLY:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    monthlyFee: payload
                },
            };
        case BOT_SUBSCRIPTION_QUARTERLY:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    quarterlyFee: payload
                },
            };
        case BOT_SUBSCRIPTION_HALFYEARLY:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    halfYearlyFee: payload
                },
            };
        case BOT_SUBSCRIPTION_ANNUAL:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    annualFee: payload
                },
            };
        case BOT_SUBSCRIPTION_ONETIME:
            return {
                ...state,
                newTemplate:{
                    ...state.newTemplate,
                    oneTimeFee: payload
                },
            };
        case SHOW_IMAGE_MODAL:
            return {
                ...state,
                show:payload
            };
        case SET_BOT_TEMPLATE_EDIT:
            return {
                ...state,
                newTemplate:payload
            };
        default:
            return state;
      }
}

export default createTemplateReducer;