import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import ToolTip from "../../help/ToolTip";
import TutorialPopup from "../../help/TutorialPopup"
import useInput from "../../hooks/useInput";
import { formatDate } from "../../../utils/Utils";

const TemplateInfoCard = (props)=>{
    const {template,t}=props
    const AuthorShortName = template.author_name && template.author_name.charAt(0).toUpperCase()
    const [performanceDuration, bindPerformanceDuration] = useInput(1);
    return (
        <>
            <div className="round-bg">
            
                <Col xs={12} className="no-padding">
                    <Row className="center-align" xs={12}>
                    <Col xs={12}>
                        <h5>{template.title} <span className={template.strategy=="Long"?"long":"short"}>{template.strategy}</span></h5>
                        <div className="d-flex justify-content-between">
                        <div className="mt-1"><span className="mp-label">By</span> <span className="text-value">{template.author_name}</span></div>
                        <div className="d-flex justify-content-between w-10">
                            <div className="view-count-div"><ToolTip content={t("num-of-views")}><span className="view-count flex-all-center"><i className="far fa-eye view-icon"></i><i className="view-count-number">{" "}{template.view_count}</i></span></ToolTip></div>
                            <div className="like-count-div"><ToolTip content={`${template.total_thumbs_up} ${template.total_thumbs_up > 1?t("renters-like-bot"):t("renter-like-bot")}`}><span className="flex-all-center"><i className="fas fa-heart like-icon red"></i><i className="view-count-number like-count">{" "}{template.total_thumbs_up}</i></span></ToolTip></div>
                            
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="mt-1"><span className="mp-label">{t("bot-listed-on")} {formatDate(template.created_at)}</span> </div>
                            <div className="mt-1"><span className="mp-label">{t("last-updated-on")} {formatDate(template.updated_at)}</span> </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                       
                       
                    </Col>
                    <hr></hr>
                    </Row>
                    <Row>
                        <Col xs={12} className="mb-4">
                           
                            <p>{template.description}</p>
                        </Col>
                        <hr></hr>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className="card-white mpd-card">
                                <div className="card-heading">
                                    <div className="cw-title">{t('perf-on-listing')}<TutorialPopup content={t("template-performance-help")} /></div>
                                    <hr></hr>
                                </div>
                                <div className="cw-body">
                                    <div className="d-flex justify-content-between">
                                        <div>APY</div>
                                        <div>{template.apy}%</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("performance")}</div>
                                        <div>{template.performance}%</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("w-l-ratio")}</div>
                                        <div>{template.wl_ratio}%</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("total-deals")}</div>
                                        <div>{template.total_deal}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("a-m-d")}</div>
                                        <div>{template.avg_monthly_deal}</div>
                                    </div>      
                                </div>
                                
                            </div>
                        
                        
                        </Col>
                        <Col lg={6}>
                            <div className="card-white mpd-card">
                                <div className="card-heading">
                                    <div className="cw-title">{t('live-trading-perfo')}<TutorialPopup content={t("live-trading-perfo-help")} /></div>
                                    <hr></hr>
                                </div>
                                <div className="cw-body">
                                    <div className="d-flex justify-content-between">
                                    <select className='form-control mt-3 mb-3 select-normal' {...bindPerformanceDuration}>
                                        <option value="1">All time</option>
                                        <option value="2">Last 3 months</option>
                                    </select>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("avg-w-l-ratio")}</div>
                                        <div>{performanceDuration==1?template.real_performances?.avg_wl:template.real_performances?.avg_wl_3_months}%</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>{t("total-deals")}</div>
                                        <div>{performanceDuration==1?template.real_performances?.total_deal:template.real_performances?.total_deal_3_months}</div>
                                    </div>
                                         
                                </div>
                                
                            </div>
                        
                        
                        </Col>
                    </Row>
                    
                    
                   
                </Col>
            </div>
        </>
    );
}
export default connect(null,{})(TemplateInfoCard)