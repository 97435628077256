import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const RentedListEmpty  = (props)=>{
    const {t,} = props
    return(
      <div className="dashboard-newuser-area">
       <div className="bot-create-area">
								<div className="bot-create-item">
									<h3>You have not rented any strategy</h3>
									<p>You can  visit our Strategy Marketplace to deploy proven strategies setup by other experienced users</p>
									<Link to={'/marketplace'}><button>Go to Strategy Marketplace</button></Link>
								</div>
							</div>
      </div>
    )
}

export default RentedListEmpty;