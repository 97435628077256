import React from "react";
import {
  CHANGE_BO_TYPE,
  CHANGE_FREQUENCY,
  CHANGE_ORDER_TYPE,
} from "../../../../actions/create-bot-type";
import BooleanView from "../BooleanView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import { useDispatch, useSelector } from "react-redux";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub, isMaster, isPro } from "../../../../utils/SubUtils";
import { Form, FormLabel, InputGroup } from "react-bootstrap";
import { formatNumberToString, getFreq, getFreqVal } from "../../../../utils/Utils";
import FundAllocationModule from "../modules/FundAllocationModule";
import OrderTypeModule from "../futures/modules/OrderTypeModule";
import { useEffect } from "react";
import FundAllocationIntraArbitrage from "./FundAllocationIntraArbitrage";
import LeftRightForm from "../modules/LeftRightForm";

const IntraArbTradeParamsView = (props) => {
  const { newBot ,t,editing} = props;
  const strategy = newBot.strategy
  const frequency = newBot.frequency;
  const fund = newBot.initFund;
  const dispatch = useDispatch();
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const FrequenciesProArbitrage = ["5m"];
  const FrequenciesPremiumArbitrage = ["5m"];
  const FrequencyValuesProArbitrage = [5];
  const FrequencyValuesPremiumArbitrage = [5];
  const tradingFreqValues = (isPro(subscription) || isMaster(subscription)) ? FrequencyValuesProArbitrage:FrequencyValuesPremiumArbitrage;
  const tradingFreqencies = (isPro(subscription) || isMaster(subscription)) ? FrequenciesProArbitrage:FrequenciesPremiumArbitrage;
  const frequencyChanged = (val) => {
    if(val){
      dispatch({
        type: CHANGE_FREQUENCY,
        payload: val,
      });
    }
  };
  useEffect(()=>{
    if(!editing)
    {
      dispatch({
        type: CHANGE_FREQUENCY,
        payload: 5,
      });
    }
  },[])
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE,
      payload: orderType,
    });
  };
  const formartFrequency = (value) => tradingFreqencies[value];
  return (
    <div>
      <div style={{ marginTop: 20 }}>
       <FundAllocationIntraArbitrage newBot={newBot} t={t} label="max-fund-allocation" editing={editing}></FundAllocationIntraArbitrage>
      </div>
      <LeftRightForm
      right={<FormLabel>{t("intra-arb-fund-msg")}</FormLabel>}
      />
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={tradingFreqValues.length - 1}
          value={tradingFreqValues.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(tradingFreqValues[value])}
          tutorial={<TutorialPopup content={t("trading-freq-intra-help")}  />}
        />
      </div>
      <div style={{ marginTop: 20 }}>
            <BooleanView
                title={t("order-type")}
                firstButton="limit"
                secondButton="market"
                selected={newBot.tradeOrderType}
                onSelect={orderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
            </div>
    </div>
  );
};

export default IntraArbTradeParamsView;
