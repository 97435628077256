import React from "react";
import { Modal } from "react-bootstrap";
import SendEmail from "../refer-now/SendEmail";

const ResendMail = (props) => {
  const { setLoading, email, bindVisibility } = props;
  return (
    <Modal {...bindVisibility}>
      <Modal.Header closeButton>
        <Modal.Title>Resend Email</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingLeft: 50 }}>
        <SendEmail
          setLoading={setLoading}
          email={email}
          done={() => bindVisibility.onHide()}
          isResend={true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ResendMail;
