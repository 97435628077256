import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { NEW_USER_INTRO_POPUP } from "../../../actions/types";
import CustomCheckbox from "../../bots/new-bot/modules/CustomCheckbox";
import { useTranslation } from "react-i18next";
import { hideIntroPopup } from "../../../actions/setting-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Tab4 = (props)=>{
    const {hideIntroPopup} = props
    const dispatch = useDispatch()
    const {t}= useTranslation()
    const takeTour = useSelector((state) => state.settings.takeTour);
    //const [dontShowAgain,setDontShowAgain] = useState(1);
    const history = useHistory()
    const exitWizard = ()=>{
        history.push("/bots");
        dispatch({
          type:NEW_USER_INTRO_POPUP,
          payload:0
        })
        hideIntroPopup(0)
    }
    return(<>
            <h3>Step 4: Success</h3>
            <br/>
            <p>Congratulations! You have successfully created your very first bot. You can find this bot in the Bots page under the Active tab. You can view or edit the bot’s settings as well.</p>
            <br/>
            <p>You can also visit our Bots Marketplace to deploy proven strategies setup by other experienced users.</p>
            <br/>
            <br/><br/>
            <ul>
                <li><Link to="/marketplace" className="qtrblue-btn pointer">Go to Bots Marketplace</Link></li>
                <li><a  className="qtrtrans-btn pointer" onClick={()=>exitWizard()}>Exit Quick Start</a></li>
            </ul>
    </>)
}
export default connect(null,{hideIntroPopup}) (Tab4);