const tslExportAdapter = (item, type) => {
  return {
    id: item.id,
    trigger_percentage: item.triggerAt,
    sl_percentage: item.stopAt,
    sl_type: type,
  };
};

export default tslExportAdapter;
