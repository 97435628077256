import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import { connect, useSelector } from "react-redux";
import { getBotProfit } from "../../actions/bot-action";
import { formatDate } from "../../utils/Utils";


const ProfitChart = (props)=>{
    const {data,getBotProfit} = props;
    const {t,timeframe,selectedExchangeId}=data;
    useEffect(() => {
        getBotProfit(timeframe, selectedExchangeId);
    }, [selectedExchangeId, timeframe]);
    const profit = useSelector((state) => state.bots.profit);
    const histories = profit && profit.histories;
    const times =
    (histories &&
      histories.map((item) =>
        formatDate(item.date, undefined, "DD MMM HH:mm")
      )) ||
    [];
    const profits = (histories && histories.map((item) => item.profit)) || [];
    // console.log("min",profits && profits.min())
    // console.log("max",profits && profits.max())
    const ratios = (histories && histories.map((item) => item.wl_ratio)) || [];
    
    let series = [
        {
            name: t("profit"),
            data: profits
        },
        {
            name: t("w-l-ratio"),
            data: ratios
        }
    ]
    let options =  {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          }
        },
        tooltip: {
            fillSeriesColor: true,
            x: {
                show: false,
            }
        },
        colors: ['#07dff7', '#66DA26'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        markers: {
            size: 5,
        },
        legend: {
            show: false,
            labels:{
                colors:"#fff"
            }
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: false,
            }
          },
        },
        xaxis: {
          categories: times,
          labels: {
            style: {
                colors: '#fff',
            },
          }
        },
        yaxis: [
            {
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: "#fff"
              },
              labels: {
                style: {
                  colors: "#fff"
                },
                formatter: (value) => { return value.toFixed(0) },
              },
              min:Math.min(...profits),
              max:Math.max(...profits)
            },
            {
              opposite: true,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: "#fff"
              },
              labels: {
                style: {
                  colors: "#fff"
                },
                formatter: (value) => { return value.toFixed(0) },
              },
              min:Math.min(...ratios),
              max:Math.max(...ratios)
              
            }
          ]
      }
    return(
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
    )
}

export default connect(null,{getBotProfit}) (ProfitChart)