import React, { useState } from "react";
import AffiliateLayout from "../common/AffliateLayout";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { getAffiliatePointRedeemHistory } from "../../../actions/affiliate-action";

const ReferralsHistory = (props) => {
  const { getAffiliatePointRedeemHistory } = props;
  const history = useHistory();
  const pointRedeemHistories =
    useSelector((state) => state.affiliate.pointRedeemHistories) || [];

  useState(() => {
    getAffiliatePointRedeemHistory();
  }, []);

  const items = pointRedeemHistories.map((item, index) => (
    <tr key={index}>
      <td>{item.date}</td>
      <td>{item.amount}</td>
      <td>{item.amount / 50}</td>
      <td>{item.identifier}</td>
      <td>{item.tnx_id}</td>
    </tr>
  ));
  return (
    <AffiliateLayout>
      <div className="my-referrals">
        <div className="left">
          <p className="header1">Points Redemption History</p>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Points</th>
                <th>DOGE</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </div>
        <div className="right">
          <div className="text-left">
            <button
              className="btn"
              onClick={() => history.push("/my-referrals/redeem")}
            >
              Redeem
            </button>
          </div>
          <img src="/images/hand1.png" />
        </div>
      </div>
    </AffiliateLayout>
  );
};

export default connect(null, { getAffiliatePointRedeemHistory })(
  ReferralsHistory
);
