import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const BooleanView = (props) => {
  const className = props.customClass?props.customClass:""
  const isFirst =
  (props.selected && props.selected.toLowerCase()) === (props.firstButton && props.firstButton.toLowerCase());
  const firstVariant = isFirst ?" success selected" : "success";

  const secondVariant = isFirst ? "danger" : "danger selected";

  return (
    <div className="d-flex align-items-center">
      <div className="col-lg-4">
				<div className="ba-asset-left">
            <h6>{props.title} {props.tutorial}</h6>
        </div>
        </div>
        <div className="col-lg-8">
						<div className="ba-asset-Strategy-btn">
              {props.firstButton!="" && <a className={`${className} ${firstVariant}`} onClick={() => props.onSelect(props.firstButton)}>{props.firstButton.toUpperCase()}</a>}
              <a className={`${className} ${secondVariant}`} onClick={() => props.onSelect(props.secondButton)}>{props.secondButton.toUpperCase()}</a>
            </div>
        </div>
    </div>
  );
};

export default BooleanView;
